import { createStore } from "redux";
import reducer from "../reducers";

import { saveState } from "../../shared/utils/localStorage";


const store = createStore(reducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() );

store.subscribe(() => {
    const newState = {
        token: store.getState().state.token,
        refresh_token: store.getState().state.refresh_token,
        client_id: store.getState().state.client_id,
        email: store.getState().state.email,
        employer: store.getState().state.employer,
        contracts: store.getState().state.contracts,
        firstView: store.getState().state.firstView,
        choices: store.getState().state.choices,
    };
    saveState(newState);
});

export default store;
