import React from 'react';

// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';


// ICONS
import BellIcon from 'assets/ico/bell-ico.png';
import { TooltipBasic, SubmitButton } from 'shared/components';

interface LiquidationFinalProps {
}

export const LiquidationFinal: React.FC<LiquidationFinalProps> = () =>{

    return(
        <Container>
            <Row>
                <Col>
                    <h5 className='text-center' >Iniciaste el proceso de liquidación y desafiliación de:</h5>
                    <p className='text-center text-10 color-symplifica' >pepito pimpollo</p>
                </Col>
            </Row>
            <Row>
                <Col className='summary-form-left col-12 justify-content-center  align-items-center flex-column d-flex' >
                    <Row className='d-flex flex-column justify-content-center align-items-center' >
                        <Col className='col-12 d-flex align-items-center justify-content-center' >
                            <img src={BellIcon} alt='bell' />
                        </Col>
                        <Col className='col-12 ' >
                            <p className='red-symplifica text-12 my-0 text-center' >Importante</p>
                            <p className='my-0 text-center' >Debitaremos de forma automática el pago de la última planilla de seguridad social (retiro)</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className='text-12 text-center my-3' >Hemos enviado a tu correo los documentos de liquidación, hazlos firmar al realizar el pago de la liquidación y entrega una copia.</p>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' >
                    <TooltipBasic 
                        placement='top'
                        text='texto'
                        question
                    />
                    <a className='select-employee-link my-2' href='#k' >Conoce el debido proceso de liquidación de contrato</a>
                </Col>
            </Row>
            <Row>
                <Col className='p-2 bg-grey-symplifica rounded-lg' >
                    <p className='color-symplifica mb-0' >Recuerda que...</p>
                    <p className='text-10 mb-0' >La ley indica la realización de un examen medico de egreso para verificar estado físico del empleado</p>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <SubmitButton
                        onClick={()=>{}}
                        block
                        variant='dark'
                        text='Adquirir examenes de egreso'
                    />
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <SubmitButton
                        onClick={()=>{}}
                        block
                        className='bg-grey-symplifica'
                        variant='link'
                        text='Volver al inicio'
                    />
                </Col>
            </Row>
        </Container>
    )
}