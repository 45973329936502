import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ token, ...rest }) => {

    return (
        <Route
            {...rest}
            render={({ location }) => {
                const pathUrl = token ? '/home' : '/auth';
                return (
                    <Redirect
                        to={{
                            pathname: pathUrl,
                            state: { from: location }
                        }}
                    />
                );
            }}
        />
    );
}

const mapStateToProps = ({ state }) => {
    return {
        token: state.token
    }
}


export default connect(mapStateToProps, null)(ProtectedRoute);