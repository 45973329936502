import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// CONTAINER
import {RegisterContainer} from 'shared/containers';

// COMPONENTS
import { SubmitButton, TooltipBasic, InputToggle } from 'shared/components';
import { CardPayment } from './components';

// IMAGES
import Ilust from 'assets/ico/ico-card.png';

// API
import {PaymentRepository} from 'shared/repository';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

// CONSTS
import { REGISTER_ADD_METHODS_PAYMENT } from 'shared/constants/urls';

const PaymentMethods = ({client_id, history}) =>{

    const { showNotify, handleNotify } = useNotifyState();
    const [methods, setMethods] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [is_automatic, setIsAutomatic] = useState(false);

    // =======================================================
    /// Consulta los datos de los métodos de pago registrados
    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const metodos = await PaymentRepository.getInstrumentsData(client_id);
            setMethods(metodos.instruments);
            setIsAutomatic(metodos.debit);
            setIsLoading(false);
            return;
        }
        fetchData();
        return;
    }, [client_id]);

    // =======================================================
    /// Redirección a página para crear un método de pago
    // =======================================================
    const addPaymethod = () => history.push(REGISTER_ADD_METHODS_PAYMENT);

    // =======================================================
    /// send a request to update the automatic debit flag in data base
    // =======================================================
    const updateAutomaticDebit = async () => {
        setIsLoading(true);
        const respuesta = await PaymentRepository.updateAutomaticDebit(client_id, is_automatic);
        
        if (respuesta.success)
            handleNotify('success', respuesta.message);
        else
            handleNotify('danger', respuesta.message);

        setIsLoading(false);
    }

    return (
        <RegisterContainer 
            title='Métodos de pago' 
            info={{title: 'Recuerda', subtitle: 'Ten en cuenta que, luego de registrar tu método de pago, tu banco puede tardar hasta 3 días hábiles en autorizar su inscripción para el débito.'}} 
            showNotify={showNotify}
        >
            <Container>
                <Row className='my-5 d-flex justify-content-center' >
                    <Col md={12} lg={6}>
                        <h4 className='color-symplifica text-center' >¡Ya casi está todo listo para empezar a gestionar tus empleados con Symplifica!</h4>
                    </Col>
                </Row>
                {
                    methods.length > 0 
                        ?
                            <Row>
                                <Col>
                                    <Row className='border-b-symplifica' >
                                        <Col>
                                            <p className='my-0 color-symplifica' >Mis métodos de pago</p>
                                        </Col>
                                        <Col className='d-flex justify-content-end pb-2' >
                                            <SubmitButton text='+' style={{ height: 30, width: 30, padding: 0 }} onClick={addPaymethod} />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            {
                                                methods.map((item, i) => <CardPayment key={`paymethod_${i}`} {...item} /> )
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Col className='d-flex  align-items-center justify-content-center' >
                                    <img width={300}  src={Ilust} alt='card' />
                                </Col>
                                <Col className='d-flex flex-column align-items-center justify-content-center' >
                                    <p className='text-center color-symplifica' >Mis metodos de pago</p>
                                    <p className='text-center' >No tienes métodos de pago, agrega uno para poder realizar</p>
                                    <SubmitButton disabled={isLoading} spinner={isLoading} text='Agregar' className='w-50' variant='dark' onClick={addPaymethod} />
                                </Col>
                            </Row>
                }
                <Row className='d-flex justify-content-center align-item-center' >
                    <Col md={4} lg={3}>
                        
                        <p className='my-0' ><TooltipBasic text='' /> {' '} Débito Automático</p>
                    </Col>
                    <Col md={4} lg={1}>
                        <InputToggle name="automatic_debit" value={is_automatic} onChange={event => setIsAutomatic(event.target.checked)} />
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center mb-5 mt-3' >
                    <Col md={4} lg={4}>
                        <SubmitButton text='Guardar' block disabled={isLoading} spinner={isLoading} onClick={updateAutomaticDebit} />
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        client_id: state.client_id
    };
}

const PaymentMethodsPage = connect(mapStateToProps, null)(PaymentMethods);

export { PaymentMethodsPage };