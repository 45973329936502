import React from 'react'
import { Button, Image } from 'react-bootstrap'
import ReportItem from './ReportItem'

const DeleteModal = ({ employees, reports, sendToMachine, stateMachine, setDeleteCounter }:
  {
    stateMachine: { context: { deleted: { id: string } } },
    reports: [{
      type: string,
      start_date: string,
      contract: string,
      end_date: string,
      id: string,
    }],
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    sendToMachine?: (arg0: {}) => void,
    setDeleteCounter: (arg0) => void
  }
) => {
  let report = reports.filter(report => report.id === stateMachine.context.deleted.id)[0]
  let employee = employees.filter(employee => employee.id === report.contract)[0]
  return (
    <div style={{ paddingTop: 130 }}>
      <button
        onClick={() => sendToMachine && sendToMachine({ type: "CANCELDELETION" })}
        style={{
          backgroundColor: "white", border: "none", margin: "0px", padding: "0px",
          position: "absolute", top: 0, right: 0, display: "grid", justifyItems: "end",
          width: "30%"
        }}>
        <Image
          style={{
            zIndex: 3,
            position: "fixed",
            top: "0",
            right: "0",
            gridColumnStart: 1, gridColumnEnd: 2,
            gridRowStart: 1, gridRowEnd: 2,
            paddingRight: "5%", paddingTop: "5%"
          }}
          src="/images/reports/equis.png" fluid />
        <Image
          style={{
            zIndex: 2,
            gridColumnStart: 1, gridColumnEnd: 2,
            gridRowStart: 1, gridRowEnd: 2
          }}
          src="/images/reports/path@1x.png" fluid />
      </button>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
        <img src='/icons/reports/img-trash.svg' width="80%" alt="" />
        <h5 className="color-symplifica" style={{ textAlign: "center" }}>
          ¿Estás seguro de que deseas eliminar esta novedad?
      </h5>
        <ReportItem
          {...report}
          isDeletable={false}
          employeeName={`${employee?.employee?.first_name ? employee.employee.first_name : ""} ${employee?.employee?.last_name ? employee.employee.last_name : ""}`}
          leftInfo={{ top: "Fecha inicio", bottom: report.start_date }}
          rightInfo={{ top: "Fecha fin", bottom: report.end_date }}
        />
        <Button
          onClick={() => {
            sendToMachine && sendToMachine({ type: "ACCEPTDELETION" })
            setDeleteCounter(-1)
          }}
          style={{
            color: 'white',
            backgroundColor: '#FD5C5C',
            borderRadius: 50,
            width: '80%',
            marginBottom: 15,
            marginTop: 15
          }}>
          Eliminar
        </Button>
        <Button
          onClick={() => sendToMachine && sendToMachine({ type: "CANCELDELETION" })}
          style={{
            color: '#565656',
            backgroundColor: '#f5f5f5',
            borderRadius: 50,
            width: '80%'
          }}>
          Cancelar
        </Button>
      </div>
    </div>
  )
}

export default DeleteModal