export const SERVER = process.env.REACT_APP_SERVER;
export const PAGE_LINK = process.env.REACT_APP_URL_HOME;
export const PRICE_PAGE_LINK = process.env.REACT_APP_URL_PRICE;
export const LOGIN_SERVER = process.env.REACT_APP_LOGIN_SERVER;
export const DASHBOARD_PAGE = process.env.REACT_APP_DASHBOARD_PAGE;
export const APP_DASHBOARD_PAGE = process.env.REACT_APP_DASHBOARD_PAGE;
export const APP_HELP_CENTER = process.env.REACT_APP_HELP_CENTER;
export const HELP_CENTER_REPORTS_WHAT_ARE_REPORTS= "https://symplificasupport.zendesk.com/hc/es/articles/360001735412--Qu%C3%A9-son-las-novedades-"
export const HELP_CENTER_REPORTS_WHICH_TO_REPORT= "https://symplificasupport.zendesk.com/hc/es/articles/360001756671--Qu%C3%A9-tipos-de-novedades-puedo-reportar-"
export const HELP_CENTER_REPORTS_HOLIDAYS_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001543471--Qu%C3%A9-son-las-vacaciones-y-como-se-pagan-"
export const HELP_CENTER_REPORTS_PERMISSION_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001757291--Qu%C3%A9-es-una-licencia-remunerada-permiso-"
export const HELP_CENTER_REPORTS_INCAPACITY_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001757091--Qu%C3%A9-es-una-incapacidad-m%C3%A9dica-"
export const HELP_CENTER_REPORTS_EXTRA_TIME_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001735852--Qu%C3%A9-son-las-horas-extra-"
export const HELP_CENTER_REPORTS_LOANS_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001527492--Se-pueden-hacer-pr%C3%A9stamos-a-mis-empleados-"
export const HELP_CENTER_REPORTS_WORKED_DAYS_KNOW_MORE= APP_HELP_CENTER
// export const HELP_CENTER_REPORTS_WORKED_DAYS_KNOW_MORE= "https://symplificasupport.zendesk.com/hc/es/articles/360001757291--Qu%C3%A9-es-una-licencia-remunerada-permiso-"



export const config = {
    accessControlUrl: LOGIN_SERVER,
    clientUrl: SERVER,
    idWebClient: process.env.REACT_APP_ID_WEB_CLIENT,
    keyWebClient: process.env.REACT_APP_KEY_WEB_CLIENT
}