import React from 'react';

const TyC = ()=> {
    return (
        <div style={{height: '80vh', overflowY:'scroll'}}>
            <p>
                Esta página establece los "Términos y Condiciones" (“Términos”), que constituyen un contrato entre usted (“Usuario”) y SYMPLIFICA SAS (“SYMPLIFICA”), en que se regula el acceso de los Usuarios a este sitio web, su contenido y los productos o servicios a los que tienen acceso a través de www.symplifica.com (el “Sitio”). Por favor lea estos Términos cuidadosamente, antes de acceder a este Sitio o utilizar los servicios del mismo.
                <br/><br/>
                Mediante el uso de este Sitio, el Usuario está indicando su aceptación a estos Términos. Si el Usuario no acepta los Términos establecidos aquí, no deberá utilizar el Sitio ni sus servicios.
                <br/><br/>
                SYMPLIFICA puede modificar estos Términos en cualquier momento. El Usuario debe visitar esta página periódicamente para revisar la última versión vigente de estos Términos, debido a que los mismos son obligatorios.
                <br/><br/>
                Las palabras "Usted" o "Usuario" tal como se usan aquí, se refieren a todas las personas naturales o jurídicas o entidades de cualquier naturaleza que accedan a este Sitio por cualquier razón.
                <br/><br/>
                Únicamente podrán registrarse en este Sitio y adquirir los productos y servicios ofrecidos por SYMPLIFICA, personas mayores de 18 años.
                <br/><br/>
                USO DE ESTE SITIO WEB
                Derechos de Propiedad: Este Sitio y sus respectivas aplicaciones móviles son de propiedad de SYMPLIFICA. SYMPLIFICA autoriza al Usuario a consultar, revisar y usar el material que se encuentra en este Sitio, únicamente para su uso personal y no comercial. El contenido de este Sitio, incluyendo pero sin limitarse a: los textos, gráficas, imágenes, logotipos, íconos, software (incluyendo cualquier software descargable), productos, servicios, marcas comerciales, nombres comerciales y cualquier otro material o información (el "Material"), está protegido bajo las leyes de derechos de autor, leyes de propiedad industrial y otras leyes aplicables.
                <br/><br/>
                A excepción de cualquier contenido enviado por Usted, todos los Materiales contenidos en el Sitio son propiedad de SYMPLIFICA o sus clientes, proveedores, aliados comerciales o terceros afiliados. Usted se compromete a no vender ni modificar el Material en manera alguna, ni distribuirlo para propósitos comerciales; tampoco podrá licenciar, copiar, publicar, exhibir, transmitir, crear trabajos derivados, o de otro modo utilizar los Materiales en formas distintas a las autorizadas expresamente en los presentes Términos. El uso no autorizado del Material puede constituir una violación de las leyes colombianas o extranjeras sobre derechos de autor, leyes de propiedad industrial u otras leyes.
                <br/><br/>
                Usos Prohibidos: De manera general, se prohíbe el uso de este Sitio con el fin de transmitir, distribuir, almacenar o destruir Material: (i) en violación de cualquier ley aplicable o regulación, (ii) de manera que se infrinjan las leyes sobre derechos de autor, propiedad industrial, secretos comerciales o cualquier otro derecho de propiedad intelectual de terceros, o de manera que se viole la privacidad, publicidad u otros derechos personales de terceros, o (iii) en forma que sea difamatoria, obscena, amenazante o abusiva.
                <br/><br/>
                Específicamente, SYMPLIFICA prohíbe a los Usuarios realizar las siguientes actividades en el uso del Sitio:
                <br/><br/>
                Borrar o revisar cualquier Material incluido en el Sitio por SYMPLIFICA o cualquiera otra persona o entidad, sin la debida autorización.
                Acceder a información que no esté dirigida o autorizada a dicho Usuario o acceder a servidores o cuentas a los cuales el Usuario no está autorizado a acceder.
                Intentar probar la vulnerabilidad de un sistema o red sin la debida autorización o violar las medidas de seguridad o autenticación.
                Usar cualquier elemento, diseño, software o rutina para interferir o intentar interferir con el funcionamiento adecuado de este Sitio o cualquier actividad que sea llevada a cabo en el Sitio.
                Intentar interferir con los servicios prestados a otro Usuario, servidor o red, incluyendo pero sin limitarse a, el envió de virus a través del Sitio.
                Intentar descifrar, decompilar o desensamblar cualquier software comprendido en el Sitio o que de cualquier manera haga parte del Sitio.
                Enviar correo electrónico no solicitado, incluyendo promociones y/o publicidad de productos o servicios, o cualquier programa de computador o herramienta con la intención de dañar, interferir, interceptar o apropiarse de cualquier sistema, datos o información.
                En general, incluir o colocar en el Sitio información falsa, inexacta, incompleta o engañosa. La violación de cualquier sistema o red de seguridad puede resultar en responsabilidades civiles y penales. SYMPLIFICA investigará la ocurrencia de hechos que puedan constituir violaciones a lo anterior y cooperará con cualquier autoridad competente que lo requiera.
                Responsabilidad por el Uso del Sitio: El Sitio y el Material se ponen a disposición de los Usuarios en el estado en que se encuentren. SYMPLIFICA no garantiza que el Sitio o cualquier Material disponible en el Sitio, funcionarán ininterrumpidamente o libre de errores, ni que los errores o defectos serán corregidos, ni que se encuentren libres de virus u otros mecanismos dañinos.
                <br/><br/>
                El Sitio podrá incluir información u opiniones sobre diversos temas, incluyendo sin limitarse a, temas laborales y de seguridad social. El uso del Sitio y del Material, al igual que las decisiones que el Usuario adopte con base en este Sitio y el Material, incluyendo las informaciones, opiniones o cualquier otro contenido del Sitio, se hacen bajo su propio y exclusivo riesgo. Nada de lo incluido en este Sitio constituye asesoría suministrada por SYMPLIFICA; recomendamos que todas las decisiones que usted *quiera adoptar con base en el Material incluido en el Sitio, sean consultadas con sus propios asesores.
                <br/><br/>
                En ningún caso SYMPLIFICA, sus proveedores o cualquier persona mencionada en el Sitio será responsable por daños de cualquier naturaleza, resultantes del uso o la imposibilidad de usar el Sitio o el Material.
                <br/><br/>
                Si del uso del Sitio o del Material resulta la necesidad para el Usuario de contratar un servicio de reparación o mantenimiento a sus equipos o información o de reemplazar sus equipos o información, SYMPLIFICA no será responsable por los costos que ello implique.
                <br/><br/>
                Links a Otros Sitios Web: Este Sitio puede contener links o vínculos a otros sitios web de terceras entidades o personas. Estos links o vínculos se suministran para su conveniencia únicamente, y SYMPLIFICA no respalda, recomienda o asume responsabilidad alguna sobre el contenido de los sitios web de terceros. Si el Usuario decide acceder a través de los links o vínculos a los sitios web de terceros, lo hace bajo su propio riesgo. La correspondencia que intercambie con estos terceros, o sus acuerdos o negocios con los mismos, son de exclusiva responsabilidad del Usuario. Usted acepta y reconoce que SYMPLIFICA no será responsable de cualquier pérdida o daño en que Usted incurra como resultado de su relación con tales terceros.
                <br/><br/>
                REGISTRO, CUENTA Y CONTRASEÑA
                Usted se compromete a que la información que proporcione a SYMPLIFICA al registrarse y crear una cuenta, y durante el tiempo en que mantenga una cuenta, será verdadera, precisa y completa, y que la actualizará cuando sea necesario. Cuando se registre, se le pedirá que cree una contraseña. Usted es el único responsable de mantener la confidencialidad de su cuenta y contraseña y de restringir el acceso a su computadora, y usted está de acuerdo en aceptar la responsabilidad de todas las actividades que ocurran bajo su cuenta y por todos los usos de su registro en el Sitio, sean o no autorizados por Usted.
                <br/><br/>
                Usted acuerda notificar a SYMPLIFICA de cualquier uso no autorizado de su cuenta y contraseña, tan pronto tenga conocimiento de tal hecho.
                <br/><br/>
                COMUNICACIONES
                Los Usuarios aceptan que los medios proporcionados en el Sitio, según estos se sujetan a lo establecido en la Política de Privacidad del Sitio, y el correo electrónico no encriptado, son medios hábiles para el flujo e intercambio de documentación, información y, en general, como canal de comunicación entre los Usuarios, cuando sea permitido, y entre éstos con Symplifica para la prestación de los servicios. Es responsabilidad del cliente revisarlo periódicamente e informar a Symplifica en caso de cambiarlo, inhabilitarlo o cerrarlo.
                <br/><br/>
                El Usuario exime a Symplifica de cualquier responsabilidad por la interceptación o acceso a los correos electrónicos por personas no autorizadas, así como de cualquier daño o perjuicio que pueda producirse al Usuario como consecuencia del incumplimiento de las Condiciones de Uso, virus informáticos, fallos en la red o supuestos análogos, salvo que fuera por causa imputable a Symplifica.
                <br/><br/>
                SUSCRIPCIÓN A SYMPLIFICA
                Suscripciones: SYMPLIFICA, a través de su plataforma ("Plataforma"), ofrece una suscripción mensual ("Suscripción") que permite a los Usuarios de este Sitio que la adquieran, acceder a servicios de administración de su nómina de empleados a través de la Plataforma, incluyendo, sin limitarse a, la liquidación y administración de pago de salarios y otras obligaciones laborales, liquidación y administración de aportes a las entidades de Seguridad Social, facilitación de formatos de contratos laborales y otros documentos, y almacenamiento de su información e historial de actividades a través de la Plataforma, a la que podrá acceder a través de su cuenta.
                <br/><br/>
                Para utilizar su Suscripción debe tener acceso a Internet.
                <br/><br/>
                A través de la Plataforma, Usted puede acceder a los servicios de terceros, tales como, sin limitarse a, Operadores de Información de la Planilla Integrada de Liquidación y Aportes (PILA), entidades proveedoras de mecanismos de pago, centros para la realización de exámenes médicos, o empresas proveedoras de estudios de seguridad; SYMPLIFICA no es un proveedor de estos servicios, no opera ni es propietaria de los mismos.
                <br/><br/>
                SYMPLIFICA únicamente ofrece sus servicios de administración de nómina y otras obligaciones laborales, para los trabajadores del Usuario mayores de edad. En ningún caso se prestarán estos servicios para trabajadores menores de edad.
                <br/><br/>
                Como requisito para la Suscripción, es indispensable que todos los Usuarios firmen y suban a la Plataforma, el documento titulado “Mandato”, que se encuentra en el tablero de control al terminar el registro de trabajadores en el Sitio.
                <br/><br/>
                Su Suscripción es personal e intransferible. SYMPLIFICA no podrá ser utilizado con fines comerciales. Usted no podrá regalar, vender ni transferir de manera alguna, a otras personas, los servicios adquiridos por medio de su Suscripción.
                <br/><br/>
                Retracto: Dentro de los cinco (5) días hábiles contados a partir de la fecha de registro y pago de una Suscripción, Usted podrá ejercer el derecho de retracto, siempre y cuando no haya comenzado a utilizar los servicios de SYMPLIFICA dentro de dicho plazo. En el evento en que Usted haga uso de la facultad de retracto, se resolverá el contrato y SYMPLIFICA le deberá reintegrar el dinero que hubiese pagado, en los términos del artículo 47 de la Ley 1480 de 2011.
                <br/><br/>
                Suscripción, Ciclo Mensual, Facturación y Pago: SYMPLIFICA comenzará a efectuar el cobro mensual de su tarifa por cada trabajador ingresado a la Plataforma los días 25 de cada mes calendario (la “Fecha de Corte”), en la cual se liquida el valor del ciclo mensual siguiente, que se cobra por anticipado. Por lo tanto, a la finalización del Periodo Gratuito, se calculará la tarifa a cobrar al Usuario de manera proporcional a los días que falten hasta la siguiente Fecha de Corte, y se facturará junto con el cobro anticipado del siguiente ciclo mensual.
                <br/><br/>
                Cada ciclo mensual comienza el día siguiente a la Fecha de Corte. Usted debe informarnos un método de pago válido, tal como tarjeta de crédito o una cuenta corriente o de ahorros ("Forma de Pago"), para poder suscribirse a SYMPLIFICA (ver sección "Forma de Pago" de este capítulo). El pago de cada ciclo mensual se debitará por parte de SYMPLIFICA, en el momento en que el Usuario haga el pago de la nómina y/o los aportes de sus trabajadores, si esto ocurre entre los días 26 y 30 del mes.
                <br/><br/>
                Si no realiza ninguno de estos pagos en las fechas mencionadas. Symplifica debitará automáticamente el valor correspondiente al servicio de uso de la plataforma los días 10 de cada mes siguiente. Si pasados 3 meses, el usuario no realiza los pagos de seguridad social, Symplifica asumirá que ha desistido del uso del servicio y por tanto desactivará la cuenta del usuario. En el momento de ser desactivado Symplifica enviará un correo electrónico a la cuenta suministrada por el usuario con la información de desactivación y los cálculos de mora a la fecha. La afiliación a la seguridad social continuará vigente y será responsabilidad única del usuario ponerse al día o gestionar su desafiliación directamente con las entidades correspondientes.
                <br/><br/>
                Al ingresar y crear tu cuenta en Symplifica podrás autorizar a Symplifica para realizar el débito automático de los pagos de seguridad social y/o nómina. Symplifica efectuará el recaudo automático entre los días 25 y 5 del siguiente mes, realizando hasta 3 intentos de recaudo. Si pasados dichos intentos Symplifica no logra recolectar el dinero por factores ajenos a nuestra operación, el Usaurio será el responsable de ejecutar los trámites necesarios para el correcto pago, asumiendo las posibles moras y riesgos generadas.
                <br/><br/>
                Al ingresar y crear tu cuenta en Symplifica podrás autorizar a Symplifica para realizar el débito automático de los pagos de seguridad social y/o nómina. Symplifica efectuará el recaudo automático entre los días 25 y 5 del siguiente mes, realizando hasta 3 intentos de recaudo. Si pasados dichos intentos Symplifica no logra recolectar el dinero por factores ajenos a nuestra operación, el Usaurio será el responsable de ejecutar los trámites necesarios para el correcto pago, asumiendo las posibles moras y riesgos generadas.
                <br/><br/>
                Es responsabilidad del usuario reportar en el servicio web y/o App de Symplifica, oportunamente cualquier novedad para que el valor a debitar corresponda la realidad. La fecha máxima para reportar novedad es el día 12 para pago de nómina quincenal y el día 23 para pago de nómina mensual o de segunda quincena.
                <br/><br/>
                Cada ciclo de SYMPLIFICA es de un (1) mes de duración (un "Ciclo"), y se renovará automáticamente cada mes hasta que Usted cancele o termine su Suscripción. La Suscripción se entenderá cancelada en el momento en que el Usuario inactive su último trabajador en la Plataforma. Sin embargo, no habrá devolución del valor de la Suscripción por los ciclos mensuales ya iniciados.
                <br/><br/>
                Al comenzar su Suscripción, Usted nos autoriza a debitar la tarifa de su Ciclo inicial, y una cuota mensual recurrente a la tarifa vigente en ese momento, que puede cambiar de tiempo en tiempo, previa notificación que se dará en los canales autorizados por Symplifica como su correo electrónico.
                <br/><br/>
                El cobro de servicio del primer mes de SYMPLIFICA será proporcional al número de días que utilice la plataforma, comenzando desde la fecha de afiliación. Para esta proporción se asumirá que Symplifica trabaja bajo un calendario de 30 días.
                <br/><br/>
                La infraestructura utilizada por Symplifca para el manejo y custodia de la información, opera de acuerdo a las mejores prácticas de administración de información definidas, garantizando así altos estándares de seguridad.
                <br/><br/>
                Se hace expresa claridad que los pagos que por defecto o exceso realice el usuario, derivado de inclusión de información errada a simplifica, o como consecuencia o debido a alguna inexactitud, imprecisión, interpretación o diferencia que se presenta frente a la ley laboral existente, son de su única y exclusiva responsabilidad. Esto incluye cualquier cálculo erróneo que genere Symplifica para pagos de nómina, parafiscales o seguridad social por interpretación errónea del usuario o por errores al digitar la información.
                <br/><br/>
                Symplifica establecerá las fechas en las que los usuarios podrán ser validados y activados en la plataforma. El empleador podrá elegir la fecha de corte en la que desee empezar a usar el servicio de acuerdo a las opciones brindadas. Si existieran retrasos en envío de documentos o tomas de firmas por parte del usuario, podría no activarse en la fecha seleccionada, en este caso se correrá para la fecha siguiente propuesta por Symplifica. Una vez el usuario sea activado podrá disfrutar de los servicios contratados con Symplifica.
                <br/><br/>
                Forma de Pago: Usted puede modificar la información de su método de pago ingresando al Sitio o APP y a la Configuración de su cuenta. Es obligación de cada Usuario mantener actualizada y vigente su Forma de Pago. Si al momento en que deba hacerse un cobro a la Forma de Pago autorizada por Usted, no se logra hacer el cargo con éxito debido a la caducidad, la insuficiencia de fondos o cualquier otro motivo, Usted seguirá siendo responsable de todos los importes cobrados, y SYMPLIFICA estará autorizado a seguir facturando el valor de su cuota mensual o cualquier otra tarifa a que haya lugar. Si no podemos cobrar su cuota mensual a través de la Forma de Pago seleccionada por Usted, nos reservamos el derecho, pero no estamos obligados, a finalizar su Suscripción y/o el acceso a nuestro Sitio.
                <br/><br/>
                Cancelación o Terminación de la Suscripción: Para cancelar una suscripción, el Usuario deberá comunicarse con los canales de servicio al cliente designados por Symplifica. Esta comunicación podrá realizarse hasta 24 horas antes de iniciarse un nuevo Ciclo.
                <br/><br/>
                Otros Planes: Podemos ofrecer otros planes de suscripción, por ejemplo, planes especiales de promoción o planes de compromiso mínimo de varios meses; tales planes tendrán condiciones especiales que publicaremos en este Sitio al momento en que comencemos a ofrecerlos, y el Usuario que se suscriba a uno de tales planes especiales estará sujeto a las directrices adicionales de los mismos. Nos reservamos el derecho de modificar o suspender cualquiera de nuestros planes de suscripción ofrecidos.
                <br/><br/>
                Tarifas: Su cuota mensual de Suscripción cubre su acceso a los servicios descritos en el Capítulo V de estos Términos, y adicionalmente SYMPLIFICA cobrará una tarifa por cada transacción de pago (tales como pagos de nómina y aportes a seguridad social), que se haga a través de nuestra Plataforma. Su pago mensual no incluye productos o servicios adicionales que terceros puedan cobrar u ofrecer (por ejemplo, exámenes médicos, asesorías jurídicas y estudios de seguridad); si Usted adquiere uno de dichos productos o servicios, será su responsabilidad pagarlos.
                <br/><br/>
                Symplifica podrá cobrar un valor adicional por los siguientes conceptos:
                <br/><br/>
                - Por el proceso de afiliación de los beneficiarios de los empleados, estos deben estar sujetos al envió de los documentos y se realizará según las normas y canales que Symplifica disponga para su correcta afiliación. Symplifica solo realizará la afiliación de hijos, padres o cónyuges. Los demás beneficiarios deberán ser afiliados por el trabajador o por el empleador.
                <br/><br/>
                - Por el proceso de traslado de EPS (únicamente) de los empleados cuando el empleador lo requiera durante el proceso de afiliación o posterior a este, el proceso debe estar sujeto al envió de documentos y se realizará según las normas y canales que Symplifica disponga para su correcta gestión. Para este caso la empelada deberá enviar una carta firmada solicitando el traslado de entidad.
                <br/><br/>
                Nos reservamos el derecho a cambiar estas políticas y tarifas o a introducir tarifas adicionales. En caso de presentarse estos cambios, lo anunciaremos en este Sitio y/o mediante el envío de un correo electrónico a la dirección registrada por Usted, con un mínimo de 15 días calendario de antelación a la fecha en la cual entrarán en efecto las nuevas tarifas o políticas. Si Usted no cancela su Suscripción, por medio de los canales autorizados para ello (correo electrónico y/o número de contacto publicados en la Página) antes de la Fecha de Corte siguiente al anuncio de tales cambios, se entenderá que ha aceptado las nuevas tarifas o políticas.
                <br/><br/>
                Conductas Prohibidas a los Usuarios Suscriptores de SYMPLIFICA: Usted se obliga a no realizar los siguientes actos:
                <br/><br/>
                Acosar o amenazar a otros Usuarios de este Sitio;
                Hacerse pasar por otra persona, o tener acceso a la cuenta de otro Usuario sin el permiso de esa persona;
                Compartir su contraseña de SYMPLIFICA con otras personas;
                Terminación o Modificación por Decisión de SYMPLIFICA. Usted entiende y acepta que, en cualquier momento y sin previo aviso, con o sin causa, SYMPLIFICA puede: (i) cancelar, desactivar y/o suspender su cuenta o su Suscripción, por uso indebido de la misma, o su acceso al Sitio (o cualquier parte del mismo), o (ii) suspender, modificar o alterar cualquier aspecto, función o política del Sitio. Si determinamos que Usted ha violado estos Términos o de otra manera ha participado en el uso ilegal o indebido del Sitio, Usted no tendrá derecho a ningún reembolso. Usted acepta que SYMPLIFICA no será responsable ante Usted o cualquier tercero por cualquier terminación o modificación del servicio, independientemente de la razón de dicha cancelación o modificación.
                <br/><br/>
                INFORMACIÓN DE LOS USUARIOS
                La privacidad de su información es muy importante para SYMPLIFICA. Al aceptar estos Términos, el Usuario acepta que los datos personales aportados en el momento de su registro, o cualquier otro facilitado a SYMPLIFICA para su acceso a algunos de los servicios contenidos en el Sitio, sean utilizados conforme a las políticas y finalidades establecidas en la Política de Privacidad de SYMPLIFICA, que se incorpora a estos Términos y que Usted declara conocer y aceptar. Por favor, consulte la Política de Privacidad más abajo para más información.
                <br/><br/>
                Para la administración de nómina y mantener un historial de la relación laboral con sus empleados, SYMPLIFICA le ofrecerá el servicio de subir y almacenar cierta información en la Plataforma, tal como copias firmadas de contratos laborales, recibos de pago de nómina, liquidación de prestaciones, entre otros. El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados. Si Usted decide subir esta información a la Plataforma, de todos modos, deberá conservar copia de la misma e sus archivos personales. Usted podrá tener acceso a estos documentos, leerlos o imprimirlos a través de su cuenta de SYMPLIFICA; SYMPLIFICA no se hace responsable si la información o historial que conste en su cuenta, se encontrare incompleta debido a la falta por parte de Usted de subir los documentos pertinentes a la Plataforma.
                <br/><br/>
                En todo caso, SYMPLIFICA no se hace responsable por la pérdida de la información subida por Usted a la Plataforma, o por la imposibilidad de acceso a la misma, ya sea de forma temporal o permanente, debido a errores, defectos, virus, otros mecanismos dañinos o hechos de terceros.
                <br/><br/>
                PAGO DE LA NÓMINA Y APORTES A SEGURIDAD SOCIAL DE LOS EMPLEADOS DEL USUARIO
                Administración de Pagos de Nómina: SYMPLIFICA ofrece entre sus servicios, la administración de pagos de los salarios y otras obligaciones laborales de los empleados de sus Usuarios. Si Usted opta por utilizar este servicio y realizar los pagos a través de nuestra Plataforma, deberá seleccionar una Forma de Pago y realizarlos quincenal o mensualmente (según aplique), teniendo en cuenta lo siguiente:
                <br/><br/>
                Si Usted elige este servicio, deberá darnos autorización mediante la selección de la opción correspondiente al diligenciar y firmar o aceptar digitalmente el formato titulado "Mandato", que se encuentra en el tablero de control al terminar el registro de trabajadores en el Sitio; esto es un requisito para que SYMPLIFICA pueda realizar a nombre de los Usuarios, la administración de los recursos con que estos efectúen los pagos de nómina de sus empleados; en virtud de este mandato, SYMPLIFICA deberá destinar dichos dineros únicamente al pago de la nómina (salarios y otras obligaciones laborales), ajustándose estrictamente a las instrucciones dadas por Usted en calidad de mandante. Usted autoriza a que sus recursos ingresen primero a una cuenta bancaria de SYMPLIFICA, y a que desde ahí, con instrucciones de SYMPLIFICA, el proveedor de pagos los distribuya a los sistemas seleccionados por los trabajadores.
                Usted deberá escoger entre las diversos métodos de pago ofrecidos en la Plataforma (Ejemplo: transferencia bancaria, tarjetas de crédito, débito automático a una cuenta corriente o de ahorros).
                Los trabajadores a los que Usted deba realizar pagos de nómina, deberán optar por uno de los mecanismos ofrecidos por SYMPLIFICA para el cobro de sus salarios u otros pagos laborales (ejemplo: recibir transferencia en su cuenta bancaria o retirar en cajero automático con PIN).
                Usted deberá realizar los pagos a través de nuestra Plataforma con al menos 3 días hábiles de antelación a la fecha de vencimiento del plazo para pagarle a sus trabajadores. Esto se debe a que los pagos realizados por el Usuario no ingresan inmediatamente a los sistemas seleccionados por los trabajadores para cobrar, ya que los recursos pueden tardarse en ingresar a la cuenta bancaria de SYMPLIFICA, y desde ahí, con instrucciones de SYMPLIFICA, el proveedor de pagos los distribuirá a los sistemas seleccionados por los trabajadores. Si el Usuario no realiza los pagos a través de la Plataforma con la antelación aquí indicada, y debido a los ciclos bancarios y/o los plazos requeridos para las autorizaciones de las tarjetas de crédito, se presentara un retardo en el pago de la nómina a los trabajadores, SYMPLIFICA no será responsable por el pago de los intereses de mora que se pudieren generar frente a estos.
                Si Usted prefiere realizar los pagos de nómina en efectivo directamente a sus trabajadores, podrá ingresar la información de los pagos que haya realizado a la Plataforma, imprimir los recibos de pago y hacerlos firmar por sus trabajadores, para posteriormente subirlos a la Plataforma o autorizarlo digitalmente desde el APP.
                Usted será responsable de actualizar la información suministrada a SYMPLIFICA, referente a salarios, fechas de inicio o terminación de contratos, o cualquiera otra que sea relevante para la adecuada administración de la nómina.
                SYMPLIFICA no será responsable por errores en la administración de la nómina, que se deban al suministro por parte del Usuario de información falsa, incorrecta, incompleta o imprecisa.
                La infraestructura utilizada por Symplifca para el manejo y custodia de la información, opera de acuerdo a las mejores prácticas de administración de información definidas, garantizando así altos estándares de seguridad.
                Afiliación a Entidades de Seguridad Social: SYMPLIFICA ofrece a sus Usuarios el servicio de trámites de afiliación de sus trabajadores a las entidades del sistema de seguridad social integral (EPS, AFP, ARL y Cajas de Compensación, Fondo de Cesantías). Si Usted elige este servicio, deberá darnos autorización mediante la selección de la opción correspondiente al diligenciar y firmar el formato titulado “Mandato”, que se encuentra en el tablero de control al terminar el registro de trabajadores en el Sitio, y subirlo nuevamente a la Plataforma o autorizarlo digitalmente desde la aplicación o cuenta web; esto es un requisito para que SYMPLIFICA pueda realizar a nombre de los Usuarios, los trámites de afiliación tanto de ellos como de sus trabajadores a las respectivas entidades.
                <br/><br/>
                Como parte del procedimiento de afiliación, SYMPLIFICA revisará el estado de afiliación de empleado y trabajador a Pensión y Salud en la base de datos de BDUA (Base de Datos Única de Afiliados), o la base de datos que reemplace a esta. SYMPLIFICA quedará exonerado de toda responsabilidad, si la BDUA o la base de datos que la reemplace, presenta información errónea, que conlleve problemas, retrasos o errores en el proceso de afiliación del empleado y el trabajador.
                <br/><br/>
                Si por deficiencias en la información suministrada por la BDUA o la base de datos que la reemplace, SYMPLIFICA realiza la afiliación a alguna entidad, pero posteriormente se evidencie que era necesario un traslado desde otra entidad, el Usuario deberá realizar por su cuenta y asumir los costos que se generen por dicho traslado y para subsanar los errores presentados.
                <br/><br/>
                Como parte del proceso de afiliación ante las entidades de seguridad social, Symplifica habilitará mediante un recurso que disponga o tercerice la asignación a toma de firmas de los formularios solicitados por las entidades, tanto para el empleador como para el trabajador, es responsabilidad del cliente atender esta cita y verificar mediante cédula la identidad. Symplifica no será responsable por posibles suplantaciones. Es responsabilidad del Usuario que estén todos presentes en el mismo momento y lugar al momento de la toma de firmas. Esta fecha debe ser a más tardas 2 días hábiles antes de la fecha de afiliación.
                <br/><br/>
                EL USUARIO RECONOCE Y ACEPTA QUE, UNA VEZ HAYA SIDO AFILIADO A LAS ENTIDADES DEL SISTEMA DE SEGURIDAD SOCIAL POR PARTE DE SYMPLIFICA, ADQUIRIRÁ LA RESPONSABILIDAD DE PAGAR A DICHAS ENTIDADES LOS APORTES ORDENADOS POR LA LEY. SERÁ RESPONSABILIDAD DEL USUARIO PAGAR CUMPLIDAMENTE LOS APORTES QUE LE CORRESPONDAN, YA SEA A TRAVÉS DE SYMPLIFICA O POR CUALQUIER OTRO MEDIO QUE EL USUARIO DECIDA.
                <br/><br/>
                Es responsabilidad del cliente informar a Symplifica a través de las opciones habilitadas en su plataforma y app o mediante los canales de atención al cliente, la desvinculación liquidación o finalización de contrato con sus empleados activos. Al no ser informado, Symplifica seguirá generando los comprobantes y no desafiliará ante las entidades de seguridad social, lo cual puede acarrear moras y posibles multas que son responsabilidad total del usuario.
                <br/><br/>
                Administración de Pagos de Aportes a Seguridad Social: SYMPLIFICA ofrece entre sus servicios, la administración de los aportes de los trabajadores de los Usuarios a las entidades del sistema de seguridad social integral a que se encuentren afiliados, a través de un operador de información de la PILA seleccionado por SYMPLIFICA. Si Usted opta por utilizar este servicio y realizar los pagos a través de nuestra Plataforma, deberá seleccionar una Forma de Pago y realizar mensualmente el pago de los aportes a través de SYMPLIFICA, teniendo en cuenta lo siguiente:
                <br/><br/>
                Si Usted elige este servicio, deberá darnos autorización mediante la selección de la opción correspondiente al diligenciar y firmar el formato titulado “Mandato”, que se encuentra en el tablero de control al terminar el registro de trabajadores en el Sitio, y subirlo nuevamente a la Plataforma o autorizarlo digitalmente desde la aplicación o cuenta web; esto es un requisito para que SYMPLIFICA pueda realizar a nombre de los Usuarios, la administración de los recursos con que estos efectúen los aportes a las entidades del sistema de seguridad social integral a las que se encuentre afiliados sus empleados; en virtud de este mandato, SYMPLIFICA deberá destinar dichos dineros únicamente al pago de los aportes a seguridad social, ajustándose estrictamente a las instrucciones dadas por Usted en calidad de mandante. Usted autoriza a que sus recursos ingresen primero a una cuenta externa (del proveedor de pagos), y a que desde ahí, con instrucciones de SYMPLIFICA, el proveedor de pagos distribuya los aportes entre las entidades de la PILA a que se encuentren afiliados los trabajadores del Usuario.
                Deberá escoger entre las diversos métodos de pago ofrecidos en la Plataforma (Ejemplo: tarjetas de crédito o débito automático a una cuenta corriente o de ahorros).
                Usted deberá realizar los pagos a través de nuestra Plataforma con al menos 3 días hábiles de antelación a la fecha de vencimiento del plazo estipulada en la normatividad vigente. Lo anterior, debido a que los transferidos por el usuario pueden tardarse en ingresar a la cuenta bancaria de SYMPLIFICA, y desde ahí, el proveedor de pagos hará el pago en el Operador de Información de la Planilla Integrada de Liquidación de Aportes (PILA) seleccionada por SYMPLIFICA.
                Para efectos de lo anterior, SYMPLIFICA, si Usted así lo autoriza, debitará automáticamente del medio de pago seleccionado por Usted, entre el día 26 y 30 de cada mes, el valor de los aportes a seguridad social de sus trabajadores correspondiente al mes inmediatamente siguiente. SYMPLIFICA le notificará las fechas máximas en que los pagos se podrán hacer a través de la Plataforma, para que no haya retrasos ante las entidades de seguridad social, teniendo en cuenta los ciclos bancarios y/o los plazos requeridos para las autorizaciones de las tarjetas de crédito. En caso de que el Usuario no cumpla con las fechas de vencimiento requeridas por SYMPLIFICA para el pago a través de la Plataforma, el Usuario deberá realizar el pago de los aportes a través de los otros mecanismos existentes en el mercado, para evitar el pago extemporáneo; si por el contrario, el Usuario decidiera realizar los pagos a través de la Plataforma de SYMPLIFICA después de la fecha de vencimiento que esta le indique, se deberán liquidar y pagar a través de la Plataforma, en forma anticipada, el valor de los intereses de mora.
                SYMPLIFICA únicamente asumirá el pago de intereses de mora ante las entidades de seguridad social integral, cuando a pesar de que el Usuario haya realizado el pago de los aportes a través de la Plataforma con la antelación requerida por SYMPLIFICA, estos sean recibidos por dichas entidades después de la fecha de vencimiento que le corresponda al Usuario conforme a la normatividad vigente, siempre y cuando no se hayan presentado situaciones por culpa del Usuario que hayan retrasado el pago (tales como, sin limitarse a, insuficiencia de fondos o error en los datos bancarios o de la tarjeta de crédito suministrados a la Plataforma). En todos los demás casos, los intereses de mora serán asumidos por el Usuario.
                SYMPLIFICA no se hará responsable en ningún caso por las sanciones, multas, suspensión de servicios, negación de beneficios, incapacidades o licencias, u otras consecuencias del pago extemporáneo a las entidades de seguridad social por parte del Usuario.
                Usted será responsable de actualizar la información suministrada a SYMPLIFICA, referente a salarios, I.B.C., fechas de inicio o terminación de contratos, o cualquiera otra que sea relevante para la adecuada administración de la nómina.
                SYMPLIFICA no será responsable por errores en la administración de los aportes a seguridad social, que se deban al suministro por parte del Usuario de información falsa, incorrecta, incompleta o imprecisa.
                Mandato: Si Usted opta por realizar a través de SYMPLIFICA, los pagos de nómina y/o de los aportes a las entidades de seguridad social a que estén afiliados sus trabajadores, se entenderá que con la aceptación de los presentes Términos, Usted otorga un mandato específico a SYMPLIFICA para la administración de los recursos con que Usted efectúe sus pagos; en virtud de este mandato, SYMPLIFCA deberá destinar dichos dineros únicamente al pago de la nómina o de los aportes a seguridad social de sus trabajadores, ajustándose estrictamente a las instrucciones dadas por Usted en calidad de mandante. Sin perjuicio de lo anterior, como requisito previo para la administración de los pagos por parte de SYMPLIFICA, Usted deberá descargar e imprimir el formato titulado “Mandato”, que se encuentra en el tablero de control al terminar el registro de trabajadores en el Sitio, firmarlo y subirlo a la Plataforma de SYMPLIFICA o autorizarlo digitalmente a través de la aplicación móvil o cuenta en web.
                <br/><br/>
                Ausencia de Relación Laboral: La administración de pagos de nómina y aportes a la seguridad social de los trabajadores de los Usuarios del Sitio, es realizada por SYMPLIFICA en calidad de mandatario de dichos Usuarios y ajustándose a las instrucciones de los mismos. Por lo tanto, en ningún caso podrá considerarse que SYMPLIFICA actúa como patrono o empleador de los trabajadores de los Usuarios que utilicen los servicios ofrecidos por SYMPLIFICA. Usted será exclusivamente responsable del cumplimiento de los términos y condiciones de los contratos laborales o de otro tipo que llegue a celebrar con sus trabajadores, así como del cumplimiento de las leyes aplicables a tales contratos.
                <br/><br/>
                EXONERACIÓN DE RESPONSABILIDAD DE SYMPLIFICA
                En ningún caso, SYMPLIFICA será responsable por cualquier acto, error u omisión de terceros, incluyendo, sin limitarse a, cualquiera que se derive del operador de información de la PILA, de las entidades de seguridad social, de los proveedores de pagos, del uso de cualquier servicio o producto anunciado a través del Sitio, o del incumplimiento de cualquier tercero en relación con los servicios anunciados o disponibles a través de la Plataforma. SYMPLIFICA es una entidad independiente de, y no es un agente de, ninguna de dichas entidades.
                <br/><br/>
                SYMPLIFICA seguirá los criterios generales establecidos por el Código Sustantivo del Trabajo y las normas que lo reglamenten, para realizar las liquidaciones de nómina, de prestaciones sociales, vacaciones y demás pagos laborales de los trabajadores del Usuario. Si el Usuario tiene alguna pregunta o inconformidad sobre alguna liquidación, la podrá formular a SYMPLIFICA a través de los canales de comunicación disponibles a través del Sitio, pero en todo caso, SYMPLIFICA no tendrá responsabilidad alguna si se presentaren diferencias de interpretación con respecto a la forma en que debían realizarse las liquidaciones, frente a los trabajadores del Usuario o cualquier autoridad competente. Cualquier pago, indemnización, sanción o interés que se derive de ello, será asumido por el Usuario, quien expresamente exonera de toda responsabilidad a SYMPLIFICA.
                <br/><br/>
                El Sitio podrá ofrecer información sobre temas laborales y de seguridad social; las decisiones que el Usuario adopte con base en este Sitio y su contenido, se hacen bajo su propio y exclusivo riesgo. Nada de lo incluido en este Sitio constituye asesoría suministrada por SYMPLIFICA, y Usted entiende y acepta que esta información no constituye recomendación o consejo por parte de SYMPLIFICA.
                <br/><br/>
                POR LO TANTO, USTED LIBERA A SYMPLIFICA, SUS ENTIDADES SUBORDINADAS, SU COMPAÑÍA MATRIZ Y LAS SUBORDINADAS DE SU MATRIZ, Y A CADA UNO DE SUS RESPECTIVOS FUNCIONARIOS, DIRECTORES, SOCIOS, EMPLEADOS, ASESORES, CONTRATISTAS, REPRESENTANTES Y AGENTES, DE CUALQUIER Y TODA RESPONSABILIDAD, RECLAMACIONES, ACCIONES, DEMANDAS, PROCEDIMIENTOS, COSTOS, GASTOS, DAÑOS Y RESPONSABILIDADES DERIVADAS DE O RELACIONADAS CON EL USO DE SU SUSCRIPCIÓN.
                <br/><br/>
                PROHIBICIÓN DE REVENTA, CESIÓN O USO COMERCIAL NO AUTORIZADO
                El Usuario declara que no revenderá o cederá sus derechos u obligaciones bajo estos Términos. El Usuario declara igualmente que no hará un uso comercial no autorizado de este Sitio, ni de su Suscripción.
                <br/><br/>
                TERMINACIÓN
                Ante el incumplimiento por parte del Usuario de estos Términos o de la ley, o ante la imposibilidad de verificar o autenticar cualquier información que el Usuario haya presentado en su forma de registro para acceder al Sitio, SYMPLIFICA se reserva el derecho, a su exclusiva discreción, de terminar inmediatamente el registro de tal Usuario y del acceso al Sitio o a determinados servicios proveídos por SYMPLIFICA. Igualmente, en caso de que exista alguna infracción a la ley o a estos Términos Symplifica se reserva el derecho de iniciar las acciones judiciales a las que haya lugar.
                <br/><br/>
                Acuerdo íntegroSalvo acuerdo en contrario entre el Usuario y Symplifica, los presente Términos constituyen la totalidad de lo acordado entre ambas partes en relación con el encargo de Servicios y normalmente se con los servicios específicos que se prestan y la determinación del valor por dicho Servicio.
                <br/><br/>
                Salvo acuerdo en contrario, estos Términos serán de aplicación a cualquier Servicio que contrate el Usuario en el Sitio en el futuro. En el supuesto de que se determinare la nulidad de cualquiera de los presentes términos y condiciones, ello no afectará al resto de los términos y condiciones, que permanecerán en pleno vigor.
                <br/><br/>
                Legislación aplicable. Fuero
                La relación entre el Usuario y Symplifica se somete expresamente a la legislación colombiana.
                <br/><br/>
                Para conocer de cuantas discrepancias o reclamaciones pudieran surgir de la interpretación o ejecución de la relación jurídica entre el Usuario y Symplifica ambos, con renuncia expresa a cualquiera otra jurisdicción que pueda corresponderles y sin perjuicio de las normas imperativas sobre competencia judicial, se someten voluntariamente a los Juzgados y Tribunales de Colombia.
                <br/><br/>
                DISPOSICIONES GENERALES
                SYMPLIFICA no asegura que el Material pueda ser legalmente visto fuera del territorio de la República de Colombia. Si el Usuario tiene acceso a este Sitio desde un lugar ubicado fuera del territorio de la República de Colombia, lo hace bajo su propio riesgo y es responsable del cumplimiento de las leyes aplicables en su jurisdicción.
                <br/><br/>
                Estos Términos están regidos por las leyes de la República de Colombia, sin dar aplicación a las normas o principios sobre conflicto de leyes. La jurisdicción para cualquier reclamación que surja de estos Términos será exclusivamente la de los tribunales y jueces de Bogotá D.C., República de Colombia. Si alguna previsión de estos Términos es declarada nula o inválida o ineficaz, ello no afectará la validez de las restantes previsiones de estos Términos. SYMPLIFICA podrá ceder los derechos y obligaciones que tiene en virtud de estos Términos.
                <br/><br/>
                Este Sitio es operado por SYMPLIFICA SAS, con domicilio en la carrera 13 No. 93-68 oficina 504, de la ciudad de Bogotá D.C. También nos puede contactar al teléfono (571) 743 7648, o al correo electrónico servicioalcliente@symplifica.com
                <br /><br />
            </p>
        </div>
    );
}

const PP = () =>{
    return(
        <div style={{height: '80vh', overflowY:'scroll'}}>
            <p>
            SYMPLIFICA S.A.S., compañía propietaria del sitio SYMPLIFICA.COM (en adelante, el “Sitio”) y
            responsable del tratamiento de la información de los usuarios de este Sitio, aplica la presente
            Política de Privacidad y Manejo de la información recopilada. Adicionalmente, esta Política será
            aplicable a los datos personales de los clientes, proveedores, empleados y colaboradores de
            SYMPLIFICA S.A.S. (quienes en adelante, en conjunto con los visitantes del Sitio, también se
            denominarán los “Usuarios”), registrados en cualquier base de datos que los haga susceptibles de
            tratamiento, que se encuentre en poder de SYMPLIFICA S.A.S.
            La presente Política también rige las relaciones de SYMPLIFICA S.A.S., como Responsable del
            Tratamiento de los datos personales de los Usuarios, y cualquiera de sus Encargados para el
            Tratamiento de los datos. El uso por parte del Usuario de los Servicios ofrecidos en este Sitio,
            indica que ha aceptado incondicionalmente esta Política de Privacidad, en los términos y
            condiciones que se señalan a continuación.
            I) Definiciones
            <br/>
            Para efectos de la presente Política, los siguientes términos se entenderán en la forma en que se
            definen en el artículo 3º la Ley 1581 de 2012, según se transcribe a continuación:
            a) Autorización: Consentimiento previo, expreso e informado del titular para llevar a cabo el
            tratamiento de datos personales.
            <br/>
            b) Base de datos: Conjunto organizado de datos personales que sea objeto de tratamiento.
            c) Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas
            naturales determinadas o determinables.
            d) Encargado del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en
            asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del
            tratamiento.
            <br/>
            e) Responsable del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o
            en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.
            f) Titular: Persona natural cuyos datos personales sean objeto de tratamiento.
            g) ratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como
            la recolección, almacenamiento, uso, circulación o supresión.
            <br/>
            II) INFORMACIÓN OBTENIDA DE TODOS LOS VISITANTES DEL SITIO
            <br/>
Cuando el Usuario accede al Sitio, SYMPLIFICA S.A.S. recibe inmediatamente información acerca
de los Usuarios y sus computadoras. SYMPLIFICA S.A.S. podrá obtener y conservar la siguiente
<br/>
información acerca de los visitantes del Sitio, en forma anónima (no asociada a la identidad de
ningún visitante):
<br/>
► La fecha y hora de acceso al Sitio.
<br/>
► La dirección IP desde la que se accedió al Sitio.
<br/>
► Navegador y sistema operativo.
<br/>
► Sitios web que han visitado, enlaces vistos y búsquedas realizadas.
<br/>
SYMPLIFICA SAS puede recopilar información personal utilizando &quot;cookies&quot;, que son pequeños
archivos de datos sobre los usuarios de Internet que los portales web guardan en su navegador.
Las cookies recuerdan la información acerca de sus actividades en el Sitio y pueden utilizarse por
este para personalizar la información que le proporcionamos, de tal forma que esta se refiera a
productos o servicios que consideremos que puedan parecerle interesantes a determinados
Usuarios, con base en sus actividades en línea anteriores y sus preferencias.
SYMPLIFICA SAS puede recopilar información personal utilizando &quot;cookies&quot;, que son pequeños
archivos de datos sobre los usuarios de Internet que los portales web guardan en su navegador.
Las cookies recuerdan la información acerca de sus actividades en el Sitio y pueden utilizarse por
este para personalizar la información que le proporcionamos, de tal forma que esta se refiera a
productos o servicios que consideremos que puedan parecerle interesantes a determinados
Usuarios, con base en sus actividades en línea anteriores y sus preferencias.
SYMPLIFICA SAS puede utilizar las cookies para identificar el navegador de red utilizado por el
Usuario, determinar si este ha instalado el software o los aplicativos necesarios para tener acceso
a cierto material en nuestro Sitio, determinar el número total de visitantes a nuestro Sitio y
reconocerlo cuando regrese al mismo. SYMPLIFICA S.A.S. utiliza las cookies para conocer y analizar
los intereses y el comportamiento de los visitantes del Sitio, y así mejorar nuestras iniciativas
comerciales y promocionales, personalizar contenidos, y en general prestarles un mejor servicio.
No obstante lo anterior, el Usuario podrá elegir en cualquier momento no recibir cookies, para lo
cual puede configurar su navegador para que las rechace o para que le avise cada vez que se cree
una cookie. Tenga en cuenta que sin estos archivos de identificación no podrá beneficiarse de
algunas funciones del Sitio, que buscan que la experiencia de cada Usuario sea personalizada. Así
mismo, las cookies pueden ser necesarias para poder ver, crear una cuenta, o efectuar
transacciones digitales por medio del Sitio. Si no tiene las cookies activadas, es posible que algunas
de las funcionalidades del Sitio no tengan el comportamiento esperado.
<br/>
III) INFORMACIÓN AUTORIZADA POR EL USUARIO
<br/>
Durante el proceso de registro en el Sitio, se entiende que el Usuario expresamente autoriza a
SYMPLIFICA SAS para consultar, verificar o cruzar con bases de datos propias o de terceros, los
datos suministrados por el Usuario.
<br/>
Cuando el Usuario del Sitio opta por adquirir uno de los servicios disponibles a través del mismo,
ya sean prestados directamente por SYMPLIFICA SAS o por terceros, deberá diligenciar un
formulario en línea. Así mismo, posteriormente se podrá requerir el envío de información
adicional vía correo electrónico u otros medios a SYMPLIFICA SAS para continuar con el proceso de
prestación de los servicios. La información así adquirida por SYMPLIFICA S.A.S. incluirá datos
personales y otros datos de los Usuarios, quienes con la remisión de la misma autorizan a
SYMPLIFICA SAS darle el tratamiento explicado en la presente Política.
<br/>
El tratamiento que realiza SYMPLIFICA S.A.S. de los datos de sus Usuarios, consiste en recolectar,
almacenar, depurar, procesar, usar, analizar, circular, actualizar y cruzar con información propia
y/o de terceros autorizados, los datos de contacto, la información sobre preferencias de consumo
y el comportamiento en los canales de contacto, con la siguiente finalidad:
<br/>
a) Facilitar la correcta ejecución de la prestación de los servicios del Sitio, tanto aquellos prestados
por SYMPLIFICA SAS como por terceros.
<br/>
b) Compartir la información con los terceros prestadores de servicios que se encuentren
disponibles por intermedio del Sitio, tales como, sin limitarse a, las compañías prestadoras de los
servicios de exámenes médicos y estudios de seguridad, las entidades de seguridad social y el
proveedor de pagos. Dichos terceros quedan autorizados por el Usuario para utilizar la
información suministrada, con el fin de ejecutar correctamente la prestación de sus servicios.
<br/>
c) Realizar estudios estadísticos que permitan diseñar mejoras en los servicios prestados.
<br/>
d) Gestionar tareas básicas de administración.
<br/>
e) Informar por cualquier medio, las promociones, productos y servicios actuales y futuros; los
eventos, actividades de promoción y otras actividades comerciales directa o indirectamente
relacionadas con la actividad propia de SYMPLIFICA S.A.S.; y/o las promociones, productos y
servicios promovidos directamente por los aliados estratégicos de SYMPLIFICA S.A.S., que les
generen valores agregados a los Usuarios.
<br/>
f) Envío de material publicitario relacionado con los productos y servicios de SYMPLIFICA S.A.S., sus
autorizadas, cesionarias, licenciatarias, filiales y/o subordinadas, y aliados comerciales.
<br/>
g) Darle cumplimiento a las obligaciones contractuales y/o legales que SYMPLIFICA S.A.S. tenga
con sus clientes, empleados, proveedores, autorizadas, cesionarias, licenciatarias, filiales y/o
subordinadas, así como con las autoridades judiciales o administrativas.
<br/>
h) Realizar estudios de mercadeo y consumo.
<br/>
i) Utilizar la información para la preparación, participación y desarrollo de concursos.
<br/>
j) Analizar y medir la calidad de los productos y servicios ofrecidos por SYMPLIFICA S.A.S., sus
autorizadas, cesionarias, licenciatarias, filiales y/o subordinadas.
<br/>
k) Transferir a terceros países la información proporcionada; en este caso, SYMPLIFICA SAS se
asegurará previamente de que dicho tercer país cumpla como mínimo los mismos estándares
colombianos de protección de datos.
<br/>
Así mismo, el Usuario autoriza a SYMPLIFICA SAS a entregar su información a terceros proveedores
de servicios, quienes actuarán como Encargados del Tratamiento y realizarán el tratamiento de los
<br/>
datos del Usuario, únicamente con el fin de prestar los servicios adquiridos por el Usuario a través
del Sitio.
<br/>
Los terceros proveedores de servicios de pago, en calidad de Encargados del Tratamiento, podrán
almacenar, depurar, procesar, analizar y usar los datos del Usuario, con el fin de ejecutar
correctamente la prestación de los servicios de pago.
<br/>
El Usuario acepta que los datos personales aportados en el momento de su registro, sean tratados
por SYMPLIFICA S.A.S, en las formas y con las finalidades antes mencionadas. Con respecto al
envío de información y de comunicaciones comerciales a través de correo electrónico o medio
equivalente, se aclara que como requisito para el registro del Usuario en el Sitio y utilización de
nuestros Servicios, el Usuario deberá aceptar esta Política de Privacidad en su integridad,
incluyendo la autorización para el envío de dichas comunicaciones; no obstante, en cualquier
momento, el Usuario tiene el derecho de optar por no recibir estas comunicaciones en el futuro,
procediendo a la cancelación de su suscripción a estos servicios, a través de nuestro Sitio, o del
medio que se informe para tal fin.
<br/>
SYMPLIFICA S.A.S podrá transferir los datos personales de los Usuarios como parte de los activos
de SYMPLIFICA S.A.S en caso de que la compañía o partes del negocio fuesen vendidas, fusionadas
o adquiridas por terceros, lo cual es expresamente autorizado por los Usuarios al registrarse en el
Sitio. Sin perjuicio de las anteriores autorizaciones por parte del Usuario, SYMPLIFICA S.A.S se
compromete al cumplimiento de su obligación de secreto de los datos de carácter personal y de su
deber de tratarlos con confidencialidad, y asume, a estos efectos, las medidas de índole técnica,
organizativa y de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no
autorizado, de acuerdo con lo establecido en la ley y en los tratados internacionales suscritos por
Colombia que rigen la materia. Al ingresar a este Sitio y registrarse, el Usuario adquiere el
compromiso de suministrar información personal correcta y verdadera, así como proceder a la
actualización de sus datos cada vez que se requiera. El Usuario responderá, en cualquier caso, de
la veracidad de los datos facilitados, reservándose SYMPLIFICA S.A.S el derecho a excluir de los
servicios a todo Usuario que haya facilitado datos falsos.
Información para pagos
<br/>
Dentro de las alternativas que se contemplan en este Sitio para el pago de los productos y/o
servicios seleccionados por el Usuario, SYMPLIFICA SAS podrá a su elección ofrecer mecanismos de
pago, incluyendo botón de pagos, vínculos (links) u otros medios que comuniquen con las páginas
de terceros proveedores de servicios de pago o los portales web de entidades financieras. Los
pagos se realizarán a través de las páginas web o mecanismos facilitados por dichos terceros, y por
consiguiente, en tales eventos el manejo de la información personal será de responsabilidad
exclusiva de las páginas de pagos y/o entidades financieras, según lo establecido en sus acuerdos
con los Usuarios. El Usuario se obliga a notificar oportunamente a sus correspondientes entidades
del sector financiero o cooperativo, la pérdida o robo de su tarjeta de crédito, débito o de los
demás instrumentos facilitados por las mismas para realizar transacciones, tales como, claves
personales, con el fin de que tales instrumentos sean inhabilitados por las mismas. SYMPLIFICA
<br/>
SAS no asume ninguna responsabilidad por el uso indebido de tales instrumentos por parte de
terceros.
<br/>
Información de Terceros suministrada por el Usuario
En caso de que el Usuario suministre datos de terceros, tales como datos de sus trabajadores
actuales o potenciales, para efectos de las afiliaciones y demás servicios disponibles en el Sitio, se
entenderá que el Usuario ha obtenido la autorización expresa de tales personas. Por lo tanto,
mantendrá indemne a SYMPLIFICA SAS en caso de que se presenten reclamaciones de dichos
terceros por el uso de su información.
<br/>
Para efectos de lo anterior, se entiende que el Usuario tiene autorización de sus trabajadores
actuales o potenciales (en adelante, el “Trabajador” o “Trabajadores”), para suministrar en
representación de ellos a SYMPLIFICA SAS, los datos personales de los mismos, a través de este
Sitio o de otros medios habilitados por SYMPLIFICA SAS, incluyendo su nombre completo, número
de cédula de ciudadanía y copia de la misma, datos bancarios, datos de contacto (correo
electrónico, teléfono, dirección, etc.), entre otros. Por lo tanto, el Usuario en representación de
sus Trabajadores, expresamente autoriza a SYMPLIFICA SAS a darle a los datos personales de
dichas personas, el tratamiento establecido en la presente Política. Adicionalmente, y sin limitar lo
anterior, el Usuario en representación de sus Trabajadores, autoriza a Symplifica SAS a: (i)
Compartir el perfil del Trabajador, que haya sido creado por él mismo o por el Usuario, con otros
usuarios del Sitio que estén interesados en contratar, hayan contratado o en el futuro contraten al
Trabajador; (ii) Enviar al Trabajador a través de los medios informados en sus datos de contacto,
tales como SMS, correo electrónico, teléfono y cualquier otro medio reportado, información sobre
los pagos realizados por parte del Usuario; (iii) Enviar al Trabajador información de promociones,
productos y servicios, así como material publicitario, de SYMPLIFICA SAS y sus aliados comerciales,
por cualquiera de los medios informados en los datos de contacto del Trabajador; (iv) Consultar,
en cualquier tiempo, en Datacredito o en cualquier otra central de información de riesgo, toda la
información relevante del Trabajador, con el fin de cruzar dicha información con aquella reportada
a través de esta Página Web, complementar la información suministrada a esta Página Web, y
facilitar al Usuario, al Trabajador o a otros usuarios que hayan contratado o en el futuro contraten
al Trabajador, el diligenciamiento de los formularios disponibles en o utilizados por este Sitio,
mediante el pre-llenado de los datos previamente reportados a las entidades mencionadas o que
por cualquier otro medio reposen en las bases de datos de SYMPLIFICA SAS o con las cuales
SYMPLIFICA SAS tenga un acuerdo.
<br/>
IV) DERECHOS DE LOS USUARIOS Y PROCEDIMIENTO PARA EJERCERLOS
<br/>
Derechos
<br/>
El Usuario, en su calidad de titular de los datos personales que informe al Sitio, tendrá los
siguientes derechos:
<br/>
a) Conocer, actualizar y rectificar sus datos personales frente a SYMPLIFICA o las demás compañías
<br/>
Encargadas del Tratamiento de sus datos personales, que se señalan en esta Política. Este derecho
se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que
induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido
autorizado.
<br/>
b) Solicitar prueba de la autorización otorgada a SYMPLIFICA, salvo cuando expresamente se
exceptúe en la ley como requisito para el Tratamiento (artículo 10 Ley 1581 de 2012).
<br/>

c) Ser informado por SYMPLIFICA o alguna de las compañías Encargadas del Tratamiento de los
datos personales, previa solicitud, acerca de la existencia de datos que le conciernan y el uso que
se le ha dado a sus datos personales.
<br/>
d) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo
dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o
complementen.
<br/>
e) Revocar la Autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se
respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o
supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que
en el Tratamiento el responsable o encargado han incurrido en conductas contrarias a la ley y a la
Constitución.
<br/>
f) Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
Procedimiento
<br/>
Los derechos a conocer, actualizar, rectificar y suprimir información y revocar la autorización, o
solicitar prueba de la misma, podrán ser ejercidos mediante petición escrita dirigida al área de
Servicio al Cliente, encargada por SYMPLIFICA S.A.S para la atención de estas solicitudes, en la
dirección Carrera 13 No. 93 - 68 oficina 504 de Bogotá D.C. - Colombia, o enviando un correo
electrónico a contactanos@symplifica.com
En caso de reclamos, se seguirá el procedimiento establecido en el artículo 15 de la Ley 1581 de
2012.
<br/>
Como requisito previo para darle trámite a cualquier reclamo o solicitud, se verificará la identidad
del reclamante, quien deberá ser el titular de la información, su representante o uno de sus
causahabientes. Una solicitud o un reclamo completo debe contener, por lo menos:
<br/>
1) Identificación del Titular y del reclamante (en caso de que no sea el mismo Titular).
<br/>
2) Descripción de los hechos que dan lugar al reclamo o solicitud.
<br/>
3) Dirección física o electrónica de notificación.
<br/>
4) Petición u objeto del reclamo.
<br/>
Las solicitudes o reclamos serán atendidas en un término máximo de diez (10) días hábiles
contados a partir de la fecha de recibo de las mismas, siempre y cuando cumplan los requisitos
mínimos mencionados arriba. Si no fuere posible dar una respuesta dentro de dicho término, se
informará al respectivo titular de la información, explicando las razones de la demora y señalando
la fecha en que se responderá su consulta, sin que en ningún caso se superen los cinco (5) días
hábiles siguientes al vencimiento del primer término.
<br/>
Si se presenta una solicitud o una reclamación en forma incompleta, dentro de los cinco (5) días
<br/>
hábiles siguientes a la recepción de la solicitud o reclamo se comunicará al interesado que debe
completar los requisitos arriba mencionados. Si transcurrieren dos (2) meses sin que el reclamante
subsane su reclamación o solicitud, se entenderá que ha desistido de la misma. De existir algún
reclamo sin solucionar, se acompañarán los datos personales registrados por el Titular con la
<br/>
leyenda: ”Reclamo en Trámite”
<br/>
Solicitud de Supresión de Datos
<br/>
Cuando se solicite la supresión de datos, esta no podrá realizarse en los siguientes casos:
<br/>
1) Cuando sea una obligación legal conservarlos.
<br/>
2) Cuando el Usuario se haya obligado contractualmente a permitir su uso.
<br/>
3) Cuando conservar los datos sea necesario para salvaguardar los intereses del Usuario o el
Interés Público.
<br/>
4) Cuando la supresión dificulte o entorpezca el ejercicio de las funciones de las autoridades
administrativas o judiciales.
<br/>
Revocatoria de Autorización
<br/>
Cuando un Usuario quiera revocar la autorización que haya dado para la utilización de sus datos
personales, deberá informar a SYMPLIFICA SAS de manera clara, si la revocatoria es total o parcial.
La revocatoria de la autorización es parcial cuando el titular de la información manifiesta que
desea revocar su autorización para el Tratamiento de sus datos personales para ciertas finalidades
específicas, tales como las de envío de información publicitaria, de estudios de consumo, entre
otras. La revocatoria de la autorización será total cuando el titular de la información solicite que
cese completamente el Tratamiento de datos personales para todas las finalidades autorizadas.
<br/>
V) REQUERIMIENTOS DE LAS AUTORIDADES
<br/>
SYMPLIFICA S.A.S cooperará con las autoridades competentes para garantizar el cumplimiento de
las leyes, en materia de protección de la propiedad industrial, derechos de autor, prevención del
fraude y otras materias.
<br/>
Los Usuarios de SYMPLIFICA.COM autorizan expresamente a SYMPLIFICA S.A.S a suministrar
cualquier información personal sobre ellos, con la finalidad de dar cumplimiento a cualquier
requerimiento de autoridad competente, y con el fin de cooperar con las autoridades
competentes en la medida en que discrecionalmente dicha compañía lo entienda necesario y
adecuado en relación con cualquier investigación de un ilícito, infracción de las normas del
Estatuto del Consumidor, infracción de derechos de propiedad intelectual o industrial, u otra
actividad que sea ilegal o que pueda exponer a SYMPLIFICA S.A.S a cualquier responsabilidad legal.
Además, los Usuarios autorizan a SYMPLIFICA SAS a comunicar sus nombres y apellidos o razón
social, domicilio, ciudad, región, código postal, país, número de teléfono, dirección de correo
electrónico, a las autoridades competentes, en relación con las investigaciones que estas
adelanten.
<br/>
VI) SEGURIDAD
<br/>
SYMPLIFICA SAS utiliza varios métodos de seguridad para proteger la información personal que
recopilamos de los Usuarios. No obstante lo anterior, ninguna medida de seguridad es perfecta o
impenetrable, y no se puede garantizar que las transmisiones de datos en Internet sean 100%
seguras. No podemos asegurar o garantizar la seguridad de ninguna información que el Usuario
transmita a SYMPLIFICA SAS, lo cual el Usuario hace bajo su propio riesgo.
<br/>
VII) CAMBIOS DE POLÍTICA
<br/>
SYMPLIFICA S.A.S se reserva la facultad de revisar en cualquier momento esta Política. SYMPLIFICA
S.A.S publicará en este Sitio cualquier cambio en esta Política. Cuando se realicen modificaciones
sustanciales a esta Política, se comunicará este hecho a los titulares de la información mediante el
envío de un aviso al correo electrónico que hayan registrado, antes de o a más tardar al momento
de implementarlos, informándoles que podrán consultar la nueva Política en el Sitio. En dicho
aviso se indicará la fecha a partir de la cual regirá la nueva Política. Cuando el cambio se refiera a
las finalidades del Tratamiento, se solicitará de los titulares de los datos personales una nueva
autorización para aplicar las mismas.
Si el Usuario nos proporciona información, o tiene acceso a o usa nuestros Servicios de cualquier
manera después de que esta Política haya sido cambiada, se considerará que ha dado su
consentimiento incondicionalmente a dichos cambios.
VIII) FECHA DE ENTRADA EN VIGENCIA DE LA PRESENTE POLÍTICA Y PERIODO DE VIGENCIA DE LA
BASE DE DATOS
<br/>
La presente Política estará vigente a partir del 1 de junio de 2016. La base de datos asociada a esta
Política, estará vigente por el término máximo que autoricen las leyes aplicables o, en caso de
silencio de la ley al respecto, estará vigente a término indefinido.
IX) DATOS DE CONTACTO DEL RESPONSABLE DEL TRATAMIENTO
<br/>
SYMPLIFICA SAS
<br/>
Dirección y domicilio: Carrera 13 No. 93 - 68 oficina 504 de Bogotá D.C. - Colombia
<br/>
Teléfono: (571) 795 3525
<br/>
Correo electrónico:contactanos@symplifica.com
<br/>
X) JURISDICCIÓN Y LEGISLACIÓN APLICABLE
<br/>
Esta Política se regirá, interpretará y aplicará según las leyes de la República de Colombia.
            </p>
        </div>
    );
}

export {TyC, PP};