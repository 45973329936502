import React, { useState } from 'react';

// BOOTSTRAP
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// COMPONENTS
import { InputText, SubmitButton } from '../../../../../shared/components';

// API
import RegisterRepository from '../../../repository/register.repository';

const PromoForm = ({ client_id, handleNotify, fetchData }) => {

    const [promo_code, setPromoCode] = useState('');
    const [promo_code_err, setPromoCodeErr] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const setValPromoCode = (e) => {
        const pattern = new RegExp('^[A-Za-z0-9]+$', 'i');
        e.persist();
        setPromoCode(e.target.value)
        if (!pattern.test(e.target.value) && e.target.value.length > 0)
            setPromoCodeErr('No es un código válido');
        else
            setPromoCodeErr('');
    }

    const postPromoCode = async () => {
        const formData = {
            promocode_name: promo_code,
            client_id,
        };

        setIsLoading(true);
        const resp = await RegisterRepository.setPromo(formData);
        setIsLoading(false);
        if (resp.success){
            handleNotify('success', 'La promoción se asignó correctamente');
            setPromoCode('');
            fetchData();
        }else
            handleNotify('danger', resp.message);

        return;
    }

    return (
        <Form >
            <Row >
                <Col >
                    <p className='text-center' >¿Tienes un código promocional?</p>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center' >
                <Col className=' col-6 col-lg-5 ' >
                    <InputText 
                        placeholder='Ingresa tu código'
                        value={promo_code}
                        error={promo_code_err}
                        onChange={setValPromoCode}
                    />
                </Col>
                <Col className='col-4 col-lg-2' >
                    <SubmitButton 
                        text='Agregar'
                        variant="light"
                        type="button"
                        disabled={promo_code_err.length > 0 || isLoading}
                        spinner={isLoading}
                        block
                        onClick={postPromoCode}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export { PromoForm }