import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { CardDocument } from 'shared/components';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

// Constants
import { SYImages } from 'shared/constants/images';
import { REGISTER_ADD_METHODS_PAYMENT, APP_ACCOUNT_BILLING, APP_ACCOUNT_CHANGE_PASS } from 'shared/constants/urls';

// Interface
import { Client } from 'shared/interfaces';
import RegisterRepository from 'modules/Register/repository/register.repository';

export const AccountPage: React.FC = () => {

    const client_id = useSelector((state:any) => state.state?.client_id || '' );

    const history = useHistory();
    const { showNotify, handleNotify } = useNotifyState();
    const [client, setClient] = useState<Client>({ id: client_id, name: 'Tus datos personales'});

    useEffect(() => {
        const fetchData = async () => {
            const resp = await RegisterRepository.getClient(client_id);

            if(resp.success)
                setClient(resp.data);
            else
                handleNotify('danger', resp.message);
        }

        fetchData();
    }, []);

    return (
        <AppContainer
            onPressBack={() => history.goBack()}
            title='Mi cuenta'
            showNotify={showNotify}
        >
            <Container>
                <Row>
                    <Col>
                        <div className="app-logo">
                            <img
                                src={SYImages.PROFILE}
                                alt='Logo'
                            />
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center text-center">
                            <h4 className="color-symplifica">{client.name}</h4>
                            <p className="font-1rem p-style">{client.email}</p>
                            <p className="font-1rem p-style">{client.phone}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col className="col-6 col-md-6 col-lg-6">
                        <CardDocument
                            onClick={() => history.push(APP_ACCOUNT_CHANGE_PASS)}
                            text="Cambiar contraseña"
                            icon={SYImages.PADLOCK}
                        />
                    </Col>
                    <Col className="col-6 col-md-6 col-lg-6">
                        <CardDocument
                            onClick={() => history.push(REGISTER_ADD_METHODS_PAYMENT)}
                            text="Mis métodos de pago"
                            icon={SYImages.CREDIT_CARD}
                        />
                    </Col>
                    <Col className="col-6 col-md-6 col-lg-6">
                        <CardDocument
                            onClick={() => history.push(APP_ACCOUNT_BILLING)}
                            text="Facturación"
                            icon={SYImages.BILLING}
                        />
                    </Col>
                </Row>
            </Container>
        </AppContainer>
    );
}