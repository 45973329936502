import React from 'react';

// Utils
import { dolarize } from 'shared/utils/utils';
import { Row, Col } from 'react-bootstrap';

interface AidItemProps {
    text: string;
    value: string | number;
    onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    editable?: boolean;
}

export const AidItem: React.FC<AidItemProps> = ({ text, value, onClick, editable }) => {
    return (
        <Row className='aid-item bg-white shadow d-block rounded-pill p-2 d-flex align-items-center justify-content-between' >
            <Col xs={6} className="aid-text">
                <p className='m-0' >{text}</p>
            </Col>
            <Col xs={5} className="aid-value">
                <p className='m-0' >{dolarize(value)}</p>
            </Col>
            <Col xs={1} className="aid-editable">
                {
                    editable && (
                        <span className='bg-symplifica rounded-circle px-1' >
                            <span
                                className='text-white m-0 pointer'
                                onClick={onClick}
                            >
                                X
                            </span>
                        </span>
                    )
                }
            </Col>
        </Row>
    );
}
