import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Row, Col, Container } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { 
    CardEmpty, 
    CardBell, 
    CardPayment, 
    InputSelect, 
    AccordionCustom, 
    SubmitButton, 
    CardInvoice 
} from 'shared/components';

// Consts
import { 
    APP_DASHBOARD_DOCUMENTS, 
    REGISTER_ADD_METHODS_PAYMENT, 
    APP_DASHBOARD_NOVELTIES 
} from 'shared/constants/urls';

// API
import { PaymentRepository, AppRepository } from 'shared/repository';

// Interface
import { ResponseHttp2, Invoice } from 'shared/interfaces';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

export const PaymentPage: React.FC = () => {

    const history = useHistory();
    const client_id = useSelector( (state:any) => state.state?.client_id || '' );
    const { showNotify, handleNotify } = useNotifyState();
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [listInvoices, setListInvoices] = useState<Array<any>>([]);
    const [listPaymentMethods, setListPaymentMethods] = useState<Array<any>>([]);
    const [payment_method, setPaymentMethod] = useState<string>('');
    const [isAutomaticDebit, setIsAutomaticDebit] = useState<boolean>(false);
    const [paymentValue, setPaymentValue] = useState<number>(0);
    const [paymentsSelected, setPaymentsSelected] = useState<Array<string>>([]);

    // ==============================================
    /// Request to server info initial like invoices and payment methods
    // ==============================================
    const fetchData = async () => {
        setIsLoading(true);

        const resp_methods: Array<{ text: string, value: string }> = await PaymentRepository.getListPaymentMethods(client_id);
        setListPaymentMethods(resp_methods);

        const resp_invoices: ResponseHttp2 = await AppRepository.getListInvoices(client_id);

        if (resp_invoices.success) {
            setIsAutomaticDebit(resp_invoices.data.automatic_debit);
            setListInvoices(resp_invoices.data.invoices);
        }

        setIsLoading(false);
    }

    // ==============================================
    /// post pay from client selection
    // ==============================================
    const postPay = async () => {

        if (paymentsSelected.length === 0) {
            handleNotify('warning', 'Debe seleccionar al menos un item');
            return;
        }
            
        setIsLoading(true);
        const formData:any = {
            amount: paymentValue,
            invoices: paymentsSelected,
            instrument_id: payment_method
        };
        const resp_pay = await AppRepository.postPayInvoices(formData);
        setIsLoading(false);

        if (resp_pay.success) {
            handleNotify('success', resp_pay.message);
            setListInvoices([]);
            fetchData();
        }else {
            handleNotify('danger', resp_pay.message);
        }

    }

    // ==============================================
    /// validate if a payment was selected
    // ==============================================
    const existPayment = (payment:string):boolean => paymentsSelected.indexOf(payment) >= 0;

    // ==============================================
    /// set or delete a payment selection
    // ==============================================
    const tooglePayments = (value:string) => {
        let new_list:Array<string> = [];
        if (existPayment(value)) {
            new_list = paymentsSelected.filter(key => key !== value);
        }else {
            new_list = [...paymentsSelected, value];
        }
        let suma_invoices = listInvoices.reduce((prev: number, current: Invoice) => {
            let suma = prev;
            if (new_list.indexOf(current.id) >= 0) {
                suma += Number(current.total_invoice);
            }
            return suma;
        }, 0);
        setPaymentsSelected(new_list);
        setPaymentValue(suma_invoices);
    }

    // ==============================================
    /// information subtext
    // ==============================================
    const renderSubText = () => {
        return (
            <Row>
                <Col>
                    <p className="font13">
                        Encuentra los detalles de los sueldos que pagas en efectivo en la sección de "Comprobantes de nómina"
                        <a href={APP_DASHBOARD_DOCUMENTS} className="color-symplifica link">&#32; Ir ahora</a>
                    </p>
                </Col>
            </Row>
        );
    }


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AppContainer title="Pagos" onPressBack={() => history.goBack()} showNotify={showNotify}>
            <Container>
                {/* empty invoices */}
                {
                    listInvoices.length == 0 && (
                        <>
                            <CardEmpty
                                text={<h5>¡Tus pagos están al día!<br />Estás haciendo las cosas bien</h5>}
                            />
                            {renderSubText()}
                        </>
                    )
                }

                {/* debit automatic */}
                {
                    isAutomaticDebit && (
                        <CardBell text="Recuerda que estás en débito automático, haremos los pagos por ti. Pero puedes autorizarlos en cualquier momento" />
                    )
                }

                {/* list invoices */}
                {
                    listInvoices.length > 0 && (
                        <>
                            <CardPayment onClick={postPay} value={paymentValue} disabled={isLoading} />

                            <InputSelect
                                value={payment_method}
                                name="payment_method"
                                onChange={event => setPaymentMethod(event.target.value)}
                                options={listPaymentMethods}
                            />

                            <Row>
                                <Col className="d-flex justify-content-end font13">
                                    <a href={REGISTER_ADD_METHODS_PAYMENT} className="link">
                                        agregar método de pago
                                </a>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AccordionCustom group="Tus pagos pendientes">
                                        <>
                                            {
                                                listInvoices.map((key: any) => {
                                                    return (
                                                        <CardInvoice
                                                            key={`card_invoice_${key.id}`}
                                                            label_text={key.label_text}
                                                            selected={existPayment(key.id)}
                                                            setSelected={tooglePayments}
                                                            invoice={key}
                                                        />
                                                    )
                                                })
                                            }
                                        </>
                                    </AccordionCustom>
                                </Col>
                            </Row>

                            {renderSubText()}

                            <Row>
                                <Col>
                                    <SubmitButton
                                        variant="secondary"
                                        block
                                        text="Agregar novedades"
                                        onClick={() => history.push(APP_DASHBOARD_NOVELTIES)}
                                        disabled={isLoading}
                                    />
                                </Col>
                            </Row>
                        </>
                    )
                }
            </Container>
            
        </AppContainer>
);
}   