import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// BOTSTRAP
import { Row, Form, Col } from 'react-bootstrap';

// Components
import { InputText, InputSelect, SubmitButton } from '../../../../../shared/components';
import { AidSelected } from '../components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// API
import RegisterRepository from '../../../repository/register.repository';

const SalaryForm = ({ aids, salary, contract_id, contract_type, handleNotify}) => {

    let defaultAid = {
        id: '',
        text: '',
        value: '',
    };

    const [aidsSelected, setAidsSelected] = useState(defaultAid);
    const [aidsSelectedError, setAidsSelectedError] = useState(defaultAid);
    const [listSalariesAids, setListSalariesAids] = useState([]);

    // =======================================================
    /// add a new aid in the salary contract data
    // =======================================================
    const setNewAid = () => {
        const finded = listSalariesAids.filter( item => item.id === aidsSelected.id );
        const added = inputs.bonus_list.filter( item => item.bonus_type === aidsSelected.id );
        if ( finded.length > 0 || added.length > 0 ) {
            setAidsSelectedError( prev => ({...prev, id: 'Este auxilio ya se ha seleccionado'}));
            return
        };

        if (!aidsSelected.id) {
            setAidsSelectedError(prev => ({ ...prev, id: 'Debes seleccionar un valor' }));
            return;
        }

        if (!aidsSelected.value) {
            setAidsSelectedError(prev => ({ ...prev, value: 'Debes ingresar un valor' }));
            return;
        }

        setListSalariesAids(prev => [...prev, aidsSelected]);

        setAidsSelected(defaultAid);
        setAidsSelectedError(defaultAid);
    }

    // =======================================================
    /// 
    // =======================================================
    const removeAid = (idx) => {
        const newListAid = listSalariesAids.filter( item => item.id !== idx );
        setListSalariesAids(newListAid);
    }

    // =======================================================
    /// 
    // =======================================================
    const updateAidSelected = (event) => {
        event.persist();
        let newValue = {};

        if (event.target.name === 'id') {
            const aux = aids.find( item => item.value === event.target.value );
            newValue.id = event.target.value;
            newValue.text = event.target.value === '' ? '' : aux.text;
        }else
            newValue.value = event.target.value;    

        setAidsSelectedError(defaultAid);
        setAidsSelected(prev => ({ ...prev, ...newValue}));
    }

    // =======================================================
    /// 
    // =======================================================
    const handleSubmitForm = async () => {

        if (!inputs.base_salary) {
            handleError({base_salary: 'No es un salario válido'});
            return;
        }

        let formData = {
            base_salary: Number(inputs.base_salary),
            salary_in_kind_value: contract_type === 2 ? 0 : Number(inputs.salary_in_kind_value),
            bonus_list_old: inputs.bonus_list,
            bonus_list: listSalariesAids.map(item => ({ bonus_type: item.id, amount: item.value })),
        };

        const resp = await RegisterRepository.updateContractSalary(contract_id, formData);

        if (resp.success) {
            window.setTimeout(() => handleNotify('success', resp.message), 2000);
        }else {
            handleNotify('danger', resp.message);
        }
        
    }

    const {
        handleInputChange, handleSubmit, inputs, errors, handleError, setInputs
    } = useCustomForm(salary, handleSubmitForm);

    return(
        <Form
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <Row>
                <Col className='col-md-6 col-12'>
                    <InputText
                        label='Salario Base'
                        type="number"
                        placeholder='$ 0.000.000'
                        name="base_salary"
                        value={`${Number(inputs.base_salary)}`}
                        error={errors.base_salary}
                        onChange={handleInputChange}
                    />
                </Col>
                {
                    contract_type === 2?
                        null
                    :
                    <Col className='col-md-6 col-12'>
                        <InputText 
                            label='Salario en Especie'
                            type="number"
                            placeholder='$ 0.000.000'
                            name="salary_in_kind_value"
                            value={`${Number(inputs.salary_in_kind_value)}`}
                            error={errors.salary_in_kind_value}
                            onChange={handleInputChange}
                        />
                    </Col>
                }
            </Row>
            <Row>
                <Col className='text-center my-3' >
                    <p className='color-symplifica pointer' >Agregar otro Auxilio no salarial</p>
                    <p className='px-5'>Los auxilios No salariales son pagos que haces a tu empleado  para apoyarlo en alguna necesidad adicional relacionada con su trabajo. Por ejemplo, transporte adicional o alimentación. Estos no suman a la Seguridad Social.</p>
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-12'>
                    <InputSelect
                        default_option='Tipo de auxilio'
                        name="id"
                        value={aidsSelected.id}
                        error={aidsSelectedError.id}
                        onChange={updateAidSelected}
                        options={aids}
                    />
                </Col>
                <Col className='col-md-6 col-12'>
                    <Row className='d-table' >
                        <Col className='d-table-cell' >
                            <InputText
                                placeholder="$ 0.000.000"
                                value={aidsSelected.value}
                                error={aidsSelectedError.value}
                                name="value"
                                type="number"
                                onChange={updateAidSelected}
                            />
                        </Col>
                        <Col className='d-table-cell' >
                            <SubmitButton text='+' type="button" className='SubmitButton block btn-more btn btn-primary' onClick={setNewAid} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='bg-gray rounded' >
                {
                    inputs.bonus_list.map((item, i) => {
                        return (
                            <AidSelected
                                key={`aid_${i}`}
                                text={item.name}
                                value={item.amount}
                                onClick={() => removeAid(item.id)}
                            />
                        )
                    })
                }
                {
                    listSalariesAids.map((item, i) => {
                        return (
                            <AidSelected
                                key={`aid_${i}`}
                                text={item.text}
                                value={item.value}
                                editable
                                onClick={() => removeAid(item.id)}
                            />
                        )
                    })
                }
            </Row>
             <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton text='Guardar' block />
                </Col>
            </Row>
        </Form>
    )
}

SalaryForm.propTypes = {
    aids: PropTypes.array,
}

export {SalaryForm};
