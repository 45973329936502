import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// Components
import { RegisterContainer } from 'shared/containers';
import { DocumentCard } from './components';

// API
import { RegisterRepository } from 'shared/repository/register.repository';

// Interface
import { ResponseHttp2 } from 'shared/interfaces';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

export const DocumentEmployerPage: React.FC = () => {

    const { client_id, employer_id } = useSelector<any, any>(state => ({
        client_id: state.state?.client_id || '',
        employer_id: state.state?.employer.id || '',
    }));

    const { showNotify, handleNotify } = useNotifyState();
    const [loadingId, setLoadingId] = useState<string>('');
    const [listDocuments, setListDocuments] = useState<any[]>([]);

    // =======================================================
    /// 
    // =======================================================
    const postData = async (form_data: FormData, idx: string) => {

        setLoadingId(idx);

        const resp: ResponseHttp2 = await RegisterRepository.uploadDocuments(form_data);

        if (resp.success) {
            handleNotify('success', resp.message);
        } else {
            handleNotify('danger', resp.message);
        }
        setLoadingId('');
    }

    useEffect(() => {
        const fetchData = async () => {
            const resp: ResponseHttp2 = await RegisterRepository.getDocumentsEmployer(employer_id);
            console.log("resp", resp);
            setListDocuments(resp.data);
        }

        fetchData();
    }, []);

    return(
        <RegisterContainer 
            title='Documentos' 
            info={{ title: 'Tip', subtitle: 'Asegúrate que la fecha de tu RUT sea superior al 2015. El documento debe estar completamente legible, los números y nombres se deben ver correctamente en la imagen y el código de barras. Si utilizas aplicaciones para escanear, tomar la foto del documento sobre una superficie oscura y con suficiente luz, en lo posible a color. Asegúrate de no cortar partes del documento.'}}
            showNotify={showNotify}
        >
            <Container >
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Sube los documentos para completar el registro</h4>
                    </Col>
                </Row>
                <Row  >
                    <Col className='col-12 col-lg-3' >
                        <p className='color-symplifica' >Documentos empleado</p>
                    </Col>
                    <Col className='col-12 col-lg-9 col-md-9' >
                        <Row >
                            {
                                listDocuments.map( doc => (
                                    <DocumentCard
                                        key={`doc_emp_${doc.document_label}`}
                                        {...doc}
                                        model_name={'Employer'}
                                        callback={postData}
                                        client_id={client_id}
                                        handleNotify={handleNotify}
                                        iddoc={`Employer_${doc.document_label}`}
                                        loading={loadingId === `Employer_${doc.document_label}`}
                                    />
                                ) )
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}
