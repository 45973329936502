import React from 'react'
import { Row, Col } from 'react-bootstrap'

const EmptyState = ({ title, body = true }: { title: string, body?: Boolean }) => {
  return (
    <>
      <Row >
      </Row>
      <Row>
        <Col className='col-12 col-lg-6 text-center'>
          <img src='/icons/reports/img-factura.svg' width="80%" alt="" />
          <p className='bold-grey-symplifica m-0'
            style={{ fontSize: 12, fontWeight: 500 }} >
            No tienes novedades{title ? ` ${title} ` : " "}reportadas.
            </p>
          {body
            ? <p className='bold-grey-symplifica m-0'
              style={{ fontSize: 10, fontWeight: 300 }}>
              Si deseas reportar alguna novedad solo debes dar clic en el botón agregar novedad.
          </p>
            : null}
        </Col>
      </Row>
    </>
  )
}

export default EmptyState