import React from 'react';
import { useLocation } from 'react-router-dom';

// Bootstrap
import { Nav } from 'react-bootstrap';

import {
    APP_DASHBOARD_HOME,
    APP_DASHBOARD_EMPLOYEES,
    APP_DASHBOARD_AFILIATIONS,
    APP_DASHBOARD_PAYMENTS,
    APP_DASHBOARD_NOVELTIES,
    APP_DASHBOARD_DOCUMENTS,
} from 'shared/constants/urls';

export const FooterNav: React.FC = () => {

    const { pathname } = useLocation();
    let links = [
        { link: APP_DASHBOARD_HOME, icon: "home", text: "Inicio" },
        { link: APP_DASHBOARD_EMPLOYEES, icon: "employees", text: "Empleados" },
        { link: APP_DASHBOARD_AFILIATIONS, icon: "afiliations", text: "Afiliaciones" },
        { link: APP_DASHBOARD_PAYMENTS, icon: "pays", text: "Pagos" },
        { link: APP_DASHBOARD_NOVELTIES, icon: "novelties", text: "Novedades" },
        { link: APP_DASHBOARD_DOCUMENTS, icon: "documents", text: "Documentos" },
    ]
    let selectedIndex = links.findIndex(item => pathname === item.link)
    return (
        <Nav className="fixed-bottom" justify variant="tabs" defaultActiveKey={pathname} style={{ boxShadow: "none" }}>
            {links.map((item, index) =>
                <Nav.Item
                    style={{
                        boxShadow: `${pathname !== item.link ? "0px -8px 8px -5px  rgba(190, 209, 209, 0.45)" : "none"}`,
                        borderRadius: `${index - 1 === selectedIndex ? "10px" : "0px"} ${index + 1 === selectedIndex ? "10px" : "0px"} 0px 0px`,
                    }}>
                    <Nav.Link disabled={pathname === item.link} href={item.link}>
                        <div style={{
                            borderRadius: "0 0 150px 150px",
                            boxShadow: `${pathname === item.link ? " inset rgba(190, 209, 209, 0.45) 0px -6px 5px -1px" : ""}`
                        }}>
                            <img
                                style={{ width: "50%", filter: `${pathname !== item.link ? "grayscale(100%)" : ""} ` }}
                                alt=""
                                src={`/icons/menus/${item.icon}.svg`} />
                        </div>
                        {item.text}
                    </Nav.Link>
                </Nav.Item>
            )}
        </Nav>
    );
}