import React from 'react';

// BOOTSTRAP
import { Col } from 'react-bootstrap';

interface Props {
    title?: string;
    value?: string;
}

export const Field: React.FC<Props> = ({ title, value }) => {

    return (
        <Col className='col-12 col-lg-3 py-3' >
            <p className='text-bold my-0' >{title}</p>
            <p>{value}</p>
        </Col>
    )
}
