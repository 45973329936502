import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CompleteModal, SubmitButton, InputSelect } from '../../../../../shared/components';

import { Container, Row, Col, Form } from 'react-bootstrap';

// API
import GeneralRepository from '../../../repository/general.repository';
import RegisterRepository from '../../../repository/register.repository';

const ModalEPS = ({ open, showModal, handleNotify, contract, callback}) => {

    const [listEPSs, setListEPSs] = useState([]);
    const [ss_entity, setSsEntity] = useState("");
    const [ss_entityError, setSsEntityError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (contract && contract.id) {
                setIsLoading(true);
                const resp = await GeneralRepository.getEPS(contract.workplace.city);
                setIsLoading(false);
                setListEPSs(resp);
            }
        }
        
        fetchData();

    }, [contract]);

    // =======================================================
    /// post data to the server, add or update EPS entity to employee
    // =======================================================
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!ss_entity) {
            setSsEntityError("Debes seleccionar una FC");
            return;
        }

        const formData = {
            contract_id: contract && contract.hasOwnProperty("id") ? contract.id : '',
            employer_id: contract && contract.hasOwnProperty("employer") ? contract.employer.id : '',
            employee_id: contract && contract.hasOwnProperty("employee") ? contract.employee.id : '',
            ss_entity
        }

        setIsLoading(true);
        const resp = await RegisterRepository.setEmployerSecuritySuscription(formData);
        setIsLoading(false);

        if( resp.success ){
            handleNotify('success', resp.message);
            showModal();
            callback();
        }else
            handleNotify('danger', resp.message);

    }


    return (
        <CompleteModal open={open} closeAction={showModal}>
            <Container >
                <div className='max-w-600 mx-auto'>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <Col className='text-center my-5'>
                                <h4 className='color-symplifica'>Salud EPS</h4>
                                <p className='text-bold'>¿A qué entidad está afiliado?</p>
                            </Col>
                        </Row>
                        <Row className="d-lg-flex justify-content-center">
                            <Col>
                                <div>
                                    <InputSelect 
                                        default_option="Escoge la entidad de salud EPS"
                                        name="eps"
                                        value={ss_entity}
                                        error={ss_entityError}
                                        disabled={isLoading}
                                        onChange={event => {
                                            setSsEntity(event.target.value);
                                            setSsEntityError('');
                                        }}
                                        options={listEPSs}
                                    />
                                </div>
                                <p className='text-center'>
                                *Tu emplea  do debe venir registrado en esta entidad. Si desea realizar un traslado deberás pedirlo más adelante una vez la afiliación a tu nombre esté completa.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SubmitButton
                                    block
                                    variant="primary"
                                    text="Guardar"
                                    disabled={isLoading}
                                    spinner={isLoading}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </CompleteModal>
    );
};

ModalEPS.propTypes = {
    open: PropTypes.bool,
};

export {ModalEPS};