import React from 'react';

// Bootstrap
import { Row, Col, Card } from 'react-bootstrap';

interface CardDocumentProps {
    text: string;
    icon: string;
    onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void);
    className?: string;
}

export const CardDocument: React.FC<CardDocumentProps> = ({ text, icon, className='', onClick=()=>{} }) => {
    return (
        <Card className="my-2 card-container" onClick={onClick}>
            <Card.Body className={`d-flex align-items-center card-body-documents ${ className }`}>
                <Row className="content-card">
                    <Col xs={4} md={4} lg={4} className="d-flex justify-content-center container-img">
                        <img className="img-responsive" src={icon} alt={icon}/>
                    </Col>
                    <Col xs={8} md={8} lg={8} className="d-flex justify-content-start align-items-center font11 grey-text padding-0">
                        {text}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}