import React, { useState, useEffect } from 'react';
import { InputSelect } from 'shared/components';
import { Container, Card, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import Bonus from "./CreateScreens/Bonus"
import ReportsRepository from './repository';

import { AppContainer } from 'shared/containers';
import ExtraTime from './CreateScreens/ExtraTime';
import WorkedDays from './CreateScreens/WorkedDays';
import Holidays from './CreateScreens/Holidays';
import Permission from './CreateScreens/Permission';
import Licences from './CreateScreens/Licences';
import Incapacity from './CreateScreens/Incapacity';
import Absences from './CreateScreens/Absences';
import Loans from './CreateScreens/Loans';
import { HELP_CENTER_REPORTS_WHICH_TO_REPORT } from 'shared/constants/app';

const CreateNewReport = ({ employer, stateMachine, sendToMachine, employeeDropdown, employees }) => {


  const SelectEmployee = ({ employeeDropdown }) => {
    let options = [{ text: "Escoge tu empleado", value: "" }, ...employeeDropdown]
    return (<>
      <InputSelect
        onChange={e => sendToMachine({ type: "EMPLOYEESELECTION", data: { employee: e.target.value } })}
        name="employee-selector"
        options={options}
        disabled={!employeeDropdown.length}
      />
    </>)
  }
  const EmployeeInfoCard = ({ employees, stateMachine }) => {
    let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
    return (
      <div
        style={{
          width: "82vw",
          padding: "4%",
          display: "grid",
          gridTemplateColumns: "20% 1fr 1fr 15%",
          gridTemplateRows: "1fr 1fr",
          alignItems: "center",
          borderRadius: "20px",
          border: "2px solid #F5F5F5",
          marginTop: 12,
          marginBottom: 12,
          marginInline: "auto"
        }}>
        <img
          alt=""
          style={{
            gridColumn: "1/2", gridRow: "1/2", filter: "grayscale(100%) opacity(80%)",
            borderRadius: "1000px",
            border: "2px solid #dddddd"
          }}
          src="/icons/reports/user.svg"
          width="80%" />
        <div
          style={{ gridColumn: "2/5", gridRow: "1/2" }}
        >
          <h3
            style={{ textTransform: "capitalize", fontSize: "0.85em", color: "#565656", fontWeight: "500", marginBottom: "0px" }}
          >
            {`${employee.employee.first_name} ${employee.employee.last_name}`}
          </h3>
          <p
            style={{ margin: "0px", fontWeight: "lighter" }}
          >
            {`C.C. ${employee.employee.doc_number ? employee.employee.doc_number : "-"}`}
          </p>
        </div>
        <div
          style={{
            gridColumn: "2/3",
            gridRow: "2/3",
            borderRight: "1px solid #E0E0E0"
          }}
        >
          <p
            style={{ margin: "0px", fontWeight: "lighter" }}
          >
            Inicio contrato
            </p>
          <p
            style={{ margin: "0px", fontWeight: "lighter" }}
          >
            {` ${employee.start_date.split("T")[0]}`}
          </p>
        </div>
        <div
          style={{ gridColumn: "3/4", gridRow: "2/3", paddingLeft: "2%", justifySelf: "center" }}
        >
          <p
            style={{ margin: "0px", fontWeight: "lighter" }}
          >
            Cargo
            </p>
          <p
            style={{ margin: "0px", fontWeight: "lighter" }}
          >
            Empleado
            </p>
        </div>
      </div>
    )
  }
  let reportTypes = [
    { eventName: "prestamos", stateName: "loan" },
    { eventName: "licencias", stateName: "licence" },
    { eventName: "ausencias", stateName: "absence" },
    { eventName: "vacaciones", stateName: "holidays" },
    { eventName: "permisos", stateName: "permission" },
    { eventName: "bonificaciones", stateName: "bonus" },
    { eventName: "horas-extra", stateName: "extraTime" },
    { eventName: "incapacidades", stateName: "incapacity" },
    { eventName: "dias-trabajados", stateName: "workedDays" },
  ]
  const ReportTypeGrid = () => {
    let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
    return (
      <div
        style={{
          width: "80%",
          margin: "auto",
          paddingTop: "10px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "4% 15px",
          justifyItems: "center"
        }}
      >
        {reportTypes.map(reportType => {
          /* don't show the worked days button if it is not a part-time employee */
          if ((reportType.stateName === "workedDays" && employee.contract_type !== "2")
            || (reportType.stateName === "absence" && employee.contract_type === "2"))
            return null
          return <ReportTypeButton type={reportType.eventName} key={reportType.stateName} />
        })}
      </div>
    )
  }
  const ReportTypeButton = ({ type }) => {
    return (
      <button
        onClick={e => sendToMachine({ type: "SELECTREPORTTYPE", data: { reportType: type } })}
        id={type}
        style={{
          border: "none",
          minWidth: "100px",
          boxShadow: "1px 3px 9px 1px #f2f2f2, -1px 3px 9px 1px #f2f2f2",
          width: "100%",
          borderRadius: "22px",
          backgroundColor: "white",
          paddingTop: 15,
        }}>
        <Image
          style={{ filter: "grayscale(100%) opacity(80%)", width: "60%" }}
          src={`/icons/reports/${type}.svg`} fluid />
        <p
          style={{
            textAlign: "center", textTransform: "capitalize",
            marginTop: 7,
            fontSize: 9, color: "#565656"
          }}
        >
          {type.replace(/-/gi, " ")}
        </p>
      </button>
    )
  }
  const stateMatcher = () => {
    let currentView = <></>
    if (stateMachine.matches("selectEmployee")) {
      currentView = <>
        <AppContainer title="Novedades" onPressBack={() => sendToMachine({ type: "BACK" })}>
          <a
            href={HELP_CENTER_REPORTS_WHICH_TO_REPORT}
            style={{
              fontSize: 10,
              textDecoration: "underline",
              width: "100%", textAlign: "center",
              display: "flex", alignContent: "center",
              justifyContent: "flex-end", marginTop: "1em",
              marginBottom: "1em"
            }}>
            <img alt="" src="/icons/reports/interrogation-circle.svg"
              style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
            <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
              No sé que novedad reportar
            </p>
          </a>
          <h3
            style={{
              fontSize: 14,
              width: "100%",
              color: "#00cccb",
              textAlign: "center",
            }}>
            Selecciona el empleado al que desees agregarle una novedad
          </h3>
          <SelectEmployee employeeDropdown={employeeDropdown} />
        </AppContainer>
      </>
    }
    else if (stateMachine.matches({ selectReportType: "reportTypeGrid" })) {
      currentView = <>
        <AppContainer title="Novedades"
          onPressBack={() => sendToMachine({ type: "GOHOME" })}
          showFooter={false}>
          <a
            href={HELP_CENTER_REPORTS_WHICH_TO_REPORT}
            style={{
              fontSize: 10,
              textDecoration: "underline",
              width: "100%", textAlign: "center",
              display: "flex", alignContent: "center",
              justifyContent: "flex-end", marginTop: "1em",
              marginBottom: "1em"
            }}>
            <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
            <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
              No sé que novedad reportar
            </p>
          </a>
          <h3
            style={{
              fontSize: 14,
              width: "100%",
              color: "#00cccb",
              textAlign: "center",
            }}>
            Selecciona el tipo de novedad
          </h3>
          <SelectEmployee employeeDropdown={employeeDropdown} />
          <EmployeeInfoCard employees={employees} stateMachine={stateMachine} />
          <ReportTypeGrid />
        </AppContainer>
      </>
    }
    else {
      let currentReportState = Object.keys(stateMachine.value.selectReportType)
      switch (currentReportState[0]) {
        case "bonus":
          currentView = <Bonus stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "extraTime":
          currentView = <ExtraTime stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "workedDays":
          currentView = <WorkedDays
            employees={employees}
            stateMachine={stateMachine}
            sendToMachine={sendToMachine} />
          break
        case "holidays":
          currentView = <Holidays stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "permission":
          currentView = <Permission stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "licence":
          currentView = <Licences stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "incapacity":
          currentView = <Incapacity stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "absence":
          currentView = <Absences stateMachine={stateMachine} sendToMachine={sendToMachine} />
          break
        case "loan":
          currentView = <Loans
            employees={employees}
            stateMachine={stateMachine}
            sendToMachine={sendToMachine} />
          break
        default:
          break;
      }
    }
    return currentView
  }
  return (
    <>
      {stateMatcher()}
    </>
  )
}
const mapStateToProps = ({ state }) => {
  return {
    employer: state.employer,
    client_id: state.client_id
  };
}

const CreateNewReportPage = connect(mapStateToProps, null)(CreateNewReport);
export { CreateNewReportPage }