import { useState } from 'react';

const useCustomForm = (state:any, callback:()=>void) => {
    const [inputs, setInputs] = useState<any>(state);
    const [errors, setErrors] = useState<any>({});

    const handleSubmit = (event: React.FormEvent) => {
        if (event) {
            event.preventDefault();
        }
        callback();
    }

    const handleInputChange = (event: React.FormEvent) => {
        event.persist();
        const data:any = event.target;
        setErrors((errors:any) => ({ ...errors, [data.name]: '' }));
        setInputs((inputs:any) => ({ ...inputs, [data.name]: data.value }));
    }

    const setInputChange = (name:string, value:any) => {
        setErrors((errors:any) => ({ ...errors, [name]: '' }));
        setInputs((inputs:any) => ({ ...inputs, [name]: value }));
    }

    const handleCheckChange = (event: React.FormEvent) => {
        event.persist();
        const data: any = event.target;
        setErrors((errors:any) => ({ ...errors, [data.name]: '' }));
        setInputs((inputs:any) => ({ ...inputs, [data.name]: data.checked }));
    }

    const handleError = (error:any) => {
        setErrors((errors:any) => ({ ...errors, ...error }));
    }

    return {
        handleSubmit,
        handleInputChange,
        handleCheckChange,
        setInputChange,
        inputs,
        errors,
        handleError,
        setInputs,
    };
}

export default useCustomForm;