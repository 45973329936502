import React from 'react';
import PropTypes from 'prop-types'

// BOOTSTRAP
import {Row, Col} from 'react-bootstrap';


// COMPONENTS
import { SubmitButton } from '../../../../../shared/components';
import { Field } from './Field';
import { timeToString, dateToString } from '../../../../../shared/utils/utils';


const ContractDetailTab = ({ action, contract, isLoading, id, setIsLoading }) => {

    return (
        <Row className='my-5'>
            <Col>

                <Row>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='¿Ya inscribiste a tu empleado en la seguridad social a tu nombre?' value={contract.health_subscription_type ? 'SI' : 'NO'} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Término de Contrato' value={contract.contract_term === 'fixed' ? 'Fijo' : 'Indefinido'} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Fecha de Inicio' value={dateToString(contract.init_date)} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='Fecha Final' value={dateToString(contract.end_date) || '0000-00-00'} />
                    </Col>
                </Row>
                <hr></hr>
                <Row >
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Cargo' value={contract.position_name} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Nivel de riesto ARL' value={contract.arl_rate_name} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Dirección del Trabajo' value={contract.workplace_name || ''} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='Ubicación del Empleado' value={contract.work_location_name} />
                    </Col>
                </Row>
                <hr></hr>
                <Row >
                    <Col md={3} xs={6} sm={12}>
                        <Field title='Días Trabajados' value={contract.working_days} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='Horarios' value={`${timeToString(contract.start_time)} a ${timeToString(contract.end_time)}`} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='Frecuencia de Pago' value={contract.payment_freq == 1 ? 'Mensual' : 'Quincenal'} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='Método de pago de Nómina' value={contract.payroll_payment_method == 1 ? "Efectivo" : "Daviplata" } />
                    </Col>
                </Row>
                <hr></hr>
                <Row >
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='¿Cómo va a estar cubierta en Salud?' value={contract.health_contribution_type_name} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field pred={!contract.updated_once} title='¿Está obligado a pagar pensión?' value={contract.saves_for_retirement_name} />
                    </Col>
                    <Col md={3} xs={6} sm={12}>
                        <Field title='¿Cuántos dias de vacaciones ha disfrutado?' value={contract.available_vacation_days} />
                    </Col>
                </Row>

                <Row className='d-lg-flex justify-content-center my-5' >
                    <Col className='col-lg-4' >
                        <SubmitButton 
                            disabled={isLoading}
                            spinner={isLoading}
                            onClick={action} 
                            text='Editar' 
                            block 
                        />
                    </Col>
                </Row>
                <Row >
                    <Col className='col-2' >
                        <p className='badge-warning'>Predeterminado</p>
                    </Col>
                    <Col >
                        <p className='text-12' >*Los campos marcados como predeterminado son sugerencias basadas en el comportamiento regular de un contrato de trabajo. Por favor revisalas y si alguna no concuerda con tu relación laboral, cámbiala.</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

ContractDetailTab.propTypes = {
    action: PropTypes.func,
    contract: PropTypes.object,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
};

export {ContractDetailTab};