import React from 'react';

import { Row, Col } from 'react-bootstrap';

interface CardBellProps {
    text: string;
}

export const CardBell: React.FC<CardBellProps> = ({text}) => {
    return (
        <div className="card-bell">
            <Row>
                <Col xs={2} md={3} className='d-flex align-items-center justify-content-center padding-right-0'>
                    <img src="/icons/cards/bell-ico.png" alt='bell' width={50} />
                </Col>
                <Col xs={10} md={9} className="d-flex align-items-center">
                    <p className='my-0 card-bell-text'>{text}</p>
                </Col>
            </Row>
        </div>
    );
}