import React from 'react';

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap';

// COMPONENT
import { SubmitButton } from 'shared/components';

// IMAGES
import ProfileIcon from 'assets/images/header-user.png';
import OffIcon from 'assets/images/ico-off.png';
import MenuIcon from 'assets/images/ico-house.png';

// Constants
import { DASHBOARD_PAGE } from 'shared/constants/app';

interface RegisterNavProps {
    title: string | React.ReactNode;
    action: () => void;
}

export const RegisterNav: React.FC<RegisterNavProps> = ({ title, action }) => {
    return (
        <Row className='RegistroContainer-nav' >
            <Col className='col-md-4 d-flex align-items-center' >
                <h1 className='color-symplifica' >{title}</h1>
            </Col>
            <Col className='col-md-8 align-items-center d-flex justify-content-end' >
                <div className='RegistroContainer-contact-container' >
                    <div className='RegistroContainer-contact-container-top' >
                        <span>
                            <p className='color-symplifica' >Llámanos</p>
                            <p>(571) 743 7648</p>
                        </span>
                        <img
                            src={ProfileIcon}
                            alt='ProfileIcon'
                        />
                    </div>
                    <Row className='justify-content-end d-flex' >
                        <Col className='col-md-4  col-xl-3' >
                            <SubmitButton
                                className='px-2 btn-panel-control ellipsis'
                                variant="secondary"
                                type="button"
                                onClick={() => window.open(DASHBOARD_PAGE, '_self')}
                                block
                            >
                                <>
                                    <img src={MenuIcon} style={{ height: 15, marginRight: 5 }} alt='Off' />
                                            Tablero de control
                                        </>
                            </SubmitButton>
                        </Col>
                        <Col className='col-md-4 col-xl-3' >
                            <SubmitButton
                                className='px-2 btn-panel-control'
                                variant="secondary"
                                block
                                onClick={action}
                            >
                                <>
                                    <img src={OffIcon} style={{ height: 15, marginRight: 5 }} alt='Off' />
                                            Cerrar sesión
                                        </>
                            </SubmitButton>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}