import React, { useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputDate } from 'shared/components';
import { APP_HELP_CENTER } from 'shared/constants/app';

const InfoScreen = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  let liStyle = {
    backgroundImage: "url(/icons/reports/permisos.svg)",
    backgroundPosition: "-5 0",
    backgroundSize: "1em 1em",
    backgroundRepeat: "no-repeat",
    paddingLeft: "-2em",
    width: "1em",
    height: "1em",
    minWidth: "1em"
  }
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
          <li style={{ fontSize: 12 }}>
            Son días en los que tu empleado dejó de asistir a trabajar sin justificación aparente.
          </li>
          <li style={{ fontSize: 12 }}>
            Debes hacer un proceso de descargos para entender la situación y, según este, decidir si pagar sueldo por estos días.
          </li>
          <li style={{ fontSize: 12 }}>
            No pagas auxilio de transporte.
          </li>
          <li style={{ fontSize: 12 }}>
            Afectan la Seguridad Social.
          </li>
        </ul>
        <a
          href={APP_HELP_CENTER}
          style={{ textAlign: "center", display: "flex", alignContent: "center", justifyContent: "center" }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const AbsencesForm = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  let [will_pay, setWillPay] = useState(true)
  let [init_date, setInitialDate] = useState<Date | null>(null)
  let [end_date, setEndDate] = useState<Date | null>(null)

  const DateSelector = () => {
    return (
      <div>
        <p style={{ textAlign: "center", marginTop: 15, fontSize: 12 }}>Reporta las fechas de la ausencia</p>
        <InputDate
          value={init_date}
          onChange={(name, date, event) => setInitialDate(date)}
          placeholder="Fecha inicio"
          name="startDate"
          label=""
        />
        <InputDate
          name="endDate"
          value={end_date}
          onChange={(name, date, event) => setEndDate(date)}
          placeholder="Fecha fin"
          label=""
        />
      </div>
    )
  }

  const WillPaySwitch = () => {
    return (
      <div
        style={{ display: "flex", width: "100%", marginTop: 15, marginBottom: 15, justifyContent: "space-between" }}
      >
        <p style={{ marginTop: "auto", marginBottom: "auto", width: "60%" }}>
          ¿Deseas pagar sueldo por estos días?
        </p>
        <button
          name="willPay"
          onClick={() => setWillPay(!will_pay)}
          style={{
            width: "25%",
            height: "2em",
            padding: "5px",
            border: "none",
            borderRadius: "20px",
            boxShadow: "0 3px 5px 1px rgba(190, 209, 209, 0.45)",
            backgroundColor: "white",
            marginLeft: 7, marginRight: 7
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }}>
            {will_pay
              ? <>
                <p
                  style={{
                    margin: "0",
                    padding: "none",
                    height: "1.5em",
                    lineHeight: "1.5em",
                    width: "50%"
                  }}>Sí</p>
                <div
                  style={{
                    margin: "0",
                    padding: "none",
                    height: "1.5em",
                    width: "25px",
                    backgroundColor: "#00CCCB",
                    borderRadius: "100%"
                  }}></div>
              </>
              : <>
                <div
                  style={{
                    margin: "0",
                    padding: "none",
                    height: "1.5em",
                    width: "25px",
                    backgroundColor: "#565656",
                    borderRadius: "100%"
                  }}></div>
                <p
                  style={{
                    margin: "0",
                    padding: "none",
                    lineHeight: "1.5em",
                    height: "1.5em",
                    width: "50%"
                  }}>No</p>
              </>}
          </div>
        </button>
      </div >
    )
  }

  return (
    <div style={{ width: "100%" }}>
      <DateSelector />
      <WillPaySwitch />
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => sendToMachine({
          type: "COMPLETE",
          data: {
            init_date: init_date ? init_date.toISOString().split("T")[0] : "",
            end_date: end_date ? end_date.toISOString().split("T")[0] : "",
            will_pay
          }
        })}
      >
        Guardar
      </Button>
    </div >
  )
}

const Absences = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean },
    sendToMachine: (arg0: {}) => void
  }
) => {

  return (
    <>
      <AppContainer title="Ausencias" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        {stateMachine.matches({ selectReportType: { absence: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} />
          : <AbsencesForm sendToMachine={sendToMachine} />}
      </AppContainer>
    </>
  )
}
export default Absences