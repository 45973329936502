import React from 'react';

// Bootstrap
import { Row, Col } from 'react-bootstrap';

// ICONS
import UserIcon from 'assets/ico/cont-icon-user.png';

// Components
import { CardOnboarding } from './CardOnboarding';

// CONSTS
import { REGISTER_LIST_METHODS_PAYMENT, REGISTER_BASIC_DATA, REGISTER_DOCUMENTS_EMPLOYER } from 'shared/constants/urls';


const CardEmployer = ({employer, paymethods, history}) => {

    const hasDataBasic = employer.hasOwnProperty('id') && employer.id !== null;

    const hasPaymethods = paymethods.length > 0;
    const hasDocuments = employer.ss_is_ready && employer.docs_is_ready;

    const fullName = hasDataBasic ? `${employer.first_name} ${employer.last_name}` : 'Tus documentos';

    return (
        <div>
            <Row >
                <Col className='d-flex align-items-center py-3'  >
                    <img height={30} className='mr-2' src={UserIcon} alt='icon' />
                    <p className='my-0' >
                        {fullName}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="person" 
                        done={hasDataBasic} 
                        title='Datos básicos' 
                        onClick={() => history.push(REGISTER_BASIC_DATA)} 
                    />
                </Col>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="document" 
                        done={hasDocuments} 
                        title='Documentos'
                        onClick={() => history.push(REGISTER_DOCUMENTS_EMPLOYER)} 
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="paymethod" 
                        done={hasPaymethods} 
                        title='Métodos de pago' 
                        onClick={() => history.push(REGISTER_LIST_METHODS_PAYMENT)}
                    />
                </Col>
            </Row>
        </div>
    );
};

CardEmployer.propTypes = {
    
};

export {CardEmployer};