import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputDate } from 'shared/components';
import { HELP_CENTER_REPORTS_PERMISSION_KNOW_MORE } from 'shared/constants/app';

const InfoScreen = ({ sendToMachine, screen }:
  { screen: number, sendToMachine: (arg0: {}) => void }) => {
  const infoText: { [key: string]: string[] } = {
    5: [
      "Son días libres que otorgas a tu empleado bajo tu criterio.",
      "No debes pagar sueldo ni auxilio de transporte.",
      "Afecta los pagos a seguridad social por este tiempo.",
    ],
    4: [
      "Son días libres que otorgas a tu empleado bajo tu criterio.",
      "No debes pagar sueldo ni auxilio de transporte.",
      "Debes pagar normalmente la seguridad social.",
    ]
  }

  const currentInfo = infoText[screen]
  const InfoList = ({ currentInfo }: { currentInfo: string[] }) =>
    <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
      {currentInfo.map((element, index) =>
        <li key={index} style={{ fontSize: 12 }}>
          {element}
        </li>
      )}
    </ul>

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <InfoList currentInfo={currentInfo} />
        <a
          href={HELP_CENTER_REPORTS_PERMISSION_KNOW_MORE}
          style={{
            width: "100%", textAlign: "center",
            display: "flex", alignContent: "center",
            justifyContent: "center", marginTop: "1em",
            marginBottom: "1em",
            textDecoration: "underline"
          }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto", textDecoration: "underline" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

        <p style={{ fontSize: 12, textAlign: "center" }}>
          ¿Estás buscando licencias de maternidad, calamidad doméstica, etc?
        </p>
        <button
          style={{ fontSize: 12, background: "none", border: "none", color: "#00CCCB", textDecoration: "underline" }}
          onClick={() => sendToMachine("REPORTLICENCE")}>
          Repórtalas en Licencias.
        </button>
      </div>
    </Container >
  )
}
const PermissionForm = ({ sendToMachine, permissionType }:
  { permissionType: number, sendToMachine: (arg0: {}) => void }) => {
  let [init_date, setInitialDate] = useState<null | Date>(null)
  let [end_date, setEndDate] = useState<null | Date>(null)


  const DateSelector = () => {
    return (
      <div
        className="border-b-gray"
      >
        <p style={{ textAlign: "center", marginTop: 15, fontSize: 12 }}>Reporta las fechas del permiso</p>
        <InputDate
          value={init_date}
          onChange={(name, date, event) => setInitialDate(date)}
          placeholder="Fecha inicio"
          name="startDate"
          label=""
        />
        <InputDate
          name="endDate"
          value={end_date}
          onChange={(name, date, event) => setEndDate(date)}
          placeholder="Fecha fin"
          label=""
        />
      </div>
    )
  }

  return (
    <div style={{ width: "100%" }}>
      <DateSelector />
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => sendToMachine({
          type: "COMPLETE",
          data: {
            init_date: init_date ? init_date.toISOString().split("T")[0] : "",
            end_date: end_date ? end_date.toISOString().split("T")[0] : "",
            type: permissionType
          }
        })}
      >
        Guardar
      </Button>
    </div >
  )
}

const TabButtons = ({ setPermissionType, permissionType }:
  { permissionType: number, setPermissionType: (arg0: number) => void }) => {
  return (
    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: 12
    }}>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${permissionType === 4 ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${permissionType === 4 ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setPermissionType(4)}
      >
        Pago
      </button>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${permissionType === 5 ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${permissionType === 5 ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setPermissionType(5)}
      >
        No Pago
      </button>
    </div>
  )
}

const Permission = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean },
    sendToMachine: (arg0: {}) => void
  }
) => {
  let [permissionType, setPermissionType] = useState(4)

  return (
    <>
      <AppContainer title="Permisos" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        <TabButtons permissionType={permissionType} setPermissionType={setPermissionType} />
        {stateMachine.matches({ selectReportType: { permission: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} screen={permissionType} />
          : <PermissionForm sendToMachine={sendToMachine} permissionType={permissionType} />}
      </AppContainer>
    </>
  )
}
export default Permission