import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import { Container, Row, Col, Button, Image, Accordion, Card } from 'react-bootstrap';

// CONTAINER
import { AppContainer } from 'shared/containers';

// COMPONENTS
import { CardSS } from 'shared/components';

// Constants
import { PAGE_LINK, APP_HELP_CENTER } from 'shared/constants/app';

import { RegisterRepository } from 'shared/repository';
import { REGISTER_CREATE_CONTRACT } from 'shared/constants/urls';

const SocialSecurity = ({ employer }) => {

  let [contracts, setContracts] = useState([]);

  useEffect(() => {
    // calls the endpoint
    const fetchData = async () => {
      const resp = await RegisterRepository.getEmployeeSocialSecurity(employer.id);

      if (resp.success)
        setContracts(resp.data);
    }

    fetchData();
  },
    [])
  return (
    <AppContainer title='Afiliaciones' >
      <Container style={{ height: '100%' }}>

        <Row className='d-block d-lg-flex justify-content-center my-5' >
          <Col className='col-12 col-lg-6 text-right'>
            <img
              style={{ height: "1em", width: "1em" }}
              src="/icons/social_security/interrogation-circle.svg"
              alt=""
            />
            <a href={APP_HELP_CENTER} className='color-symplifica'>
              ¿Qué es Afiliaciones?
            </a>
          </Col>
        </Row>
        {contracts.length
          ?
          <Row className='d-block d-lg-flex justify-content-center my-5' >
            <Col>
              <a
                className='shadow border-r-10 my-5'
                href={`${PAGE_LINK}/register/document/list-documents`}
                target="_blank" rel="noopener noreferrer">
                <div className='my-4 shadow border-r-10 p-2'
                  style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                  <Image src={`/icons/social_security/ico-doc-edit.svg`} fluid></Image>
                  <p style={{ margin: "0px", paddingLeft: "1em" }}>
                    Ver planillas
                  </p>
                </div >
              </a>

            </Col>
          </Row>
          : null
        }
        <Row className='border-b-symplifica' >
          <Col>
            <p className='color-symplifica m-0 text-bold' >
              Las entidades de tus empleados
            </p>
          </Col>
        </Row>
        {
          contracts?.length
            ?
            <>
              {contracts.map((employee, index) =>
                <Accordion defaultActiveKey="0" key={index}>
                  <EmployeeSS eventKey={index} currentEmployee={employee} />
                </Accordion>
              )}
              <FloatingButton />
            </>
            : <EmptyState />
        }
      </Container>
    </AppContainer >
  )
}
const EmployeeSS = ({ eventKey, currentEmployee }) => {
  let { start_date, employee } = currentEmployee
  let { first_name, last_name } = employee
  let nameArray = employee.afiliaciones.map(el => {
    return {
      ss: el.employer_inscription.ss_entity.ss_subsystem,
      name: el.employer_inscription.ss_entity.name
    }
  })
  const ssKeys = Object.keys(nameArray)
  return (
    <Card style={{ border: "none" }}>
      <Accordion.Toggle className="width100" as={Button} variant="link" eventKey={`${eventKey}`}>
        <div style={{ width: "100%", display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ width: "80%", textAlign: "center" }}>
            <img src="/icons/social_security/cont-icon-user.png"
              style={{ height: "40px" }}
              alt=""
            />
          </div>
          <div className="width100" style={{ width: "100%", flexGrow: 5, textAlign: "left" }}>
            <p style={{ whiteSpace: "nowrap", margin: 0 }}>
              Activo desde {start_date}
            </p>
            <h5 style={{ whiteSpace: "nowrap", textTransform: "capitalize" }}>
              {`${first_name} ${last_name}`}
            </h5>
          </div>
          <div className="width100" style={{ width: "100%", textAlign: "right", padding: "auto" }}>
            <Image src="/icons/social_security/cont-icon-arrow.png" roundedCircle fluid />
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${eventKey}`}>
        <Card.Body>
          {ssKeys.map((socialsec, index) =>
            index % 2
              ? <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "1em"
                }}>
                <CardSS
                  title={nameArray[index - 1].ss}
                  value={nameArray[index - 1].name}
                  onClick={() => { }} />
                <CardSS
                  title={nameArray[index].ss}
                  value={nameArray[index].name}
                  onClick={() => { }} />
              </div>
              : null
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

const EmptyState = () => {

  return (
    <>
      <Row>
        <Col className='col-12 col-lg-6 text-center'>
          <img src='/icons/social_security/img-factura.svg' width={"100%"} alt="" />
          <p className='bold-grey-symplifica m-0' >
            Parece que no tienes empleados activos, crea uno ahora.
          </p>
        </Col>
      </Row>
      <Row >
        <Col className='col-12 col-lg-6 text-center'>
          <a href={REGISTER_CREATE_CONTRACT}>
            <Button
              style={{
                color: 'white',
                backgroundColor: 'red-symplifica',
                borderRadius: 50,
                width: '80%'

              }}>
              Crear empleado
          </Button>
          </a>
        </Col>
      </Row>
    </>
  )
}
const FloatingButton = () =>
  <a
    style={{
      margin: "auto",
      padding: "auto",
      borderRadius: "100",
      textDecoration: "none",
    }}
    href="/">
    <Card
      style={{ borderRadius: "100px", padding: "4%" }}
      className="text-center shadow border-r-10">
      <p style={{ padding: "0px", margin: "0px" }}>
        Conoce más sobre seguridad social
        </p>
    </Card>
  </a>
const mapStateToProps = ({ state }) => {
  return {
    employer: state.employer,
    client_id: state.client_id
  };
}

const DashSocialSecurityPage = connect(mapStateToProps, null)(SocialSecurity);

export { DashSocialSecurityPage }
