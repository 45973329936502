import React from 'react';

// BOOTSTRAP
import { InputGroup, FormGroup, Col, Form} from 'react-bootstrap';

import { TooltipBasic, TypeTooltipBasic } from '../Tooltips';

import { Choice } from 'shared/interfaces'; 

interface InputSelectProps {
    options: Choice[];
    className?: string;
    default_option?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    error?: string;
    name: string;
    label?: string;
    value?: string;
    disabled?: boolean;
    tooltip?: TypeTooltipBasic;
}

export const InputSelect: React.FC<InputSelectProps> = ({ 
    tooltip, label, options = [], className, name, disabled, value, default_option, onChange, error
}) => {

    return(
        <Col className={className ? className : `px-0 ${className}`} >
            <FormGroup>
                {
                    label && <p className='text-bold my-1' >{label}</p>
                }
                <InputGroup className={`input-text-no-border-r`}>

                    <Form.Control id={`select_${name}`} name={name} as='select'
                        className={`input-select ${className} ${error && error.length > 0 ? 'input-error' : ''}`}
                        onChange={onChange}
                        disabled={disabled}
                        value={value}
                    >

                        {
                            default_option && (<option key={`n_option`} value={''}>{default_option}</option>)
                        }

                        {
                            options.map((item, i) => <option key={`${i}_option`} value={item.value}>{item.text}</option>)
                        }

                    </Form.Control>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                            <div className="chevron"></div>

                            {tooltip && <TooltipBasic {...tooltip} />}

                        </InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>
                
                {
                    error && error.length > 0 && (
                            <Form.Text className="text-muted input-text-error">
                                {error}
                            </Form.Text>
                        )
                }
            </FormGroup>
            
        </Col>
    )
}