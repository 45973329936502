import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { SubmitButton } from 'shared/components';

import IconUser from 'assets/ico/cont-icon-user.png';

// constans
import { REGISTER_ONBOARDING_PAGE } from 'shared/constants/urls';

interface RegisterEmployeeProps {
    text: string;
}

export const RegisterEmployee: React.FC<RegisterEmployeeProps> = ({ text }) => {

    const history = useHistory();    

    return (
        <Row className="my-2 register-employee-container">
            <Col xs={2} className="padding-0">
                <img src={IconUser} />
            </Col>
            <Col xs={5} className="padding-0">
                <span>{text}</span>
            </Col>
            <Col xs={5} className="padding-0">
                <SubmitButton className="font-rem-08" variant="dark" onClick={() => history.push(REGISTER_ONBOARDING_PAGE)} text="Completar registro" />
            </Col>
        </Row>
    );
}