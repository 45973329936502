import React from 'react';
import PropTypes from 'prop-types';

// DATEPICKER
import "react-datepicker/dist/react-datepicker.css";

// BOOSTRAP
import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

// COMPONENTS 
import DatePicker from 'react-datepicker';

// Icons
import DownIcon from '../../../assets/ico/cont-icon-arrow.png';

const InputTime = ({placeholder, className, error, value, name, onChange}) => {

    return(
        <FormGroup>
            <InputGroup className={`input-text`} >
                <DatePicker
                    className={`input-text form-control ${className} datepicker  ${error && error.length > 0 ? 'input-error' : ''}`}
                    placeholderText={placeholder}
                    type="text"
                    name={name}
                    id={name}
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="hh:mm"
                    onChange={date => onChange(name, date)}
                />
                <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                        <label htmlFor={name} ><div className="chevron"></div> </label>
                    </InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
            {
                error && error.length > 0
                    ? (
                        <Form.Text className="text-muted input-text-error">
                            {error}
                        </Form.Text>
                    )
                    : null
            }
        </FormGroup>
    )
}

InputTime.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
}

export {InputTime};