
export class Employer {

    id = null;
    first_name = "";
    middle_name = "";
    last_name = "";
    mothers_name = "";
    creation_date = null;
    is_active = false;
    is_ready = false;
    ss_is_ready = false;
    docs_is_ready = false;
    client_id = null;
    client = null;
    employer_terms_aceptance_file = null;
    front_doc_file = null;
    back_doc_file = null;
    secondary_id_file = null;
    tax_id_file = null;
    bank_certification = null;

}
