import React from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import { Row, Col, Image } from 'react-bootstrap';

// ICONS
import EditIcon from '../../../assets/ico/cont-icon-edit.png';

const CardSS = ({ title, value, onClick }) => {
    let showTitle = ""
    switch (title) {
        case "AFP":
            showTitle = "Fondo de Pensiones"
            break
        case "CCF":
            showTitle = "Caja de compensación familiar"
            break
        case "CES":
            showTitle = "Fondo de cesantías"
            break
        default:
            showTitle = title
            break;
    }
    return (
        <div
            className='my-4 shadow border-r-10 p-2 bg-grey-symplifica'
            style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <div style={{ flexGrow: 1, width: "20%" }}>
                <img
                    style={{ width: "40px" }}
                    src={`/icons/social_security/${title.toLowerCase()}.svg`} />
            </div>
            <div style={{ flexGrow: 4 }}>
                <p className='my-0' >
                    <span className="bold-grey-symplifica text-bold">{showTitle}:</span> {value}
                </p>
            </div>
        </div>
    )
}

export { CardSS }
