import React, { useState } from 'react';
import { History } from 'history';

// Bootstrap
import { Row, Col } from 'react-bootstrap';

// Components
import { SubmitButton, CardDocument } from 'shared/components';

// Interfaces
import { Contract } from 'shared/interfaces';

// Icons
import IconUser from 'assets/ico/cont-icon-user.png';

// Constants
import { 
    APP_NOVELTIES_ADD_PAYROLL, 
    APP_DOCUMENTS_SOCIAL_SECURITY, 
    APP_EMPLOYEES_LIQUIDATIONS,
    APP_EMPLOYEES_CONTRACT,
    APP_EMPLOYEES_DETAIL,
    APP_PAYMENTS_HISTORY,
} from 'shared/constants/urls';

// Utils
import { valueChoice } from 'shared/utils/utils';
import { TypeChoice } from 'shared/utils/emuns';

interface ActiveEmployeeProps {
    contract: Contract;
    history: History;
}

export const ActiveEmployee: React.FC<ActiveEmployeeProps> = ({contract, history}) => {

    const [show, setShow] = useState<boolean>(false);

    return (
        <div>
            <Row className="my-2 active-employee-container">
                <Col xs={2} className="padding-0">
                    <img src={IconUser} />
                </Col>
                <Col xs={6} className="padding-0">
                    <div className="d-flex flex-column">
                        <span className="font13">{contract.employee?.first_name} {contract.employee?.last_name}</span>
                        <span className="font12 color-darkgrey">{valueChoice( TypeChoice.POSITION, contract.position)}</span>
                    </div>
                </Col>
                <Col xs={3} className="padding-0">
                    <div className="d-flex flex-column">
                        <span className="font12 color-darkgrey">Trabaja desde</span>
                        <span className="font12 color-darkgrey">{contract.start_date}</span>
                    </div>
                </Col>
                <Col xs={1} className="padding-0 text-center" onClick={() => setShow( prev => !prev )}>
                    <span className={`chevron ${!show || 'up-arrow'}`}></span>
                </Col>
            </Row>
            {
                show && (
                    <div className="active-employee-container-docs">
                        <Row>
                            <Col xs="4" className="padding-0 d-flex justify-content-center">
                                <SubmitButton 
                                    className="lite-button font11 color-darkgrey" 
                                    variant="secondary" 
                                    onClick={() => history.push(APP_NOVELTIES_ADD_PAYROLL)} 
                                    text="Agregar Novedad" 
                                />
                            </Col>
                            <Col xs="4" className="padding-0 d-flex justify-content-center">
                                <SubmitButton 
                                    className="lite-button font11 color-darkgrey" 
                                    variant="secondary" 
                                    onClick={() => history.push(APP_DOCUMENTS_SOCIAL_SECURITY)} 
                                    text="Planillas" 
                                />
                            </Col>
                            <Col xs="4" className="padding-0 d-flex justify-content-center">
                                <SubmitButton 
                                    className="lite-button font11 color-darkgrey" 
                                    variant="secondary" 
                                    onClick={() => history.push(APP_EMPLOYEES_LIQUIDATIONS)} 
                                    text="Liquidar" 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <CardDocument 
                                    onClick={() => history.push(APP_EMPLOYEES_CONTRACT, {contract})} 
                                    text="Datos del contrato" 
                                    icon="/icons/cards/contract.png"
                                />
                            </Col>
                            <Col xs="6">
                                <CardDocument 
                                    onClick={() => history.push(APP_EMPLOYEES_DETAIL, { employee_id: contract.employee?.id })} 
                                    text="Datos personales" 
                                    icon="/icons/cards/personal_data.png"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <CardDocument 
                                    onClick={() => history.push(APP_PAYMENTS_HISTORY)} 
                                    text="Historico de sueldo" 
                                    icon="/icons/cards/pig.png"
                                />
                            </Col>
                            <Col xs="6"></Col>
                        </Row>
                    </div>
                )
            }
        </div>
    );
}