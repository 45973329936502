import React, { useState } from 'react';
import Timer from 'react-compound-timer';

// BOOTSTRAP
import {Form, Col, Row} from 'react-bootstrap';

// COMPONENTS
import { InputText, LinkButton } from '../../../../../shared/components';

import { AccessControl } from '../../../repository/auth.repository';

const timerFormat = (time) => {
    return String(parseInt(time / 1000)).padStart(2, '0');
}

const RecoveryPasswordForm = ({ history, forgotPass, handleNotify, setForgotPass }) => {

    const [posteo, setPosteo] = useState(true);
    const [posteoEnviado, setPosteoEnviado] = useState(false);
    const [code_password, setCodePassword] = useState('');

    window.setTimeout(() => {
        setPosteo(false);
        setPosteoEnviado(true);
        setCodePassword('');
    }, 30000);

    const postCodePassword = async (value) => {
        setPosteo(true);

        const resp = await AccessControl.sendCodeOTP(value);

        if (resp.success) {
            setForgotPass({ ...forgotPass, otp_code: value });
            history.push('/auth/change-password');
        } else {
            handleNotify('danger', 'Código incorrecto', true);
        }

        window.setTimeout(() => {
            setPosteo(false);
            setPosteoEnviado(true);
        }, 30000);

    }

    const reSendEmailPhone = async (email, type) => {
        const resp = await AccessControl.forgotPass(email, type);

        if (resp.success)
            handleNotify('success', 'Se envió un nuevo código a tu email/celular', true);
        else
            handleNotify('danger', 'El correo/celular no existe', true);
    }

    return (
        <Col >
            <Form>
                <InputText
                    type="number"
                    placeholder='000000'
                    className='my-3'
                    name="code_password"
                    value={code_password}
                    onChange={(event) => {
                        event.persist();

                        if (event.target.value.length <= 6)
                            setCodePassword(event.target.value);

                        if (event.target.value.length === 6)
                            postCodePassword(event.target.value);
                    }}
                />
                <Row>
                    <Col className='d-flex justify-content-center'>
                        {
                            posteo
                                ? (
                                    <Timer
                                        initialTime={30000}
                                        direction="backward"
                                    >
                                        {({ getTime }) => (
                                            <div>
                                                00:{timerFormat(getTime())}
                                            </div>
                                        )}
                                    </Timer>
                                )
                                : posteoEnviado
                                    ? (
                                        <LinkButton
                                            text='Reenviar'
                                            action={(e) => {
                                                reSendEmailPhone(forgotPass.email, forgotPass.type);
                                            }}
                                        />
                                    )
                                    : null
                        }
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export { RecoveryPasswordForm }
