import React, { useState } from "react";

// BOOTSTRAP
import {Accordion, Row, Col} from "react-bootstrap";

// ICONS
import ArrowIcon from '../../../../../assets/ico/cont-icon-arrow.png'

// Utils
import { dateToString } from '../../../../../shared/utils/utils';

// Components
import { InputToggle } from "../../../../../shared/components";

// API
import RegisterRepository from '../../../repository/register.repository';

const AccordionPromo = ({ data, onChange, handleNotify }) => {

    const [aplicado, setAplicado] = useState(data.promo_code.label === 'aplicado');

    const onChangePromo = async (e) => {
        e.persist();
        let resp = null;
        setAplicado(e.target.checked);

        if (e.target.checked) {
            resp = await RegisterRepository.applyPromoCode({ promo_code_id: data.id });
        }else {
            resp = await RegisterRepository.unapplyPromoCode({ promo_code_id: data.id });
        }

        if (resp.success) {
            handleNotify('success', 'Se realizó el cambio correctamente');
            onChange();            
        } else {
            setAplicado(!e.target.checked);
            handleNotify('danger', resp.message);
        }

    }

    return(
        <div >
            <Row className='py-3' >
                <Col >
                    <Accordion >
                        <Accordion.Toggle as={Row} eventKey={data.id} >
                            <Col>
                                <Row>
                                    <Col >
                                        <p className='m-0' >{data.promo_code.product_category.name}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <p className='m-0' >Código: {data.promo_code.name}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row >
                                    <Col >
                                        <Row>
                                            <Col >
                                                <p className='m-0 text-12 text-right' >Descuento: {Number(data.promo_code.discount_value)}%</p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col>
                                                <p className='m-0 text-right' >Producto: {data.promo_code.product_category.name}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='col-2' >
                                        <img className='m-3' src={ArrowIcon} alt='^' />
                                    </Col>
                                </Row>
                            </Col>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={data.id} >
                            <>
                                <Row>
                                    <Col >
                                        <Row >
                                            <Col>
                                                <p className='text-12' >{data.promo_code.description}</p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className='accordion-promo-card m-3 p-2' >
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >Asignado</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >{dateToString(new Date(data.promo_code.assigned_start_date))}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='accordion-promo-card m-3 p-2' >
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >Redimible desde</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >{dateToString(new Date(data.promo_code.redeem_start_date))}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='accordion-promo-card m-3 p-2' >
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >Redimible hasta</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <p className='m-0' >{dateToString(new Date(data.promo_code.redeem_end_date))}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {
                                            data.promo_code.label === 'no aplicable'
                                                ? <span>No aplicable</span>
                                                : (
                                                    <InputToggle
                                                        textos={{ active: 'Aplicado', inactive: 'Aplicar' }}
                                                        value={aplicado}
                                                        name="prueba"
                                                        onChange={onChangePromo}
                                                    />
                                                )
                                        }
                                    </Col>
                                </Row>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
            </Row>
        </div>
    )
}

export { AccordionPromo };