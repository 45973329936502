import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// Components
import { SubmitButton, BackTitle, ModalDeleteBeneficiary } from 'shared/components';
import { RegisterContainer } from 'shared/containers';
import { DocumentCard } from './components';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

// ICONS
import TrashIcon from 'assets/ico/cont-icon-trash.png';

// API
import {RegisterRepository} from 'shared/repository/register.repository';

// Interface
import {ResponseHttp2, Contract} from 'shared/interfaces';

export const DocumentEmployeePage: React.FC = () => {

    const history = useHistory();
    const params:any = useParams();
    const { showNotify, handleNotify } = useNotifyState();

    const contract: Contract = useSelector<any, any>(state => (
        state.state?.contracts && params?.id ? state.state?.contracts.find( c => c.id == params?.id ) : {}
    ) );

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [idBeneficiary, setIdBeneficiary] = useState<string>('');
    const [loadingId, setLoadingId] = useState<string>('');
    const [listDocuments, setListDocuments] = useState<any[]>([]);
    const [listBeneficiaries, setListBeneficiaries] = useState<any[]>([]);

    // =======================================================
    /// 
    // =======================================================
    const postData = async (form_data: FormData, idx:string) => {

        setLoadingId(idx);

        const resp = await RegisterRepository.uploadDocuments(form_data);

        if (resp.success) {
            handleNotify('success', resp.message);
        } else {
            handleNotify('danger', resp.message);
        }
        setLoadingId('');
    }

    useEffect(() => {
        const fetchData = async () => {
            const resp: ResponseHttp2 = await RegisterRepository.getDocumentsEmployee({ 
                employee_id: contract?.employee_id || '', 
                contract_id: contract?.id || ''
            });
            console.log("resp", resp);
            if( resp.success ) {
                setListDocuments(resp.data.employee_documents);
                setListBeneficiaries(resp.data.beneficiaries);
            }
        }

        fetchData();
    }, []);

    // ==============================================
    /// Show modal to delete a beneficiary
    // ==============================================
    const deleteBeneficiary = (beneficiary_id: string) => {
        setIdBeneficiary(beneficiary_id);
        setOpenModal(true);
    }

    return(
        <RegisterContainer 
            title={<BackTitle history={history} text="Documentos" />} 
            info={{title:'Tip', subtitle: 'El documento debe estar completamente legible, los números y nombres se deben ver correctamente en la imagen y el código de barras. Si utilizas aplicaciones para escanear, tomar la foto del documento sobre una superficie oscura y con suficiente luz, en lo posible a color. Asegúrate de no cortar partes del documento.'}} 
            showNotify={showNotify}
        >
            <ModalDeleteBeneficiary id={idBeneficiary} open={openModal} showModal={setOpenModal} handleNotify={handleNotify} callback={()=>{}} />
            <Container >
                <Row className='justify-content-center'>
                    <Col className='col-lg-9'>
                        <h4 className="color-symplifica text-center my-5" >Sube estos documentos tomando fotos desde tu dispositivo o subiendo los archivos si los tienes a la mano.</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12 col-lg-3' >
                        <p className='color-symplifica' >Documentos empleado</p>
                    </Col>
                    <Col className='col-12 col-lg-9 col-md-9' >
                        <Row>
                            {
                                listDocuments.map(doc =>
                                    <DocumentCard
                                        key={`doc_emp_${doc.document_label}`}
                                        {...doc}
                                        model_name={'Employee'}
                                        callback={postData}
                                        client_id={contract.employee?.client_id || ''}
                                        handleNotify={handleNotify}
                                        iddoc={`Employee_${doc.document_label}`}
                                        loading={loadingId === `Employee_${doc.document_label}`}
                                    />)
                            }
                        </Row>
                    </Col>
                    
                </Row>
                {
                    listBeneficiaries.map( benf => (
                        <Row className='mt-5' key={`benf_${benf.id}`}>
                            <Col className='col-12 col-md-3 col-lg-3' >
                                <p className='color-symplifica my-0' >Documentos:</p>
                                <p className='color-symplifica' >{benf.first_name} {benf.last_name}</p>
                                <Row >
                                    <Col className='col-9 col-md-9 col-lg-9'>
                                        <p className='text-12' >Si no tienes los documentos del beneficiario, puedes removerlo y agregarlo más adelante</p>
                                    </Col>
                                    <Col className='col-3 col-md-3 col-lg-3 padding-0'>
                                        <SubmitButton variant="secondary" onClick={() => deleteBeneficiary(benf.id)}>
                                            <img src={TrashIcon} height={30} alt='trash' />
                                        </SubmitButton>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-12 col-md-9 col-lg-9' >
                                <Row>
                                    {
                                        benf.docs.map(doc =>
                                            <DocumentCard
                                                key={`doc_ben_${doc.document_label}`}
                                                {...doc}
                                                model_name="Beneficiary"
                                                employee_id={benf.employee}
                                                client_id={benf.client_id}
                                                callback={postData}
                                                handleNotify={handleNotify}
                                                iddoc={`beneficiary_${benf.id}_${doc.document_label}`}
                                                loading={loadingId === `beneficiary_${benf.id}_${doc.document_label}`}
                                            />)
                                    }
                                </Row>
                            </Col>
                        </Row>
                    ) )
                }
            </Container>
        </RegisterContainer>
    )
}