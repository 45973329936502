import store from '../../redux/storages/global';

// =======================================================
/// return the token from the redux state
// =======================================================
export const getToken = () => {
    const { token } = store.getState().state;
    return { "Authorization": token };
}

// =======================================================
/// return a new array of choices
// =======================================================
export const newChoice = (data) => {
    return data.map(item => {
        const nameKey = Object.keys(item)[0];
        return { value: nameKey, text: item[nameKey] };
    });
}


// =======================================================
/// handle the response from server according to status code
// =======================================================
export const parseResponse = async (response) => {

    const { status } = response;

    try {

        switch (status) {
            case 200:
                const data_200 = await response.json();

                if (data_200 && data_200.error_message)
                    return {
                        success: false,
                        message: data_200.error_message,
                        data: null,
                        errors: data_200
                    };
                else
                    return {
                        success: true,
                        message: 'El proceso se completó correctamente',
                        data: data_200.hasOwnProperty('data') ? data_200.data : data_200
                    };

            case 201:
                return {
                    success: true,
                    message: 'Se ha creado correctamente',
                    data: null
                };

            case 204:
                const data_204 = await response.json();

                if (data_204 && data_204.error_message)
                    return {
                        success: false,
                        message: data_204.error_message,
                        data: null,
                        errors: data_204
                    };
                else
                    return {
                        success: true,
                        message: 'Se ha actualizado correctamente',
                        data: data_204.hasOwnProperty('data') ? data_204.data : data_204
                    };

            case 400:

                const data_400 = await response.json();
                return {
                    success: false,
                    message: data_400.error_message || 'No se ha podido finalizar el proceso',
                    data: null,
                    errors: data_400
                };

            case 401:
                const data_401 = await response.json();

                return {
                    success: false,
                    message: data_401.detail || 'No tiene acceso a este recurso',
                    data: null,
                    errors: data_401
                };

            case 404:
                const data_404 = await response.json();

                return {
                    success: false,
                    message: data_404.error_message || 'No tiene acceso a este recurso',
                    data: null,
                    errors: data_404
                };

            case 409:
                const data_409 = await response.json();

                return {
                    success: false,
                    message: data_409.error_message || 'Se generó un conflicto de información',
                    data: null,
                    errors: data_409
                };

            case 500:
                const data_500 = await response.json();

                return {
                    success: false,
                    message: data_500.error_message || 'Se presentó un problema desde el servidor',
                    data: null,
                    errors: data_500
                };

            default:
                const resp_error = await response.json();
                return {
                    success: false,
                    message: resp_error.error_message || `No ha sido posible completar el proceso (${status})`,
                    data: null,
                    errors: { error_code: status }
                };
        }
    } catch (error) {
        return {
            success: false,
            message: error.message,
            data: null,
            errors: { error_code: status }
        };
    }

}
