import React from 'react';

// BOOSTRAP
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from 'react-bootstrap/FormGroup';
interface Props { 
    label?: string;
    placeholder?: string;
    className?: string;
    name: string
    type?: string;
    onChange: any;
    value: string;
    icon?: any;
    onIconClick?: any;
    error?: any;
    disabled?: boolean
    min?: string | number;
    max?: string | number;
    style?: React.CSSProperties;
}

export const InputText: React.FC<Props> = ({ label, placeholder, className, name, type = 'text', onChange, value, icon, error, disabled, min, max, onIconClick, style}) => {

    return(
        <FormGroup style={style}>
            {
                label ?
                    <p className='text-bold my-1' >{label}</p>
                    :
                    null
            }
            <InputGroup className={`${className}  ${icon ? 'input-text-no-border-r ' : null} input-text`} >
                <Form.Control
                    name={name}
                    className={`input-text ${icon ? 'input-text-no-border-r ' : null} ${error && error.length > 0 ? 'input-error' : ''}`}
                    placeholder={placeholder}
                    type={type}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
                {
                    icon ?
                        <InputGroup.Prepend onClick={()=>onIconClick()}>
                            <InputGroup.Text id="inputGroupPrepend"><img style={{ height: 20 }} src={icon} alt='icon' /></InputGroup.Text>
                        </InputGroup.Prepend>
                        :
                        null
                }
            </InputGroup>
            {
                error && error.length > 0
                    ? (
                        <Form.Text className="text-muted input-text-error">
                            {error}
                        </Form.Text>
                    )
                    : null
            }
        </FormGroup>
    )
}
