import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Bootstrap
import { Row, Col, Form } from 'react-bootstrap';

// Components
import { 
    InputToggle, InputSelect, InputText, InputDate, GroupForm, InputCheck, SubmitButton 
} from '../../../../../shared/components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

import RegisterRepository from '../../../repository/register.repository';
import GeneralRepository from '../../../repository/general.repository';

// Utils
import { validateName, validateNumber } from '../../../../../shared/utils/validate';

const BeneficiaryForm = ({ choices, employee_id, handleNotify, history, isLoading, setIsLoading }) => {

    const [listCities, setListCities] = useState([]);

    // =======================================================
    /// Request data cities by state choiced
    // =======================================================
    const getCities = async (state) => {
        setIsLoading(true);
        const resp = await GeneralRepository.getCities(state);
        setListCities(resp);
        setIsLoading(false);
    }

    // =======================================================
    /// Validate form data
    // =======================================================
    const validate = () => {
        let errors = 0;

        if (!inputs.first_name || inputs.first_name.length === 0) {
            handleError({ first_name: 'Por favor ingresa un Primer Nombre válido' });
            errors++;
        } else if (!validateName(inputs.first_name)) {
            handleError({ first_name: 'Por favor ingresa un Primer Nombre válido' });
            errors++;
        }

        if (!inputs.last_name || inputs.last_name.length === 0) {
            handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
            errors++;
        } else if (!validateName(inputs.last_name)) {
            handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
            errors++;
        }

        if (inputs.middle_name && !validateName(inputs.middle_name)) {
            handleError({ middle_name: 'Por favor ingresa un Segundo Nombre válido' });
            errors++;
        }

        if (inputs.mothers_name && !validateName(inputs.mothers_name)) {
            handleError({ mothers_name: 'Por favor ingresa un Segundo Apellido válido' });
            errors++;
        }

        if (!inputs.birthdate || inputs.birthdate.length === 0) {
            handleError({ birthdate: 'No es una fecha válida' });
            errors++;
        }

        if (!inputs.document_type || inputs.document_type.length === 0) {
            handleError({ document_type: 'Seleccione un tipo de documento' });
            errors++;
        }

        if (!inputs.birth_country || inputs.birth_country.length === 0) {
            handleError({ birth_country: 'Seleccione un país' });
            errors++;
        }

        if (!inputs.birth_state || inputs.birth_state.length === 0) {
            handleError({ birth_state: 'Seleccione un departamento' });
            errors++;
        }

        if (!inputs.birth_city || inputs.birth_city.length === 0) {
            handleError({ birth_city: 'Seleccione una ciudad' });
            errors++;
        }

        if (!inputs.document_number || inputs.document_number.length < 5 || !validateNumber(inputs.document_number)) {
            handleError({ document_number: 'Ingresa un No. de documento válido' });
            errors++;
        }

        if (!inputs.relationship || inputs.relationship.length === 0) {
            handleError({ relationship: 'Este valor es obligatorio' });
            errors++;
        }

        if (!inputs.gender || inputs.gender.length === 0) {
            handleError({ gender: 'Seleccione un género' });
            errors++;
        }

        return errors;
    }

    // =======================================================
    /// 
    // =======================================================
    const handleSubmitForm = async () => {
        console.log('formData', inputs);

        if ( validate() > 0 ) return;

        // seteo employee_id
        const formData = {
            ...inputs,
            employee_id
        }

        // posteo de data
        const resp = await RegisterRepository.setEmployerBeneficiary(formData);

        // validar respuesta
        if (resp.success) {
            handleNotify('success', resp.message);
            window.setTimeout(() => history.goBack(), 2000);
        }else
            handleNotify('danger', resp.message);

    }


    const {
        handleSubmit, handleInputChange, inputs, errors, handleError, setInputChange, handleCheckChange
    } = useCustomForm({
        gender: '',
        subscribe_eps: true,
        subscribe_ccf: true,
        ccf_benefit: true,
        relationship: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        mothers_name: '',
        birth_country: '57',
        birth_state: '',
        birth_city: '',
        birthdate: null,
        document_type: '',
        document_number: '',
    }, handleSubmitForm);

    // =======================================================
    /// 
    // =======================================================
    const changeStatusCCF = (event) => {
        handleCheckChange(event);

        if (event.target.name === 'subscribe_ccf' && !event.target.checked) {
            setInputChange('ccf_benefit', event.target.checked);
        }

        if (event.target.name === 'ccf_benefit' && event.target.checked) {
            setInputChange('subscribe_ccf', event.target.checked);
        }

    }

    return (
        <Form
            onSubmit={handleSubmit}
        >
            <Row>
                <Col className="col-12 col-lg-6">
                    <Row>
                        <Col className="col-12 col-lg-5">
                            <p>¿A qué entidades quiere afiliar al beneficiario?</p>
                        </Col>
                        <Col className="col-12 col-lg-7">
                            <Row>
                                <Col className="col-7 col-lg-6"><p className='color-symplifica'>Afiliar a EPS</p></Col>
                                <Col className="col-5 col-lg-5 text-right">
                                    <InputToggle
                                        name="subscribe_eps"
                                        value={inputs.subscribe_eps}
                                        error={errors.subscribe_eps}
                                        onChange={handleCheckChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7 col-lg-6"><p className='color-symplifica'>Afiliar a caja</p></Col>
                                <Col className="col-5 col-lg-5 text-right">
                                    <InputToggle
                                        name="subscribe_ccf"
                                        value={inputs.subscribe_ccf}
                                        error={errors.subscribe_ccf}
                                        onChange={changeStatusCCF}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12 col-lg-6">
                    <Row>
                        <Col className="col-8">
                            <p className='color-symplifica'>
                                Si tu empleado tiene hijos puede recibir subsidio familiar dado por la Caja de Compensación.
                                Para ciertas edades es necesario presentar Certificado Escolar. ¿Deseas incluirlo?
                                </p>
                        </Col>
                        <Col className="col-4 text-right">
                            <InputToggle
                                name="ccf_benefit"
                                value={inputs.ccf_benefit}
                                error={errors.ccf_benefit}
                                onChange={changeStatusCCF}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputSelect
                        name="relationship"
                        error={errors.relationship}
                        value={inputs.relationship}
                        onChange={handleInputChange}
                        default_option='Parentesco'
                        disabled={choices.listRelationship.length === 0}
                        options={choices.listRelationship}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputText 
                        name="first_name"
                        error={errors.first_name}
                        value={inputs.first_name}
                        onChange={handleInputChange} 
                        placeholder='Primer nombre' 
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputText 
                        name="middle_name"
                        error={errors.middle_name}
                        value={inputs.middle_name}
                        onChange={handleInputChange} 
                        placeholder='Segundo nombre' 
                    />
                </Col>
            </Row>

            <Row>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputText 
                        name="last_name"
                        error={errors.last_name}
                        value={inputs.last_name}
                        onChange={handleInputChange} 
                        placeholder='Primer apellido' 
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputText 
                        name="mothers_name"
                        error={errors.mothers_name}
                        value={inputs.mothers_name}
                        onChange={handleInputChange} 
                        placeholder='Segundo apellido' 
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputSelect
                        name="birth_country"
                        error={errors.birth_country}
                        value={inputs.birth_country}
                        onChange={handleInputChange}
                        default_option='País de residencia'
                        options={[
                            {value: '57', text: 'Colombia'}
                        ]}
                    />
                </Col>
            </Row>

            <Row>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputSelect 
                        name="birth_state"
                        error={errors.birth_state}
                        value={inputs.birth_state}
                        onChange={(event) => {
                            handleInputChange(event);
                            getCities(event.target.value);
                        }}
                        default_option='Departamento de residencia'
                        disabled={choices.listStates.length === 0}
                        options={choices.listStates}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputSelect 
                        name="birth_city"
                        error={errors.birth_city}
                        value={inputs.birth_city}
                        onChange={handleInputChange}
                        disabled={listCities.length === 0}
                        default_option='Ciudad de residencia'
                        options={listCities}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputDate
                        placeholder='Fecha de nacimiento'
                        name='birthdate'
                        showYearDropdown
                        showMonthDropdown
                        value={inputs.birthdate}
                        error={errors.birthdate}
                        onChange={setInputChange}
                    />
                </Col>
            </Row>

            <Row>
                <Col className='col-lg-4 col-12 my-2'>
                    <GroupForm error={errors.gender} text="Género">
                        <div className="d-sm-table-cell px-2"><InputCheck name="gender" onChange={handleInputChange} checked={inputs.gender === 'F'} text="Femenino" value="F" id="female" /></div>
                        <div className="d-sm-table-cell px-2"><InputCheck name="gender" onChange={handleInputChange} checked={inputs.gender === 'M'} text="Masculino" value="M" id="male" /></div>
                    </GroupForm>
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputSelect
                        name="document_type"
                        error={errors.document_type}
                        value={inputs.document_type}
                        onChange={handleInputChange}
                        default_option='Tipo de documento'
                        disabled={choices.listDocTypes.length === 0}
                        options={choices.listDocTypes}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <InputText
                        name="document_number"
                        type="number"
                        error={errors.document_number}
                        value={inputs.document_number}
                        onChange={handleInputChange}
                        placeholder='Número de documento' 
                    />
                </Col>
            </Row>

            <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton 
                        text='Guardar' 
                        block 
                        disabled={isLoading}
                        spinner={isLoading}
                    />
                </Col>
            </Row>
        </Form>
    );
};

BeneficiaryForm.propTypes = {
    handleNotify: PropTypes.func,
    choices: PropTypes.object,
};

export {BeneficiaryForm};