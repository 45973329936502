import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';

// Components
import { SubmitButton } from '../Buttons';

// Utils
import { dolarize } from 'shared/utils/utils';

interface CardPaymentProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    value: number;
    disabled?: boolean;
}

export const CardPayment: React.FC<CardPaymentProps> = ({ onClick, value, disabled=false }) => {
    return (
        <Card className="card-payment-container my-2 add-shadow">
            <Card.Body>
                <Row>
                    <Col xs={8} md={8}>
                        <Card.Subtitle className="mb-2 card-payment-text" style={{ fontSize: "0.75rem" }}>Total a pagar</Card.Subtitle>
                        <Card.Title className="card-payment-value" style={{ fontSize: "1.1rem" }}>{dolarize(value)}</Card.Title>
                    </Col>
                    <Col xs={4} md={4} className="padding-none">
                        <SubmitButton 
                            className="card-payment-button" 
                            variant="secondary" 
                            text="pagar" 
                            onClick={onClick} 
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}