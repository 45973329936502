import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// CONTAINER
import {RegisterContainer} from '../../../../shared/containers';
import { TabHorizontal, Notify } from '../../../../shared/components';

// Forms
import { CreditCardForm, BankAccountForm } from './forms';

// API
import GeneralRepository from '../../repository/general.repository';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

const NewPaymentMethod = ({ client_id, email, history }) => {

    const [tipoDoc, setTypeDocs] = useState([]);
    const [listBanks, setListBanks] = useState([]);
    const [listAccountType, setListAccountTypes] = useState([]);
    const { showNotify, handleNotify } = useNotifyState();

    // =======================================================
    /// Solicita los datos de tipos de documento
    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            const tipoDoc = await GeneralRepository.getDocumentTypes();
            setTypeDocs(tipoDoc);
            const bancos = await GeneralRepository.getBanks();
            setListBanks(bancos);
            const accounts = await GeneralRepository.getAccountTypes();
            setListAccountTypes(accounts);
            return;
        }
        fetchData();
        return;
    }, []);

    return(
        <RegisterContainer 
            title='Métodos de pago' 
            info={{ title: 'Ten en cuenta que', subtitle: 'Luego de registrar tu método de pago, tu banco puede tardar hasta 3 días hábiles en autorizar su inscripción para el débito.'}} 
            showNotify={showNotify}
        >
            <Container>
                <Row className='my-5 d-flex justify-content-center' >
                    <Col className='col-12 col-lg-6' >
                        <h4  className='text-center color-symplifica' >¡Ya casi está todo listo para empezar a gestionar tus empleados con Symplifica!</h4>
                        <p className='text-center' >Por favor ingresa un método de pago.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TabHorizontal >
                            <CreditCardForm 
                                id='Tarjeta de crédito'
                                client_id={client_id}
                                tipoDoc={tipoDoc} 
                                handleNotify={handleNotify}
                                email={email}
                                history={history}
                            />
                            <BankAccountForm 
                                id='Cuenta Bancaria' 
                                client_id={client_id}
                                email={email}
                                tipoDoc={tipoDoc}  
                                handleNotify={handleNotify}
                                listBanks={listBanks}
                                listAccountType={listAccountType}
                                history={history}
                            />
                        </TabHorizontal>
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        client_id: state.client_id,
        email: state.email
    }
}

const NewPaymentMethodPage = connect(mapStateToProps, null)(NewPaymentMethod);

export { NewPaymentMethodPage };