import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Bootstrap
import { Container } from 'react-bootstrap';

// COMPONENTS
import { InfoTab, FormTab } from './components';

// API
import {RegisterRepository, GeneralRepository} from 'shared/repository';

// Interface
import { Employee, Choice, ResponseHttp2 } from 'shared/interfaces';

// CONTAINER
import { RegisterContainer } from 'shared/containers';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

export const BasicDataEmployeePage: React.FC = () => {

    const location:any = useLocation();
    const { showNotify, handleNotify } = useNotifyState();
    
    const employee_id = location.state?.employee_id || '';

    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [employee_client, setEmployeeClient] = useState<Employee>({id:''});
    const [listStates, setListStates] = useState<Array<Choice>>([]);
    const [listCitiesBirth, setListCitiesBirth] = useState<Array<Choice>>([]);
    const [listCities, setListCities] = useState<Array<Choice>>([]);
    const [infoData, setInfoData] = useState<any>({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            
            let resp_cities:Choice[] = [];
            let resp: ResponseHttp2 = {success:false, message: '', data: null};

            const resp_states = await GeneralRepository.getStates();
            setListStates(resp_states);
            
            if (employee_id !== '' ) {
                resp = await RegisterRepository.getEmployeeClient(employee_id);
                setEmployeeClient(resp.data);
            }
            
            if( resp.success && resp.data.id ) {

                const resp_cities_birth = await GeneralRepository.getCities(resp.data.client.birth_state);
                setListCitiesBirth(resp_cities_birth);

                if (resp.data.client.birth_state !== resp.data.client.state) {
                    resp_cities = await GeneralRepository.getCities(resp.data.client.state);
                    setListCities(resp_cities);
                }else
                    setListCities(resp_cities_birth);

                let birth_state: any = resp_states.find(cb => cb.value == resp.data.client.birth_state);
                let birth_city: any = resp_cities_birth.find(cb => cb.value == resp.data.client.birth_city);
                let expedition_place: any = resp_cities_birth.find(cb => cb.value == resp.data.client.id_expedition_place);
                let _state: any = resp_states.find(cb => cb.value == resp.data.client.state);
                let city: any = resp_cities_birth.find(cb => cb.value == resp.data.client.city);
                
                setInfoData({
                    birth_state: birth_state?.text || '',
                    birth_city: birth_city?.text || '',
                    id_expedition_place: expedition_place?.text || '',
                    state: _state?.text || '',
                    city: city?.text || '',
                });
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <RegisterContainer
            title='Empleado: Datos básicos'
            info={{
                title: 'Recuerda',
                subtitle: 'La dotación de tus empleados deberás hacerla cada 6 meses de acuerdo a la ley.'
            }}
            showNotify={showNotify}
        >
            <Container>
                {
                    employee_id !== '' && !editing
                        && (<InfoTab employee={employee_client} infoData={infoData} setEditing={setEditing} loading={loading} />)
                }

                {
                    (employee_id === '' || editing)
                        && (<FormTab
                            choices={{
                                listStates,
                                listCitiesBirth,
                                listCities,
                            }}
                            employeedata={employee_client}
                            handleNotify={handleNotify}
                        />)
                }
            </Container>
        </RegisterContainer>
    )
}
