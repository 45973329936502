import React from 'react';
import { connect } from 'react-redux';
// Boostrap
import {Container, Row, Col} from 'react-bootstrap';

// Components
import { TabHorizontal, Notify } from 'shared/components';
// Container
import { AuthContainer } from 'shared/containers';

// Forms
import {LoginForm, RegisterForm} from './forms';

// Actions
import { signInAction } from 'redux/actions';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

const LoginPage = ({ setLogIn }) => {

    const { showNotify, handleNotify } = useNotifyState();

    return (
        <AuthContainer alignItem="center">
            <Notify {...showNotify} />
            <Container className='vw-xs-100 mx-0 px-0 authPage-cont' >
        
                    <TabHorizontal>
                        <Col id="Iniciar sesión" >
                            <Row className="eso">
                                <Container className='my-md-4 py-0 px-0' >
                                    <LoginForm handleNotify={handleNotify} />
                                </Container>
                            </Row>
                        </Col>
                        <Col id="Crear cuenta" >
                            <Row >
                                <Container className='my-md-4 py-0 px-0' >
                                <RegisterForm setLogIn={setLogIn} handleNotify={handleNotify} />
                                </Container>
                            </Row>
                        </Col>
                    </TabHorizontal>
    
            </Container>
        </AuthContainer>
            
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setLogIn: (token, refresh_token, email, employer, contracts, choices) =>
            dispatch(signInAction(token, refresh_token, email, employer, contracts, choices)),
    }
}

const AuthPage = connect(null, mapDispatchToProps)(LoginPage);

export { AuthPage };