import Http from 'shared/utils/Http2';

// Constants
import { SERVER } from 'shared/constants/app';

// Interface
import { ResponseHttp2 } from 'shared/interfaces';

export class AppRepository {

    // ==============================================
    /// Get main info to display in Dashboard page by employer
    // ==============================================
    static async employerDashboard(employer_id: string): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/employer/${employer_id}/dashboard/`;
        return await Http.get(url);
    }

    // ==============================================
    /// Get main info to display in Dashboard page by employer
    // ==============================================
    static async updateRenewal(contract_id: string, value: boolean): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/contract/${contract_id}/renewal_state/`;
        return await Http.put(url, {contract_id, value});
    }


    // ==============================================
    /// Do a pay from client
    // ==============================================
    static async postPayInvoices(formData:any): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/invoice/pay/`;
        return await Http.post(url, formData);
    }

    // ==============================================
    /// Get list invoices by client
    // ==============================================
    static async getListInvoices(contract_id: string): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/client/${contract_id}/invoices_detail/`;
        return await Http.get(url);
    }

    // ==============================================
    /// Get pending payrolls
    // ==============================================
    static async getPendingPayrolls(contract_id: string): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/client/${contract_id}/salaries/`;
        return await Http.get(url);
    }

    // ==============================================
    /// Post Simulate liquidation
    // ==============================================
    static async postSimulateLiquidation(formData:any, employee_id: string): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/liquidation/${employee_id}/simulate/`;
        return await Http.post(url, formData);
    }


}