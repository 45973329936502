import React, { useState } from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap';

// IMAGES
import Avatar from 'assets/ico/cont-icon-user.png';
import Trash from 'assets/ico/cont-icon-trash.png';

import { ModalDeleteBeneficiary } from 'shared/components/Modals/ModalDeleteBeneficiary';

const CardBeneficiary = ({ id, first_name, last_name, relationship, has_eps_inscription, ccf_inscription_type, handleNotify, callback}) => {

    const relationshipOpt = {
        "1": "Hijo / nieto",
        "2": "Padre",
        "3": "Cónyuge"
    };

    // 1 === null
    // 2 === sf && ccf
    // 3 === ccf

    const [modal, setModal] = useState(false);

    const createIndicator = (type) => {
        const listType = {
            eps: 'var(--syGreen)',
            cc: 'var(--syRed)',
            sf: 'var(--syGreen-dark)',
        };
        const styleBasic = { marginLeft: 10, width: 10, height: 10, borderRadius: '50%', display: 'inline-block', background: listType[type] };
        return(
            <div style={styleBasic}></div>
        )
    };

    return(
        <Row className='border-b-gray py-2'>
            <ModalDeleteBeneficiary open={modal} showModal={setModal} id={id} handleNotify={handleNotify} callback={callback} />
            <Col >
                <div className='d-flex align-items-center justify-content-start' >
                    <img
                        src={Avatar}
                        alt='avatar'
                    />
                    <div className='mx-3' >
                        <p className='my-0' >{first_name} {last_name}</p>
                        <p className='my-0 text-11' >{relationshipOpt[relationship]}
                            {
                                has_eps_inscription && createIndicator('eps')
                            }
                            {
                                (ccf_inscription_type === "2" || ccf_inscription_type === "3") && createIndicator('cc')
                            }
                            {
                                ccf_inscription_type === "2" && createIndicator('sf')
                            }
                          
                        </p>
                    </div>
                </div>
            </Col>
            <Col className='d-flex justify-content-end align-items-center' >
                <img 
                    src={Trash}
                    alt='trash'
                    className="pointer"
                    onClick={() => setModal(true) }
                />
            </Col>
        </Row>
    )
}

CardBeneficiary.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    parent: PropTypes.string,
    eps: PropTypes.bool,
    cc: PropTypes.bool, 
    sf: PropTypes.bool,
    handleNotify: PropTypes.func,
}

export { CardBeneficiary };