import React from 'react';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// CONTAINERS
import { RegisterContainer } from '../../../../shared/containers';

// COMPONENTS
import { SubmitButton, AccordionContainer } from '../../../../shared/components';

// ICONS
import ArrowIcon from '../../../../assets/ico/cont-icon-arrow.png';
import AvatarIcon from '../../../../assets/ico/cont-icon-user.png';

const OnBoardingEmployeePage = () => {

    return (
        <RegisterContainer title='Completa los datos' info={{title:'Recuerda', subtitle: 'La dotación de tus empleados deberás hacerla cada 6 meses de acuerdo a la ley.'}} >
            <Container >
                <Row className='d-flex justify-content-center' >
                    <Col className='col-12 col-lg-3 mt-5' >
                        <SubmitButton block text='Agregar empleado' />
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center' >
                    <Col className='col-12 col-lg-4 mt-3 mb-5' >
                        <p>Ó escoge uno de tus empleados anteriores para recontratarlos</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='color-symplifica border-b-symplifica' >Empleados antiguos</p>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <AccordionContainer 
                            data={[
                                {
                                    toggle: {name: "Pepito"},
                                    body: {cargo: "Pupusero", from_date: "00 / 00 / 0000", to_date: "00 / 00 / 0000"}
                                },
                                {
                                    toggle: {name: "Pepito"},
                                    body: {cargo: "Pupusero", from_date: "00 / 00 / 0000", to_date: "00 / 00 / 0000"}
                                },
                                {
                                    toggle: {name: "Pepito"},
                                    body: {cargo: "Pupusero", from_date: "00 / 00 / 0000", to_date: "00 / 00 / 0000"}
                                },
                            ]}
                            ToggleComponent={({name, active})=>{
                                return(
                                    <Row className='my-2' >
                                        <Col className='col-4 col-lg-1 d-flex align-items-center' >
                                            <img height={50} src={AvatarIcon} alt='avatar' />
                                        </Col>
                                        <Col className='col-4 col-lg-7 d-flex align-items-center' >
                                            <p className='my-0' >{name}</p>
                                        </Col>
                                        <Col className='col-4 d-flex align-items-center justify-content-end '>
                                            <img src={ArrowIcon} alt='down' />
                                        </Col>
                                    </Row>
                                )
                            }}
                            BodyComponent={({cargo, from_date, to_date})=>{
                                return (
                                    <Row>
                                        <Col>
                                            <Row >
                                                <Col className='m-2 p-3 on-boarding-employee-page-card' >
                                                    <p className='my-0' >Cargo</p>
                                                    <p className='my-0' >{cargo}</p>
                                                </Col>
                                                <Col className='m-2 p-3 on-boarding-employee-page-card' >
                                                    <p className='my-0' >Inició labores</p>
                                                    <p className='my-0' >{from_date}</p>
                                                </Col>
                                                <Col className='m-2 p-3 on-boarding-employee-page-card' >
                                                    <p className='my-0' >Terminó labores</p>
                                                    <p className='my-0' >{to_date}</p>
                                                </Col>
                                            </Row>
                                            <Row className='d-flex my-4 justify-content-center' >
                                                <Col className='col-12 col-lg-4' >
                                                    <SubmitButton text="Recontratar" variant='light' block/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

export { OnBoardingEmployeePage }