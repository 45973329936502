import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { AppContainer } from 'shared/containers';
import { TabHorizontal, CardData, InputToggle, SubmitButton } from 'shared/components';
import { DownloadDocument, ConfirmModal } from './components';

// Components
import { Container, Row, Col } from 'react-bootstrap';

// Interface
import { Contract, Employee, ResponseHttp2 } from 'shared/interfaces';

// Utils
import { dolarize, timeToString, valueChoice, mapChoice, workingDaysText, diffMonthDate } from 'shared/utils/utils';

// Enums
import { TypeChoice } from 'shared/utils/emuns';

// API
import { RegisterRepository } from 'shared/repository';

// Constants
import { APP_EMPLOYEES_CONTRACT_SALARY } from 'shared/constants/urls';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

export const ContractEmployeePage: React.FC = () => {

    const history = useHistory();
    const location: any = useLocation();
    const { showNotify, handleNotify } = useNotifyState();

    const [salary, setSalary] = useState<any>({});
    const [contract, setContract] = useState<Contract>(location.state.contract);
    const [modal, showModal] = useState<boolean>(false);

    const employee: Employee = contract.employee || { id: '' };

    const map_bonus_type = mapChoice(TypeChoice.BONUS_TYPE);

    useEffect(() => {
        const fetchData = async () => {
            const resp: ResponseHttp2 = await RegisterRepository.getContractSalary(contract.id);

            if (resp.success) {
                setSalary(resp.data);
            } else 
                console.error(resp.message);
        }

        fetchData();
    }, []);

    return (
        <AppContainer title="Datos personales" onPressBack={() => history.goBack()} showFooter={false} showNotify={showNotify}>

            <ConfirmModal 
                modal={modal} 
                showModal={showModal} 
                contract={contract} 
                handleNotify={handleNotify}
                callback={(value) => setContract(prev => ({...prev, automatic_renewal: value})) }
            />

            <Container fluid>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <p className="text-center w80 font13">Revisa y edita las condiciones contractuales con tu empleado.</p>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center">
                        <h4 className="text-center color-symplifica">{`${employee.first_name || 'Empleado'} ${employee.last_name || '1'}`}</h4>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col xs={12}>
                        <DownloadDocument text="Descargar contrato" onClick={() => handleNotify('warning', 'En estos momentos no es posible descargar el documento. Por favor intente más tarde') } />
                    </Col>
                </Row>

                {
                    contract.fixed_term && (
                        <Row className="my-2">
                            <Col xs={6}>
                                Renovación automática contrato termino fijo
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                <InputToggle
                                    name="is_cardholder"
                                    value={contract.automatic_renewal}
                                    onChange={() => {
                                        showModal(true);
                                    }}
                                />
                            </Col>
                        </Row>
                    )
                }

                <TabHorizontal className="my-3 tab-container">


                    <Col id="Detalles">
                        <Row className="eso">
                            <Container className='my-md-4 py-0 px-0' >
                                <CardData text="Cargo" subtext={valueChoice(TypeChoice.POSITION, contract.position)} />
                                <CardData text="Fecha inicio de contrato" subtext={contract.start_date ? contract.start_date : ''} />
                                
                                {
                                    contract.fixed_term && <CardData text="Fecha fin de contrato" subtext={contract.planned_end_date ? contract.planned_end_date : ''} />
                                }
                                
                                <CardData text="Término del contrato" subtext={valueChoice(TypeChoice.FIXED_TERM, contract.fixed_term)} />

                                {
                                    contract.fixed_term && <CardData text="Duración del contrato" subtext={diffMonthDate(contract.start_date, contract.planned_end_date)} />
                                }
                                
                                <CardData text="Lugar de trabajo" subtext={contract.workplace ? contract.workplace?.address_line_1 : ''} />
                                <CardData text="Cuenta de nómina" subtext={valueChoice(TypeChoice.PAYMENT_METHOD, contract.payroll_payment_method)} />
                                <CardData text="Modalidad de trabajo" subtext={valueChoice(TypeChoice.CONTRACT_TYPE, contract.contract_type)} />
                                <CardData text="Jornada Laboral" subtext={`${timeToString(contract.start_time)} - ${timeToString(contract.end_time)}`} />
                                <CardData text="Frecuencia de pago" subtext={valueChoice( TypeChoice.PAYMENT_FREQ, contract.payment_freq )} />
                                <CardData text="Días laborales por semana" subtext={workingDaysText(contract)} />
                                <CardData text="Días laborales al mes" subtext={`${Number(contract.monthly_worked_days)}`} />
                                <CardData text="Ubicación del empleado" subtext={valueChoice(TypeChoice.WORKING_DISTANCE, contract.employee_working_distance)} />
                            </Container>
                        </Row>
                    </Col>


                    <Col id="Salario">
                        <Row>
                            <Container className='my-md-4 py-0 px-0' >
                                <CardData text="Salario" subtext={dolarize(salary?.base_salary) || '$ 0'} />
                                
                                {
                                    contract.contract_type != "2" && <CardData text="Salario en especie" subtext={dolarize(salary?.salary_in_kind_value) || '$ 0'} />
                                }
                                
                                <CardData text="Auxilio de transporte" subtext={dolarize(contract?.transport_aid || 0)} />
                                {
                                    salary.bonus_list && salary.bonus_list.map((item: any) => 
                                        <CardData 
                                            key={`key_${item.id}`} 
                                            text="Auxilios no salariales" 
                                            subtext={`(${map_bonus_type[item.bonus_type].replace('Auxilio de ','')}) ${dolarize(item.amount)}`} 
                                        /> 
                                    )
                                }

                                <br />

                                <SubmitButton
                                    variant="primary"
                                    block
                                    onClick={() => history.push(APP_EMPLOYEES_CONTRACT_SALARY, { contract_id: contract.id, contract_type: contract.contract_type, salary })}
                                    text="Editar" 
                                />
                            </Container>
                        </Row>
                    </Col>

                </TabHorizontal>
            </Container>

        </AppContainer>
    );
}