import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// BOOTSTRAP
import { Form, Col, Row } from 'react-bootstrap';

// COMPONENTS
import {
    InputText, InputSelect, InputDate, InputCheck, GroupForm,
    LinkButton, SubmitButton
} from 'shared/components';

// API
import { GeneralRepository, RegisterRepository} from 'shared/repository';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// Utils
import { timeToString, dateToString } from 'shared/utils/utils';

// Constants
import { REGISTER_ONBOARDING_PAGE } from 'shared/constants/urls';

// Interface
import { FuncNotify, Contract as TypeContract , Choice } from 'shared/interfaces';

// Models
import { Contract } from 'shared/models';


interface ContractDetailsFormProps {
    contract: TypeContract;
    choices: any;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleNotify: FuncNotify;
    id?: string;
}

export const ContractDetailsForm: React.FC<ContractDetailsFormProps> = ({ contract, choices, isLoading, setIsLoading, handleNotify}) => {

    const history = useHistory();

    let defaultContract:any = {
        ...contract,
        new_workplace_name: '',
        new_workplace_address1: '',
        new_workplace_address2: '',
        new_workplace_state: '',
        new_workplace_city: '',
    };

    const [showWorkplace, setShowWorkplace] = useState<boolean>(false);
    const [endDateChoice, setEndDateChoice] = useState<any>({
        value: '',
        type: '',
        select: 'days',
    });
    
    const [listCities, setListCities] = useState<Choice[]>([]);

    // =======================================================
    /// Validate data form, break the thread if have any error
    // =======================================================
    const validate = () => {
        if (
            inputs.new_workplace_name ||
            inputs.new_workplace_address1 ||
            inputs.new_workplace_state ||
            inputs.new_workplace_city
        ) {
            if (!inputs.new_workplace_name) {
                setShowWorkplace(true);
            }
            if (!inputs.new_workplace_address1) {
                setShowWorkplace(true);
            }
            if (!inputs.new_workplace_state) {
                setShowWorkplace(true);
            }
            if (!inputs.new_workplace_city) {
                setShowWorkplace(true);
            }
        }
        return Contract.validateEditForm(inputs, handleError);
    }

    // =======================================================
    /// Get list of citys by state selected
    // =======================================================
    const getCitys = async (state:string) => {
        setIsLoading(true);
        setListCities([]);
        const citys = await GeneralRepository.getCities(state);
        setListCities(citys);
        setIsLoading(false);
    }

    // =======================================================
    /// submit contract data to server
    // =======================================================
    const handleSubmitForm = async () => {
        // submit data to server
        if (validate() > 0 ) return;

        setIsLoading(true);

        let formData = {
            contract_id: inputs.id,
            contract_term: inputs.contract_term,
            position: inputs.position,
            workplace: inputs.workplace,
            start_date: dateToString(inputs.init_date),
            end_date: dateToString(inputs.end_date) || null,
            new_workplace_name: inputs.new_workplace_name || null,
            new_workplace_address1: inputs.new_workplace_address1 || null,
            new_workplace_address2: inputs.new_workplace_address2 || null,
            new_workplace_state: inputs.new_workplace_state || null,
            new_workplace_city: inputs.new_workplace_city || null,

            work_location: inputs.work_location,
            init_hour: timeToString(inputs.init_hour),
            end_hour: timeToString(inputs.end_hour),
            payment_frequency: inputs.payment_frequency,
            payment_destination: inputs.payment_destination,
            arl_level: inputs.arl_rate,
            health_contribution_type: inputs.health_contribution_type,
            enjoyed_vacations: inputs.available_vacation_days,
            saves_for_retirement: inputs.saves_for_retirement,
            retirement_subscription_type: inputs.saves_for_retirement,
        };

        const resp = await RegisterRepository.updateContract(contract.id, formData);
        setIsLoading(false);

        if( resp.success ) {
            handleNotify('success', resp.message);
            window.setTimeout(() => history.push(REGISTER_ONBOARDING_PAGE), 2000);
        }else {
            handleNotify('danger', resp.message);
        }

    }

    // =======================================================
    /// Implements hook to handle form state
    // =======================================================
    const {
        inputs, handleSubmit, handleInputChange, errors, handleError, setInputChange
    } = useCustomForm( defaultContract, handleSubmitForm);

    // =======================================================
    /// set a new End Date from a choice in "duración" fields
    // =======================================================
    const handleEndDate = (value) => {
        let type = value.type === 'button' ? 'month' : value.select;
        let newEndDate = new Date(moment(inputs.init_date).add(Number(value.value), type).format('YYYY-MM-DD'));
        setInputChange('end_date', newEndDate);
        setEndDateChoice(prev => ({ ...prev, ...value }));
    }

    // =======================================================
    /// clean "duración" choices if selected date from datepicker of end_date
    // =======================================================
    const handlePickerEndDate = (name, value) => {
        setEndDateChoice({ value: '', type: '', select: 'days' });
        setInputChange(name, value);
    }

    return (
        <Form className='my-5'
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='Término de Contrato'
                        name="contract_term"
                        value={inputs.contract_term}
                        onChange={event => {
                            handleInputChange(event);
                            if (event.target.value === 'indefinite' ) {
                                handlePickerEndDate('end_date', null);
                            }
                        }}
                        options={[
                            {value:'fixed', text:'Término Fijo'},
                            {value:'indefinite', text:'Término Indefinido'},
                        ]}
                    />
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <InputDate
                        label='Fecha inicio'
                        placeholder='0000-00-00'
                        tooltip={{
                            keyTooltip: 'date_1',
                            placement: 'top',
                            text: "Si pones una fecha antigua, ten en cuenta que debes tener al día la Seguridad Social de tu empleado. La fecha de afiliación será establecida de acuerdo a términos y condiciones"
                        }}
                        name="init_date"
                        value={inputs.init_date}
                        error={errors.init_date}
                        onChange={setInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputDate
                        label='Fecha Fin'
                        placeholder='0000-00-00'
                        name="end_date"
                        disabled={inputs.contract_term === "indefinite"}
                        value={inputs.end_date}
                        onChange={handlePickerEndDate}
                    /> 
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <p className="text-bold">Duración</p>
                    <GroupForm>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='w-100 d-table'>
                                    <div className='d-table-cell'>
                                        <InputCheck 
                                            name="duration" 
                                            onChange={(e) => handleEndDate({value: e.target.value, type: 'button', select: 'days'})} 
                                            checked={endDateChoice.type === 'button' && endDateChoice.value === '1'} 
                                            text="1 Mes" 
                                            value="1" 
                                            id="duration_30" 
                                            disabled={inputs.contract_term === "indefinite"}
                                            className="text-10"
                                        />
                                    </div>
                                    <div className='d-table-cell px-2'>
                                        <InputCheck 
                                            name="duration" 
                                            onChange={(e) => handleEndDate({value: e.target.value, type: 'button', select: 'days'})} 
                                            checked={endDateChoice.type === 'button' && endDateChoice.value === '6'} 
                                            text="6 Meses" 
                                            value="6" 
                                            id="duration_180" 
                                            disabled={inputs.contract_term === "indefinite"}
                                            className="text-10"
                                        />
                                    </div>
                                    <div className='d-table-cell'>
                                        <InputCheck 
                                            name="duration" 
                                            onChange={(e) => handleEndDate({value: e.target.value, type: 'button', select: 'days'})} 
                                            checked={endDateChoice.type === 'button' && endDateChoice.value === '12'} 
                                            text="1 Año" 
                                            value="12" 
                                            id="duration_360" 
                                            disabled={inputs.contract_term === "indefinite"}
                                            className="text-10"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='d-table'>
                                    <div className='d-table-cell'><p>Otro:</p></div>
                                    <div className='d-table-cell px-2'>
                                        <InputText
                                            placeholder="00"
                                            name="duration_text"
                                            style={{width: '5.5rem'}}
                                            value={endDateChoice.type === 'choice' ? endDateChoice.value : ''}
                                            onChange={ (e) => {
                                                handleEndDate({ value: e.target.value, type: "choice", select: endDateChoice.select});
                                            }}
                                            disabled={inputs.contract_term === "indefinite"}
                                            className="text-10"
                                        />
                                    </div>
                                    <div className='d-table-cell'>
                                        <InputSelect
                                            name="select"
                                            value={endDateChoice.select}
                                            onChange={(e) => {
                                                handleEndDate({ type: "choice", select: e.target.value, value: endDateChoice.value });
                                            }}
                                            options={[
                                                { value: 'days', text: 'Días' },
                                                { value: 'months', text: 'Meses' },
                                                { value: 'years', text: 'Años' },
                                            ]}
                                            disabled={inputs.contract_term === "indefinite"}
                                            className="text-10"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </GroupForm>
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='Cargo'
                        name="position"
                        value={inputs.position}
                        onChange={handleInputChange}
                        options={choices.positions}
                    />
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='Nivel de riesgo'
                        name="arl_rate"
                        value={inputs.arl_rate}
                        onChange={handleInputChange}
                        options={choices.arl_levels}
                    />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='Dirección del trabajo'
                        name="workplace"
                        value={inputs.workplace_id}
                        onChange={handleInputChange}
                        tooltip={{
                            keyTooltip: '2',
                            placement: 'top',
                            text: "Es el lugar donde prestará sus servicios tu empleado"
                        }}
                        options={choices.employer_workplaces}
                    />
                    <LinkButton className='w-100' action={() => setShowWorkplace(wp => !wp)} text='Agregar nueva dirección' />
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect 
                        label='¿Dónde vive tu empleado?'
                        name="work_location"
                        value={inputs.work_location}
                        onChange={handleInputChange}
                        tooltip={{
                            keyTooltip: '3',
                            placement: 'top',
                            text: "Es el lugar donde vive tu empleado"
                        }}
                        options={choices.employee_distance}
                    />
                </Col>
            </Row>

            {/* New workspace */}
            {
                showWorkplace
                    ? (
                        <div className="bg-gray p-3 my-3 rounded-lg">
                            <Row>
                                <Col className='col-12'>
                                    <Row>
                                        <Col className='col-lg-4 col-12 my-2'>
                                            <InputText
                                                placeholder="Nombre de lugar de trabajo"
                                                name="new_workplace_name"
                                                value={inputs.new_workplace_name}
                                                error={errors.new_workplace_name}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col className='col-lg-4 col-12 my-2'>
                                            <InputText
                                                placeholder="Dirección de trabajo"
                                                name="new_workplace_address1"
                                                value={inputs.new_workplace_address1}
                                                error={errors.new_workplace_address1}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col className='col-lg-4 col-12 my-2'>
                                            <InputText
                                                placeholder="Ejemplo: Apartamento 00 interior 00"
                                                name="new_workplace_address2"
                                                value={inputs.new_workplace_address2}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='col-lg-4 col-12 my-2'>
                                            <InputSelect
                                                default_option="Ciudad"
                                                name="new_workplace_city"
                                                value={inputs.new_workplace_city}
                                                error={errors.new_workplace_city}
                                                onChange={handleInputChange}
                                                disabled={listCities.length === 0}
                                                options={listCities}
                                            />
                                        </Col>
                                        <Col className='col-lg-4 col-12 my-2'>
                                            <InputSelect
                                                default_option="Departamento"
                                                name="new_workplace_state"
                                                value={inputs.new_workplace_state}
                                                error={errors.new_workplace_state}
                                                onChange={event => {
                                                    handleInputChange(event);
                                                    getCitys(event.target.value);
                                                }}
                                                options={choices.list_states}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )
                    : null
            }
            {/* END New workspace */}

            <Row >
            <   Col className='col-md-6 col-sm-12'>
                    <GroupForm >
                        <p className='text-left w-100 text-bold' >Frecuencia de pago</p>
                        <Row className="w-100">
                            <Col md={6}>
                                <InputCheck className="w-100" onChange={handleInputChange} checked={inputs.payment_frequency == "1"} name="payment_frequency" text="Mensual" value="1" id="Mensual" />
                            </Col>
                            <Col md={6}>
                                <InputCheck className="w-100" onChange={handleInputChange} checked={inputs.payment_frequency == "2"} name="payment_frequency" text="Quincenal" value="2" id="Quincenal" />
                            </Col>
                        </Row>
                    </GroupForm>
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <GroupForm >
                        <p className='text-left w-100 text-bold' >Método de pago de Nómina</p>
                        <Row className="w-100">
                            <Col md={6}>
                                <InputCheck className="w-100" onChange={handleInputChange} checked={inputs.payment_destination === "2"} name="payment_destination" text="Daviplata" value="2" id="Daviplata" />
                            </Col>
                            <Col md={6}>
                                <InputCheck className="w-100" onChange={handleInputChange} checked={inputs.payment_destination === "1"} name="payment_destination" text="Efectivo" value="1" id="Efectivo" />
                            </Col>
                        </Row>
                    </GroupForm>
                    <LinkButton className='w-100 my-3' text='Cuéntale a tu empleado como usar Daviplata' />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='¿Como va a estar cubierta en Salud?'
                        name="health_contribution_type"
                        value={inputs.health_contribution_type}
                        onChange={handleInputChange}
                        options={choices.health_suscriptions}
                    />
                </Col>
                <Col className='col-md-6 col-sm-12'>
                    <InputSelect
                        label='¿Está obligado a pagar pensión?'
                        name="saves_for_retirement"
                        value={inputs.saves_for_retirement}
                        onChange={handleInputChange}
                        options={choices.retirement_subscription_type}
                    />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-sm-12'>
                    <InputText
                        label='¿Cuántos días de vacaciones ha disfrutado?'
                        name="available_vacation_days"
                        value={inputs.available_vacation_days}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton
                        disabled={isLoading}
                        spinner={isLoading}
                        text='Guardar'
                        block
                    />
                </Col>
            </Row>
        </Form>
    )
}
