import React from 'react';

import { Col, Accordion, Row, Container } from 'react-bootstrap';

const AccordionContainer = ({ToggleComponent,BodyComponent , data=[]}) => {

    return (
        <Row >
            <Col>
                <Accordion >
                    {
                        data.map((item,i)=>{
                            return(
                                <Row>
                                    <Col>
                                        <Accordion.Toggle as={Container} eventKey={i}  >
                                            <ToggleComponent {...item.toggle} active={i} />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={i}>
                                            <Container >
                                                <BodyComponent {...item.body} />
                                            </Container>
                                        </Accordion.Collapse>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Accordion>
            </Col>
        </Row>
    )
} 

export { AccordionContainer };