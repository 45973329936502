import React from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import {Row, Col, Container} from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from '../../../../shared/containers';

// FORMS
import { BasicDataForm } from './forms';

// Actions
import { setEmployerAction } from '../../../../redux/actions';

// Utils
import { parseJwt } from '../../../../shared/utils/utils';

import Employer from '../../../../shared/models/Employer';

const BasicData = ({ token, employer, history, setEmployer }) => {

    const { client_id, vendor } = parseJwt(token) || {};

    const employerSel = {
        ...employer,
        client_id,
        vendor_id: vendor,
        birth_date: employer && employer.birth_date ? new Date(employer.birth_date) : null,
        expedition_date: employer && employer.expedition_date ? new Date(employer.expedition_date) : null,
    };

    return(
        <RegisterContainer title='Tus datos básicos' info={{ title: '¿Qué debo hacer?', subtitle: 'Pon aquí los datos de quien será el empleador'}} >
            <Container>
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Completa todos tus datos básicos</h4>
                    </Col>
                </Row>
                <BasicDataForm
                    history={history}
                    employer={employerSel}
                    setEmployer={setEmployer}
                />
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({ state }) => {
    return {
        token: state.token,
        employer: state.employer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEmployer: employer => dispatch(setEmployerAction(employer))
    };
}

const BasicDataPage = connect(mapStateToProps, mapDispatchToProps)(BasicData);

export {BasicDataPage};