import React from 'react';

// Components
import { Col, Image } from 'react-bootstrap';

// Images
import Logo from 'assets/images/logo.png';

interface SidebarRegisterProps {
    title: string;
    subtitle: string;
}

export const SidebarRegister: React.FC<SidebarRegisterProps> = ({title, subtitle}) => {
    return (
        <Col className="RegistroContainer-sidebar d-none d-lg-flex col-md-2 p-0" >
            <div className='RegistroContainer-sidebar-contain' >
                <div className="RegistroBrand-logo"><Image src={Logo} alt='logo' /></div>
                <span className='text-center' >
                    <p className='color-symplifica' >{title}</p>
                    <p >{subtitle}</p>
                </span>
                <span>{/*SPACING */}</span>
            </div>
        </Col>
    );
}