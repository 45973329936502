import React from 'react';

import { Card } from 'react-bootstrap';

interface CardInfoProps {
    title?: string;
    value: string;
    icon?: 'sueldo' | 'aportes' | 'nomina' | 'terminar_contrato';
    onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void);
}

export const CardInfo: React.FC<CardInfoProps> = ({ title = "", value, icon = 'nomina', onClick = ()=>{}}) => {

    const pathIcon = `/icons/cards/${icon}.png`;

    return (
        <Card className="card-container" onClick={onClick}>
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted" style={{fontSize: "0.75rem"}}>{title}</Card.Subtitle>
                <Card.Title style={{ fontSize: "1rem" }}>{value}</Card.Title>
            </Card.Body>
            <img className="info-img" src={pathIcon} alt={icon} />
        </Card>
    );
}