import Http from '../../../shared/utils/Http2';
import { SERVER } from '../../../shared/constants/app';
import { newChoice } from '../../../shared/utils/http_utils';

export default class GeneralRepository {

    // =======================================================
    /// [GET]: Request list of cities in a specific state
    // =======================================================
    static async getRelationships() {
        const url = `${SERVER}/enchantress/relationship_choices/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            return data.map(item => {
                const nameKey = Object.keys(item)[0];
                return { value: nameKey, text: item[nameKey] };
            });
        } else
            return [];
    } // END getRelationships
    
    // =======================================================
    /// [GET]: Request list of cities in a specific state
    // =======================================================

    static async getMaritalStatus() {
        const url = `${SERVER}/enchantress/marital_status_choices/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            return data.map(item => {
                const nameKey = Object.keys(item)[0];
                return { value: nameKey, text: item[nameKey] };
            });
        } else
            return [];
    } // END getMaritalStatus

    // =======================================================
    /// [GET]: Request list of cities in a specific state
    // =======================================================
    static async getCities(state = 1) {
        const url = `${SERVER}/enchantress/cities/?state_id=${state}`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const listCities = data && data.hasOwnProperty('cities') && data.cities
                ? JSON.parse(data.cities)
                : [];
            return listCities.map(item =>
                ({ value: item.id, text: item.name })
            );
        } else
            return [];
    } // END getCities

    // =======================================================
    /// [GET]: Request list of states in a specific country
    // =======================================================
    static async getStates(coverage = null) {
        const url = `${SERVER}/enchantress/states/?pagination=0&limit=50${coverage !== null ? `&coverage=${coverage}` : ''}`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const statesList = data || [];

            return statesList.map(item =>
                ({ value: item.id, text: item.name })
            );
        } else
            return [];
    } // END getStates

    // =======================================================
    /// [GET]: Request list of document types to select it
    // =======================================================
    static async getDocumentTypes() {
        const url = `${SERVER}/enchantress/id_type/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const typesList = data && data.hasOwnProperty('id_types') && data.id_types
                ? JSON.parse(data.id_types) : [];
            return typesList.map(item => {
                const nameKey = Object.keys(item)[0];
                return { value: nameKey, text: item[nameKey] };
            });
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of bank accounts 
    // =======================================================
    static async getAccountTypes() {
        const url = `${SERVER}/enchantress/account_types/?pagination=0&name__contains=Cuenta&fields=id,name`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            return data.map(item => ({ value: item.id, text: item.name }));
        } else
            return []
    }

    // =======================================================
    /// [GET]: Request list of banks to select it
    // =======================================================
    static async getBanks() {
        const url = `${SERVER}/enchantress/banks/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return data.map(item =>
                ({ value: item.name, text: item.name })
            );
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of banks to select it
    // =======================================================
    static async getChoiceARLLevels() {
        const url = `${SERVER}/enchantress/arl_levels/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of positions availables
    // =======================================================
    static async getChoicePositions() {
        const url = `${SERVER}/enchantress/load_positions/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of contract types to choice
    // =======================================================
    static async getChoiceContractTypes() {
        const url = `${SERVER}/enchantress/contract_types/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of employee home choice
    // =======================================================
    static async getChoiceEmployeeDistance() {
        const url = `${SERVER}/enchantress/working_distances/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of aids choice
    // =======================================================
    static async getChoiceAids() {
        const url = `${SERVER}/enchantress/contract_bonus/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of health suscriptions choice
    // =======================================================
    static async getChoiceHealthSuscriptions() {
        const url = `${SERVER}/enchantress/health_subscriptions/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Request list of health suscriptions choice
    // =======================================================
    static async getChoiceRetirementSubscriptionType() {
        const url = `${SERVER}/enchantress/retirement_subscription_types/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return newChoice(data);
        } else
            return [];
    }

    // =======================================================
    /// 
    // =======================================================
    static async getEPS(city_id = '11001') {
        const url = `${SERVER}/enchantress/load_eps/?city_id=${city_id}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return resp.data.map(item => ({ value: `${item.id}`, text: item.name }));
        } else
            return [];
    }

    // =======================================================
    /// 
    // =======================================================
    static async getAFP(state_id = '11') {
        const url = `${SERVER}/enchantress/load_afps/?state_id=${state_id}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return resp.data.map(item => ({ value: `${item.id}`, text: item.name }));
        } else
            return [];
    }

    // =======================================================
    /// 
    // =======================================================
    static async getFC(state_id = '11') {
        const url = `${SERVER}/enchantress/load_afcs/?state_id=${state_id}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return resp.data.map(item => ({ value: `${item.id}`, text: item.name }));
        } else
            return [];
    }

    // =======================================================
    /// 
    // =======================================================
    static async getCCF(state_id = '11') {
        const url = `${SERVER}/enchantress/load_ccfs/?state_id=${state_id}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return resp.data.map(item => ({ value: `${item.id}`, text: item.name }));
        } else
            return [];
    }

    // =======================================================
    /// 
    // =======================================================
    static async getARL(state_id = '11') {
        const url = `${SERVER}/enchantress/load_arls/?state_id=${state_id}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return resp.data.map(item => ({ value: `${item.id}`, text: item.name }));
        } else
            return [];
    }

}