import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { CardDocument, TooltipBasic } from 'shared/components';


export const HomeDocuments: React.FC = () => {

    const history = useHistory();
    const location:any = useLocation();

    return (
        <AppContainer title="Documentos" onPressBack={() => history.goBack()} showFooter={true}>
            <Container fluid>

                {/* MESSAGE TEXT */}
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <p className="text-center w80 font-1rem">Encuentra o genera todos los documentos de la relacion laboral con tus empleados</p>
                    </Col>
                </Row>

                {/* CARDS */}
                <Row className="d-flex justify-content-space-between">
                    <Col xs={6} >
                        <CardDocument 
                                text={"Comprobantes de nómina"}
                                icon={"/icons/cards/nomina-m.png"}
                            />
                    </Col>
                    <Col xs={6}>
                        <CardDocument
                            text={"Planillas de seguridad social"}
                            icon={"/icons/cards/nomina-m.png"}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <CardDocument
                            text={"Contratos"}
                            icon={"/icons/cards/nomina-m.png"}
                        />
                    </Col>
                    <Col xs={6}>
                        <CardDocument 
                            text={"Generar otros documentos y soportes"}
                            icon={"/icons/cards/nomina-m.png"}
                        />
                    </Col>
                </Row>

                {/* TOOLTIP */}
                <Row>
                    <Col xs={12} className="d-flex justify-content-center align-items-center">
                        <TooltipBasic
                            question={true}
                            placement="bottom"
                            text={"¿Qué es la sección de documentos?"}
                        />
                        <a href="#" className="text-link-employees">¿Qué es la sección de documentos?</a>
                    </Col>
                </Row>

            </Container>
        </AppContainer>
    );
}