import React from 'react';
import { useHistory } from 'react-router-dom';

// Bootstrap
import { Row, Col } from 'react-bootstrap';

// ICONS
import UserIcon from 'assets/ico/cont-icon-user.png';

// Components
import { CardOnboarding } from './CardOnboarding';

// Constants
import { 
    REGISTER_CONTRACT_DATA, 
    REGISTER_SOCIAL_SECURITY, 
    REGISTER_CREATE_EMPLOYEE, 
    REGISTER_DOCUMENTS_EMPLOYEE,
} from 'shared/constants/urls';

import { Contract, Employee } from 'shared/interfaces';

interface CardEmployeeProps {
    contract: Contract;
    employee: Employee;
    callback?: () => void;
}

export const CardEmployee: React.FC<CardEmployeeProps> = ({contract, employee, callback= ()=>{}}) => {

    const history = useHistory();

    const hasContract = contract && contract.hasOwnProperty('id') && Boolean(contract.id);
    const hasEmployeeData = employee && employee.hasOwnProperty('id') && Boolean(employee.id);
    const employeeName = hasEmployeeData ? `${employee.first_name} ${employee.last_name}` : 'Empleado 1';
    const hasEmployeeDocuments = hasEmployeeData && employee.docs_is_ready;
    const hasEmployeeSecurity = hasEmployeeData && employee.ss_is_ready;
    const employee_id = hasContract ? contract?.employee_id : '';

    return (
        <div>
            <Row >
                <Col className='d-flex align-items-center py-3'  >
                    <img height={30} className='mr-2' src={UserIcon} alt='icon' />
                    <p className='my-0'>{employeeName}</p>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="person" 
                        done={hasEmployeeData} 
                        title='Datos empleado' 
                        onClick={() => history.push(REGISTER_CREATE_EMPLOYEE, {employee_id})} 
                    />
                </Col>
                <Col md={12} lg={6}>
                    <CardOnboarding
                        type="sign" 
                        done={hasContract}
                        title='Datos de contrato' 
                        onClick={() => history.push(`${REGISTER_CONTRACT_DATA}/${contract.id}`)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="security" 
                        done={hasEmployeeSecurity} 
                        title='Seguridad social' 
                        onClick={
                            hasEmployeeData 
                                ? () => history.push(`${REGISTER_SOCIAL_SECURITY}/${contract.id}`) 
                                : callback
                        }
                    />
                </Col>
                <Col md={12} lg={6}>
                    <CardOnboarding 
                        type="document" 
                        done={hasEmployeeDocuments} 
                        title='Documentos' 
                        onClick={
                            hasEmployeeData 
                                ? () => history.push(`${REGISTER_DOCUMENTS_EMPLOYEE}/${contract.id}`) 
                                : callback
                        }
                    />
                </Col>
            </Row>
        </div>
    );
};