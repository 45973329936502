import React, {useState, useEffect} from 'react';

// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

// Components
import { InputSelect, TooltipBasic, SubmitButton } from 'shared/components';

// ICONs
import UserIcon from 'assets/ico/cont-icon-user.png';


// Repositories
import {RegisterRepository} from 'shared/repository/register.repository';

// Redux
import { useStore } from 'react-redux'

// Interface
import {Contract, Employee} from 'shared/interfaces';

interface SelectEmployeeProps {
    onNextClick: (event: any) => void;
}

export const SelectEmployee: React.FC<SelectEmployeeProps> = ({onNextClick}) =>{

    const [employeeList, setEmployeeList] = useState([])
    const [employees, setEmployees] = useState<Contract[]>()
    const [currentEmployee, setCurrentEmployee] = useState<any>()
    const [error, setError] = useState<string>()
    const store = useStore()

    useEffect(()=>{
        const getEmployeeList = async () =>{

            let employes = await RegisterRepository.getEmployerEmployees(store.getState().state.employer.id)
            
            // Parse data to list inputSelect
            let list = employes.data.active_employees.map((item: {employee: Employee}, i: number) => {
                return {value: i, text: `${item.employee.first_name} ${item.employee.last_name}`}
            })


            // set employees
            setEmployees(employes.data.active_employees)
            // set list to inputSelect
            setEmployeeList(list)
        }
        getEmployeeList()
    },[store])

    const handleSelectEmployee = (event: any) =>{
        setError("")
        setCurrentEmployee(parseInt(event.target.value))
    }


    const handleNext = () => {

        if(currentEmployee >= 0){
            onNextClick(employees && employees[currentEmployee].id)
        }
        else{
            setError("Seleccione un empleado")
        }
    }
        

    return(
        <Container>
            <Row>
                <Col xs={12} className="d-flex justify-content-center" >
                    <p className='text-center w80' >Revisa los cálculos y genera la liquidación fe tus emplados</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputSelect 
                        default_option='Selecciona un empleado'
                        onChange={handleSelectEmployee}
                        name='select_employee'
                        options={employeeList}
                        error={error}
                    />
                </Col>
            </Row>
            {
                currentEmployee >= 0 ?
                    <Row>
                        <Col className='p-3 m-2 card-selected-employee' >
                            <Row className='my-3' >
                                <Col xs={2} >
                                    <img height={50} src={UserIcon} alt='user' />
                                </Col>
                                <Col  >
                                    <p className='w80 my-0' ><strong>{employees && `${employees[currentEmployee].employee?.client?.name}`}</strong></p>
                                    <p>{employees && `${employees[currentEmployee].employee?.client?.id_type} ${employees[currentEmployee].employee?.client?.id_num}`}</p>
                                </Col>
                            </Row>
                            < Row className='d-flex justify-content-center' >
                                <Col xs={5} >
                                    <p className='my-0' >Inicio contrato</p>
                                    <p>{employees && `${employees[currentEmployee].start_date?.replace('-', '/').replace('-', '/')}`}</p>
                                </Col>
                                <Col xs={5} >
                                    <p className='my-0' >Cargo</p>
                                    <p>{employees && `${employees[currentEmployee]?.position_name}`}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                : null
            }
            <Row>
                <Col className='d-flex justify-content-center align-items-center' >
                    <TooltipBasic 
                        placement='top'
                        text='texto'
                        question
                    />
                    <a className='select-employee-link my-4' href='#k' >¿Qué es liquidación de contrato?</a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubmitButton
                        onClick={handleNext}
                        block
                        text='Continuar'
                    />
                </Col>
            </Row>
        </Container> 
    )
}