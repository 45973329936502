import React from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap';

// ICONS
import EditIcon from '../../../../../assets/ico/cont-icon-edit.png';

const CardSS = ({ name, subsystem, id_subscription, onClick = () => { } }) => {

    return (
        <div className='w-100 d-table my-4 shadow border-r-10 p-3' >
            <div className='d-table-cell'>
                <p className='my-0' >{subsystem}</p>
                <p className='color-symplifica my-0' >{name}</p>
            </div>
            <div className='d-table-cell text-right align-middle'>
                <img
                    src={EditIcon}
                    alt='Edit'
                    height={30}
                    className='cursor-pointer'
                    onClick={onClick}

                />
            </div>
        </div>
    )
}

CardSS.propTypes = {
    name: PropTypes.string,
    subsystem: PropTypes.string,
    onClick: PropTypes.func,
};

export { CardSS };