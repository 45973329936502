import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// CONTAINER
import { RegisterContainer } from 'shared/containers';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// COMPONENTS
import { CardPrice, TablePrices, SubmitButton } from 'shared/components';

// Utils
import { dolarize } from 'shared/utils/utils';

// CONSTS
import { REGISTER_CHECKOUT_SUMMARY } from 'shared/constants/urls';

const EmployeeSummary = ({contract, history}) => {

    const [valores, setValores] = useState({
        sueldoNeto: 0,
        seguridadSocial: 0,
        provision: 0,
        totalMensual: 0,
    });

    useEffect(() => {
        const sueldoNeto = Number(contract.net_monthly_value);

        const seguridadSocial = Number(contract.afp_employee) + Number(contract.afp) + Number(contract.eps_employee) +
            Number(contract.eps) + Number(contract.arl) + Number(contract.ccf);

        const provision = Number(contract.mandatory_unemployment_savings_provision) +
            Number(contract.mandatory_unemployment_savings_arrears_provision) +
            Number(contract.mandatory_bonus_provision) +
            Number(contract.vacation_provision);

        const totalMensual = sueldoNeto + seguridadSocial;

        setValores({ sueldoNeto, seguridadSocial, provision, totalMensual });
        return;
    }, [contract]);

    return(
        <RegisterContainer title='Costos totales' info={{title:'¿Cuánto?', subtitle: 'Estos son los valores mensuales aproximados para tu empleado. El sueldo neto es el valor real que recibiría tu empleado, la seguridad social incluye el aporte a todas las entidades de ley y los costos de Symplifica son nuestro servicio.'}} >
             <Container>
                <Row>
                    <Col>
                        <h4 className="color-symplifica text-center mt-5" >Esta es la estimación de los costos mensuales para tu empleado.</h4>
                        <p className="text-center mb-5" >Estos valores pueden variar de acuerdo a las condiciones de contrato que escojas.</p>
                    </Col>
                </Row>
                <Row >
                    <Col className='mb-3'>
                        <CardPrice
                            title='Total mensual'
                            price={dolarize(valores.totalMensual)}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col className='col-12 col-lg-6' >
                        <TablePrices 
                            title='Costos empleado'
                            data={[
                                { title: 'Sueldo Neto', amount: dolarize(valores.sueldoNeto)},
                                { title: 'Seguridad Social', amount: dolarize(valores.seguridadSocial)},
                            ]}
                        />
                    </Col>
                    <Col className='col-12 col-lg-6' >
                        <TablePrices 
                            title='Provisión'
                            data={[
                                { title: 'Cesantías, Intereses de cesantías y Prima', amount: dolarize(valores.provision)}
                            ]}
                            tooltip={{
                                placement: 'top',
                                key: 'info',
                                text: 'Valores que sugerimos ahorres mensualmente para poder pagar las prestaciones de tu empleado cuando sea el momento de cada una'
                            }}
                        />
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center my-3' >
                    <Col className='col-12 col-lg-6' >
                        <p className='text-center' >Hemos creado un contrato estándar para tu empleado. Crea tu empleado para precisar su información y completar datos adicionales. </p>
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center my-4' >
                    <Col className='col-lg-4' >
                        <SubmitButton text='Guardar' type="button" block onClick={() => history.push(REGISTER_CHECKOUT_SUMMARY)} />
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center' >
                    <Col className='col-12 col-lg-6' >
                        <p className='text-center mb-0' >Conoce los detalles de los costos y deducciones aquí:</p>
                        <a href='https://symplifica.com/salario' target="_blank" rel="noopener noreferrer" className='text-center d-block color-symplifica mb-5' >https://symplifica.com/salario</a>
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({ state }) => {

    return {
        token: state.token,
        employer: state.employer,
        client_id: state.client_id,
        contract: state.contracts[0] || {}
    }
}

const EmployeeSummaryPage = connect(mapStateToProps, null)(EmployeeSummary);

export { EmployeeSummaryPage };