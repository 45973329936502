import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// CONTAINERS
import { RegisterContainer } from 'shared/containers';

// COMPONENTS
import { SubmitButton } from 'shared/components';
import { CardEmployee, CardEmployer } from './components';

// API
import { RegisterRepository, PaymentRepository } from 'shared/repository';

// Hoosk
import useNotifyState from 'shared/utils/NotifyHook';

// Constants
import { APP_DASHBOARD_HOME } from 'shared/constants/urls';

// Interface
import { Contract, Employer, Employee } from 'shared/interfaces';

// Actions
import { contractToEmployerAction } from 'redux/actions';

export const OnBoardingPage: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const client_id = useSelector<any, string>(state => state.state.client_id || '');
    const contract = useSelector<any, Contract>(state => state.state.contracts[0] || {});

    const { showNotify, handleNotify } = useNotifyState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contractData, setContractData] = useState<Contract>({id:''});
    const [employerData, setEmployerData] = useState<Employer>({id:''});
    const [employeeData, setEmployeeData] = useState<Employee>({id:''});
    const [paymethodsData, setPaymethodsData] = useState<Array<any>>([]);

    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true);
            const resp = await RegisterRepository.getDashboardData(contract.id);
            const resp_payment = await PaymentRepository.getListPaymentMethods(client_id);
            
            setPaymethodsData(resp_payment);
            
            if (resp.success) {
                dispatch(contractToEmployerAction(resp.data.contract));
                setContractData(resp.data.contract);
                setEmployerData(resp.data.employer);
                setEmployeeData(resp.data.employee);
                
            } else {
                handleNotify('danger', resp.message);
            }

            setIsLoading(false);
        }

        fetchData();

    }, []);

    // =======================================================
    /// Do a finish register to a employer
    // =======================================================
    const finishRegister = async () => {
        const formData = {
            contract_id: [contract.id]
        }
        setIsLoading(true);
        const resp = await RegisterRepository.activateEmployees(employeeData?.id, formData);
        
        setIsLoading(false);

        if (resp.success) {
            handleNotify('success', resp.message);
            history.push(APP_DASHBOARD_HOME);
        } else {
            handleNotify('danger', resp.message);
            history.push(APP_DASHBOARD_HOME);
        }
    };

    return(
        <RegisterContainer title='Completa los datos' info={{ title: 'Recuerda', subtitle: 'La dotación de tus empleados deberás hacerla cada 6 meses de acuerdo a la ley.' }} showNotify={showNotify}>
            <Container >
                <Row>
                    <Col>
                        <h4 className="color-symplifica text-center my-5" >Completa la información de tu empleado para finalizar tu registro</h4>
                    </Col>
                </Row>

                <Row >
                    <Col>
                        <p className='color-symplifica my-0' >Tu empleado</p>
                    </Col>
                </Row>

                {/* Employee Data */}
                <CardEmployee contract={contractData} employee={employeeData} callback={() => handleNotify('danger', 'Debes registrar a tu empleado primero')} />

                <Row className='d-lg-flex justify-content-center my-5' >
                    <Col className='col-lg-4' >
                        <SubmitButton
                            disabled={isLoading}
                            spinner={isLoading}
                            text='Finalizar registro empleado' 
                            block 
                            onClick={finishRegister}
                        />
                    </Col>
                </Row>

                <Row >
                    <Col>
                        <p className='color-symplifica my-0' >Información empleador</p>
                    </Col>
                </Row>
                
                {/* Employer Data */}
                <CardEmployer employer={employerData} paymethods={paymethodsData} history={history} />

                <br />
            </Container>
        </RegisterContainer>
    )
}
