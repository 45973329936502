import React, {useState, useEffect} from 'react';

// BOOTSTRAP COMPONENTS
import { Container, Row, Col, Form, Table } from 'react-bootstrap';

// COMPONENTS
import { InputSelect, InputDate, TooltipBasic, SubmitButton, CompleteModal } from 'shared/components';


// Redux
import { useStore } from 'react-redux'

// ICONS
import CheckGray from 'assets/ico/check-gray.png';
import CheckGreen from 'assets/ico/check.png';

// Repositories
import {AppRepository} from 'shared/repository';

interface LiquidationFormProps {
    onNextClick: (event: any) => any;
    contract_id: string;
}

export const LiquidationForm: React.FC<LiquidationFormProps> = ({onNextClick, contract_id}) => {

    const [data, setData] = useState({last_worked_day: '', causal: '', pendingPayroll: false})
    const [errorCausal, setErrorCausal] = useState('')
    const [pending_Payroll, setPending_Payroll] = useState()
    const [errorLWD, setErrorLWD] = useState('')
    const [modal, setModal] = useState<boolean>(false)
    const store = useStore()


    // INIT DATA

    useEffect(()=>{
        let getPendingPayrolls = async () => {
            const pendings = await AppRepository.getPendingPayrolls(contract_id)
            if(pendings.success){
                setPending_Payroll(pendings.data)
            }
        }

        getPendingPayrolls()
    },[contract_id])

    // get form choices
    const getChoices = () =>{
        let choices = store.getState().state?.choices?.TERMINATION_CHOICES_SYCORECOLOMBIADOMESTICS
        return (Object.keys(choices).map((item, i) =>{
            return {value: `${i + 1}`, text: choices[i][i+1]}
        }))
    }

    
    // STATE HANDLERS
    const handleCausal = (e: any) =>{
        setData({...data, causal: e.target.value})
        setErrorCausal('')
    }

    const handleLastWorkedDay = (name: string, date: any) => {
        setData({...data, last_worked_day: date})
        setErrorLWD('')
    }

    const handleCheck = () => {
        setData({...data, pendingPayroll: !data.pendingPayroll})
    }

    const handleModal = () =>{
        setModal(!modal)
    }

    // SUBMIT HANDLERS
    const handleNext = () =>{
        if(data.causal === ''){
            setErrorCausal("Debe seleccionar una opción")
        }
        if(data.last_worked_day === ''){
            setErrorLWD('Debes seleccionar una fecha')
        }
        if(data.causal !== '' && data.last_worked_day!==''){
            handleModal()
        }
    }

    const handlerConfirmNext = () => {
        onNextClick(data)
    }

    return(
        <>
        <CompleteModal open={modal} closeAction={handleModal} >
            <Container className='d-flex justify-content-center align-items-center flex-column' >
                <Row>
                    <Col>
                        <h4  className='text-center' >¿Deseas agregar la última nómina entro de la liquidación</h4>
                        <p className='text-center' >Si la agregas se incluirá en el documento, de lo contrario serán generadas por la plataforma</p>
                    </Col>
                </Row>
                <Row className='w-100' >
                    <Col >
                        <p className='color-symplifica text-center my-4' style={{borderBottomWidth: 1, borderBottomColor: '#f2f2f2', borderBottomStyle:'solid'}} >
                            última nómina
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table>
                            <tbody>
                                <tr  >
                                    <td className='border-top-0 d-flex align-items-center' >
                                        <label htmlFor='pending_payroll' >
                                            <img height={30} src={data?.pendingPayroll ? CheckGreen : CheckGray} alt='check' />
                                            <input onChange={handleCheck} id='pending_payroll' name='pending_payroll' type='checkbox' className='invisible' />
                                        </label>
                                        <p>Agregar nónima noviembre</p>
                                    </td>
                                    <td className='border-top-0' >
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SubmitButton
                            onClick={handlerConfirmNext}
                            block
                            text='Aceptar y continuar'
                        />
                    </Col>
                </Row>
            </Container>
        </CompleteModal>
        <Container>
            <Row>
                <Col xs={12} className="d-flex flex-column align-items-center" >
                    <p className='text-center w80' style={{fontSize:20}} >Datos de la liquidación</p>
                    <p className='text-center w80' >Completa los datos para finalizar el contrato</p>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col>
                        <InputSelect 
                            name='causal'
                            options={getChoices()}
                            default_option='Rázon de liquidación de contrato'
                            onChange={handleCausal}
                            error={errorCausal}
                            />
                        <InputDate 
                            placeholder='Fecha último día de trabajo'
                            onChange={(name, date)=>handleLastWorkedDay(name, date)}
                            value={data?.last_worked_day}
                            error={errorLWD}
                            label=''
                            name='last_worked_day'
                            key=''
                        />
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' >
                    <TooltipBasic 
                        placement='top'
                        text='texto'
                        question
                    />
                    <a className='select-employee-link my-4' href='#k' >Preguntas frecuentes sobre la liquidación</a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubmitButton
                        onClick={handleNext}
                        block
                        text='Calcular liquidación'
                    />
                </Col>
            </Row>
        </Container>
        </>
    )
}