import React from 'react';

import { Row, Col } from 'react-bootstrap';

// Components
import { SubmitButton } from 'shared/components';

interface DownloadDocumentProps {
    text: string;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

// ==============================================
/// 
// ==============================================
export const DownloadDocument: React.FC<DownloadDocumentProps> = ({ text, onClick}) => {
    return (
        <div className="card-container-download" onClick={onClick}>
            <Row className="my-2">
                <Col xs={2} className="padding-0">
                    <div className="container-img">
                        <img src="/icons/cards/down-document.png" />
                    </div>
                </Col>
                <Col xs={5} className="padding-0 card-body">
                    <span>{text}</span>
                </Col>
                <Col xs={5} className="padding-0 action-label">
                    <label>
                        por firmar
                    </label>
                </Col>
            </Row>
        </div>
    );
}