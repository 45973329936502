import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from '../../../assets/ico/cont-icon-arrow-left.png';

const BackTitle = ({history, text}) => {
    return (
        <>
            <div className='arrow-head-l' style={{cursor: 'pointer'}} alt='arrow down' onClick={() => history.goBack()}></div>
            <span style={{ marginLeft: 15 }}>{text}</span>
        </>
    );
};

BackTitle.propTypes = {
    history: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
};

export {BackTitle};