
export enum TypeChoice {
    PAYMENT_FREQ = "PAYMENT_FREQ",
    FIXED_TERM = "FIXED_TERM",

    ENTITIES = "SS_SUBSYSTEM_CHOICES_SYCORECOLOMBIADOMESTICS",
    BONUS_TYPE = "BONUS_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    GENDER = "GENDER_CHOICES_SYCORECOLOMBIADOMESTICS",
    RELATIONSHIP = "RELATIONSHIP_CHOICES_SYCORECOLOMBIADOMESTICS",
    ID_TYPE = "ID_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    MARITAL_STATUS = "CLIENT_MARITAL_STATUS_CRM",
    CCF_INSCRIPTION = "CCF_INSCRIPTION_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    TERMINATION_CHOICES = "TERMINATION_CHOICES_SYCORECOLOMBIADOMESTICS",
    PAYMENT_METHOD = "PAYMENT_METHOD_CHOICES",
    ARL_RATE = "ARL_RATE_CHOICES_SYCORECOLOMBIADOMESTICS",
    CONTRACT_TYPE = "CONTRACT_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    WORKING_DISTANCE = "WORKING_DISTANCE_CHOICES_SYCORECOLOMBIADOMESTICS",
    POSITION = "POSITION_CHOICES_SYCORECOLOMBIADOMESTICS",
    HEALTH_SUBSCRIPTION_TYPE = "HEALTH_SUBSCRIPTION_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    RETIREMENT_SUBSCRIPTION_TYPE = "RETIREMENT_SUBSCRIPTION_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    RECORD_TYPE = "RECORD_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    INCIDENCE_TYPE = "INCIDENCE_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    ID_TYPES = "ID_TYPES_CHOICES_SYCORECOLOMBIADOMESTICS",
    SHOPPING_CART = "SHOPPING_CART_STATE_CHOICES_SYCORECOLOMBIADOMESTICS",
    EXTRA_HOURS = "EXTRA_HOURS_CHOICES_SYCORECOLOMBIADOMESTICS",
    LICENSES_TYPE = "LICENSES_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    PERMISSIONS_TYPE = "PERMISSIONS_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    INCAPACITY_TYPE = "INCAPACITY_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    VACATIONS_TYPE = "VACATIONS_TYPE_CHOICES_SYCORECOLOMBIADOMESTICS",
    USERS_EXTERNAL_LOGIN = "USERS_EXTERNAL_LOGIN_VENDOR_SYSUPPORTACCESSCONTROL",
    VENDOR_LOGIN_MECHANISM = "VENDOR_LOGIN_MECHANISM_SYSUPPORTACCESSCONTROL",
    MESSAGE_GATEWAY = "MESSAGE_GATEWAY_CHOICES_SYSUPPORTACCESSCONTROL",
    TRANSACTION_TYPE = "TRANSACTION_TYPE_CHIOCES_SYSUPPORTPAYMENTS",
    RESPONSE_TYPE = "RESPONSE_TYPE_OWNER_CHOICES_SYINFORMATIONOPERATOR",
    DOCUMENT_TYPE = "DOCUMENT_TYPE_CHOICES_ARUS",
    ANSWER_ARUS = "ANSWER_CHOICE_ARUS",
    ACCOUNT_TYPE_ARUS = "ACCOUNT_TYPE_CHOICES_ARUS",
    CLASIFICATION_ID_ARUS= "CLASIFICATION_ID_CHOICES_ARUS",
    CONTRIBUTOR_ID_ARUS = "CONTRIBUTOR_ID_CHOICES_ARUS",
    LEGAL_NATURE_ARUS = "LEGAL_NATURE_CHOICES_ARUS",
    PENSION_PAYER_ARUS = "PENSION_PAYER_CHOICES_ARUS",
    PLANILLA_TYPE_ARUS = "PLANILLA_TYPE_CHOICES_ARUS"
}
