
export class EmployeeClient {
    id: string = "";
    vendor: string = "";
    name: string = "USUARIO Prueba";
    id_type: string = "CC";
    id_num: string = '';
    id_expedition_date: string = '';
    id_expedition_place: string = '';
    gender: string = '';
    marital_status: string = '';
    birthdate: string = '';
    birth_country: string = '';
    birth_city: string = '';
    birth_state: string = '';
    email: string = '';
    phone: string = '';
    address_line1: string = '';
    address_line2: string = '';
    city: string = '';
    country: string = '';
    state: string = '';
    zipcode: string = '';
    debit: boolean = false;
    debit_month_day: number = 5;
    pipedrive_id: string = '';
    referral_code: string = "";
    is_active: boolean = true;
    referred_by: string = '';
}
