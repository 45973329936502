import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputSelect, InputText } from 'shared/components';
import { APP_HELP_CENTER, HELP_CENTER_REPORTS_WORKED_DAYS_KNOW_MORE } from 'shared/constants/app';

const InfoScreen = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  let liStyle = {
    backgroundImage: "url(/icons/reports/permisos.svg)",
    backgroundPosition: "-5 0",
    backgroundSize: "1em 1em",
    backgroundRepeat: "no-repeat",
    paddingLeft: "-2em",
    width: "1em",
    height: "1em",
    minWidth: "1em"
  }
  return (
    <Container>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center"
      }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
          <li style={{ fontSize: 12 }}>
            Esto afectará el valor del pago de la quincena o mes de tu empleado y su Seguridad Social.
          </li>
          <li style={{ fontSize: 12 }}>
            Un empleado de tiempo parcial no puede trabajar más de 21 días al mes.
          </li>
        </ul>
        <a
          href={HELP_CENTER_REPORTS_WORKED_DAYS_KNOW_MORE}
          style={{
            width: "100%", textAlign: "center",
            display: "flex", alignContent: "center",
            justifyContent: "center", marginTop: "1em",
            marginBottom: "1em"
          }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto", textDecoration: "underline" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const WorkedDaysForm = ({ stateMachine, sendToMachine, employees }:
  {
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    stateMachine: { context: { employee: string } },
    sendToMachine: (arg0: {}) => void
  }) => {
  let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
  let [amount, setAmount] = useState(0)
  return (
    <div style={{ width: "100%" }}>
      <p style={{ textAlign: "center", width: "100%", marginTop: 8 }}>
        Selecciona los días trabajados efectivamente por tu empleado en este período de nómina.
      </p>
      <div
        style={{
          height: "100%",
          width: "70%",
          display: "grid",
          gridTemplateColumns: "40% 20% 40%",
          gridTemplateRows: "40% 20% 40%",
          margin: "auto",
          justifyItems: "center",
          alignItems: "center",
          marginBottom: 8
        }}
      >
        <div
          id="daysAmount"
          style={{
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            height: "60%",
            minWidth: "125px",
            minHeight: "125px",
            width: "60%",
            borderRadius: "1000px",
            gridColumn: "1/4",
            gridRow: "1/4",
            boxShadow: "0 3px 5px 2px rgba(190, 209, 209, 0.45)",
            textAlign: "center",
            fontSize: "3em"
          }}>{amount}</div>
        <button
          onClick={() => setAmount(amount - 1)}
          id="substractDays"
          style={{
            zIndex: 3,
            fontSize: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "30%",
            minWidth: "35px",
            minHeight: "35px",
            width: "20%",
            borderRadius: "1000px",
            gridColumn: "1/2",
            gridRow: "2/3",
            border: "none",
            boxShadow: "0px 3px 5px 2px #BED1D1",
            color: "white",
            backgroundColor: "#FD5C5C"
          }}>-</button>
        <button
          onClick={() => setAmount(amount + 1)}
          id="addDays"
          style={{
            zIndex: 3,
            fontSize: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
            minWidth: "35px",
            minHeight: "35px",
            width: "30%",
            gridColumn: "3/4",
            gridRow: "2/3",
            backgroundColor: "#FD5C5C",
            boxShadow: "0px 3px 5px 2px #BED1D1",
            border: "none",
            color: "white",
            borderRadius: "1000px"
          }}>+</button>
      </div >
      <p style={{ textAlign: "center", width: "100%", marginTop: 8, marginBottom: 0 }}>
        Días modificados
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          backgroundColor: "#f5f5f5",
          borderRadius: "20px",
          height: "60%",
          minWidth: "135px",
          minHeight: "40px",
          textAlign: "center",
          width: "30%",
          marginBottom: 15
        }}>
        {amount}
      </div>
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => sendToMachine({ type: "COMPLETE", data: { amount } })}
      >
        Guardar
      </Button>
    </div >
  )
}

const WorkedDays = ({ stateMachine, sendToMachine, employees }:
  {
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    stateMachine: {
      matches: (arg0: {}) => boolean,
      context: {
        employee: string
      }
    },
    sendToMachine: (arg0: {}) => void
  }
) => {

  return (
    <>
      <AppContainer title="Dias Trabajados" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        {stateMachine.matches({ selectReportType: { workedDays: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} />
          : <WorkedDaysForm sendToMachine={sendToMachine} stateMachine={stateMachine} employees={employees} />}
      </AppContainer>
    </>
  )
}
export default WorkedDays