import React from 'react';

interface InputToggleProps {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    textos?: any;
}

export const InputToggle: React.FC<InputToggleProps> = ({ name, onChange, value = false, textos = {active: 'SI', inactive: 'NO'} }) =>{

    return (
        <label className='btn input-toggle'>
            {
                value
                    ? (
                        <div className='input-toggle-container' >
                            <div className='input-toggle-dot input-toggle-dot-active' ></div>
                            <p className='my-0' >{textos.active}</p>
                        </div>
                    )
                    : (
                        <div className='input-toggle-container'>
                            <p className='my-0' >{textos.inactive}</p>
                            <div className='input-toggle-dot' ></div>
                        </div>
                    )
            }
            <input type="checkbox" checked={value} name={name} style={{ display: 'none' }} onChange={onChange} />
        </label>
    )
}