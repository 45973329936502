import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { EmptyEmployee, RegisterEmployee, ActiveEmployee } from './components';
import { AccordionCustom, SubmitButton } from 'shared/components';

// Interface
import { Contract, ResponseHttp2, Employer } from 'shared/interfaces';

// API
import { RegisterRepository } from 'shared/repository';

// Constants
import { REGISTER_CREATE_CONTRACT } from 'shared/constants/urls';

export const ListEmployeesPage: React.FC = () => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [numEmployees, setNumEmployees] = useState<number>(1);
    const [list_active_employees, setListActiveEmployees] = useState<Array<Contract>>([]);
    const [list_new_employees, setListNewEmployees] = useState<Array<Contract>>([]);

    const employer: Employer = useSelector<any, Employer>(state => state.state.employer || {});

    // ==============================================
    /// Get init Data
    // ==============================================
    useEffect(() => {
        const fetchData = async () => {
            if (employer.id) {
                setIsLoading(true);
                const resp: ResponseHttp2 = await RegisterRepository.getEmployerEmployees(employer.id);

                if(resp.success){
                    setNumEmployees( resp.data.active_employees.length + resp.data.new_employees.length );
                    setListActiveEmployees(resp.data.active_employees);
                    setListNewEmployees(resp.data.new_employees);
                }else
                    console.error(resp.message);

                setIsLoading(false);
            }
        }

        fetchData();
    }, []);


    return (
        <AppContainer title="Empleados" onPressBack={() => history.goBack()}>
            <Container fluid>

                <Row>
                    <Col xs={12} className="my-3">
                        <a href="#" className="d-flex justify-content-end text-link-employees">
                            <img style={{ width: 13, marginRight: 5 }} src="/icons/reports/interrogation-circle.svg" alt="?" />
                            ¿Qué es empleados?
                        </a>
                    </Col>
                    <Col xs={12}>
                        <p className="d-flex justify-content-center">Gestiona los detalles de tus empleados</p>
                    </Col>
                </Row>

                {
                    numEmployees == 0 && <EmptyEmployee history={history} /> 
                }
                
                {
                    numEmployees > 0 && (
                        
                        <>
                            <AccordionCustom group="Empleados en registro">
                                <>
                                    {
                                        list_new_employees.map((newE: Contract, idx: number) =>
                                            <RegisterEmployee 
                                                key={newE.employee?.id || `ne_id_${idx}`} 
                                                text={`${newE.employee?.first_name || 'Empleado'} ${newE.employee?.last_name || (idx + 1)}`} 
                                            />
                                        )
                                    }
                                </>
                            </AccordionCustom>

                            <AccordionCustom group="Tus empleados activos">
                                <>
                                    {
                                        list_active_employees.map((activeE: Contract, idx: number) =>
                                            <ActiveEmployee
                                                key={activeE.employee?.id || `ae_id_${idx}`}
                                                contract={activeE}
                                                history={history}
                                            />
                                        )
                                    }
                                </>
                            </AccordionCustom>
                        </>

                    )
                }

                {
                    list_new_employees.length == 0 && list_active_employees.length > 0 && (
                        <SubmitButton
                            className="button-circle float-button"
                            text="+"
                            variant="primary"
                            onClick={() => history.push(REGISTER_CREATE_CONTRACT)}
                        />
                    )
                }

            </Container>
        </AppContainer>
    );

} // FIN ListEmployeesPage