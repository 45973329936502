import React, { useState } from 'react';

// bOOTSTRAP
import { Col, Row, Form } from 'react-bootstrap';

// Components
import { InputText, InputDate, InputToggle, InputSelect, SubmitButton } from '../../../../../shared/components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// API
import PaymentRepository from '../../../repository/payment.repository';

// Utils
import { dateToString } from '../../../../../shared/utils/utils';

const CreditCardForm = ({ tipoDoc, handleNotify, history, client_id, email }) => {

    const [isLoading, setIsLoading] = useState(false);

    // =======================================================
    /// 
    // =======================================================
    const submitForm = async () => {
        if (validate() > 0) return;

        const formData = {
            ...inputs,
            expiration_date: dateToString(inputs.expiration_date),
        }

        setIsLoading(true);
        const resp = await PaymentRepository.setCreditCard(formData);
        setIsLoading(false);
        if (resp.success) {
            handleNotify('success', 'Se creo la tarjeta de crédito correctamente');
            window.setTimeout(() => history.goBack(), 2000);
        }else {
            handleNotify('danger', resp.message);
        }
    }

    // =======================================================
    /// 
    // =======================================================
    const validate = () => {

        let count = 0;

        if (!inputs.account_number || inputs.account_number.length === 0 ) {
            handleError({ account_number: 'Este campo es obligatorio'})
            count++;
        }

        if (!inputs.cvc || inputs.cvc.length === 0 ) {
            handleError({ cvc: 'Este campo es obligatorio'})
            count++;
        } else if (inputs.cvc.length < 3 || inputs.cvc.length > 4) {
            handleError({ cvc: 'No es un código válido' })
            count++;
        }

        if (!inputs.expiration_date || inputs.expiration_date.length === 0 ) {
            handleError({ expiration_date: 'Este campo es obligatorio'})
            count++;
        }

        if (!inputs.hasOwnProperty('is_cardholder') ) {
            handleError({ is_cardholder: 'Este campo es obligatorio'})
            count++;
        }

        if (!inputs.name || inputs.name.length === 0 ) {
            handleError({ name: 'Este campo es obligatorio'})
            count++;
        }

        if (!inputs.holder_id_type || inputs.holder_id_type.length === 0 ) {
            handleError({ holder_id_type: 'Este campo es obligatorio'})
            count++;
        }

        if (!inputs.holder_id || inputs.holder_id.length === 0 ) {
            handleError({ holder_id: 'Este campo es obligatorio'})
            count++;
        }


        return count;

    }

    const {
        handleSubmit, handleInputChange, inputs, errors, handleError, setInputChange, handleCheckChange
    } = useCustomForm({
        account_number: '',
        cvc: '',
        expiration_date: '',
        is_cardholder: true,
        name: '',
        holder_id_type: '',
        holder_id: '',
        method_expiration_date: dateToString(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
        client_id,
        email
    }, submitForm);

    return(
        <Form 
            className='my-5' 
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            
            <Row className='my-2' >
                <Col md={4} sm={12}>
                    <InputText 
                        name="account_number"
                        type="number"
                        error={errors.account_number}
                        value={inputs.account_number}
                        onChange={handleInputChange}
                        placeholder='Número de tarjeta' 
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputText 
                        name="cvc"
                        type="number"
                        error={errors.cvc}
                        value={inputs.cvc}
                        onChange={handleInputChange}
                        placeholder='Código de seguridad CVC' 
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputDate 
                        name="expiration_date"
                        error={errors.expiration_date}
                        value={inputs.expiration_date}
                        onChange={setInputChange}
                        minDate={new Date()}
                        showMonthYearPicker
                        placeholder='Fecha de vencimiento' 
                    />
                </Col>
            </Row>

            <Row className='my-2' >
                <Col className='d-table justify-content-around' md={4} sm={12}>
                    <div className='d-table-cell'><p className='text-bold' >¿Eres el títular?</p></div>
                    <div className='d-table-cell text-right'>
                        <InputToggle
                            name="is_cardholder"
                            error={errors.is_cardholder}
                            value={inputs.is_cardholder}
                            onChange={handleCheckChange}
                        />
                    </div>
                </Col>
                <Col md={4} sm={12}>
                    <InputText 
                        name="name"
                        error={errors.name}
                        value={inputs.name}
                        onChange={handleInputChange}
                        placeholder='Nombre del títular' 
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputSelect 
                        name="holder_id_type"
                        error={errors.holder_id_type}
                        value={inputs.holder_id_type}
                        onChange={handleInputChange}
                        default_option='Tipo de documento' 
                        options={tipoDoc}
                    />
                </Col>
            </Row>

            <Row className='my-2'>
                <Col md={4} sm={12} >
                    <InputText 
                        placeholder='Número de documento' 
                        name="holder_id"
                        type="number"
                        error={errors.holder_id}
                        value={inputs.holder_id}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>

            <Row className='d-lg-flex justify-content-center my-5'>
                <Col className='col-lg-4' >
                    <SubmitButton 
                        text='Guardar' 
                        block 
                        disabled={isLoading}
                        spinner={isLoading}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export {CreditCardForm}