import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputSelect, InputText } from 'shared/components';
import { HELP_CENTER_REPORTS_EXTRA_TIME_KNOW_MORE } from 'shared/constants/app';

const InfoScreen = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
          <li style={{ fontSize: 12 }}>
            Son horas laborales adicionales a la jornada laboral ordinaria que trabaja tu empleado. Estas debes pagarlas a tu empleado adicional a su salario.
          </li>
          <li style={{ fontSize: 12 }}>
            De acuerdo al horario de tu empleado y el rango trabajado tendrá¡n un incremento distinto si se tratan de horas diurnas (6am - 9pm), nocturnas (9pm - 6am), dominicales o festivas.
          </li>
          <li style={{ fontSize: 12 }}>
            *Una jornada ordinaria está comprendida según lo pactado en el contrato. Tu empleado no puede trabajar más de 2 horas extra al día ni 12 a la semana.
          </li>
        </ul>
        <a
          href={HELP_CENTER_REPORTS_EXTRA_TIME_KNOW_MORE}
          style={{
            width: "100%", textAlign: "center",
            display: "flex", alignContent: "center",
            justifyContent: "center", marginTop: "1em",
            marginBottom: "1em"
          }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto", textDecoration: "underline" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const ExtraTimeForm = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  let [type, setType] = useState("")
  let [hours_amount, setHours] = useState(0)
  const [hoursError, setHoursError] = useState(false)
  const [selectError, setSelectError] = useState(false)
  let [save, setSave] = useState(false)
  let checkHours = () => hours_amount !== 0
  let checkSelect = () => type.length
  return (
    <div style={{ width: "100%" }}>
      <p style={{ textAlign: "center", width: "100%" }}>
        Indícanos la cantidad de horas adicionales y el tipo de las mismas.
     </p>
      <InputSelect
        name="type"
        value={type}
        error={selectError ? "Debes seleccionar un tipo de hora extra" : undefined}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectError(false)
          setType(event.target.value)
        }}
        options={[
          { value: "", text: "Tipo de hora extra" },
          { value: "13", text: "Hora extra diurna" },
          { value: "14", text: "Hora extra nocturna" },
          { value: "15", text: "Hora extra dominical" },
          { value: "16", text: "Hora extra nocturna dominical " },
          { value: "17", text: "Hora extra festiva" },
          { value: "18", text: "Hora extra nocturna festiva" },
        ]}
      />
      <InputText
        error={hoursError ? "No es un valor válido" : false}
        style={{ display: "grid", gridTemplateColumns: "2fr 1fr", alignItems: "center", width: "80%", marginLeft: "auto", marginRight: "auto" }}
        name="hours"
        value={`${hours_amount}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setHoursError(false); setHours(parseInt(e.target.value)) }}
        type="number"
        label="Número de horas extra:"
      />
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => checkHours()
          ? checkSelect() ?
            sendToMachine({ type: "COMPLETE", data: { type, hours_amount, save: true } })
            : setSelectError(true)
          : setHoursError(true)}
      >
        Guardar
      </Button>
    </div >
  )
}

const ExtraTime = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean },
    sendToMachine: (arg0: {}) => void
  }
) => {

  return (
    <>
      <AppContainer title="Horas Extra" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        {stateMachine.matches({ selectReportType: { extraTime: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} />
          : <ExtraTimeForm sendToMachine={sendToMachine} />}
      </AppContainer>
    </>
  )
}
export default ExtraTime