import React from 'react';
import { Router, Switch, Route, Redirect } from "react-router-dom";

// Utils
import history from './shared/constants/history';

// Guards
import PrivateRoute from './shared/guards/private_routes';
import PublicRoute from './shared/guards/public_routes';
import ProtectedRoute from './shared/guards/protected_routes';
import ImpersonateRoute from './shared/guards/impersonate_routes';

// Router
import AuthRouter from './modules/Auth/AuthRouter';
import RegisterRouter from './modules/Register/RegisterRouter';
import AppRouter from './modules/App/AppRouter';

// Pages
import { Page404 } from './modules/Error/Pages';

const AppsRouter = () => {

    const RedirectTo = () => <Redirect to='/auth' />;

    return (
        <Router history={history}>
            <Switch>
                <Route exact path='/'>{RedirectTo()}</Route>
                <PublicRoute path='/auth' restricted={true} component={AuthRouter} />
                <PrivateRoute path='/register' component={RegisterRouter} />
                <PrivateRoute path='/app' component={AppRouter} />
                <ImpersonateRoute path='/impersonate' />
                <Route component={Page404} />
                <Route component={ProtectedRoute} />
            </Switch>
        </Router>
    );
};


export default AppsRouter;