import { 
    LOGIN_WITH_EMAIL_PASS,
    FIRST_VIEW_PAGE,
    SET_EMPLOYER,
    SET_EMAIL,
    LOGIN_OUT,
    SET_LOGIN,
    SET_CONTRACT_TO_EMPLOYER,
    SET_PRE_CONTRACT,
    FORGOT_PASSWORD,
    SET_INVOICE_TO_STATE,
} from "../../shared/constants/redux";

import { loadState } from '../../shared/utils/localStorage';
import { parseJwt } from '../../shared/utils/utils';

const localState = loadState();
const stateDefault = {
    token: '',
    refresh_token: '',
    client_id: '',
    employer: {},
    contracts: [],
    email: '',
    firstView: true
};

let defaultState = localState
    ? {...stateDefault, ...localState}
    : stateDefault;

export default function global(state = defaultState, action){

    switch(action.type){

        case LOGIN_WITH_EMAIL_PASS:
            let tokenLoginEmail = parseJwt(action.payload.token) || {};
            return {
                ...state,
                ...action.payload,
                client_id: tokenLoginEmail.client_id
            };

        case FIRST_VIEW_PAGE:
            return {
                ...state,
                firstView: false
            };

        case SET_EMPLOYER:
            return {
                ...state,
                employer: action.data
            }

        case SET_EMAIL:
            return {
                ...state,
                email: action.payload
            };

        case SET_LOGIN:
            let tokenLogin = parseJwt(action.payload.token);
            return {
                ...state,
                ...action.payload,
                client_id: tokenLogin.client_id
            };

        case LOGIN_OUT:
            return {};

        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPass: {...action.payload}
            };

        case SET_PRE_CONTRACT:
            return {
                ...state,
                preContract: action.payload
            }

        case SET_CONTRACT_TO_EMPLOYER:
            let { contracts } = state;
            if ( contracts && contracts.length > 0) {
                let encontrado = false;
                contracts.forEach( (key, idx) => {
                    if (action.payload.id && action.payload.id === key.id) {
                        encontrado = true;
                        contracts[idx] = action.payload;
                    }
                });
                if( !encontrado )
                    contracts.unshift(action.payload);
            }else
                contracts.push(action.payload);

            return {
                ...state,
                contracts
            };

        case SET_INVOICE_TO_STATE:
            return {
                ...state,
                invoice: action.payload
            }

        default:
            return state
    } // FIN switch
}