import Http from 'shared/utils/Http2';
import { SERVER } from 'shared/constants/app';

import {Contract, Employer, Employee } from 'shared/models';

export default class RegisterRepository {

    // =======================================================
    /// ** CLIENT **
    // =======================================================

    // =======================================================
    /// [GET]: Consulta Obtiene los datos de un empleador
    // =======================================================
    static async getClient(client_id) {
        const url = `${SERVER}/enchantress/client/${client_id}/`;
        return await Http.get(url);
    }

    // =======================================================
    /// ** EMPLOYER **
    // =======================================================

    // =======================================================
    /// [POST]: crea los datos del registro en su primera parte
    // =======================================================
    static async createEmployer(formData) {

        const url = `${SERVER}/enchantress/employer/`;
        return await Http.post(url, formData);
    }

    // =======================================================
    /// [GET]: Consulta Obtiene los datos de un empleador
    // =======================================================
    static async getEmployer(client_id, token = '') {
        const url = `${SERVER}/enchantress/client/${client_id}/employer/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        const employer = resp.data.length > 0 ? resp.data[0] : {};
        return {
            ...resp,
            data: employer
        };

    }

    // =======================================================
    /// [GET]: Consulta Obtiene los lugares de trabajo de un empleador
    // =======================================================
    static async getEmployerWorkplaces(client_id, token = '') {
        const url = `${SERVER}/enchantress/employer/${client_id}/workplaces/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        return resp.success ? resp.data : [];
    }

    // =======================================================
    /// [GET]: Request choise of employer workplaces
    // =======================================================
    static async getChoiceEmployerWorkplaces(client_id) {
        const url = `${SERVER}/enchantress/employer/${client_id}/workplaces/?fields=id,name&pagination=0`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return data.map(item =>
                ({ value: item.id, text: item.name })
            );
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Consulta los datos de contratos de un empleador
    // =======================================================
    static async getEmployerContracts(employer_id, token = '') {
        const url = `${SERVER}/enchantress/employer/${employer_id}/contracts/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        return resp.success ? resp.data : [];
    }

    // =======================================================
    /// [GET]: Consulta el workspace por defecto
    // =======================================================
    static async getDefaultWorkspace(employer_id) {
        const url = `${SERVER}/enchantress/employer/${employer_id}/workplace/`;
        let resp = await Http.get(url);

        const workplace = resp.data && resp.data.length > 0 ? resp.data[0] : {};
        return {
            ...resp,
            data: workplace
        };

    }

    // =======================================================
    /// [POST]: Set a ARL entity to a employer
    // =======================================================
    static async setArlEmployer(formData) {
        const url = `${SERVER}/enchantress/employer/ss_entity/`; //#PENDING
        const resp = await Http.post(url, formData);

        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            return {
                ...resp,
                data: contract
            };
        } else
            return resp;
    }

    // =======================================================
    /// ** END EMPLOYER **
    // =======================================================

    // =======================================================
    /// ** CONTRACT **
    // =======================================================

    // =======================================================
    /// [POST]: Crea un contrato por defecto
    // =======================================================
    static async createContract(formData) {
        const url = `${SERVER}/enchantress/contract/`;
        const resp = await Http.post(url, formData);

        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            return {
                ...resp,
                data: contract
            };
        } else
            return resp;
    }

    // =======================================================
    /// [GET]: Consulta los datos de contrato de un empleado
    // =======================================================
    static async getContract(contract_id) {
        const url = `${SERVER}/enchantress/contract/${contract_id}/?pagination=0&depth=1`;
        const resp = await Http.get(url);
        if (resp.success)
            return {
                ...resp,
                data: resp.data[0] || {}
            };

        return resp;
    }

    // =======================================================
    /// [GET]: Request the salary data from a contract employee
    // =======================================================
    static async getContractSalary(contract_id) {
        const url = `${SERVER}/enchantress/contract/${contract_id}/salary/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const contract = resp.data.contract ? JSON.parse(resp.data.contract) : {};
            return {
                ...resp,
                data: {
                    base_salary: `${Number(contract.base_salary)}` || '',
                    salary_in_kind_value: `${contract.salary_in_kind_value}` || '',
                    bonus_list: contract.bonus_list || [],
                }
            }
        } else {
            return resp;
        }
    }

    // =======================================================
    /// [PUT]: Update the employee contract data
    // =======================================================
    static async updateContract(contract_id, formData) {
        const url = `${SERVER}/enchantress/contract/${contract_id}/`;
        const resp = await Http.put(url, formData);
        return resp;
    }

    // =======================================================
    /// [PUT]: Update the salary data from a contract employee
    // =======================================================
    static async updateContractSalary(contract_id, formData) {
        const url = `${SERVER}/enchantress/contract/${contract_id}/contract_salary/`;
        return await Http.put(url, formData);
    }

    // =======================================================
    /// ** END CONTRACT **
    // =======================================================

    // =======================================================
    /// [POST]: Finaliza el proceso del checkout
    // =======================================================
    static async checkout(payment_subscription_id) {
        const resp = await Http.post(`${SERVER}/enchantress/checkout/`, { payment_subscription_id });

        if (resp.success) {
            const { data } = resp;
            const invoice = JSON.parse(data.invoice);
            return {
                ...resp,
                data: invoice
            };
        } else {
            return resp;
        }
    }

    // =======================================================
    /// [GET]: Consulta los items de shopping cart de un cliente
    // =======================================================
    static async shoppingCart() {
        const url = `${SERVER}/enchantress/shopping_cart/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            let shoppingcart_items = data.shoppingcart_items ? JSON.parse(data.shoppingcart_items) : [];
            let totals_list = data.totals_list ? JSON.parse(data.totals_list) : {};
            return {
                ...resp,
                data: {
                    shoppingcart_items,
                    totals_list,
                }
            }
        } else {
            return {
                ...resp,
                data: {
                    shoppingcart_items: [],
                    totals_list: [],
                }
            }
        }
    }

    // =======================================================
    /// [GET]: Consulta los items de promociones asignadas a un cliente
    // =======================================================
    static async loadPromoCodes() {
        const url = `${SERVER}/enchantress/load_promocodes/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const assigned_promocodes = data.assigned_promocodes ? JSON.parse(data.assigned_promocodes) : [];
            return {
                ...resp,
                data: assigned_promocodes
            };
        } else {
            return {
                ...resp,
                data: []
            };
        }
    }

    // =======================================================
    /// [POST]: Agrega un código de referido a un cliente
    // =======================================================
    static async applyReferralCode(formData) {
        return await Http.post(`${SERVER}/enchantress/apply_referal_code/`, formData);
    };

    // =======================================================
    /// [POST]: Agrega una promoción a un cliente
    // =======================================================
    static async setPromo(formData) {
        return await Http.post(`${SERVER}/enchantress/promo_codes/validate/`, formData);
    };

    // =======================================================
    /// [POST]: Asigna una promoción registrada a un cliente
    // =======================================================
    static async applyPromoCode(formData) {
        return await Http.post(`${SERVER}/enchantress/promo_codes/apply/`, formData);
    };

    // =======================================================
    /// [POST]: Asigna una promoción registrada a un cliente
    // =======================================================
    static async unapplyPromoCode(formData) {
        return await Http.post(`${SERVER}/enchantress/promo_codes/apply/`, formData);
    };

    // =======================================================
    /// [GET]: Consulta los items de invoice para mostrar resumen
    // =======================================================
    static async getInvoices(invoice_id) {
        const url = `${SERVER}/enchantress/invoice/${invoice_id}/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;

            let listItems = {
                items: [],
                promo_codes: [],
                items_value: 0,
                promo_codes_value: 0,
            };
            data.items.forEach(key => {
                listItems.items_value += Number(key.amount);
                listItems.items.push({ amount: key.amount, name: key.description });
            });
            data.promo_codes.forEach(key => {
                listItems.promo_codes_value += Number(key.amount);
                listItems.promo_codes.push({ amount: key.amount, name: key.description });
            });
            return {
                ...resp,
                data: listItems
            }
        } else
            return resp;

    } static async getDashboardData(contract_id) {
        const url = `${SERVER}/enchantress/load_onboarding/?contract_id=${contract_id}`;
        const resp = await Http.get(url);

        if (resp.success) {
            const contract = resp.data.contract ? JSON.parse(resp.data.contract) : new Contract();
            const employer = resp.data.employer ? JSON.parse(resp.data.employer) : new Employer();
            const employee = resp.data.employee ? JSON.parse(resp.data.employer) : new Employee();

            return {
                ...resp,
                data: {
                    contract,
                    employer,
                    employee
                }
            }
        }

        return resp;
    }

    // =======================================================
    /// [GET]: Ask for reports by employer
    // =======================================================
    static async getReportsByEmployer(employer_id, isOld = false) {
        const url = `${SERVER}/enchantress/novedades/${employer_id}/${isOld ? "old" : ""}`;
        const resp = await Http.get(url);
        if (resp.success) {
            return {
                ...resp,
                data: JSON.parse(resp.data.contracts)
            }
        }

        return resp;
    }


    // =======================================================
    /// [GET]: Ask by employer and employee documents status
    // =======================================================
    static async getEmployeeSuscriptions(contract_id) {
        const url = `${SERVER}/enchantress/contract/${contract_id}/ss_subscriptions_view/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const suscriptions = resp.data && resp.data.ss_subsystems ? JSON.parse(resp.data.ss_subsystems) : [];
            return suscriptions;
        } else
            return [];

    }

    // =======================================================
    /// [GET]: Request the beneficiaries by employee
    // =======================================================
    static async getEmployeeBeneficiaries(employer_id) {
        const url = `${SERVER}/enchantress/employee/${employer_id}/beneficiaries/?pagination=0&depth=2&is_active=true`;

        const resp = await Http.get(url);

        if (resp.success) {
            return resp.data;
        } else
            return [];

    }


    // =======================================================
    /// [GET]: get employee by employee_id
    // =======================================================
    static async getEmployee(employee_id) {
        const url = `${SERVER}/enchantress/employee/${employee_id}/`;
        const resp = await Http.get(url);
        if (resp.success) {
            return {
                ...resp
            };
        }

        return resp;
    }

    static async getEmployeeClient(employee_id) {
        const url = `${SERVER}/enchantress/client/employee/${employee_id}/`;
        const resp = await Http.get(url);
        if (resp.success) {
            return {
                ...resp
            };
        }

        return resp;
    }
    // =======================================================
    /// [POST]: create employee by employee_id
    // =======================================================
    static async createEmployee(formData) {
        const url = `${SERVER}/enchantress/employee/`;
        const resp = await Http.post(url, formData);

        if (resp.success) {
            return {
                ...resp
            };
        }

        return resp;
    }

    // =======================================================
    /// [DELETE]: request delete a beneficiary
    // =======================================================
    static async deleteBeneficiary(beneficiary_id) {
        const url = `${SERVER}/enchantress/beneficiary/${beneficiary_id}/`;
        return await Http.reqDelete(url);
    }

    // =======================================================
    /// [DELETE]: Delete a Report
    // =======================================================
    static async deleteReportById(type, report_id) {
        const url = `${SERVER}/enchantress/novedades/${type}/${report_id}`;
        const resp = await Http.reqDelete(url);
        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            const employer = JSON.parse(resp.data.employer);
            return {
                ...resp,
                data: {
                    contract,
                    employer
                }
            }
        }

    }

    // =======================================================
    /// [GET]: get employee inscriptions per employer
    // =======================================================
    static async getEmployeeSocialSecurity(employer_id) {

        const url = `${SERVER}/enchantress/employer/${employer_id}/ss_employee_subscriptions/`;
        const resp = await Http.get(url);
        if (resp.success) {
            let data = []
            let incoming = JSON.parse(resp.data.contracts);
            if (incoming && Array.isArray(incoming)) {
                data = incoming;
            }
            return {
                data
            }
        }
    }

    // =======================================================
    /// [POST]: Set ARL suscription to employer
    // =======================================================
    static async setEmployerArlSuscription(formData) {

        const url = `${SERVER}/enchantress/employer_arl_subscription/`;
        const resp = await Http.post(url, formData);
        return resp;
    }

    /// [POST]: Set SS entity suscription to employer
    // =======================================================
    static async setEmployerSecuritySuscription(formData) {

        const url = `${SERVER}/enchantress/employer_ss_subscription/`;
        const resp = await Http.post(url, formData);
        return resp;
    }

    // =======================================================
    /// [POST]: Create a employee's beneficiary
    // =======================================================
    static async setEmployerBeneficiary(formData) {

        const url = `${SERVER}/enchantress/beneficiary/`;
        const resp = await Http.post(url, formData);
        return resp;
    }

    // =======================================================
    /// [POST]: upload employee documents
    // =======================================================
    static async getDocumentsEmployee(formData) {
        const url = `${SERVER}/enchantress/employee/documents/`;
        const resp = await Http.post(url, formData);

        if (resp.success) {
            const contract = resp.data && resp.data.contract ? JSON.parse(resp.data.contract) : {};
            const beneficiary_documents = resp.data && resp.data.beneficiary_documents ? JSON.parse(resp.data.beneficiary_documents) : [];
            const employee_documents = resp.data && resp.data.employee_documents ? JSON.parse(resp.data.employee_documents) : {};
            const beneficiaries = resp.data && resp.data.beneficiaries ? JSON.parse(resp.data.beneficiaries) : {};

            const newResp = {
                ...resp,
                data: {
                    contract,
                    beneficiary_documents,
                    employee_documents,
                    beneficiaries,
                }
            }

            console.log("newResp", newResp);

            return newResp;
        }

        return resp;
    }

    // =======================================================
    /// [GET]: Consulta los datos de contratos de un empleador
    // =======================================================
    static async getEmployerContractsWithEmployeeInfo(employer_id) {
        const url = `${SERVER}/enchantress/employer/${employer_id}/contracts_with_employee_info/`;
        const resp = await Http.get(url);

        return resp.success ? resp.data : [];
    }
    /// [POST]: upload employee documents
    // =======================================================
    static async uploadDocuments(formData) {
        const url = `${SERVER}/enchantress/save_document/`;
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return await Http.post(url, formData);
    }


    // =======================================================
    /// [POST]: Finish the register process
    // =======================================================
    static async activateEmployees(employer_id, formData) {
        const url = `${SERVER}/enchantress/employer/${employer_id}/activate/`;
        return await Http.post(url, formData);
    }

}


