import React from 'react';

// BOOTSTRAP
import { OverlayTrigger, Tooltip} from 'react-bootstrap';

// IMAGES
import AlertIcon from 'assets/ico/cont-icon-alert.png';
import QuestionIcon from 'assets/ico/cont-icon-question.png';

export type TypePlacement = "auto-start" | "auto" | "auto-end" | "top-start" | "top" | "top-end" | "right-start" | "right" | "right-end" | "bottom-end" | "bottom" | "bottom-start" | "left-end" | "left" | "left-start";

export type TypeTooltipBasic = { placement: TypePlacement, text: string, keyTooltip?: string, question?: boolean; };

interface TooltipBasicProps {
    keyTooltip?: string;
    placement: TypePlacement;
    text: string;
    question?: boolean;
}

export const TooltipBasic: React.FC<TooltipBasicProps> = ({ placement, text, question = false, keyTooltip = '' }) => {

    return(
        <OverlayTrigger
            key={keyTooltip}
            placement={placement}
            overlay={(
                <Tooltip arrowProps={{ ref: keyTooltip, style:{ background: 'red' }}} id={`tooltip-${keyTooltip}`}>
                    {text}
                </Tooltip>
            )}
        >
            <button className='tooltip-button' ><img style={{height:15}} src={question ? QuestionIcon : AlertIcon} alt='alert' /></button>
      </OverlayTrigger>
    )
}