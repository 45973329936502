import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from 'shared/containers';

// COMPONENTS
import { AccordionConfirmCheckout, SubmitButton, BackTitle } from 'shared/components';

// API
import {RegisterRepository} from 'shared/repository';
import { dolarize } from 'shared/utils/utils';

// CONST
import { REGISTER_ONBOARDING_PAGE } from 'shared/constants/urls';

const Done = ({history}) => {

    const {state} = useLocation();
    const invoice = state && state.invoice ? state.invoice : {};

    const [isLoading, setIsLoading] = useState(false);
    const [ detailInvoice, setDetailInvoice ] = useState({
        items: [],
        promo_codes: [],
        items_value: 0,
        promo_codes_value: 0,
    });

    useEffect( () => {

        const fetchData = async () => {
            setIsLoading(true);
            const resp = await RegisterRepository.getInvoices(invoice.id);

            if (resp.success)
                setDetailInvoice(resp.data);

            setIsLoading(false);

        }

        fetchData();

    }, [] );

    return(
        <RegisterContainer title={<BackTitle history={history} text="Finaliza tu registro" />} info={{title:'Recuerda', subtitle: 'Este es el cobro inicial de los servicios de Symplifica. Las afiliaciones se cobran una sola vez y los fees se cobran mensualmente en la fecha de débito.'}} >
            <Container >
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Continua y crea tu empleado en Symplifica</h4>
                    </Col>
                </Row>
                <Row className='promo-page-title py-0' >
                    <Col>
                        <p className='color-symplifica my-0' >Afiliación</p>
                    </Col>
                    <Col>
                        <p className='color-symplifica my-0 text-right' >{dolarize(detailInvoice.items_value)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AccordionConfirmCheckout 
                            data={detailInvoice.items}
                        />
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center mt-5 mb-0' >
                    <Col className='col-lg-4' >
                        <p className='text-center' >Primer pago: {dolarize(Number(detailInvoice.items_value) - Number(detailInvoice.promo_codes_value))} - En proceso</p>
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center mb-5 mt-0' >
                    <Col className='col-lg-4' >
                        <SubmitButton 
                            disabled={isLoading}
                            spinner={isLoading}
                            text='Guardar' 
                            type="button" 
                            block onClick={() => history.push(REGISTER_ONBOARDING_PAGE)} 
                        />
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <p className='p-4 mb-4 summary-page-info' >*Cada transacción tiene un costo de $5.900 más 4xmil. El primer débito se realiza al inscribir tu método de pago para activar tu cuenta. El recaudo puede tardar hasta 3 días hábiles (cuenta bancaria). Realizaremos débitos de forma automática a tu cuenta a finales de mes correspondientes a seguridad social, nuestro servicio y nómina (si así lo seleccionas más adelante), podrás solicitar cambiar esto si lo deseas.</p>
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        invoice: state.invoice
    }
}

const DonePage = connect(mapStateToProps, null)(Done);

export { DonePage }