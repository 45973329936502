import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// GLOBAL PAGES
import AppsRouter from './AppsRouter';

const App = ()  =>{

	return (
		<AppsRouter />
	);
}

export default App;
