import { Contract as ContractInterface } from 'shared/interfaces';

export class Contract implements ContractInterface{

    id = '';
    contract_adendum = [];
    parent_contract = '';
    child_contract = '';
    employee = {id:''};
    employer = {id:''};
    employee_id = '';
    employer_id = '';
    workplace = {
        id: '',
        name: ''
    };
    ces_inscription = '';
    afp_inscription = '';
    arl_inscription = '';
    eps_inscription = '';
    ccf_inscription = '';
    pipedrive_id = '';
    contract_type = "1";
    employee_working_distance = "3";
    position = "1";
    health_subscription_type = "3";
    retirement_subscription_type = "4";
    automatic_renewal = false;
    existing_contract = false;
    arl_rate = "1";
    planned_end_date = new Date();
    start_date = '';
    test_period = '';
    service_start_date = '';
    ss_start_date = '';
    real_end_date = '';
    works_on_monday = true;
    works_on_tuesday = true;
    works_on_wednesday = true;
    works_on_thursday = true;
    works_on_friday = true;
    works_on_saturday = false;
    works_on_sunday = false;
    start_time = '';
    end_time = '';
    payroll_payment_method = "2";
    payment_freq = 1;
    net_monthly_value = 0;
    net_daily_value = 0;
    base_salary = 0;
    salary_in_kind_value = 0;
    monthly_worked_days = 0;
    afp_employee = 0;
    afp = 0;
    eps_employee = 0;
    eps = 0;
    arl = 0;
    ccf = 0;
    transport_aid = 0;
    mandatory_unemployment_savings_provision = 0;
    mandatory_unemployment_savings_arrears_provision = 0;
    mandatory_bonus_provision = 0;
    vacation_provision = 0;
    available_vacation_days = 0;
    vacation_days_given = 0;
    state = "1";
    termination_reason = null;
    subscription_id = '';
    product_id = '';
    salary_aids = [];
    updated_once = false;

    // ==============================================
    /// Return a instance of Contract from a object
    // ==============================================
    static getInstance(contract:any): Contract {
        let new_contract = new Contract();
        new_contract.setData(contract); 
        return new_contract;
    }

    // ==============================================
    /// Init the fiels of Contract class
    // ==============================================
    setData(contract):void {
        if (contract) {
            for (const key in contract) {
                if (this.hasOwnProperty(key)) {
                    this[key] = contract[key];
                }
            }
        }
    }

    // =======================================================
    /// Return a contract with the fields to edit contract form
    // =======================================================
    getContractToEditFormData() {
        let newContract:any = {};

        newContract.id = this.id;
        newContract.contract_type = this.contract_type;
        newContract.position = this.position;
        newContract.position_name = "";
        newContract.workplace = this.workplace.id;
        newContract.workplace_name = this.workplace.name;
        newContract.work_location = this.employee_working_distance;
        newContract.work_location_name = "";

        newContract.contract_term = 'fixed'; // fijo = true || indefinido = false
        newContract.init_date = new Date(this.start_date);
        newContract.end_date = new Date(this.planned_end_date);
        newContract.start_time = new Date(this.start_time);
        newContract.init_hour = this.start_time;
        newContract.end_time = new Date(this.end_time);
        newContract.end_hour = this.end_time;
        newContract.arl_rate = this.arl_rate;
        newContract.arl_rate_name = "";
        newContract.payment_frequency = this.payment_freq;
        newContract.payment_destination = this.payroll_payment_method;
        newContract.health_contribution_type = this.health_subscription_type;
        newContract.health_contribution_type_name = '';
        newContract.saves_for_retirement = this.retirement_subscription_type;
        newContract.saves_for_retirement_name = "";
        newContract.available_vacation_days = `${this.vacation_days_given}`;
        newContract.vacation_days_given = `${this.vacation_days_given}`;
        newContract.working_days = this.getWorkingDaysText();
        newContract.updated_once = this.updated_once;

        return newContract;
    }

    getWorkingDaysText() {
        let week_days_workeds = '';
        week_days_workeds += this.works_on_monday ? 'Lun ' : '';
        week_days_workeds += this.works_on_tuesday ? ', Mar' : '';
        week_days_workeds += this.works_on_wednesday ? ', Mie' : '';
        week_days_workeds += this.works_on_thursday ? ', Jue' : '';
        week_days_workeds += this.works_on_friday ? ', Vie' : '';
        week_days_workeds += this.works_on_saturday ? ', Sab' : '';
        week_days_workeds += this.works_on_sunday ? ', Dom' : '';
        return week_days_workeds;
    }

    // ==============================================
    /// Return the number of fields wrong in edit contract form
    // ==============================================
    static validateEditForm(inputs:any, callback: Function): number {
        let count = 0;
        if (!inputs.init_date) {
            callback({ init_date: 'Este campo es obligatorio' });
            count++;
        }
        if (inputs.end_date && inputs.end_date < inputs.init_date) {
            callback({ end_date: 'No puede ser inferior a la fecha de inicio' });
            count++;
        }
        if (
            inputs.new_workplace_name ||
            inputs.new_workplace_address1 ||
            inputs.new_workplace_state ||
            inputs.new_workplace_city
        ) {
            if (!inputs.new_workplace_name) {
                callback({ new_workplace_name: 'Completa este campo' });
                count++;
            }
            if (!inputs.new_workplace_address1) {
                callback({ new_workplace_address1: 'Completa este campo' });
                count++;
            }
            if (!inputs.new_workplace_state) {
                callback({ new_workplace_state: 'Completa este campo' });
                count++;
            }
            if (!inputs.new_workplace_city) {
                callback({ new_workplace_city: 'Completa este campo' });
                count++;
            }
        }

        return count;
    }

}
