import React from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import {Row, Col} from 'react-bootstrap';

interface FieldProps {
    title: string;
    value: string | number;
    pred?: boolean;
}

export const Field: React.FC<FieldProps> = ({ title, value, pred = false }) => {

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <p className='text-bold'>{title}</p>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <p>{value}</p>
                    </Col>
                    <Col>
                        {
                            pred && (<p className='badge-warning'>Predeterminado</p>)
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}