import React from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from '../../../../shared/containers';

// Components
import { Notify } from '../../../../shared/components';

// FORM
import { WorkplaceForm } from './forms';

// Actions
import { setEmployerAction } from '../../../../redux/actions';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

const Workplace = ({ setEmployer, employer, history }) => {

    const { showNotify, handleNotify } = useNotifyState();

    return(
        <RegisterContainer 
            title='Tus datos contacto' 
            info={{title:'¿Por qué estos datos?', subtitle: 'Quedarán registrados en las entidades de seguridad social.'}} 
            showNotify={showNotify}
        >
            <Container >
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Completa todos tus datos básicos</h4>
                    </Col>
                </Row>
                <WorkplaceForm setEmployer={setEmployer} employer={employer} history={history} handleNotify={handleNotify} />
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({ state }) => {
    return {
        token: state.token,
        employer: state.employer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEmployer: employer => dispatch(setEmployerAction(employer))
    };
}

const WorkplacePage = connect(mapStateToProps, mapDispatchToProps)(Workplace);

export {WorkplacePage};