 import React, {useState} from 'react';

// BOOTSTRAP
import {Form, Col, Row, Image, Container} from 'react-bootstrap';

// COMPONENTS
import { InputText, SubmitButton, LinkButton, CompleteModal, closeModal, openModal } from 'shared/components';

// Images
import Google from 'assets/ico/ico-google.png';
import Facebook from 'assets/ico/ico-facebook.png';

// DATA
import {TyC, PP} from './helperData';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// API
import { AccessControl } from '../../../repository/auth.repository';

// Utils
import { validateEmail, validatePhone } from 'shared/utils/validate';


const RegisterForm = ({ setLogIn, handleNotify }) =>{

    const [tycModalState, tycModalChange] = useState(false);
    const [policyModalState, policyModalChange] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitForm = async () => {

        if (validate()) return;

        setIsLoading(true);
        const resp = await AccessControl.registerMailAndPassword(
            inputs.email,
            inputs.password,
            inputs.cellphone,
            inputs.name
        );

        if (resp.success) {

            let login = await AccessControl.loginMailAndPassword(inputs.email, inputs.password);
            setIsLoading(false);

            if (login.success)
                redirectTo(login);
            else
                handleNotify('danger', resp.message, true);

        } else {
            setIsLoading(false);
            if(resp.message === 'No se ha podido finalizar el proceso'){
                handleNotify('danger', "Usuario ya existente", true);
            }
            if(resp.errors.email && resp.errors.email === "already exist")
                handleError({"email": "Parece que ya existe un usuario con este email. Si eres tú, inicia sesión."})
            else
                handleNotify('danger', resp.message, true);
        }
            
    }

    // =======================================================
    /// Valida cada campo del formulario para resaltar si no es correcto
    // =======================================================
    const validate = () => {
        let errores = 0;
        // validar tipo de dato email/telefono
        if (!validateEmail(inputs.email)) {
            handleError({ 'email': 'Ingresa un email válido' });
            errores++;
        }

        // validar tipo de dato email/telefono
        if (!validatePhone(inputs.cellphone)) {
            handleError({ 'cellphone': 'Ingresa un número celular válido' });
            errores++;
        }

        if (!inputs.password || inputs.password.length < 8 || inputs.password.length > 60) {
            handleError({ 'password': 'Tu contraseña debe contenerEntre 8 y 60 caracteres. Debe tener al menos un número. Debe tener al menos una mayúscula.' });
            errores++;
        }

        if (!inputs.name || inputs.name.length < 6) {
            handleError({ 'name': 'No es un nombre válido' });
            errores++;
        }

        return errores > 0;
    }

    // =======================================================
    /// 
    // =======================================================
    const loginGoogle = async (e) => {
        const resp = await AccessControl.loginWithGoogle(e);
        redirectTo(resp);

    }

    // =======================================================
    /// 
    // =======================================================
    const loginFacebook = async (e) => {
        const resp = await AccessControl.loginWithFacebook(e);
        redirectTo(resp);

    }

    // =======================================================
    /// Valida el token y redirecciona según la respuesta
    // =======================================================
    const redirectTo = async (resp) => {
        if (resp.success) {
            const { token, refresh_token } = resp.data;
            if (token) {
                const listChoices = await AccessControl.getChoices();
                setLogIn(token, refresh_token, inputs.email, {}, [], listChoices);
                window.location.reload();

            } else {
                handleNotify('danger', 'Ups, no se pudo realizar el login automaticamente', true);
            }

        } else {
            handleNotify('danger', resp.message, true);
        }
    }

    // =======================================================
    /// Hook: Manejo de los datos del formulario
    // =======================================================
    const {
        handleSubmit, handleInputChange, inputs, errors, handleError
    } = useCustomForm({ email: '', password: '', cellphone: '', name: '' }, handleSubmitForm);

    // const styleButton = { background: 'white', border: '2px solid #E8E8E8', };

    return(
        <Col  >
            <CompleteModal closeAction={()=>closeModal(tycModalChange)} open={tycModalState}  >
                <Container >
                    <Row >
                        <Col >
                            <h2 className='color-symplifica text-center my-5 pt-3' >Términos & Condiciones Symplifica</h2>
                            <TyC />
                        </Col>
                    </Row>
                </Container>
            </CompleteModal>
            <CompleteModal closeAction={()=>closeModal(policyModalChange)} open={policyModalState}  >
                <Container >
                    <Row >
                        <Col >
                            <h2 className='color-symplifica text-center my-5 pt-3' >Términos & Condiciones Symplifica</h2>
                            <PP />
                        </Col>
                    </Row>
                </Container>
            </CompleteModal>
            <Form
                onSubmit={handleSubmit}
            >
                <InputText
                    placeholder='Nombre'
                    className='my-3'
                    name="name"
                    value={inputs.name}
                    error={errors.name}
                    onChange={handleInputChange}
                />
                <InputText
                    placeholder="Correo electrónico"
                    className='my-3'
                    name="email"
                    value={inputs.email}
                    error={errors.email}
                    onChange={handleInputChange}
                />
                <InputText
                    placeholder="Celular"
                    className='my-3'
                    name="cellphone"
                    value={inputs.cellphone}
                    error={errors.cellphone}
                    onChange={handleInputChange}
                />
                <InputText
                    placeholder="Contraseña"
                    className='my-3'
                    name="password"
                    type="password"
                    value={inputs.password}
                    error={errors.password}
                    onChange={handleInputChange}
                />
                <br />
                <SubmitButton 
                    text='Crear cuenta' block
                    disabled={isLoading}
                    spinner={isLoading}
                />
            </Form>
            <p className='text-center mt-2 mb-0' >Al dar clic en “crear cuenta” estas aceptando nuestros</p>
            <Col className='d-flex justify-content-center align-items-center' >
                <LinkButton className='LinkButton-underline' text='Términos &amp; condiciones' action={()=>openModal(tycModalChange)} />
                <p className='my-0' >y</p>
                <LinkButton className='LinkButton-underline' text='Políticas de privacidad' action={()=>openModal(policyModalChange)} />
            </Col>
            <p className='text-center my-4' >Inicia con un clic</p>
            <Row>
                <Col className='d-flex justify-content-end' >
                    <SubmitButton 
                        block 
                        className='w-75'
                        onClick={loginFacebook}
                        variant="outline-gray"
                        disabled={isLoading}
                        noShadow
                    >
                        <Image src={Facebook} />
                    </SubmitButton>
                </Col>
                <Col  className='d-flex justify-content-start' >
                    <SubmitButton 
                        block 
                        noShadow
                        className='w-75'
                        onClick={loginGoogle}
                        variant="outline-gray"
                        disabled={isLoading}
                    >
                        <Image src={Google} />
                    </SubmitButton>
                </Col>
            </Row>
        </Col>
    )
}

export  {RegisterForm}