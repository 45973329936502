import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Bootstrap
import { Container, Row, Col, Form } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { InputText, AccordionCustom, InputSelect, SubmitButton, AidItem } from 'shared/components';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';
import useCustomForm from 'shared/utils/CustomHooks';

// Enums
import { TypeChoice } from 'shared/utils/emuns';

// Utils
import { valueChoice, dolarize, getChoicesOf } from 'shared/utils/utils';

// API
import { RegisterRepository } from 'shared/repository';

// interface
import { ResponseHttp2 } from 'shared/interfaces';

export const SalaryContractEmployeePage: React.FC = () => {

    const history = useHistory();
    const location: any = useLocation();

    const salary: any = location.state.salary;
    const contract_type: string = location.state.contract_type;
    const list_bonus_types = getChoicesOf(TypeChoice.BONUS_TYPE);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showNotify, handleNotify } = useNotifyState();
    const [new_bonus_type, setNewBonusType] = useState<{bonus_type:string, amount:string}>({bonus_type:'', amount: ''});
    const [list_new_bonus_type, setListNewBonusType] = useState<Array<{ bonus_type: string, amount: string }>>([]);
    const [error_new_bonus, setErrorNewBonus] = useState<{bonus_type:string, amount:string}>({bonus_type:'', amount: ''});

    // =======================================================
    /// Post data to update salary contract
    // =======================================================
    const handleSubmitForm = async () => {

        if (!inputs.base_salary) {
            handleError({ base_salary: 'No es un salario válido' });
            return;
        }

        setIsLoading(true);

        let formData:any = {
            ...inputs,
            bonus_list: list_new_bonus_type,
            base_salary: Number(inputs.base_salary),
            salary_in_kind_value: Number(inputs.salary_in_kind_value),
        };

        const resp:ResponseHttp2 = await RegisterRepository.updateContractSalary(location.state?.contract_id, formData);

        setIsLoading(false);

        if (resp.success) {
            handleNotify('success', resp.message);
            window.setTimeout(() => history.goBack(), 2000);
        } else {
            handleNotify('danger', resp.message);
        }

    }

    const {
        handleInputChange, handleSubmit, inputs, errors, handleError, setInputs
    } = useCustomForm({
        base_salary: Number(salary.base_salary),
        salary_in_kind_value: Number(salary.salary_in_kind_value),
        bonus_list_old: salary.bonus_list,
        bonus_list: [],
    }, handleSubmitForm);

    // =======================================================
    /// Remove a choice bonus_type
    // =======================================================
    const removeAid = (idx: string): void => {
        const bonus_list = list_new_bonus_type.filter((item: any) => item.bonus_type !== idx);
        setListNewBonusType(bonus_list);
    }

    // =======================================================
    /// Add a value of bonus_type
    // =======================================================
    const addBonusType = (event: React.FormEvent<HTMLSelectElement>) => {
        event.persist();
        const data: any = event.target;

        if(data.name === 'bonus_type')
            setErrorNewBonus((prev: any) => ({ ...prev, bonus_type: '' }));

        if(data.name == 'amount')
            setErrorNewBonus((prev: any) => ({ ...prev, amount: '' }));

        setNewBonusType((prev:any) => ({ ...prev, [data.name]:data.value }));
    }

    // ==============================================
    /// Add a new bonus_type choiced by client
    // ==============================================
    const updateAidSelected = () => {

        const find_bonus_old = inputs.bonus_list_old.find((item: any) => item.bonus_type === new_bonus_type.bonus_type);
        const find_bonus = list_new_bonus_type.find((item: any) => item.bonus_type === new_bonus_type.bonus_type);

        if (!new_bonus_type.bonus_type) {
            setErrorNewBonus((prev: any) => ({ ...prev, bonus_type: 'Este campo es obligatorio' }));
            return;
        }

        if (!new_bonus_type.amount) {
            setErrorNewBonus((prev: any) => ({ ...prev, amount: 'Este campo es obligatorio' }));
            return;
        }

        if (find_bonus && find_bonus.bonus_type) {
            setErrorNewBonus((prev: any) => ({ ...prev, bonus_type: 'El auxilio ya existe' }));
            return;
        }

        if (find_bonus_old && find_bonus_old.bonus_type) {
            setErrorNewBonus((prev: any) => ({ ...prev, bonus_type: 'Ya tiene agregado este auxilio' }));
            return;
        }

        const bonus_list = [...list_new_bonus_type, new_bonus_type];

        setNewBonusType({ bonus_type: '', amount: '' });
        setListNewBonusType(bonus_list);
    }

    return (
        <AppContainer title="Contrato" onPressBack={() => history.goBack()} showNotify={showNotify} showFooter={false}>
            <Container fluid>
                
                <Form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">
                            <p className="text-center w80 font-1rem">Edita los salarios y crea nuevos auxilios</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={3}>
                            Salario base
                        </Col>
                        <Col xs={6} md={3}>
                            <InputText
                                name="base_salary"
                                onChange={handleInputChange}
                                value={inputs.base_salary}
                                error={errors['base_salary']}
                                type="number"
                            />
                        </Col>
                        {
                            contract_type == '2' && (
                                <>
                                    <Col xs={6} md={3}>
                                        Salario en especie
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InputText
                                            name="salary_in_kind_value"
                                            onChange={handleInputChange}
                                            value={inputs.salary_in_kind_value}
                                            type="number"
                                        />
                                    </Col>
                                </>
                            )
                        }
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <AccordionCustom group="Auxilios no salariales">
                                <>
                                    {
                                        salary.bonus_list && salary.bonus_list.map((item: any, idx: number) =>
                                            <Row className="detail-bonus-list" key={`bonus_id_${item.id || idx}`}>
                                                <Col xs={6} className="font12">
                                                    {valueChoice(TypeChoice.BONUS_TYPE, item.bonus_type)}
                                                </Col>
                                                <Col xs={6} className="d-flex justify-content-center font12">
                                                    {dolarize(item.amount)}
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </>
                            </AccordionCustom>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='text-center my-3' >
                            <p className='color-symplifica pointer' >Agregar otro Auxilio no salarial</p>
                            <p>Los auxilios No salariales son pagos que haces a tu empleado  para apoyarlo en alguna necesidad adicional relacionada con su trabajo. Por ejemplo, transporte adicional o alimentación. Estos no suman a la Seguridad Social.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <InputSelect
                                default_option='Tipo de auxilio'
                                name="bonus_type"
                                value={new_bonus_type.bonus_type}
                                error={error_new_bonus.bonus_type}
                                onChange={addBonusType}
                                options={list_bonus_types}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={9} md={9}>
                                    <InputText
                                        placeholder="$ 0.000.000"
                                        value={new_bonus_type.amount}
                                        error={error_new_bonus.amount}
                                        name="amount"
                                        type="number"
                                        onChange={addBonusType}
                                    />
                                </Col>
                                <Col xs={3} md={3}>
                                    <SubmitButton 
                                        className='SubmitButton-circle rounded-circle center-content-button' 
                                        disabled={isLoading}
                                        text='+' 
                                        type="button" 
                                        onClick={updateAidSelected} 
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='bg-grey-symplifica my-4 rounded' >
                        {
                            list_new_bonus_type.map((item: any, i: number) => (
                                <Col xs={12} className="my-2" key={`aid_${i}`}>
                                    <AidItem
                                        text={valueChoice(TypeChoice.BONUS_TYPE, item.bonus_type)}
                                        value={item.amount}
                                        editable
                                        onClick={() => removeAid(item.bonus_type)}
                                    />
                                </Col>
                            ))
                        }
                    </Row>

                    <Row className='d-lg-flex justify-content-center my-5' >
                        <Col className='col-lg-4' >
                            <SubmitButton text='Guardar' block disabled={isLoading} spinner={isLoading} onClick={() => { }} />
                        </Col>
                    </Row>
                </Form>

            </Container>
        </AppContainer>
    );
}