import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// CONSTS
import { REGISTER_BASIC_DATA, REGISTER_WORKPLACE_DATA, REGISTER_CREATE_CONTRACT, REGISTER_CHECKOUT_SUMMARY } from "shared/constants/urls";

const PublicRoute = ({ component: Component, restricted, employer, contracts, token, ...rest }) => {

    let url = '/auth';
    if (token) {
        if (employer && employer.hasOwnProperty('id') && employer.id) {

            if (!employer.hasOwnProperty('workplaces') || employer.workplaces.length === 0)
                url = REGISTER_WORKPLACE_DATA;
            else if (!contracts || contracts.length === 0)
                url = REGISTER_CREATE_CONTRACT;
            else
                url = REGISTER_CHECKOUT_SUMMARY;

        } else
            url = REGISTER_BASIC_DATA;

    } else
        url = '/auth';

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={({ location }) => {
                return token && restricted
                    ? (
                        <Redirect
                            to={{
                                pathname: url,
                                state: { from: location }
                            }}
                        />
                    )
                    : (<Component />);
            }}
        />
    );
};

const mapStateToProps = ({ state }) => {
    return {
        token: state.token,
        employer: state.employer,
        contracts: state.contracts,
    }
}


export default connect(mapStateToProps, null)(PublicRoute);