import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputDate } from 'shared/components';
import { APP_HELP_CENTER } from 'shared/constants/app';

const InfoScreen = ({ sendToMachine, screen }:
  { screen: number, sendToMachine: (arg0: {}) => void }) => {
  const infoText: { [key: string]: { list: string[], disclaimer: string, remember: string } } = {
    9: {
      list: [
        "Son días que otorgas a forma de licencia debido al fallecimiento de un familiar de tu empleado. ",
        "Debes otorgarla por ley para familiares hasta del grado segundo de consanguinidad, primero de afinidad y segundo civil.",
        "Debes pagar normalmente el sueldo y aportes.",
      ],
      disclaimer: "*La licencia de luto tiene una duración por ley de 5 días hábiles",
      remember: "Esta información debes corroborarla mediante una partida de defunción."
    },
    6: {
      list: [
        "Son días remunerados entregados a tu empleado bajo una incapacidad médica de su EPS.",
        "Se dan para el cuidado del hijo recién nacido de tu empleado.",
        "Deberás pagar el sueldo al 100% y posteriormente hacer el recobro en la entidad.",
      ],
      disclaimer: "*La licencia de maternidad debe durar mínimo 18 semanas por ley",
      remember: "Esta información debes obtenerla del comprobante otorgado por la institución de salud."
    },
    7: {
      list: [
        "Son días remunerados entregados a tu empleado bajo una incapacidad médica de su EPS.",
        "Se dan para el cuidado del hijo recién nacido de tu empleado.",
        "Deberás pagar el sueldo al 100% y posteriormente hacer el recobro en la entidad.",
      ],
      disclaimer: "*La licencia de paternidad tiene una duración mínimo de 8 días hábiles.",
      remember: "Esta información debes obtenerla del comprobante otorgado por la institución de salud."
    },
    8: {
      list: [
        "Son días que otorgas a forma de licencia para que tu empleado pueda atender alguna Calamidad Doméstica.",
        "Debe durar todo el tiempo que el empleado necesite para solucionar la calamidad.",
        "Debes pagar normalmente el sueldo y aportes.",
      ],
      disclaimer: "*Sugerimos que esta licencia dure el tiempo necesario para que tu empleado solucione la calamidad.",
      remember: "Una licencia por luto no califica como calamidad."
    }
  }

  const currentInfo = infoText[screen]
  const InfoList = ({ currentInfo }: { currentInfo: { list: string[], disclaimer: string, remember: string } }) =>
    <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop:  15}}>
      {currentInfo.list.map((element, index) =>
        <li key={index} style={{ fontSize: 12 }}>
        {element}
      </li>
      )}
    </ul>

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <InfoList currentInfo={currentInfo} />
        <a
          href={APP_HELP_CENTER}
          style={{ textAlign: "center", display: "flex", alignContent: "center", justifyContent: "center" ,textDecoration: "underline"}}>
          <img alt= "" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <p style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto", fontSize: 10 }}>
          {currentInfo.disclaimer}
        </p>
        <div style={{ width: "100%", backgroundColor: "#F5F5F5", borderRadius: "10px", paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          <h6 style={{ color: "#00CCCB", fontSize: 11 }}>
            Recuerda
          </h6>
          <p style={{fontSize: 9}}>
            {currentInfo.remember}
          </p>
        </div>
        <Button
          style={{marginTop: 15, marginBottom: 15}}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

        <p style={{ fontSize: 12, textAlign: "center" }}>
          ¿Estás buscando dar días libres a tu empleado?
        </p>
        <button 
          style={{ fontSize: 12, background: "none", border: "none", color: "#00CCCB", textDecoration: "underline" }}
          onClick={()=> sendToMachine("REPORTPERMISSION")}>
          Repórtalas en Permisos.
        </button>
      </div>
    </Container >
  )
}
const LicenceForm = ({ sendToMachine, licenceType }:
  { licenceType: number, sendToMachine: (arg0: {}) => void }) => {
  let [init_date, setInitialDate] = useState<null | Date>(null)
  let [end_date, setEndDate] = useState<null | Date>(null)


  const DateSelector = () => {
    return (
      <div
        className="border-b-gray"
      >
        <p style={{ textAlign: "center", marginTop: 15, fontSize: 12 }}>Reporta las fechas de la licencia</p>
        <InputDate
          value={init_date}
          onChange={(name, date, event) => setInitialDate(date)}
          placeholder="Fecha inicio"
          name="startDate"
          label=""
        />
        <InputDate
          name="endDate"
          value={end_date}
          onChange={(name, date, event) => setEndDate(date)}
          placeholder="Fecha fin"
          label=""
        />
      </div>
    )
  }

  return (
    <div style={{ width: "100%" }}>
      <DateSelector />
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => sendToMachine({
          type: "COMPLETE",
          data: {
            init_date: init_date ? init_date.toISOString().split("T")[0] : "",
            end_date: end_date ? end_date.toISOString().split("T")[0] : "",
            type: licenceType
          }
        })}
      >
        Guardar
      </Button>
    </div >
  )
}

const TabButtons = ({ setlicenceType, licenceType, isMale = false }:
  { isMale: boolean, licenceType: number, setlicenceType: (arg0: number) => void }) => {
  return (
    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: 12
    }}>
      {isMale ?
        <button
          style={{
            backgroundColor: "white",
            border: "none",
            width: "100%",
            borderBottom: `${licenceType === 7 ? "2px solid #00CCCB" : "1px solid #565656"}`,
            color: `${licenceType === 7 ? " #00CCCB" : "#565656"}`
          }}
          onClick={() => setlicenceType(7)}
        >
          Paternidad
      </button>
        : <button
          style={{
            backgroundColor: "white",
            border: "none",
            width: "100%",
            borderBottom: `${licenceType === 6 ? "2px solid #00CCCB" : "1px solid #565656"}`,
            color: `${licenceType === 6 ? " #00CCCB" : "#565656"}`
          }}
          onClick={() => setlicenceType(6)}
        >
          Maternidad
      </button>
      }
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${licenceType === 9 ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${licenceType === 9 ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setlicenceType(9)}
      >
        Luto
      </button>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${licenceType === 8 ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${licenceType === 8 ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setlicenceType(8)}
      >
        Calamidad
      </button>
    </div>
  )
}

const Licenses = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean },
    sendToMachine: (arg0: {}) => void
  }
) => {
  let [isMale, setIsMale] = useState(false)
  let [licenceType, setLicenceType] = useState(6)
  if (isMale) setLicenceType(7)
  return (
    <>
      <AppContainer title="Licencias" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        <TabButtons licenceType={licenceType} setlicenceType={setLicenceType} isMale={isMale} />
        {stateMachine.matches({ selectReportType: { licence: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} screen={licenceType} />
          : <LicenceForm sendToMachine={sendToMachine} licenceType={licenceType} />}
      </AppContainer>
    </>
  )
}
export default Licenses