import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Bootstrap
import { Navbar } from 'react-bootstrap';

// components
import { SubmitButton } from '../Buttons';

// Icons
import ProfileIcon from 'assets/images/header-user.png';
import Logo from 'assets/images/logo.png';

// Actions
import { logOutAction } from 'redux/actions';

// Constants
import { APP_ACCOUNT_HOME } from 'shared/constants/urls';

interface HeaderNavProps {
    title?: string;
    onPressBack?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const HeaderNav: React.FC<HeaderNavProps> = ({ title, onPressBack }) => {

    const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState<boolean>(false);

    // ==============================================
    /// Do a logout of platform
    // ==============================================
    const logOut = () => dispatch(logOutAction())

    return (
        <Navbar fixed="top" className="app-navbar border-b-gray">

            <Navbar.Brand className="justify-content-start"
                onClick={onPressBack ? onPressBack : () => { }}
            >
                {
                    onPressBack ? <div className="chevron rotate-225 pointer"></div> : null
                }
            </Navbar.Brand>

            <Navbar.Brand
                className="justify-content-center w100"
                style={{ marginRight: 0 }}
            >
                {
                    title
                        ? (<h4
                            style={{ fontSize: 16, marginBottom: 0, fontWeight: 500 }}
                            className="color-symplifica text-center">
                            {title}
                        </h4>)
                        : (
                            <div
                                className="app-logo"
                                style={{ margin: 0, paddingRight: 0 }}>
                                <img
                                    src={Logo}
                                    alt='Logo'
                                />
                            </div>
                        )

                }
            </Navbar.Brand>

            <Navbar.Brand className="justify-content-end" style={{ marginRight: 0 }}>
                <div onClick={() => setShowMenu(prevState => !prevState)}>
                    <img
                        className="profile-pic pointer"
                        src={ProfileIcon}
                        alt='ProfileIcon'
                        style={{ width: 40, height: 40 }}
                    />
                </div>
                {
                    showMenu && (
                        <div className="dropdown-menu show">
                            <a href={APP_ACCOUNT_HOME} className="dropdown-item">
                                <img className="menu-pic" src="/icons/menus/profile.png" alt='ProfileIcon' />
                                Mi cuenta
                            </a>
                            <a href="/app/dashboard/help" className="dropdown-item">
                                <img className="menu-pic" src="/icons/menus/help.png" alt='ProfileIcon' />
                                Centro de ayuda
                            </a>
                            <div className="dropdown-divider" role="separator"></div>
                            <div>
                                <SubmitButton onClick={logOut} variant="secondary">
                                    <>
                                        <img className="menu-pic" src="/icons/menus/close.png" alt='ProfileIcon' />
                                            cerrar sesión
                                        </>
                                </SubmitButton>
                            </div>
                        </div>
                    )
                }
            </Navbar.Brand>

        </Navbar>
    );
}