import React from 'react';

// BOOTSTRAP
import {Col, Row} from 'react-bootstrap';

// CONTAINERS
import {AuthContainer} from '../../../shared/containers';

// IMAGES
import Logo from '../../../assets/images/logo.png'
import Upps from '../../../assets/images/upps.png'

const Page404 = () => {

    return (
        <Row>
            <Col>
                <Row>
                    <Col className='text-center my-5' >
                        <img src={Logo} alt='logo' />
                    </Col>
                </Row>
                <Row >
                    <Col >
                        <h3 className='color-symplifica text-center'>¡Página no encontrada!</h3>
                        <p className='text-center' >¿Tienes empleados en tu casa? Symplifica es para ti.</p>
                        <p className='text-center' >Deja tus datos y uno de nuestros asesores expertos te dará asesoría gratuita.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center my-4' >
                        <img src={Upps} alt='ilust' />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export {Page404}