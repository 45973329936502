import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'

const AddNewReportButton = ({ onClick }: {
   onClick: (arg0: { type: string }) => void }) => {
  return (
    <Row >
      <Col className='col-12 col-lg-6 text-center'>
        <Button
          style={{
            color: 'white',
            backgroundColor: '#00CCCB',
            borderRadius: 50,
            width: '80%',
            marginTop: 15,
            marginBottom: 15
          }}
          onClick={() => onClick({ type: "CREATE" })}
        >
          Agregar novedad
      </Button>
      </Col>
    </Row >
  )
}
export default AddNewReportButton