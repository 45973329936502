import React from 'react';
import { useDispatch } from 'react-redux';

// BOOTSTRAP
import {Row, Col} from 'react-bootstrap';

// COMPONENT
import { SidebarRegister, RegisterNav, Notify } from 'shared/components';

//Actions
import { logOutAction } from 'redux/actions';

// Interface
import { TypeNotify } from 'shared/interfaces';

interface RegisterContainerProps {
    title: string | React.ReactNode;
    info: {title:string, subtitle:string};
    children: React.ReactNode;
    showNotify?: TypeNotify;
}

export const RegisterContainer: React.FC<RegisterContainerProps> = ({ title, info, children, showNotify = { open: false, message: '', type: 'success' } }) => {

    const dispatch = useDispatch();

    const logOut = () => dispatch(logOutAction());

    return(
        <div className="row-container" >
            <Col className="d-none d-lg-flex col-md-2 p-0" >{/* SPACING */}</Col>

            {/* SIDEBAR */}
            <SidebarRegister {...info} />
            

            <Col className='col-12 col-lg-10'>
                
                <RegisterNav title={title} action={logOut} />

                <Notify {...showNotify} />

                {children}

            </Col>
        </div>
    );
}