import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers/AppContainer';
import { SubmitButton, CardInfo } from 'shared/components';

// Interface
import { Employer, Client, ResponseHttp2 } from 'shared/interfaces';

// Api
import { AppRepository } from 'shared/repository/app.repository';

// Utils
import { dolarize } from 'shared/utils/utils';

// Constants
import {
  APP_DOCUMENTS_VOUCHERS,
  APP_DASHBOARD_PAYMENTS,
  APP_EMPLOYEES_LIQUIDATIONS,
  REGISTER_CREATE_CONTRACT,
} from 'shared/constants/urls';

import {
  APP_HELP_CENTER,
  APP_DASHBOARD_PAGE,
} from 'shared/constants/app';

const DasboardPage: React.FC = () => {

  const history = useHistory();

  const local_employer = useSelector<any, Employer>(state => (state.state.employer));

  const [employer, setEmployer] = useState<Employer | null>(local_employer);
  const [client, setClient] = useState<Client | null>(null);
  const [data_dashboard, setDataDashboard] = useState<any | null>({ month_name: '', month_payroll: 0, month_ss: 0 });

  useEffect(() => {
    const fetchData = async () => {
      const resp: ResponseHttp2 = await AppRepository.employerDashboard(local_employer.id);

      if (resp.success) {
        const { month_name, month_payroll, month_ss } = resp.data;
        setDataDashboard({ month_name, month_payroll, month_ss });
        setClient(resp.data.client);
      }
    }
    fetchData();
  }, []);

  return (
    <AppContainer>
      <Container fluid>
        <Row>
          <Col xs="12">
            <div className="d-flex justify-content-center">
              <h4 className="color-symplifica">Hola {employer?.first_name || ''} {employer?.last_name || ''}</h4>
            </div>
          </Col>
          <Col xs="12">
            <div className="d-flex justify-content-center">
              <SubmitButton
                onClick={() => {
                  history.push(REGISTER_CREATE_CONTRACT);
                }}
                text="Registro nuevo empleado"
                variant="secondary"
              />
            </div>
          </Col>
        </Row>

        <br />

        <Row>
          <Col xs="6" sm="6" md="3" className="my-3">
            <CardInfo
              title={`Salario ${data_dashboard.month_name || ''}`}
              value={`${dolarize(data_dashboard.month_payroll)}`}
              icon="sueldo"
              onClick={() => history.push(APP_DOCUMENTS_VOUCHERS)}
            />
          </Col>
          <Col xs="6" sm="6" md="3" className="my-3">
            <CardInfo
              title={`Aportes ${data_dashboard.month_name || ''}`}
              value={`${dolarize(data_dashboard.month_ss)}`}
              icon="aportes"
              onClick={() => history.push(APP_DASHBOARD_PAYMENTS)}
            />
          </Col>
          <Col xs="6" sm="6" md="3" className="my-3">
            <CardInfo
              value="Comprobante de nómina"
              icon="nomina"
              onClick={() => history.push(APP_DOCUMENTS_VOUCHERS)}
            />
          </Col>
          <Col xs="6" sm="6" md="3" className="my-3">
            <CardInfo
              value="Terminar contrato"
              icon="terminar_contrato"
              onClick={() => history.push(APP_EMPLOYEES_LIQUIDATIONS)}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col xs="4">
            <div className="d-flex justify-content-center flex-column align-items-center text-center">
              <SubmitButton onClick={() => window.location.href = `${APP_DASHBOARD_PAGE}/referidos`} variant="secondary" className="button-circle">
                <img src={'/icons/cards/referidos.png'} alt="go_to_pay" />
              </SubmitButton>
              <p className="font-10">Plan referidos</p>
            </div>
          </Col>
          <Col xs="4">
            <div className="d-flex justify-content-center flex-column align-items-center text-center">
              <SubmitButton onClick={() => window.location.href = "https://productos.symplifica.com/"} variant="secondary" className="button-circle">
                <img src={'/icons/cards/store.png'} alt="go_to_pay" />
              </SubmitButton>
              <p className="font-10">Symplifica store</p>
            </div>
          </Col>
          <Col xs="4">
            <div className="d-flex justify-content-center flex-column align-items-center text-center">
              <SubmitButton onClick={() => window.location.href = APP_HELP_CENTER || ''} variant="secondary" className="button-circle">
                <img src={'/icons/cards/ayuda.png'} alt="go_to_pay" />
              </SubmitButton>
              <p className="font-10">Centro de ayuda</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="d-flex justify-content-center"
              style={{ width: "80%", margin: "auto" }}>
              <SubmitButton onClick={() => history.push('/app/payments/pay')} variant="dark" block>
                <div style={{
                  display: "flex", alignItems: "center", justifyContent: "center", fontSize: 14
                }}>
                  <img
                    src={'/icons/dashboard/creditcard.svg'}
                    alt="go_to_pay"
                    style={{
                      width: "3em",
                      marginRight: 5,
                      color: "white",
                      filter: "grayscale(100%) brightness(0%) invert(100%)"
                    }} />
                  <p style={{ margin: 0, color: "white" }}>
                    Ir a pagar
                  </p>
                </div >
              </SubmitButton>
            </div>
          </Col>
        </Row>

        <Row className="my-3" style={{ marginBottom: 40 }}>
          <Col xs="12">
            <div className="d-flex justify-content-center">
              <Card style={{ border: 'none' }} onClick={() => history.push('/app/dashboard/init')}>
                <Card.Body style={{ padding: 0 }}>
                  <img src="/icons/cards/banner_referido.png" alt="" style={{ width: '100%' }} />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>

      </Container>
    </AppContainer>
  );
};

export { DasboardPage };