import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from 'shared/containers'

// COMPONENTS
import { SubmitButton } from 'shared/components';
import { CardSS, CardBeneficiary } from './components';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

// API
import {RegisterRepository} from 'shared/repository';

// Constants
import { REGISTER_ADD_NEW_BENEFICIARY } from 'shared/constants/urls';
import { ModalEPS, ModalAFP, ModalFC, ModalARL, ModalCCF } from './components';

const SocialSecurityPage = ({history}) => {

    const EPS = "Salud EPS";
    const AFP = "Pensión AFP";
    const FC = "Cesantías FC";
    const ARL = "Riesgos profesionales ARL";
    const CCF = "Caja de compensación CCF";

    const { id: contract_id } = useParams(); // contract_id
    const [isLoading, setIsLoading] = useState(false);
    const { showNotify, handleNotify } = useNotifyState();

    const [openModalEPS, setOpenModalEPS] = useState(false);
    const [openModalAFP, setOpenModalAFP] = useState(false);
    const [openModalFC, setOpenModalFC] = useState(false);
    const [openModalARL, setOpenModalARL] = useState(false);
    const [openModalCCF, setOpenModalCCF] = useState(false);

    const [suscriptions, setSuscriptions] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [contract, setContract] = useState({});
    const [employee, setEmployee] = useState({
        id: "",
        first_name: "empleado",
        last_name: "1",
    });


    const fetchSubscriptions = async () => {
        setSuscriptions([]);
        const resp_suscriptions = await RegisterRepository.getEmployeeSuscriptions(contract_id);
        setSuscriptions(resp_suscriptions);   
    }


    const fetchEmployee = async (employee_id) => {
        setBeneficiaries([]);
        const resp_beneficiaries = await RegisterRepository.getEmployeeBeneficiaries(employee_id);
        setBeneficiaries(resp_beneficiaries);
    }


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const resp_contract = await RegisterRepository.getContract(contract_id);
            fetchSubscriptions();

            if (resp_contract.success) {
                setContract(resp_contract.data);

                if (resp_contract.data.employee ) {
                    setEmployee(resp_contract.data.employee);
                    fetchEmployee(resp_contract.data.employee.id);
                }
            }
            setIsLoading(false);
        }

        fetchData();
    }, [contract_id]);

    // =======================================================
    /// 
    // =======================================================
    const handleOpenModalEPS = () => setOpenModalEPS(openEPS => !openEPS );
    const handleOpenModalAFP = () => setOpenModalAFP(openAFP => !openAFP );
    const handleOpenModalFC = () => setOpenModalFC(openFC => !openFC );
    const handleOpenModalARL = () => setOpenModalARL(openARL => !openARL );
    const handleOpenModalCCF = () => setOpenModalCCF(openCCF => !openCCF );

    // =======================================================
    /// 
    // =======================================================
    const setModalOpen = (type) => {
        switch (type) {
            case EPS:
                return handleOpenModalEPS;
            case AFP:
                return handleOpenModalAFP;
            case FC:
                return handleOpenModalFC;
            case ARL:
                return handleOpenModalARL;
            case CCF:
                return handleOpenModalCCF;
            default:
                return handleOpenModalEPS;
        }
    }

    return(
        <RegisterContainer 
            title='Seguridad Social' 
            info={{title: 'Cuentanos...', subtitle: 'Las entidades a las que ya están registrado tu empleado y tú para que hagamos la afiliación bajo tu nombre. Si aún no están afiliados selecciona las que desees.'}} 
            showNotify={showNotify}
        >

            {
                suscriptions.map( (item, idx) => {
                    if (item.subsystem === EPS)
                        return (<ModalEPS key={`Modal_${idx}`} open={openModalEPS} showModal={handleOpenModalEPS} handleNotify={handleNotify} contract={contract} callback={fetchSubscriptions} />)
                    if (item.subsystem === AFP)
                        return (<ModalAFP key={`Modal_${idx}`} open={openModalAFP} showModal={handleOpenModalAFP} handleNotify={handleNotify} contract={contract} callback={fetchSubscriptions} />)
                    if (item.subsystem === FC)
                        return (<ModalFC key={`Modal_${idx}`} open={openModalFC} showModal={handleOpenModalFC} handleNotify={handleNotify} contract={contract} callback={fetchSubscriptions} />)
                    if (item.subsystem === ARL)
                        return (<ModalARL key={`Modal_${idx}`} open={openModalARL} showModal={handleOpenModalARL} handleNotify={handleNotify} contract={contract} callback={fetchSubscriptions} />)
                    if (item.subsystem === CCF)
                        return (<ModalCCF key={`Modal_${idx}`} open={openModalCCF} showModal={handleOpenModalCCF} handleNotify={handleNotify} contract={contract} callback={fetchSubscriptions} />)
                    
                } )
            }
                
            <Container>
                <Row className='d-block d-lg-flex justify-content-center my-5'  >
                    <Col className='col-12 col-lg-6 text-center'>
                        <p>Completa los datos de la afiliación de:</p>
                        <h4 className='color-symplifica' >{employee.first_name} {employee.last_name}</h4>
                        <p>Validaremos esta información contra las entidades de Seguridad Social y te afiliaremos a las entidades que hagan falta</p>
                    </Col>
                </Row>

                <Row >
                    <Col>
                        <div className='border-b-symplifica pb-2'>
                            <p className='color-symplifica m-0 text-bold'>Entidades</p>
                        </div>
                    </Col>
                </Row>
                <Row >
                    <Col >
                        {
                            suscriptions.map((item, idx) => <CardSS key={`ss_${idx}`} {...item} onClick={setModalOpen(item.subsystem)} /> )
                        }
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col>
                        <div className='border-b-symplifica pb-2'>
                            <p className='color-symplifica m-0 text-bold'>Beneficiarios</p>
                        </div>
                    </Col>
                </Row>
                <Row className='my-5' >
                    <Col >
                        <p className='color-symplifica' >Convenciones</p>
                        <Row >
                            <Col className='d-flex align-items-center' >
                                <div className='point bg-symplifica'></div>
                                <p className='my-0 mx-2' >Salud - EPS</p>
                            </Col>
                            <Col className='d-flex align-items-center' >
                                <div className='point bg-red'></div>
                                <p className='my-0 mx-2' >Caja de compensación</p>
                            </Col>
                            <Col className='d-flex align-items-center' >
                                <div className='point bg-green-dark'></div>
                                <p className='my-0 mx-2' >Con subsidio familiar</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-12 col-lg-5' >
                        <SubmitButton
                            variant='light'
                            block
                            text='Agregar beneficiario'
                            className='my-4'
                            onClick={() => history.push(REGISTER_ADD_NEW_BENEFICIARY, { employee_id: employee?.id}) }
                        />
                    </Col>
                </Row>

                <Row className='mb-5'>
                    <Col>
                        {
                            beneficiaries.map(item => <CardBeneficiary key={`beneficiary_${item.id}`} {...item} handleNotify={handleNotify} callback={() => fetchEmployee(employee.id)} /> )
                        }
                    </Col>
                </Row>

                <br />
            </Container>
        </RegisterContainer>
    )
}

export { SocialSecurityPage };
