import React from 'react';
import { History } from 'history';

// Bootstrap
import { Row, Col } from 'react-bootstrap';

// Components
import { AccordionCustom, SubmitButton } from 'shared/components';

// Constants
import { REGISTER_CREATE_CONTRACT } from 'shared/constants/urls';

interface EmptyEmployeeProps {
    history: History,
}

export const EmptyEmployee: React.FC<EmptyEmployeeProps> = ({ history }) => {

    return (
        <Row>
            <Col xs={12}>
                <AccordionCustom group="Tus empleados activos">
                    <>
                        <div className="w100">
                            <img className="w100" src="/images/img_aux/empty_card.png" alt="empty_card" />
                        </div>
                        <p className="text-center">Parece que aún no tienes empleados activos, crea uno nuevo haciendo click en el botón +</p>
                        <div className="d-flex justify-content-center">
                            <SubmitButton 
                                className="button-circle" 
                                text="+" 
                                variant="primary" 
                                onClick={() => history.push(REGISTER_CREATE_CONTRACT)}
                            />
                        </div>
                    </>
                </AccordionCustom>
            </Col>
        </Row>
    );
}