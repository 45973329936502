import React from 'react';
import PropTypes from 'prop-types';

// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const ItemAccordion = ({ start_top, start_bottom, center, end_top, end_bottom }) => {

    return (
        <Row>
            <div className="container-item-toggle">
                <Col md={8} xs={4} className="item-start">
                    <div className='align-items-end flex-column d-flex' >
                        <p className='accordion-checkout-promo-amount item-toogle-line-through' >{start_top}</p>
                        <p style={{ margin: 0 }}>{start_bottom}</p>
                    </div>
                </Col>
                <Col md={2} xs={4} className="item-center">
                    {
                        center
                        ? (
                            <span className='px-3 py-1 accordion-checkout-promo-card'>
                                <p className='m-0' >{center}</p>
                            </span>
                        )
                        : null
                    }
                </Col>
                <Col md={2} xs={4} className="item-end">
                    <div className='align-items-center justify-content-end d-flex' >
                        <div className='align-items-end flex-column d-flex' >
                            <p className='accordion-checkout-promo-amount item-toogle-line-through' >{end_top}</p>
                            <p className='my-0' >{end_bottom}</p>
                        </div>
                    </div>
                </Col>
            </div>
        </Row>
    );
}

ItemAccordion.propTypes = {
    start_top: PropTypes.any,
    start_bottom: PropTypes.any,
    center: PropTypes.any,
    end_top: PropTypes.any, 
    end_bottom: PropTypes.any,
}

export {ItemAccordion};