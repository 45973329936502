import React from 'react';

// BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// COMPONENTS
import { TooltipBasic } from '../Tooltips';
import { Accordion } from 'react-bootstrap';


// ICONS
import ArrowIcon from '../../../assets/ico/cont-icon-arrow.png'

const TablePricesCategory  = ({title, data = [], tooltip}) => {

    return(
        <Row className='' >
            <Col >
                <Row >
                    <Col className='d-flex align-items-center mb-3' >
                        <p className='color-symplifica mx-0 my-0' >{title}</p>
                        {
                            tooltip ?
                                <TooltipBasic {...tooltip} />
                            :
                                null
                        }
                    </Col>
                </Row>
           
                {
                    data.map((item, i) => {

                        return(
                            <Row key={`TablePricesCategory_${i}`}>
                                <Col >
                                    <Accordion>
                                        <Accordion.Toggle as={Row} eventKey={i} className='pointer'>
                                                <Col>
                                                    <p>{item.category}</p>
                                                </Col>
                                                <Col className='mx-3' >
                                                    <p className='text-right text-bold' >{item.total} <div className='chevron-b mx-2'></div></p>
                                                </Col>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={i} >
                                            <div>
                                            {
                                                item.items.map((ite, index)=>{
                                                    return (
                                                        <Row key={`tp_${index}`}>
                                                            <Col className='mx-0' >
                                                                <p>{ite.title}</p>
                                                            </Col>
                                                            <Col className='mx-3' >
                                                                <p className='text-right' >{ite.amount}</p>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            </div>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Col>
        </Row>
    )
}

export { TablePricesCategory }