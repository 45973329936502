import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// BOOTSTRAP
import {Container, Row, Col} from 'react-bootstrap';

// COMPONENTS
import { RegisterContainer } from 'shared/containers';
import { TabHorizontal } from 'shared/components';
import { ContractSalaryTab, ContractDetailTab } from './components';

// FORMS
import { ContractDetailsForm } from './forms';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';

// API
import { RegisterRepository, GeneralRepository } from 'shared/repository';

// Models
import { Contract } from 'shared/models';

// Utils
import { TypeChoice } from 'shared/utils/emuns';
import { getChoicesOf } from 'shared/utils/utils';

export const ContractDataPage: React.FC = () => {

    const history = useHistory();
    const params:any = useParams();
    const contract_id:string = params?.id || '';
    const { showNotify, handleNotify } = useNotifyState();

    const employer_id = useSelector((state:any) => state.state?.employer.id || '' );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditing, changeEditing] = useState<boolean>(false);
    const [salary, setSalary] = useState<any>({});
    const [contract, setContract] = useState<Contract>(new Contract());

    // Choises
    const positions = getChoicesOf(TypeChoice.POSITION);
    const arl_levels = getChoicesOf(TypeChoice.ARL_RATE);
    const retirement_subscription_type = getChoicesOf(TypeChoice.RETIREMENT_SUBSCRIPTION_TYPE);
    
    const [choices, setChoices] = useState<any>({
        positions: [],
        arl_levels: [],
        retirement_subscription_type: [],
        employer_workplaces: [],
        employee_distance: [],
        health_suscriptions: [],
        list_states: [],
    });

    // =======================================================
    /// Find the name of choice value
    // =======================================================
    const setValueOfList = (value, listData) => {
        const itemChoice = listData.find( key => key.value == value );
        return itemChoice?.text || '';
    }

    const changeIsEditing = () =>{
        changeEditing(!isEditing);
    }

    // =======================================================
    /// 
    // =======================================================
    const setAidsList = (aids, choise_aid) => {
        return aids.map( aid => {
            const bonus = choise_aid.find( item => item.value === aid.bonus_type );
            if ( bonus && bonus.text ) 
                return {
                    ...aid,
                    name: bonus.text
                };
            else
                return {
                    ...aid,
                    name: ''
                };
        } );
    }

    // =======================================================
    /// 
    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const [
                resp_employer_workplaces,
                resp_employee_distance,
                resp_list_states,
                resp_health_suscriptions,
                resp_aids_list,
                resp_salary_contract,
                resp_contract,
            ] = await Promise.all([
                RegisterRepository.getChoiceEmployerWorkplaces(employer_id),
                GeneralRepository.getChoiceEmployeeDistance(),
                GeneralRepository.getStates(),
                GeneralRepository.getChoiceHealthSuscriptions(),
                GeneralRepository.getChoiceAids(),
                RegisterRepository.getContractSalary(contract_id),
                RegisterRepository.getContract(contract_id),
            ]);

            setChoices({
                positions,
                arl_levels,
                retirement_subscription_type,
                employer_workplaces: resp_employer_workplaces,
                employee_distance: resp_employee_distance,
                health_suscriptions: resp_health_suscriptions,
                list_states: resp_list_states,
            });

            if (resp_contract.success) {
                let preContract = Contract.getInstance(resp_contract.data);

                if (resp_salary_contract.success) {
                    preContract.salary_aids = setAidsList(resp_salary_contract.data.bonus_list, resp_aids_list);
                } else
                    preContract.salary_aids = [];

                let newContract = preContract.getContractToEditFormData();
                newContract.arl_rate_name = setValueOfList(newContract.arl_rate, arl_levels);
                newContract.position_name = setValueOfList(newContract.position, positions);
                newContract.saves_for_retirement_name = setValueOfList(newContract.saves_for_retirement, retirement_subscription_type);
                newContract.health_contribution_type_name = setValueOfList(newContract.health_contribution_type, resp_health_suscriptions);
                newContract.work_location_name = setValueOfList(newContract.work_location, resp_employee_distance);
                setContract(newContract);
                setSalary(preContract);
            } else
                handleNotify('danger', 'No se ha podido obtener un contrato');

            setIsLoading(false);
        }
        fetchData();

    }, []);

    return(
        <RegisterContainer 
            title='Datos contrato' 
            info={{title:'Recuerda', subtitle: 'La dotación de tus empleados deberás hacerla cada 6 meses de acuerdo a la ley.'}} 
            showNotify={showNotify}
        >
            <Container >
                <Row className='d-flex justify-content-center y-4' >
                    <Col className='col-12 col-lg-9' >
                        <h4 className='text-center color-symplifica my-5' >Los empleadores de Symplifica con una configuración de contrato como la tuya usualmente tienen las siguientes condiciones de contrato:</h4>
                    </Col>
                </Row>
                <TabHorizontal>
                    {
                        isEditing 
                            ? (
                                <ContractDetailsForm
                                    id='Detalles'
                                    contract={contract}
                                    choices={choices}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    handleNotify={handleNotify}
                                />
                            )
                            : (
                                <ContractDetailTab 
                                    id='Detalles'
                                    action={changeIsEditing}  
                                    contract={contract}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                />
                            )
                    }
                    <ContractSalaryTab
                        id='Salario'
                        contract={salary}
                        history={history}
                    />
                </TabHorizontal>
            </Container>
        </RegisterContainer>
    )
}