import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { InputDate, InputText } from '../../../../../shared/components';
import { AppContainer } from 'shared/containers';
import { APP_HELP_CENTER, HELP_CENTER_REPORTS_LOANS_KNOW_MORE } from 'shared/constants/app';
import { dolarize } from 'shared/utils/utils';
const InfoScreen = ({ sendToMachine, selected }: { selected: string, sendToMachine: (arg0: {}) => void }) => {
  let texts = {
    loan: {
      list: [
        "Es decisión tuya otorgarlos ya que la relación laboral puede cambiar en cualquier momento.",
        "La cuota mensual no debe ser mayor al 50% del salario.",
        "Recomendamos que no sea mayor a 3 salarios teniendo en cuenta que no puede ser cubierto por la liquidación."
      ],
      remember: "Ten en cuenta que las cuotas se descuentan cada vez que le pagues a tu empleado."
    },
    advance: {
      list: [
        "El anticipo corresponde a pagar por adelantado una fracción del sueldo a entregar a fin de mes o quincena.",
        "No podrás anticipar más del 50% del salario."
      ],
      remember: "Ten en cuenta que el anticipo se descontará en la próxima nómina mensual o quincenal."
    }
  }
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
          {texts[selected].list.map(el =>
            <li key={el} style={{ fontSize: 12 }}>
              {el}
            </li>
          )}
        </ul>
        <a
          href={HELP_CENTER_REPORTS_LOANS_KNOW_MORE}
          style={{
            width: "100%", textAlign: "center",
            display: "flex", alignContent: "center",
            justifyContent: "center", marginTop: "1em",
            marginBottom: "1em"
          }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
            ¿Quieres saber más?
          </p>
        </a>
        <div style={{ width: "100%", backgroundColor: "#F5F5F5", borderRadius: "10px", paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          <h6 style={{ color: "#00CCCB", fontSize: 11 }}>
            Recuerda
          </h6>
          <p style={{ fontSize: 9 }}>
            {texts[selected].remember}
          </p>
        </div>
        <Button
          style={{ marginTop: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const LoansForm = ({ sendToMachine, selected, employees, stateMachine }:
  {
    employees: [{
      id: string,
      payment_freq: number
    }],
    stateMachine: { context: { employee: string } },
    selected: string,
    sendToMachine: (arg0: {}) => void
  }) => {
  const [capital_value, setBonusAmount] = useState<number>(0)
  const [amountError, setAmountError] = useState(false)
  const [number_fees, setPayments] = useState<number>(0)
  const [feesError, setFeeError] = useState(false)
  let checkAmount = () => capital_value !== 0
  let checkFees = () => number_fees !== 0
  let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
  return (
    <div style={{ width: "100%" }}>
      <p style={{ textAlign: "center", width: "100%" }}>
        Ingresa el valor del {selected === "loan" ? "préstamo" : "anticipo"}:
      </p>
      <InputText
        key="valor"
        name="valor"
        min={0}
        value={`${capital_value ? (capital_value) : "Valor"}`}
        placeholder="Valor"
        error={amountError ? "No es un valor válido" : false}
        type="number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setAmountError(false)
          setBonusAmount(parseInt(e.target.value))
        }
        } />
      {selected === "loan"
        ? <><InputText
          name="payments"
          value={`${number_fees}`}
          error={feesError ? "No es un valor válido" : false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFeeError(false)
            setPayments(parseInt(e.target.value))
          }}
          type="number"
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            width: "80%",
            alignItems: "center",
            margin: "auto",
            marginBottom: 8
          }}
          label="Número de cuotas:"
        />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: 8
            }}
          >
            <p>Valor de cuotas</p>
            <p style={{
              marginInline: 8,
              backgroundColor: "#F5F5F5",
              borderRadius: 20,
              width: "40%",
              textAlign: "right",
              paddingBlock: 4,
              textAlignLast: "center"
            }}>
              {`${isNaN(capital_value / number_fees) ? dolarize(0) : dolarize(Math.round(capital_value / number_fees))}`}
            </p>
            <p>{employee.payment_freq === 1 ? "mensuales" : "quincenales"}</p>
          </div>
        </>
        : null}
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => checkAmount()
          ? checkFees()
            ? sendToMachine({ type: "COMPLETE", isLoan: selected === "loan", data: { capital_value, number_fees } })
            : setFeeError(true)
          : setAmountError(true)
        }
      >
        Guardar
      </Button>
    </div>
  )
}

const TabButtons = ({ setPermissionType, permissionType }:
  { permissionType: string, setPermissionType: (arg0: string) => void }) => {
  return (
    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: 12
    }}>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${permissionType === "loan" ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${permissionType === "loan" ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setPermissionType("loan")}
      >
        Préstamo
      </button>
      <button
        style={{
          backgroundColor: "white",
          border: "none",
          width: "100%",
          borderBottom: `${permissionType === "advance" ? "2px solid #00CCCB" : "1px solid #565656"}`,
          color: `${permissionType === "advance" ? " #00CCCB" : "#565656"}`
        }}
        onClick={() => setPermissionType("advance")}
      >
        Anticipo
      </button>
    </div>
  )
}

const Loans = ({ stateMachine, sendToMachine, employees }:
  {
    employees: [{
      id: string,
      payment_freq: number
    }],
    stateMachine: {
      matches: (arg0: {}) => boolean,
      context: {
        employee: string
      }
    },
    sendToMachine: (arg0: {}) => void
  }
) => {
  let [loanType, setLoanType] = useState("loan")
  return (
    <>
      <AppContainer title="Préstamos" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        <TabButtons permissionType={loanType} setPermissionType={setLoanType} />
        {stateMachine.matches({ selectReportType: { loan: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} selected={loanType} />
          : <LoansForm sendToMachine={sendToMachine} selected={loanType} stateMachine={stateMachine} employees={employees} />}
      </AppContainer>
    </>
  )
}
export default Loans