import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ token, component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (token)
                    return (<Component />);
                else
                    return (
                        <Redirect
                            to={{
                                pathname: "/auth",
                                state: { from: location }
                            }}
                        />
                    );
            } }
        />
    );
}

const mapStateToProps = ({ state }) => {
    return {
        token: state.token
    }
}


export default connect(mapStateToProps, null)(PrivateRoute);