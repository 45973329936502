import React, { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap'

const ReportItem = ({ id, sendToMachine, type, employeeName, leftInfo = null, rightInfo, isDeletable = false,
  record_type, incidence_type, number_fees, capital_value, amount, end_date, start_date, extra_hours, novelty, units }:
  {
    id?: string,
    sendToMachine?: (arg0: {}) => void,
    type: string,
    employeeName: string,
    record_type?: string,
    incidence_type?: string,
    leftInfo?: { top: string | null | undefined, bottom: string | null | undefined } | null,
    rightInfo?: { top: string | null | undefined, bottom: string | null | undefined } | null,
    isDeletable?: boolean,
    number_fees?: number,
    capital_value?: number,
    amount?: number,
    extra_hours?: number,
    end_date?: string,
    start_date?: string,
    novelty?: string,
    units?: string
  }) => {
  const [typeTitle, setTypeTitle] = useState("")
  const [rightText, setRightText] = useState<{
    top: string | undefined | null,
    bottom: string | undefined | null
  }>
    ({ top: "", bottom: "" })
  const [leftText, setLeftText] = useState<{
    top: string | undefined | null,
    bottom: string | undefined | null
  }>
    ({ top: "", bottom: "" })
  useEffect(() => {

    const sortTexts = () => {
      let typeText = ""
      let leftText:
        { top: undefined | string | null, bottom: undefined | string | null } =
      {
        top: "Fecha inicio",
        bottom: start_date
      }
      let rightText:
        { top: undefined | string | null, bottom: undefined | string | null } =
      {
        top: "Fecha fin",
        bottom: end_date
      }
      switch (type) {
        case "loans":
          typeText = "Préstamo"
          leftText = { top: "Cuotas", bottom: number_fees?.toString() }
          rightText = { top: "Valor cuota", bottom: capital_value?.toString() }
          break
        default:
          if (incidence_type)
            switch (incidence_type) {
              case "1": typeText = "Vacaciones en tiempo"
                break
              case "2": typeText = "Incapacidad General"
                break
              case "3": typeText = "Incapacidad Laboral"
                break
              case "4": typeText = "Permiso Remunerado"
                break
              case "5": typeText = "Permiso No Remunerado"
                break
              case "6": typeText = "Licencia de maternidad"
                break
              case "7": typeText = "Licencia de paternidad"
                break
              case "8": typeText = "Licencia por calamidad"
                break
              case "9": typeText = "Licencia por luto"
                break
              case "10": typeText = "Vacaciones en dinero"
                leftText = { top: "Dias", bottom: amount?.toString() }
                rightText = { top: "Valor total", bottom: capital_value?.toString() }
                break
              case "11":
                typeText = "Bonificaciones"
                leftText = { top: undefined, bottom: undefined }
                rightText = { top: "Valor total", bottom: amount?.toString() }
                break
              case "12":
                typeText = "Anticipo"
                leftText = { top: undefined, bottom: undefined }
                rightText = { top: "Valor total", bottom: amount?.toString() }
                break
              case "13":
                typeText = "Hora extra"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "14":
                typeText = "Hora extra diurna"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "15":
                typeText = "Hora extra nocturna"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "16":
                typeText = "Hora extra diurna dominical"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "17":
                typeText = "Hora extra nocturna dominical"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "18": typeText = "Hora extra nocturna festiva"
                leftText = { top: "Cantidad", bottom: extra_hours?.toString() }
                rightText = { top: "Valor", bottom: amount?.toString() }
                break
              case "19": typeText = "Días Trabajados"
                leftText = { top: undefined, bottom: undefined }
                rightText = { top: "Dias", bottom: units?.toString() }
                break
              case "20": typeText = "Ausencia Remunerada"
                break
              case "21": typeText = "Ausencia No Remunerada"
                break

              default:
                break;
            } else {
            typeText = "Préstamo"
            leftText = { top: "Cuotas", bottom: number_fees?.toString() }
            rightText = { top: "Valor cuota", bottom: capital_value?.toString() }
            break
          }
          break
      }
      setTypeTitle(typeText)
      setRightText(rightText)
      setLeftText(leftText)
      if (novelty && (rightInfo || leftInfo)) {
        if (rightInfo) setRightText(rightInfo)
        if (leftInfo) setLeftText(leftInfo)
        setTypeTitle(novelty)
      }
    }
    sortTexts()
  }, [end_date, start_date, amount, novelty, extra_hours,
    capital_value, number_fees, leftInfo, rightInfo, record_type,
    incidence_type, type])

  return (
    <div className="border-b-gray"
      style={{
        width: "100%",
        display: "grid",
        gridTemplate: "1fr / 40% 1fr 1fr 26px",
        alignItems: "center",
        paddingTop: "12px",
        paddingBottom: "12px",
        gridGap: 7,
      }}>
      <div>
        <p className="blue-symplifica"
          style={{ width: "100%", margin: "0px", fontSize: 12, fontWeight: 300 }}>
          {typeTitle}
        </p>
        <p className=""
          style={{ width: "100%", margin: "0px", fontSize: 10, fontWeight: 500 }}
        >
          {employeeName}
        </p>
      </div>
      {leftText && leftText.top && leftText.bottom ?
        <div
          className="bg-grey-symplifica border-r-10"
          style={{
            width: "100%", height: "2.5em", display: "flex",
            flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}>
          <p
            style={{
              width: "100%", textAlign: "center", margin: "0px",
              fontSize: 10, fontWeight: 300
            }} >
            {leftText.top}
          </p>
          <p
            style={{
              width: "100%", textAlign: "center", margin: "0px",
              fontSize: 10, fontWeight: 300
            }} >
            {leftText.bottom}
          </p>
        </div>
        : <div />
      }
      <div
        className="bg-grey-symplifica border-r-10"
        style={{
          height: "2.5em", display: "flex", flexDirection: "column",
          justifyContent: "center", alignItems: "center"
        }}>
        <p
          style={{
            width: "100%", textAlign: "center", margin: "0px",
            fontSize: 10, fontWeight: 300
          }} >
          {rightText?.top ? rightText.top : ""}
        </p>
        <p
          style={{
            width: "100%", textAlign: "center", margin: "0px",
            fontSize: 10, fontWeight: 300
          }} >
          {rightText?.bottom ? rightText.bottom : ""}
        </p>
      </div>
      {isDeletable
        ? <button
          id="delete"
          onClick={(e) => sendToMachine
            && sendToMachine({ type: "DELETE", data: { id, type: type ? type : "incidences" } })}
          style={{
            border: "none", backgroundColor: "white",
            width: 26, height: 26,
            padding: 0
          }}>
          <img src="/icons/reports/trash.svg" style={{ width: 26, height: 26 }} />
        </button>
        : <div />
      }
    </div>
  )
}

export default ReportItem