import { EmployeeClient } from './EmployeeClient';

const employee_client = new EmployeeClient();

export class Employee {

    id: string = '';
    first_name: string = '';
    middle_name: string = '';
    last_name: string = '';
    mothers_name: string = '';
    creation_date: string = '';
    is_ready: boolean = false;
    ss_is_ready: boolean = false;
    docs_is_ready: boolean = false;
    client_id: string = '';
    client: EmployeeClient = employee_client;       
    front_doc_file: string = '';
    back_doc_file: string = '';
    pension_certificate: string = '';
    sisben_certificate: string = ''; 
    employee_terms_aceptance_file: string = ''; 
    last_ss_payment_support: string = '';
    is_active: boolean = false;
}
