import React from 'react';

// DATEPICKER
import "react-datepicker/dist/react-datepicker.css";

// BOOSTRAP
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

// COMPONENTS 
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import CalIcon from 'assets/ico/cont-icon-cal.png';
import { TooltipBasic, TypeTooltipBasic } from '../Tooltips';

registerLocale("es", es);

interface InputDateProps {
    tooltip?: TypeTooltipBasic;
    label?: string;
    placeholder?: string;
    className?: string;
    showYearDropdown?: boolean;
    showMonthDropdown?: boolean;
    minDate?: Date;
    maxDate?: Date;
    value: Date | string | null;
    disabled?: boolean;
    name: string;
    error?: string;
    onChange: (name: string, date: Date | null, event: React.SyntheticEvent<any, Event> | undefined ) => void;
    showMonthYearPicker?: boolean;
}

export const InputDate: React.FC<InputDateProps> = ({ 
    tooltip, 
    label, 
    placeholder, 
    className = '', 
    showYearDropdown = false, 
    showMonthDropdown = false, 
    minDate, 
    maxDate, 
    value, 
    disabled, 
    name, 
    error, 
    onChange, 
    showMonthYearPicker 
}) => {

    const formatText = showMonthYearPicker ? "yyyy-MM" : "yyyy-MM-dd";
    const selected = value ? new Date(value) : null;

    return (
        <FormGroup>

            { label && (<p className='text-bold my-1' >{label}</p>) }

            <InputGroup className={`${tooltip ? 'date-tooltip' : ''} input-text`} >
                <DatePicker
                    className={` ${tooltip ? 'date-tooltip' : ''} form-control ${className} datepicker ${error && error.length > 0 ? 'input-error' : ''}`}
                    placeholderText={placeholder}
                    name={name}
                    id={name}
                    selected={selected}
                    minDate={minDate}
                    maxDate={maxDate}
                    locale="es"
                    disabled={disabled}
                    onChange={(date, e) => onChange(name, date, e)}
                    peekNextMonth
                    showYearDropdown={showYearDropdown}
                    showMonthDropdown={showMonthDropdown}
                    showMonthYearPicker={showMonthYearPicker}
                    dateFormat={formatText}
                />
                <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend"><label htmlFor={name} ><img style={{ height: 20 }} src={CalIcon} alt='icon' /></label>
                        
                        { tooltip && (<TooltipBasic {...tooltip} />) }

                    </InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
            {
                error && error.length > 0 && (
                    <Form.Text className="text-muted input-text-error">
                        {error}
                    </Form.Text>
                )
            }
        </FormGroup>
    )
}