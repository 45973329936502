import React from 'react'
import { Button, Image, Accordion } from 'react-bootstrap'
import EmptyState from './EmptyState'
import ReportItem from './ReportItem'

const PriorReports = ({ employees, reports }
  : {
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    reports: [{
      id: string,
      type: string,
      contract: string,
      end_date: string,
      start_date: string,
      record_type?: string,
      incidence_type?: string,
    }]
  }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
        }}>
        <Accordion.Toggle as={Button}
          variant="link"
          eventKey="1"
          style={{
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "none",
            padding: 0
          }}>
          <p className="blue-symplifica text-bold border-b-symplifica" 
          style={{ margin: 0, textAlign: "left", width:"90%" }}>
             Novedades Anteriores
          </p>
          <Image src="/images/reports/cont-icon-arrow.png" fluid />

        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey="1">
        {reports?.length
          ?
          <>
            {reports.map((report, index) => {
              let employee = employees.filter(employee => employee.id === report.contract)[0]
              return <ReportItem
                key={index}
                {...report}
                employeeName={`${employee?.employee?.first_name ? employee.employee.first_name : ""} ${employee?.employee?.last_name ? employee.employee.last_name : ""}`}
                leftInfo={{ top: "Fecha inicio", bottom: report.start_date }}
                rightInfo={{ top: "Fecha fin", bottom: report.end_date }}
              />
            })}
          </>
          : <EmptyState title="anteriores" body={false} />
        }
      </Accordion.Collapse>
    </div>
  )
}
export default PriorReports