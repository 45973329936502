import React from 'react';
import PropTypes from 'prop-types';

// Bootstrap
import { Col } from 'react-bootstrap';

// Utils
import { dolarize } from 'shared/utils/utils';

const AidSelected = ({ text, value, onClick, editable }) => {
    return (
        <Col className='col-12 col-lg-6 my-3' >
            <div className='w-100 bg-white shadow d-block rounded-pill p-2 d-flex align-items-cente justify-content-between' >
                <p className='m-0' >{text}</p>
                <p className='m-0' >{dolarize(value)}</p>
                {
                    editable && (
                            <span className='bg-symplifica rounded-circle px-1' >
                                <span
                                    className='text-white m-0 pointer'
                                    onClick={onClick}
                                >
                                    X
                                </span>
                            </span>
                        )
                }
            </div>
        </Col>
    );
};

AidSelected.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    editable: PropTypes.bool,
};

export {AidSelected};