import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from '../../../../shared/containers';

// Components
import { Notify } from '../../../../shared/components';

// FORM
import {EmployeeCreateForm } from './forms';

// Actions
import { setPreContractAction, contractToEmployerAction } from '../../../../redux/actions';

// API
import RegisterRepository from '../../repository/register.repository';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

const EmployeeCreate = ({ employer, client_id, history, preContract, setPreContract, setContractToEmployer }) => {

    const { showNotify, handleNotify } = useNotifyState();

    const contractDefault = {
        works_on_monday: false,
        works_on_tuesday: false,
        works_on_wednesday: false,
        works_on_thursday: false,
        works_on_friday: false,
        works_on_saturday: false,
        works_on_sunday: false,
        salary: '',
        start_time: '',
        end_time: '',
        salary_type: '',
        is_living_in: false,
        is_employee_subscribed: false,
        default_workplace_id: '',
        client_id: '',
        employer_id: '',
        contract_id: '',
        vacation_days_given: 0
    };

    let contractSel = {
        ...contractDefault,
        ...preContract,
        client_id,
        employer_id: employer.id || '',
        contract_id: preContract.contract_id || '',
        start_time: new Date('1970-01-01T08:00:00'),
        end_time: new Date('1970-01-01T18:00:00'),
    };  

    useEffect(() => {
        const fetchData = async () => {
            if (contractSel.employer_id) {
                const workplace = await RegisterRepository.getDefaultWorkspace(contractSel.employer_id);
                if (workplace.success)
                    contractSel.default_workplace_id = workplace.data.id;

                return;
            }
        }
        fetchData();
        return;
    }, [contractSel.default_workplace_id, contractSel.employer_id]);

    return(
        <RegisterContainer 
            title='Crea tu empleado' 
            info={{title:'¿Por qué estos datos?', subtitle: 'Crearemos un contrato usado por el 85% de las personas y así podrás ver cuánto puede costar gestionar a tu empleado. Podrás gestionar a tu empleado más adelante.'}} 
            showNotify={showNotify}
        >
            <Container >
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Descubre cuánto costará mensualmente tu empleado</h4>
                    </Col>
                </Row>
                <EmployeeCreateForm
                    contract={contractSel}
                    history={history}
                    setPreContract={setPreContract}
                    setContractToEmployer={setContractToEmployer}
                    handleNotify={handleNotify}
                />
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({ state }) => {

    return {
        token: state.token,
        employer: state.employer,
        client_id: state.client_id,
        preContract: state.preContract || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPreContract: (preContrato) => dispatch(setPreContractAction(preContrato)),
        setContractToEmployer: (contract) => dispatch(contractToEmployerAction(contract)),
    }
}

const EmployeeCreatePage = connect(mapStateToProps, mapDispatchToProps)(EmployeeCreate);

export {EmployeeCreatePage};