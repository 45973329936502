import React from 'react'
import { Button, Image } from 'react-bootstrap'
import ReportItem from './ReportItem'
import { APP_DOCUMENTS_VOUCHERS } from 'shared/constants/urls'

const AddedModal = ({ employees, stateMachine, sendToMachine }:
  {
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    stateMachine: {
      context: {
        user: {
          data: {
            data: { start_date: string, end_date: string, type: string };
          }
        },
        employee: {}
      }
    },
    sendToMachine: (arg0: {}) => void
  }) => {
  let reportCreated = stateMachine.context.user.data.data
  let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
  return (
    <div style={{ paddingTop: 130 }}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
        <img src='/icons/reports/saved.svg' width="80%" alt="" />
        <h5 className="color-symplifica" style={{ textAlign: "center" }}>
          ¡Ya hemos guardado la novedad!
      </h5>
        <ReportItem
          {...reportCreated}
          employeeName={`${employee.employee.first_name} ${employee.employee.last_name}`}
          leftInfo={{ top: "Fecha inicio", bottom: reportCreated.start_date }}
          rightInfo={{ top: "Fecha fin", bottom: reportCreated.end_date }}
        />
        <a
          href={APP_DOCUMENTS_VOUCHERS}
          style={{
            color: 'white',
            backgroundColor: '#FD5C5C',
            borderRadius: 50,
            width: '80%',
            marginBottom: 15,
            marginTop: 15,
            padding: 10,
            textAlign: "center",

          }}>
          <p style={{
            marginBottom: 0,
            color: "white",
            textDecoration: "none",
            fontSize: "1em"
          }}>
            Ver Comprobantes de Nómina
            </p>
        </a>
        <Button
          onClick={() => sendToMachine({ type: "VIEWREPORTS" })}
          className="bold-grey-symplifica"
          style={{
            backgroundColor: '#f5f5f5',
            borderRadius: 50,
            width: '80%'
          }}>
          Ver Novedades
        </Button>
      </div>
    </div>
  )
}

export default AddedModal