import { 
    LOGIN_WITH_EMAIL_PASS,
    FIRST_VIEW_PAGE,
    LOGIN_OUT,
    SET_LOGIN,
    SET_EMPLOYER,
    SET_EMAIL,
    SET_PRE_CONTRACT,
    SET_CONTRACT_TO_EMPLOYER,
    FORGOT_PASSWORD,
    SET_INVOICE_TO_STATE,
} from "../../shared/constants/redux";

export const loginAction = (token, refresh_token) => {
    return {
        type: LOGIN_WITH_EMAIL_PASS,
        payload: {
            token,
            refresh_token,
        }
    };
}

export const emailAction = (email) => {
    return {
        type: SET_EMAIL,
        payload: email
    };
}

export const logOutAction = () => {
    return {
        type: LOGIN_OUT,
    };
}

export const signInAction = (token, refresh_token, email, employer, contracts, choices ) => {
    return {
        type: SET_LOGIN,
        payload: {
            token,
            refresh_token,
            email,
            employer,
            contracts,
            choices,
        }
    };
}

export const contractToEmployerAction = (contract) => {
    return {
        type: SET_CONTRACT_TO_EMPLOYER,
        payload: contract
    };
}

export const setPreContractAction = (contract) => {
    return {
        type: SET_PRE_CONTRACT,
        payload: contract
    };
}

export const firstViewAction = () => {
    return {
        type: FIRST_VIEW_PAGE
    };
}

export const setEmployerAction = (employer) => {
    return {
        type: SET_EMPLOYER,
        data: employer
    }
}

export const setForgotPasswordAction = (email) => {
    return {
        type: FORGOT_PASSWORD,
        payload: email
    }
}

export const setInvoceAction = (invoice) => {
    return {
        type: SET_INVOICE_TO_STATE,
        payload: invoice
    }
}