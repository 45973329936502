import React from 'react';

// Bootstrap
import { Container } from 'react-bootstrap';

// Components
import { Notify } from 'shared/components';
import { FooterNav, HeaderNav } from '../components';

// Interface
import { TypeNotify } from 'shared/interfaces';

interface AppContainerProps {
    children: React.ReactNode;
    title?: string;
    onPressBack?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    showFooter?: boolean;
    showNotify?: TypeNotify;

}

export const AppContainer: React.FC<AppContainerProps> = ({ 
        children, 
        title, 
        onPressBack, 
        showFooter = true, 
        showNotify = { open: false, message: '', type:'success' } 
    }) => {
        
        return (
            <Container className="app-container">

                <HeaderNav title={title} onPressBack={onPressBack} />

                <Notify {...showNotify} />

                <div>
                    {children}
                </div>

                {
                    showFooter && <FooterNav />
                }
            </Container>
        );
}