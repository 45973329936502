import React from 'react';

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap';

// ICONS
import CheckIcon from '../../../../../assets/ico/check-green.png'
import UnCheckIcon from '../../../../../assets/ico/cont-icon-x.png'
import WaitIcon from '../../../../../assets/ico/cont-icon-arrow-clock.png'

const CardPayment = ({type, name, amount, status}) => {

    const handleStatus = () => {
        switch(status){
            case 'done':
                return CheckIcon
                
            case 'undone':
                return UnCheckIcon
            case 'wait':
                return WaitIcon
                
            default:
                return UnCheckIcon

        }
    }

    return(
        <Row className='my-3' >
            <Col>
                <p  className='text-12 my-0' >{type}</p>
                <p  className='' >{name}</p>
            </Col>
            <Col  className='d-flex justify-content-end' >
                <p>{amount} <img height={20} src={handleStatus()} alt='icon' /> </p>
            </Col>
        </Row>
    )
}

export { CardPayment }