import React, { useState } from 'react';


// BOOTSTRAP COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';

// COMPONENTS
import { SubmitButton, CompleteModal } from 'shared/components';

// ICONS
import SearchIcon from 'assets/ico/search.png';
import CheckGray from 'assets/ico/check-gray.png';
import CheckGreen from 'assets/ico/check.png';

interface LiquidationCheckProps {
    onNextClick: (event: any) => any;
    detailClick: (event: any) => any;
}

export const LiquidationCheck: React.FC<LiquidationCheckProps> = ({onNextClick, detailClick}) => {

    const [data, setData] = useState({agree:false})
    const [modal, setModal] = useState(false)

    // STATE HANDLERS
    const handleCheck = () => {
        setData({...data, agree: !data.agree})
    }

    const handleSumbit = () =>{
        onNextClick('h')
    }

    return(
        <Container>
            <CompleteModal open={modal} closeAction={()=>setModal(!modal)} >
                <Container className='d-flex justify-content-center flex-column' >
                    <Row>
                        <Col>
                            <h5 className='text-center' >¿Éstas seguro que deseas liquidar el contrato con pepito pimpollo?</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul >
                                <li><p>Lo Desafiliaremos de las entidades de seguridad social.</p></li>
                                <li><p>Cerraremos su contrato en nuestra plataforma</p></li>
                                <li><p>Dejarás de verlo como activo.</p></li>
                                <li><p>Recuerda que deberás realizar el pago de la última planilla (retiro) para hacer esto efectivo realizaremos el débito por ti en la fecha <strong>00 / 00 / 0000.</strong></p></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col  >
                            <label className='d-flex align-items-center mb-4' htmlFor='agree' >
                                <img height={28} width={28} src={data?.agree ? CheckGreen : CheckGray} alt='check' />
                                <p className='text-12 my-0 mx-2' >Estoy de acuerdo en que debiten de mi cuenta el valor de la planilla de retiro por <strong>$000.000</strong> y desero <strong>finalizar el contrato</strong> con este empleado</p>
                                <input onChange={handleCheck} id='agree' name='agree' type='checkbox' className='invisible' />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitButton
                                onClick={handleSumbit}
                                block
                                text='Liquidar empleado'
                            />
                        </Col>
                    </Row>
                </Container>
            </CompleteModal>
            <Row>
                <Col className='text-center' >
                    <p  style={{fontSize:18}} >Revisa os cálculos de la preliquidación  y continúa para aplicarla</p>
                    <p><strong>Total a pagar en liquidación</strong></p>
                    <p>$0.000.000</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubmitButton 
                        block
                        variant='dark'
                        onClick={detailClick}
                    >
                        <div className='d-flex align-items-center' >
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{height:28, width: 28, background: 'white', borderRadius:'50%'}} >
                                <img src={SearchIcon} height={18} alt='search' />
                            </div>
                            Ver detalles de la liquidación
                        </div>
                    </SubmitButton>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <SubmitButton
                        onClick={()=>setModal(!modal)}
                        block
                        text='Liquidar empleado'
                    />
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <SubmitButton
                        onClick={()=>{}}
                        block
                        className='bg-grey-symplifica'
                        variant='secondary'
                        text='Cancelar'
                    />
                </Col>
            </Row>
        </Container>
    )
}