import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CompleteModal, SubmitButton, InputSelect, InputText } from '../../../../../shared/components';

import { Container, Row, Col, Form } from 'react-bootstrap';

// API
import GeneralRepository from '../../../repository/general.repository';
import RegisterRepository from '../../../repository/register.repository';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

const ModalARL = ({ open, showModal, handleNotify, contract, callback }) => {

    const [listARLs, setListARLs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (contract && contract.id) {
                setIsLoading(true);
                const resp = await GeneralRepository.getARL(contract.workplace.state);
                setIsLoading(false);
                setListARLs(resp);
            }
        }

        fetchData();

    }, [contract]);

    // =======================================================
    /// Confirm the form is complete
    // =======================================================
    const validate = () => {
        let count = 0;

        if (["4", "8"].indexOf(inputs.ss_entity) >= 0 && !inputs.entity_user) {
            handleError({ entity_user: "El usuario es necesario"});
            count++;
        }

        if (["4", "8"].indexOf(inputs.ss_entity) >= 0 && !inputs.entity_password) {
            handleError({ entity_password: "La contraseña es obligatoria" });
            count++;
        }

        return count;
    }

    // =======================================================
    /// post data to the server, add or update EPS entity to employee
    // =======================================================
    const handleSubmitForm = async () => {

        if ( validate() > 0 ) return;

        const formData = {
            ...inputs,
            ss_entity: inputs.ss_entity ? inputs.ss_entity : 0,
            contract_id: contract && contract.hasOwnProperty("id") ? contract.id : '',
            employer_id: contract && contract.hasOwnProperty("employer") ? contract.employer.id : '',
            employee_id: contract && contract.hasOwnProperty("employee") ? contract.employee.id : '',
        }

        setIsLoading(true);
        const resp = await RegisterRepository.setEmployerArlSuscription(formData);
        setIsLoading(false);

        if (resp.success) {
            handleNotify('success', resp.message);
            showModal();
            callback();
        } else
            handleNotify('danger', resp.message);

    }

    // =======================================================
    /// Hook: Manejo de los datos del formulario
    // =======================================================
    const {
        handleSubmit, handleInputChange, inputs, errors, handleError
    } = useCustomForm({ ss_entity: '', entity_user: '', entity_password: '' }, handleSubmitForm);


    return (
        <CompleteModal open={open} closeAction={showModal}>
            <Container>
                <div className='max-w-600 mx-auto'>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <Col className='text-center my-5'>
                                <h4 className='color-symplifica'>Riesgos Laborales ARL</h4>
                                <p className='text-bold'>¿A qué ARL estás afiliado como empleador?</p>
                            </Col>
                        </Row>
                        <Row className="d-lg-flex justify-content-center">
                            <Col>
                                <InputSelect
                                    default_option="Escoge la entidad"
                                    disabled={isLoading}
                                    name="ss_entity"
                                    value={inputs.ss_entity}
                                    error={errors.arlError}
                                    onChange={handleInputChange}
                                    options={listARLs}
                                />
                            </Col>
                        </Row>
                        <Row className="d-lg-flex justify-content-center">
                            <Col>
                                <InputText
                                    placeholder='Nombre de usuario'
                                    disabled={isLoading}
                                    className='my-3'
                                    name='entity_user'
                                    error={errors.entity_user}
                                    value={inputs.entity_user}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row className="d-lg-flex justify-content-center">
                            <Col>
                                <InputText
                                    placeholder="Contraseña"
                                    disabled={isLoading}
                                    className='mt-3'
                                    type="password"
                                    name='entity_password'
                                    value={inputs.entity_password}
                                    error={errors.entity_password}
                                    onChange={handleInputChange}
                                />
                                <a href="#" style={{display: 'flex',justifyContent: 'center'}}>
                                    recuperar contraseña positiva
                                </a>
                            </Col>
                        </Row>
                        <br />
                        <Row className="d-lg-flex justify-content-center">
                            <Col>
                                <p className='text-center'>
                                    *Si no está afiliado a ninguno como empleador, te afiliaremos a ARL SURA. Ten en cuenta que debes estár completamente seguro
                                    de que no presentan afiliaciones como empleador a otra ARL, ya que esto te podria ocasionar inconvenientes en la administración y pagos de Seguridad Social.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SubmitButton
                                    block
                                    variant="primary"
                                    text="Guardar"
                                    disabled={isLoading}
                                    spinner={isLoading}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </CompleteModal>
    );
};

ModalARL.propTypes = {
    pen: PropTypes.bool,
};

export {ModalARL};