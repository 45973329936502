import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { doRedirect } from '../utils/redirectTo';

const ImpersonateRoute = ({ location, setLogIn, ...rest }) => {

    if (location.search) {

        const urlSearch = location.search.replace('?', '');
        const paresKeyValues = urlSearch.split('&');
        let newState = {};
        paresKeyValues.forEach(key => {
            let aux = key.split('=');
            newState[aux[0]] = aux[1];
        });
        
        doRedirect(newState);

    }

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return (
                    <Redirect
                        to={{
                            pathname: '/auth',
                            state: { from: location }
                        }}
                    />
                )
            }}
        />
    );
};

export default ImpersonateRoute;