import _ from 'lodash';
import moment from 'moment';

import store from 'redux/storages/global';

// Enums
import { TypeChoice } from 'shared/utils/emuns';

// =======================================================
/// Decodifica un JWT para obtener datos del payload
// =======================================================
export const parseJwt = (token) => {
    if (!token)
        return {};

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// =======================================================
/// retorna una fecha en un string YYYY-MM-DD
// =======================================================
export const dateToString = (setdate, time=false) => {
    if (!setdate)
        return '';
 
    let date = new Date(setdate);

    const month = String((date.getMonth() + 1)).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}${time ? "T00:00:00" : ''}`;
}

// =======================================================
/// return a string of time width format HH:mm
// =======================================================
export const timeToString = (time) => {
    let newTime = new Date(time);
    let aux = 'am';
    let hours = '';
    if (!time)
        return '-';

    if (newTime.getHours() > 12) {
        hours = String((newTime.getHours() - 12)).padStart(2, '0');
        aux = 'pm';
    }else
        hours = String(newTime.getHours()).padStart(2, '0');
    
    const minutes = String(newTime.getMinutes() > 12 ? (newTime.getMinutes() - 12) : newTime.getMinutes() ).padStart(2, '0');
    return `${hours}:${minutes} ${aux}`;
}


// =======================================================
/// Formatea un valor numerico a tipo dolar
// =======================================================
export const dolarize = (num) => {
    const numero = isNaN(num) || !num ? 0 : num;
    let format_value = new Intl.NumberFormat('es-CO', { 
        style: 'currency', 
        currency: 'COP',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(numero);
    const aux_value = format_value.split('.');
    if (aux_value.length > 2) {
        format_value = `${aux_value[0]}'${aux_value[1]}.${aux_value[2]}`;
    }
    return format_value;
};

// =======================================================
/// Formatea los datos retornados del Back para shoppingcart
// =======================================================
export const setFormatPromo = (shoppingcart_items) => {

    let categories: any[] = [];
    shoppingcart_items.forEach((category:any) => {
        if (categories.indexOf(category.price.item.category.name) === -1)
            categories.push(category.price.item.category.name);
    });

    let shopCarts: any[] = categories.map(categ => {
        return {
            group: categ,
            amount: 0,
            open: true,
            items: []
        };
    });

    let aux:any[] = shoppingcart_items.map((key, idx) => {
        let spci: any = {
            id: key.id,
            amount: Number(key.price.price),
            promocode_assigned: key.promocode_assigned,
            name: key.price.name,
            description: key.price.description,
            category: key.price.item.category.name,
        };

        // Agrega valor de descuento si aplica
        if (spci.promocode_assigned && spci.promocode_assigned.hasOwnProperty('promo_code') ) {
            spci.amount_discount = spci.amount * (1 - (Number(key.promocode_assigned.promo_code.discount_value) / 100) )
        }

        return spci;
    });

    shopCarts.forEach((key, idx) => {
        aux.forEach(k => {
            if (k.category === key.group) {
                shopCarts[idx].items.push(k);
                shopCarts[idx].amount += k.hasOwnProperty('amount_discount') ? k.amount_discount : k.amount;
            }
        });

    });

    return shopCarts;

}

// =======================================================
/// Return the list of choices selected by key
// =======================================================
export const listChoice = (name) => {
    const { choices } = store.getState().state;
    return choices[name];
}

// =======================================================
/// Return the list of choices selected by key
// =======================================================
export const getChoicesOf = (name) => {
    const { choices } = store.getState().state;

    if(!choices[name])
        return [];

    return choices[name].map( key => {
        const value = Object.keys(key)[0];
        return {
            value,
            text: key[value]
        };
    } );
}

// =======================================================
/// Return map of choices selected by key
// =======================================================
export const mapChoice = (name) => {
    const { choices } = store.getState().state;
    let choiceMap = {};
    
    return choices[name].reduce((prev, item) => {
        let key = Object.keys(item)[0];
        return {
            ...prev,
            [key]: item[key]
        }
    }, choiceMap);
}

// =======================================================
/// Return value
// =======================================================
export const valueChoice = (name, value) => {

    if(value === null || value === undefined || value === '') return '';

    switch (name) {
        case TypeChoice.PAYMENT_FREQ:
            return value == 1 ? 'Mensual' : 'Quincenal';

        case TypeChoice.FIXED_TERM:
            return value ? 'Fijo' : 'Indefinido';
    
        default:
            const mapData = mapChoice(name);
            return mapData[value];
    }

    

}

// =======================================================
/// Return a string of days working at week
// =======================================================
export const workingDaysText = (contract) => {
    const daysW = [
        {key: 'works_on_monday', value: 'Lun'},
        {key: 'works_on_tuesday', value: 'Mar'},
        {key: 'works_on_wednesday', value: 'Mie'},
        {key: 'works_on_thursday', value: 'Jue'},
        {key: 'works_on_friday', value: 'Vie'},
        {key: 'works_on_saturday', value: 'Sab'},
        {key: 'works_on_sunday', value: 'Dom'}
    ];

    let week_days_workeds = '';

    return daysW.reduce( (prev, item) => {
        let prevText = prev;
        let prevVal = prevText !== ''; // coma previa
        prevText += contract[item.key] ? `${prevVal ? ', ' : ''}${item.value}` : ''; // valor si es true en contrato
        return prevText;
    }, week_days_workeds );

}

// =======================================================
/// Return text of difference between dates
// =======================================================
export const diffMonthDate = (date_init, date_end) => {
    const daysFull = moment(new Date(date_end)).diff(new Date(date_init || ''), 'days');
    const resultado = Math.round(Number(daysFull) / 30);
    return resultado === 1 ? `${resultado} Mes` : `${resultado} Meses`;
}

// =======================================================
/// Get the extension of a image
// =======================================================
export const validFile = (image:string): boolean => {
    if (!image) return false;

    const FILE_EXTENSION = ['txt', 'csv', 'pdf', 'html', 'htm', 'docx', 'doc', 'png', 'tiff', 'jpeg', 'jpg'];
    const array_split = image.split('.');

    if(array_split.length < 2) return false;

    const extension_sel = array_split[(array_split.length - 1)];

    return FILE_EXTENSION.indexOf(extension_sel) >= 0;
}

// ==============================================
/// Confirm if the string is a JSON valid
// ==============================================
export const isJsonString = (str:string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}