import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { AppContainer } from 'shared/containers';
import { InputDate } from 'shared/components';
import { HELP_CENTER_REPORTS_HOLIDAYS_KNOW_MORE, APP_HELP_CENTER } from 'shared/constants/app';
import ReportsRepository from '../repository';

const InfoScreen = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <ul style={{ padding: 0 }}>
          <li style={{ fontSize: 12, color: "#565656" }}>
            Tus empleados deben recibir 15 días de vacaciones por año, pagos en tiempo o en dinero. Proporcional para empleados por días.
          </li>
          <li style={{ fontSize: 12, color: "#565656" }}>
            Puedes reportar vacaciones anticipadas siempre que llegues a un acuerdo con tu empleado.
          </li>
          <li style={{ fontSize: 12, color: "#565656" }}>
            Tu empleado debe pasarte una carta solicitando las vacaciones (en tiempo o en dinero). Súbela en tu carpeta de documentos.
          </li>
        </ul>
        <a
          href={HELP_CENTER_REPORTS_HOLIDAYS_KNOW_MORE}
          style={{
            width: "100%", textAlign: "center",
            display: "flex", alignContent: "center",
            justifyContent: "center",
            marginBottom: "1em"
          }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
            ¿Quieres saber más?
          </p>
        </a>
        <Button
          style={{ marginTop: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const HolidaysForm = ({ stateMachine, sendToMachine, type }:
  {
    stateMachine: { context: { employee: string } },
    type: number,
    sendToMachine: (arg0: {}) => void
  }) => {
  let [init_date, setInitialDate] = useState<null | Date>(null)
  let [end_date, setEndDate] = useState<null | Date>(null)
  const DaysInfo = () => {
    let days = [
      { text: "causados", value: 0 },
      { text: "disfrutados", value: 0 },
      { text: "disponibles", value: 0, isBlue: true }
    ]
    useEffect(() => {
      async function getData() {
        let holidaysData = await ReportsRepository.getEmployeeVacations(stateMachine.context.employee)
        console.log(holidaysData)
      }
      getData()
    }, [])
    return (
      <div
        className="border-b-gray"
        style={{
          display: "flex", justifyContent: "space-between",
          paddingTop: "10px", paddingBottom: "10px",
          marginBottom: 8
        }}>
        {days.map(day =>
          <div style={{ display: "flex", justifyContent: "space-around", width: "33%" }} key={day.text}>
            <div>
              <p style={{ marginBottom: "0px", fontSize: 10 }}>Días</p>
              <p style={{ marginBottom: "0px", fontSize: 10 }}>{day.text}</p>
            </div>
            <div style={{
              fontSize: 12,
              color: `${day.isBlue
                ? "#fff"
                : "#565656"
                }`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: " 2px 2px 8px #d6e6ed",
              width: "2.5em",
              minWidth: "32px",
              height: "32px",
              borderRadius: "1000px",
              backgroundColor: `${day.isBlue
                ? "#00CCCB"
                : "white"
                }`
            }}>
              {day.value}
            </div>
          </div>
        )}
      </div >
    )
  }
  const DateSelector = () => {
    return (
      <div
        className="border-b-gray"
        style={{ marginTop: 8 }}
      >
        <p style={{ marginBottom: 8, fontSize: 10, textAlign: "center" }}>
          ¿En qué fecha quieres que tu empleado tome sus vacaciones?
        </p>
        <InputDate
          value={init_date}
          onChange={(name, date, event) => setInitialDate(date)}
          placeholder="Fecha inicio"
          name="startDate"
          label=""
        />
        <InputDate
          name="endDate"
          value={end_date}
          onChange={(name, date, event) => setEndDate(date)}
          placeholder="Fecha fin"
          label=""
        />
      </div>
    )
  }
  const SelectorInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBlock: 15
        }}>
        <div style={{
          width: "50%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}>
          <p style={{ margin: 0, fontSize: "0.75em" }}>
            Días novedad:
          </p>
          <div
            style={{
              fontSize: 12,
              color: "#565656",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: " 2px 2px 8px #d6e6ed",
              width: "2.5em",
              minWidth: "32px",
              height: "32px",
              borderRadius: "1000px",
              backgroundColor: "white"
            }}>
            {0}
          </div>
        </div>
        <div style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <p style={{ margin: 0, fontSize: "0.75em", textAlign: "center" }}>
            Estas dando {2} de estos días de forma anticipada.
          </p>
          <a href={APP_HELP_CENTER}>
            <p>
              Saber más
            </p>
          </a>
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: "100%" }}>
      <DaysInfo />
      <DateSelector />
      <SelectorInfo />
      <Button
        id="saveButton"
        style={{ width: "100%" }}
        onClick={() => sendToMachine({
          type: "COMPLETE",
          data: {
            type: type,
            init_date: init_date ? init_date.toISOString().split("T")[0] : "",
            end_date: end_date ? end_date.toISOString().split("T")[0] : "",
            save: true
          }
        })}
      >
        Guardar
      </Button>
    </div >
  )
}

const Holidays = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean, context: { employee: string } },
    sendToMachine: (arg0: {}) => void
  }
) => {
  const [type, setType] = useState(1)
  return (
    <>
      <AppContainer title="Vacaciones" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        {stateMachine.matches({ selectReportType: { holidays: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} />
          : <HolidaysForm stateMachine={stateMachine} sendToMachine={sendToMachine} type={type} />}
      </AppContainer>
    </>
  )
}
export default Holidays