import React from 'react';

interface InputCheckProps {
    className?: string;
    name?: string;
    id?: string;
    text?: string;
    value?: string | number | string[];
    type?: string;
    checked?: boolean;
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);
    disabled?: boolean;
}

export const InputCheck: React.FC<InputCheckProps> = ({ 
    className = "", 
    name, 
    id, 
    text, 
    value, 
    type="radio", 
    checked, 
    onChange, 
    disabled
}) => {

    return (
        <label className={`btn btn-block ${disabled ? "disabled" : ''} ${className} ${checked ? 'input-checked' : ''} font-07`}>
            {text}
            <input
                id={id}
                type={type}
                style={{ display: 'none' }}
                name={name}
                onChange={onChange}
                value={value}
                checked={checked}
                disabled={disabled}
            />
        </label>
    );
};