// ==============================================
/// APP
// ==============================================
export const APP_DASHBOARD_HOME = '/app/dashboard/home'; // MENÚ home
export const APP_DASHBOARD_EMPLOYEES = '/app/employees/list-employees'; // MENÚ empleados
export const APP_EMPLOYEES_CREATE = '/app/employees/create'; // crear empleado
export const APP_EMPLOYEES_DETAIL = '/app/employees/detail'; // detalles de empleado
export const APP_EMPLOYEES_CONTRACT = '/app/employees/contract'; // datos de contrato
export const APP_EMPLOYEES_CONTRACT_SALARY = '/app/employees/contract_salary'; // datos de salario por contrato 
export const APP_EMPLOYEES_LIQUIDATIONS = '/app/employees/liquidations'; // liquidacion de empleado
export const APP_DASHBOARD_AFILIATIONS = '/app/afiliations/list-afiliations'; // MENÚ afiliaciones
export const APP_DASHBOARD_PAYMENTS = '/app/payments/list-payments'; // MENÚ pagos
export const APP_PAYMENTS_HISTORY = '/app/payments/history'; // historico de pagos
export const APP_DASHBOARD_NOVELTIES = '/app/reports'; // MENÚ novedades
export const APP_NOVELTIES_ADD_PAYROLL = '/app/novelties/add-payroll'; // realizar pago
export const APP_DASHBOARD_DOCUMENTS = '/app/documents/home'; // MENÚ documentos
export const APP_DOCUMENTS_VOUCHERS = '/app/documents/vouchers'; // comprobantes de nómina
export const APP_DOCUMENTS_SOCIAL_SECURITY = '/app/documents/social-security'; // documentos aportes seguridad social

// Novedades
export const APP_REPORTS_HOME = '/app/reports'; // Página principal 
export const APP_REPORTS_CREATE = '/app/reports/create'; // Sección de creación

// Liquidations
export const APP_LIQUIDATION_RESUME = '/app/liquidations'; // Resumen principal de liquidación

export const APP_ACCOUNT_HOME = '/app/account/home'; // Página de mi cuenta
export const APP_ACCOUNT_BILLING = '/app/account/billing'; // Página de mi cuenta > facturación
export const APP_ACCOUNT_CHANGE_PASS = '/app/account/change-pass'; // Página de mi cuenta > facturación

// ==============================================
/// REGISTER
// ==============================================
// Employer
export const REGISTER_BASIC_DATA = '/register/employer/basic-data';
export const REGISTER_WORKPLACE_DATA = '/register/employer/workplace';

// Default Contract
export const REGISTER_CREATE_CONTRACT = '/register/employee/create-contract';
export const REGISTER_EMPLOYER_SUMMARY = '/register/employee/employee-summary';

// Checkout
export const REGISTER_CHECKOUT_SUMMARY = '/register/checkout/checkout-summary';
export const REGISTER_CHECKOUT_PROMO = '/register/checkout/promo';
export const REGISTER_CHECKOUT_CONFIRM = '/register/checkout/checkout-confirm';

// Dashboard
export const REGISTER_CONTRACT_SALARY = '/register/contract/salary';
export const REGISTER_CONTRACT_DATA = '/register/contract/contract-data';
export const REGISTER_ONBOARDING_PAGE = '/register/dashboard/onboarding';
export const REGISTER_LIST_METHODS_PAYMENT = '/register/payment/methods';
export const REGISTER_ADD_METHODS_PAYMENT = '/register/payment/add';

export const REGISTER_ADD_NEW_BENEFICIARY = '/register/social-security/add-beneficiary';
export const REGISTER_CREATE_EMPLOYEE = '/register/dashboard/employee';

// Dashboard
export const REGISTER_DOCUMENTS_EMPLOYEE = '/register/dashboard/employee-documents';
export const REGISTER_DOCUMENTS_EMPLOYER = '/register/document/list-documents';
export const REGISTER_SOCIAL_SECURITY = '/register/social-security/entity';
