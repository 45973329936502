import React from 'react';

// Bootstrap
import { Alert } from 'react-bootstrap';

import { TypeNotify } from 'shared/interfaces';

export const Notify: React.FC<TypeNotify> = ({ open, type, message, invasive=false }) => {
    return (
        <>
            {
                open && (
                    <Alert variant={type} className={invasive ? 'top-fixed' : ''}>
                        {message}
                    </Alert>
                    )
            }
        </>
    );
};