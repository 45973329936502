import React from 'react';
import PropTypes from 'prop-types';

// Boostrap
import {Row, Col, Image} from 'react-bootstrap';

// CONST
import { PAGE_LINK } from "../../shared/constants/app";

// Images
import Logo from '../../assets/images/logo.png';


const AuthContainer = ({ children, alignItem = 'center'}) => {

    const typeAlign = {
        initial: 'align-items-baseline',
        center: 'align-items-center',
    }

    return (
        <div className='row-container'>
            <Col className='d-none d-lg-table-cell bg-img-login'>
                {/* Desktop banner*/}
                <div className='login-txt'>Somos tu departamento de recursos humanos</div>
                <div className='badge p-2 mb-3' >
                    <div className='badge-btn'><p className='d-inline' >Conoce más en </p> <a href={PAGE_LINK} >Symplifica.com</a></div>
                </div>
            </Col>
            <Col className='d-lg-table-cell'>
                <div className="icono-login">
                    <Image src={Logo} />
                </div>
                <Row className={`AuthPage-right-container aquifue mx-lg-5 d-flex ${typeAlign[alignItem]}`}>
                    <Col className='d-flex justify-content-center align-items-center flex-column'>
                        {/* Mobile banner */}
                        <div className='d-lg-none vw-100 mb-4 bg-img-login-mobil'>
                            <div className='login-txt'>Somos tu departamento de recursos humanos</div>
                        </div>
                        {children}
                    </Col>  
                </Row>
            </Col>
        </div>
    );
}

AuthContainer.propTypes = {
    alignItem: PropTypes.oneOf(['initial', 'center']),
}

export {AuthContainer};