import React, { useRef } from 'react';
import {Route, Switch} from 'react-router-dom'

// PAGES
import {AuthPage} from './Pages/AuthPage';
import {ForgotPasswordPage, RecoveryPasswordPage, ChangePasswordPage} from './Pages/PasswordPages';
import { Page404 } from '../Error/Pages';


const AuthRouter = () => {

    return(
        <Switch ref={useRef()}>
            <Route exact path='/auth' component={AuthPage} />
            <Route path='/auth/forgot-password' component={ForgotPasswordPage} />
            <Route path='/auth/recovery-password' component={RecoveryPasswordPage} />
            <Route path='/auth/change-password' component={ChangePasswordPage} />
            <Route component={Page404} />
        </Switch>
    )
}

export default AuthRouter;