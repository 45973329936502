import { Machine, assign, actions } from 'xstate';
import ReportsRepository from "./repository"

const postBonus = ({ contract, bonusAmount }) =>
  ReportsRepository.createBonus(contract, { amount: bonusAmount })

const postExtraHours = ({ contract, type, hours_amount, save }) =>
  ReportsRepository.createExtraTime(contract, { type, hours_amount, save })

const postWorkedDays = ({ contract, amount }) =>
  ReportsRepository.createWorkedDays(contract, { amount })

const postHolidays = ({ contract, type, init_date, end_date }) =>
  ReportsRepository.createHolidays(contract, { type, init_date, end_date })

const postPermission = ({ contract, type, init_date, end_date }) =>
  ReportsRepository.createPermission(contract, { init_date, end_date, type })

const postLicence = ({ contract, type, init_date, end_date }) =>
  ReportsRepository.createLicence(contract, { init_date, end_date, type })

const postIncapacity = ({ contract, type, init_date, end_date }) =>
  ReportsRepository.createIncapacity(contract, { init_date, end_date, type })

const postAbsence = ({ contract, type, init_date, end_date, will_pay }) =>
  ReportsRepository.createAbsence(contract, { init_date, end_date, type, will_pay })

const postLoan = ({ contract, capital_value, number_fees }) =>
  ReportsRepository.createLoan(contract, { capital_value, number_fees })

const postAdvance = ({ contract, capital_value }) =>
  ReportsRepository.createAdvance(contract, { capital_amount: capital_value, capital_value, amount: capital_value })

const deleteReport = ({ type, id }) =>
  ReportsRepository.deleteReportById(type, id)

const targetCreator = () => [
  { eventName: "prestamos", stateName: "loan" },
  { eventName: "licencias", stateName: "licence" },
  { eventName: "ausencias", stateName: "absence" },
  { eventName: "vacaciones", stateName: "holidays" },
  { eventName: "permisos", stateName: "permission" },
  { eventName: "bonificaciones", stateName: "bonus" },
  { eventName: "horas-extra", stateName: "extraTime" },
  { eventName: "incapacidades", stateName: "incapacity" },
  { eventName: "dias-trabajados", stateName: "workedDays" },
].map(element => {
  return {
    cond: (ctx, event) => event?.data?.reportType === element.eventName,
    target: `selectReportType.${element.stateName}`,
  }
})

let targets = targetCreator()
const reportsMachine = Machine(
  {
    id: 'reports',
    initial: 'home',
    context: {},
    states: {
      home: {
        id: "home",
        on: {
          CREATE: 'selectEmployee',
          DELETE: {
            target: "delete",
            actions: [
              assign({ notification: "" }),
              assign({ deleted: (context, event) => event.data })
            ]
          }
        }
      },
      delete: {
        states: {
          postDelete: {
            invoke: {
              id: 'postDelete',
              autoforward: true,
              src: (context, event) => deleteReport(context.deleted),
              onDone: {
                target: '#home',
                actions: [assign({ notification: (context, event) => event.data.success ? "Se eliminó tu novedad" : null })]
              },
              onError: {
                target: '#home',
                actions: assign({ error: (context, event) => event })
              }
            }
          },
        },
        on: {
          CANCELDELETION: 'home',
          ACCEPTDELETION: {
            target: "delete.postDelete",
          }
        }
      },
      selectEmployee: {
        id: "selectEmployee",
        on: {
          EMPLOYEESELECTION: [
            {
              target: "selectReportType",
              cond: (ctx, event) => event.data.employee,
              actions: assign({ employee: (context, event) => event.data.employee })
            }
          ],
          BACK: '#home',
        }
      },
      addNewReport: {
        id: "addNewReport",
        initial: "postSelector",
        states: {
          postSelector: {
            on: {
              "": [
                {
                  target: "#acceptedReport",
                  cond: "reportIsAccepted",
                },
                { target: "#rejectedReport" }
              ],
            },
          },
          acceptedReport: {
            id: "acceptedReport",
            on: {
              VIEWREPORTS: '#home',
            }
          },
          rejectedReport: {
            id: "rejectedReport",
            on: {
              VIEWREPORTS: '#home',
            },
          },
        }
      },
      selectReportType: {
        id: "selectReportType",
        initial: "reportTypeGrid",
        on: {
          EMPLOYEESELECTION: [
            {
              target: "selectReportType",
              cond: (ctx, event) => event.data.employee,
              actions: assign({ employee: (context, event) => event.data.employee })
            }
          ],
          SELECTREPORTTYPE: targets,
          GOHOME: "#home"
        },
        states: {
          reportTypeGrid: {
            id: "reportTypeGrid"
          },
          bonus: {
            id: "bonus",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postBonus',
                  autoforward: true,
                  src: (context, event) => postBonus({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              },
            },
          },

          extraTime: {
            id: "extraTime",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postExtraTime',
                  autoforward: true,
                  src: (context, event) => postExtraHours({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              }
            },
          },
          workedDays: {
            id: "workedDays",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postWorkedDays',
                  autoforward: true,
                  src: (context, event) => postWorkedDays({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              }
            },
          },
          holidays: {
            id: "holidays",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postHolidays',
                  autoforward: true,
                  src: (context, event) => postHolidays({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              },
            },
          },
          permission: {
            id: "permission",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid",
                  REPORTLICENCE: "#licence"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postPermission',
                  autoforward: true,
                  src: (context, event) => postPermission({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              }
            }
          },
          licence: {
            id: "licence",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid",
                  REPORTPERMISSION: "#permission"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postLicence',
                  autoforward: true,
                  src: (context, event) => postLicence({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              }
            }
          },
          incapacity: {
            id: "incapacity",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid",
                  REPORTLICENCE: "#licence"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postIncapacity',
                  autoforward: true,
                  src: (context, event) => postIncapacity({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              }
            }
          },
          absence: {
            id: "absence",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postAbsence',
                  autoforward: true,
                  src: (context, event) => postAbsence({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              },
            },
          },
          loan: {
            id: "loan",
            initial: "start",
            states: {
              start: {
                on: {
                  ACCEPT: "form",
                  GOBACK: "#reportTypeGrid"
                }
              },
              form: {
                on: {
                  COMPLETE: "post",
                  GOBACK: "start"
                }
              },
              post: {
                invoke: {
                  id: 'postLoan',
                  src: (context, event) => event.isLoan
                    ? postLoan({ contract: context.employee, ...event.data })
                    : postAdvance({ contract: context.employee, ...event.data }),
                  onDone: {
                    target: '#addNewReport',
                    actions: assign({ user: (context, event) => event })
                  },
                  onError: {
                    target: 'form',
                    actions: assign({ error: (context, event) => event })
                  }
                }
              },
            },
          },
        },
      },
    }
  },
  {
    guards: {
      "reportIsAccepted": (context, event) => {
        return context.user.success ? context.user.success : context.user.data.success
      }
    }
  }
)
export default reportsMachine