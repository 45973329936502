import store from '../../redux/storages/global';
import { loginAction } from '../../redux/actions/';
import { LOGIN_SERVER } from '../constants/app';
// require('isomorphic-fetch');

import { getToken, parseResponse } from './http_utils';

export default class Http {

    // =======================================================
    /// 
    // =======================================================
    static async post(url, formData, headers = {}, tries = 1) {
        let count = tries;
        const resp = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                ...getToken(),
                ...headers
            },
            body: JSON.stringify(formData)
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.post(url, formData, { ...headers, Authorization: refresh }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }

    // =======================================================
    /// 
    // =======================================================
    static async postFile(url, formData, headers = {}, tries = 1) {
        let count = tries;
        const resp = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                ...getToken(),
                ...headers
            },
            body: formData
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.post(url, formData, { ...headers, Authorization: refresh }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }

    // =======================================================
    /// 
    // =======================================================
    static async put(url, formData, headers = {}, tries = 1) {
        let count = tries;
        const resp = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                ...getToken(),
                ...headers
            },
            body: JSON.stringify(formData)
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.put(url, formData, { ...headers, Authorization: refresh }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }


    // =======================================================
    /// 
    // =======================================================
    static async patch(url, formData, headers = {}, tries = 1) {
        let count = tries;
        const resp = await fetch(url, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                ...getToken(),
                ...headers
            },
            body: JSON.stringify(formData)
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.patch(url, formData, { ...headers, Authorization: refresh }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }


    // =======================================================
    /// 
    // =======================================================
    static async reqDelete(url, formData, headers = {}, tries = 1) {
        let count = tries;
        const resp = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                ...getToken(),
                ...headers
            },
            body: JSON.stringify(formData)
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.reqDelete(url, formData, { ...headers, Authorization: refresh }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }

    // =======================================================
    /// 
    // =======================================================
    static async get(url, options = {}, tries = 1) {

        const { headers, params } = options;
        let headersParams = {
            'Content-type': 'application/json',
            ...getToken(),
        };
        if (headers)
            headersParams = { ...headersParams, ...headers }
        let newURL = url;
        if (params)
            newURL += `?${new URLSearchParams(params)}`;

        let count = tries;

        const resp = await fetch(newURL, {
            method: 'GET',
            mode: 'cors',
            headers: headersParams
        });

        const { status } = resp;

        if (status === 401 && count > 0) {
            const refresh = await Http.refreshToken();
            count--;
            if (refresh)
                return await Http.get(url, { headers: { Authorization: refresh }, params }, count);
            else
                return parseResponse(resp.clone());
        } else
            return parseResponse(resp.clone());
    }

    // =======================================================
    /// get a new token and refresh_token
    // =======================================================
    static async refreshToken() {
        let { refresh_token } = store.getState().state;

        const resp = await fetch(`${LOGIN_SERVER}/api/token/refresh/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refresh_token })
        });

        const { status } = resp;
        const { token, refresh_token: refreshToken } = await resp.json();

        if (status === 200) {
            let oldToken = refreshToken ? refreshToken : refresh_token;
            store.dispatch(loginAction(token, oldToken));
            return token;
        } else
            return '';

    } // FIN refreshToken

}

