import React, { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateNewReportPage } from './Pages/Reports/CreateNewReport';
import { ReportsPage } from './Pages/Reports';

// Pages
import { 
    DasboardPage,
    ListEmployeesPage,
    DetailEmployeePage,
    ContractEmployeePage,
    SalaryContractEmployeePage,
    DashSocialSecurityPage,
    HomeDocuments,
    PaymentPage,
    LiquidationResume,
    AccountPage,
} from './Pages';

// Urls
import { 
    APP_DASHBOARD_HOME,
    APP_DASHBOARD_EMPLOYEES,
    APP_DASHBOARD_AFILIATIONS,
    APP_EMPLOYEES_DETAIL,
    APP_EMPLOYEES_CONTRACT,
    APP_EMPLOYEES_CONTRACT_SALARY,
    APP_REPORTS_CREATE,
    APP_REPORTS_HOME,
    APP_DASHBOARD_DOCUMENTS,
    APP_DASHBOARD_PAYMENTS,
    APP_LIQUIDATION_RESUME,
    APP_ACCOUNT_HOME,
} from 'shared/constants/urls';

const AppRouter = () => {
    return (
        <Switch ref={useRef()}>
            <Route path={APP_DASHBOARD_HOME} component={DasboardPage} />
            <Route path={APP_DASHBOARD_EMPLOYEES} component={ListEmployeesPage} />
            <Route path={APP_DASHBOARD_AFILIATIONS} component={DashSocialSecurityPage} />

            <Route path={APP_EMPLOYEES_DETAIL} component={DetailEmployeePage} />
            <Route path={APP_EMPLOYEES_CONTRACT} component={ContractEmployeePage} />
            <Route path={APP_EMPLOYEES_CONTRACT_SALARY} component={SalaryContractEmployeePage} />

            <Route path={APP_REPORTS_HOME} exact component={ReportsPage} />
            <Route path={APP_REPORTS_CREATE} exact component={CreateNewReportPage} />
            
            <Route path={APP_ACCOUNT_HOME} exact component={AccountPage} />

            <Route path={APP_DASHBOARD_DOCUMENTS} exact component={HomeDocuments} />

            <Route path={APP_DASHBOARD_PAYMENTS} exact component={PaymentPage} />
            
            <Route path={APP_LIQUIDATION_RESUME} exact component={LiquidationResume} />

        </Switch>
    );
};

export default AppRouter;