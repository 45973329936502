import React from 'react';

// BOOTSTRAP
import {Image} from 'react-bootstrap';

// IMAGES
import CloseImage from 'assets/ico/path@1x.png';

interface CompleteModalProps {
    open: boolean;
    children: React.ReactChild;
    closeAction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CompleteModal: React.FC<CompleteModalProps> = ({open, children, closeAction}) => {

    return(
        <>
            {
                open && (
                    <div className="modal modal-open">
                        <span onClick={closeAction} className='modal-close-button'>
                            <p className='modal-close-button-p'>X</p>
                            <Image className='modal-close-button-img' src={CloseImage} />
                        </span>
                        {children}
                    </div>
                )
            }
        </>
    )
}