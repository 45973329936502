import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// CONTAINER
import { RegisterContainer } from '../../../../shared/containers';

// BOOTSTRAP
import { Container, Spinner, Row, Col} from 'react-bootstrap';

// COMPONENTS
import { AccordionCustom, ItemAccordion, Notify } from '../../../../shared/components';

// FORMS
import { SummaryForm } from './forms';

// API
import RegisterRepository from '../../repository/register.repository';
import PaymentRepository from '../../repository/payment.repository';

// Utils
import { setFormatPromo, dolarize } from '../../../../shared/utils/utils';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

const Summary = ({ history, client_id }) =>{

    const { showNotify, handleNotify } = useNotifyState();
    const [isLoading, setIsLoading] = useState(false);

    const [shoppingcart_items, setShoppingcartItems] = useState([]);
    const [methods, setMethods ] = useState([]);
    const [client, setClient] = useState({});

    // =======================================================
    /// Consulta la información de shoppingCard, si no existen datos se crean
    // =======================================================
    const fetchDataShoppingCard = async () => {
        let shoppingcart_items = [];
        setIsLoading(true);
        const resp = await RegisterRepository.shoppingCart();
        if (resp.success) {
            shoppingcart_items = setFormatPromo(resp.data.shoppingcart_items);
        }

        setShoppingcartItems(shoppingcart_items);
        setIsLoading(false);
        return;
    }

    // =======================================================
    /// Consulta los datos de metodos de pago registrados
    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const metodos = await PaymentRepository.getListPaymentMethods(client_id);
            setMethods(metodos);
            setIsLoading(false);
            return;
        }
        fetchData();
        return;
    }, [client_id]);


    // =======================================================
    /// 
    // =======================================================
    useEffect(() => {
        
        fetchDataShoppingCard();
        return;
    }, []);
    

    // =======================================================
    /// consulta la información del cliente
    // =======================================================
    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true);
            const cliente = await RegisterRepository.getClient(client_id);
            if (cliente.success)
                setClient(cliente.data);

            setIsLoading(false);
            return;
        }
        
        fetchData();
        return;
    }, [client_id]);

    // =======================================================
    /// formatea los datos para mostrar en el listado de shoppingCard
    // =======================================================
    const formatData = (data) => {

        const { promocode_assigned, name, amount_discount, amount} = data;

        return {
            start_top: null,
            start_bottom: name,
            center: promocode_assigned && promocode_assigned.promo_code && promocode_assigned.promo_code.hasOwnProperty('id') ? `PROMO: ${promocode_assigned.promo_code}` : null,
            end_top: amount_discount ? `${dolarize(amount)}` : null,
            end_bottom: amount_discount ? `${dolarize(amount_discount)}` : `${dolarize(amount)}`
        };
    }

    return(
        <RegisterContainer 
            title='Finaliza tu registro' 
            info={{title:'Recuerda', subtitle: 'Este es el cobro inicial de los servicios de Symplifica. Las afiliaciones se cobran una sola vez y los fees se cobran mensualmente en la fecha de débito.'}} 
            showNotify={showNotify}
        >
             <Container >
                <Row className='d-flex justify-content-center'>
                    <Col md={6}>
                        <h4 className="color-symplifica text-center my-5" >Muy pronto estarás listo para gestionar tus empleados con Symplifica</h4>
                    </Col>
                </Row>
                <Row className='my-2' >
                    {
                        isLoading
                            ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                />
                            )
                            : (shoppingcart_items.map((shop, i) => 
                                <AccordionCustom key={`toggle_${i}`} {...shop} amount={`${dolarize(shop.amount)}`}>
                                    <>
                                        {
                                            shop.items.map( (item, idx) => <ItemAccordion key={`item_${idx}`} {...formatData(item)} /> )
                                        }
                                    </>
                                </AccordionCustom>
                            )) 
                    }
                </Row>
                <SummaryForm 
                    metodos={methods} 
                    client={client} 
                    handleNotify={handleNotify} 
                    history={history}
                />
                
                <Row >
                    <Col >
                        <p className='p-4 mb-4 summary-page-info' >*Cada transacción tiene un costo de $5.900 más 4xmil. El primer débito se realiza al inscribir tu método de pago para activar tu cuenta. El recaudo puede tardar hasta 3 días hábiles (cuenta bancaria). Realizaremos débitos de forma automática a tu cuenta a finales de mes correspondientes a seguridad social, nuestro servicio y nómina (si así lo seleccionas más adelante), podrás solicitar cambiar esto si lo deseas.</p>
                    </Col>
                </Row>
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        client_id: state.client_id,
    }
}


const SummaryPage = connect(mapStateToProps, null)(Summary);

export { SummaryPage };