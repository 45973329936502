import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// Components
import { AppContainer } from 'shared/containers';
import { CardData, TabHorizontal } from 'shared/components';

// Interfaces
import { Employee, ResponseHttp2, Choice } from 'shared/interfaces';

// APIS
import { RegisterRepository, GeneralRepository } from 'shared/repository';
import { valueChoice } from 'shared/utils/utils';
import { TypeChoice } from 'shared/utils/emuns';

export const DetailEmployeePage: React.FC = () => {

    const history = useHistory();
    const location:any = useLocation();
    const [employee, setEmployee] = useState<Employee>({id:''});
    const [dataCity, setDataCity] = useState({
        birth_city: '',
        city: '',
        state: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            const resp: ResponseHttp2 = await RegisterRepository.getFullEmployee(location.state.employee_id || '');

            if(resp.success) {
                setEmployee(resp.data);

                let string_birth_city = '';
                let string_city = '';
                let string_state = '';
                let depto_birth_city: Choice[] = [];
                let depto_depto: Choice[] = [];

                const client_birth_city: string = resp.data?.client.birth_city.length > 0 ? resp.data?.client.birth_city.substr(0, 2) : '';
                const client_city: string = resp.data?.client.city.length > 0 ? resp.data?.client.city.substr(0, 2) : '';

                const resp_depto = await GeneralRepository.getStates();
                let aux_resp_depto: any = resp_depto.find((rp: any) => rp.value === resp.data?.client.birth_state );
                string_state = aux_resp_depto?.text || '';

                if ( client_birth_city ) {
                    depto_birth_city = await GeneralRepository.getCities(client_birth_city);
                    let aux_depto_birth_city:any = depto_birth_city.find((dd:Choice) => dd.value === resp.data?.client.birth_city);
                    string_birth_city = aux_depto_birth_city?.text || '';
                }

                if (client_city ) {
                    depto_depto = await GeneralRepository.getCities(client_city);
                    let aux_depto_depto:any = depto_depto.find((dd:Choice) => dd.value === resp.data?.client.city );
                    string_city = aux_depto_depto?.text || '';
                }

                setDataCity({
                    birth_city: string_birth_city,
                    city: string_city,
                    state: string_state,
                });

            }

        }

        fetchData();
    }, []);

    return (
        <AppContainer title="Datos personales" onPressBack={() => history.goBack()} showFooter={false}>
            <Container fluid>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <p className="text-center w80 font-1rem">Encuentra los datos básicos y de contacto de tu empleado</p>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center">
                        <h4 className="text-center color-symplifica">Empleado</h4>
                    </Col>
                </Row>

                <TabHorizontal className="tab-container">
                    <Col id="Datos personales" >
                        <Row className="eso">
                            <Container fluid className='my-md-4 py-0 px-0' >
                                <CardData text="Primer nombre" subtext={employee.first_name || ''} />
                                <CardData text="Segundo nombre" subtext={employee.middle_name || ''} />
                                <CardData text="Primer Apellido" subtext={employee.mothers_name || ''} />
                                <CardData text="Segundo Apellido" subtext={employee.last_name || ''} />
                                <CardData text="Tipo de documento" subtext={valueChoice(TypeChoice.ID_TYPES, employee.client?.id_type )} />
                                <CardData text="Número de documento" subtext={employee.client?.id_num || ''} />
                                <CardData text="Fecha de expedición" subtext={employee.client?.id_expedition_date || ''} />
                                <CardData text="Estado civil" subtext={valueChoice(TypeChoice.MARITAL_STATUS, employee.client?.marital_status)} />
                                <CardData text="Fecha de nacimiento" subtext={employee.client?.birthdate || ''} />
                                <CardData text="Lugar de nacimiento" subtext={dataCity.birth_city || ''} />
                                <CardData text="Pais de nacimiento" subtext={employee.client?.birth_country ? 'Colombia' : ''} />
                            </Container>
                        </Row>
                    </Col>
                    <Col id="Datos contacto" >
                        <Row >
                            <Container className='my-md-4 py-0 px-0' >
                                <CardData text="Dirección residencia" subtext={employee.client?.address_line1 || ''} />
                                <CardData text="Detalles" subtext={employee.client?.address_line2 || ''} />
                                <CardData text="Ciudad y departamento" subtext={`${dataCity.city || ''} ${dataCity.state || ''}`} />
                                <CardData text="Celular" subtext={employee.client?.phone || ''} />
                                <CardData text="Correo electrónico" subtext={employee.client?.email || ''} />
                            </Container>
                        </Row>
                    </Col>
                </TabHorizontal>


            </Container>
        </AppContainer>
    );
}