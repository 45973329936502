import React, { useState } from 'react';

// Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

import { CompleteModal, SubmitButton } from 'shared/components';

// API
import { AppRepository } from 'shared/repository';

// Interface
import { ResponseHttp2, Contract, AlertType } from 'shared/interfaces';

interface ConfirmModalProps {
    modal: boolean;
    showModal: React.Dispatch<React.SetStateAction<boolean>>;
    contract: Contract;
    handleNotify: (type: AlertType, message:string) => void;
    callback: (value:boolean) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ modal, showModal, contract, handleNotify, callback }) => {

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [acepted, setAcepted] = useState<boolean>(false);
    const newValue: boolean = !contract.automatic_renewal;

    const updateRenewal = async () => {
        setIsloading(true);

        const resp: ResponseHttp2 = await AppRepository.updateRenewal(contract.id, newValue );

        setIsloading(false);

        if(resp.success) {
            handleNotify('success', resp.message);
            callback(newValue);
            showModal(false);
        }else {
            handleNotify('danger', resp.message);
        }

    }

    return (
        <CompleteModal open={modal} closeAction={isLoading ? ()=>{} : () => showModal(prev => !prev)}>
            <Container fluid className="modal-confirm-container">
                <Row>
                    <Col xs={12} className="my-2">
                        <h4 className="text-center color-symplifica">¿Estás seguro que deseas activar la renovación automática?</h4>
                    </Col>
                    <Col xs={12} className="my-2">
                        <p className="text-center font12">Renovaremos el contrato a término fijo por el mismo periodo y seguirá activo en las entidades de seguridad social a tu nombre</p>
                    </Col>
                    <Col xs={12} className="justify-content-center">
                        <div className="accept-text">
                            <input type="checkbox" name="mandato" checked={acepted} onChange={(event) => setAcepted(event.target.checked) } />
                            <span>
                                Estoy de acuerdo en aplicar el cambio
                            </span>
                        </div>
                    </Col>
                    <Col xs={12} className="my-2">
                        <SubmitButton block disabled={isLoading || !acepted} spinner={isLoading} variant="primary" text="Aceptar" onClick={updateRenewal} />
                    </Col>
                    <Col xs={12} className="my-2">
                        <SubmitButton block disabled={isLoading} variant="secondary" text="Cancelar" onClick={() => showModal(prev => !prev)} />
                    </Col>
                </Row>
            </Container>
        </CompleteModal>
    );
}