import React from 'react';

// BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// COMPONENTS
import { TooltipBasic } from '../Tooltips';

const TablePrices  = ({title, data = [], tooltip}) => {

    return(
        <Row className='' >
            <Col >
                <Row >
                    <Col className='d-flex align-items-center mb-3' >
                        <p className='color-symplifica mx-0 my-0' >{title}</p>
                        {
                            tooltip ?
                                <TooltipBasic {...tooltip} />
                            :
                                null
                        }
                    </Col>
                </Row>
           
                {
                    data.map((item, i) => {
                        return (
                            <Row key={`tp_${i}`}>
                                <Col className='mx-0' >
                                    <p>{item.title}</p>
                                </Col>
                                <Col className='mx-3' >
                                    <p className='text-right' >{item.amount}</p>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Col>
        </Row>
    )
}

export { TablePrices }