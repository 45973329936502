import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { InputDate, InputText } from '../../../../../shared/components';
import { AppContainer } from 'shared/containers';
import { APP_HELP_CENTER } from 'shared/constants/app';
const InfoScreen = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  let liStyle = {
    backgroundImage: "url(/icons/reports/permisos.svg)",
    backgroundPosition: "-5 0",
    backgroundSize: "1em 1em",
    backgroundRepeat: "no-repeat",
    paddingLeft: "-2em",
    width: "1em",
    height: "1em",
    minWidth: "1em"
  }
  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0, marginTop: 15 }}>
          <li style={{ fontSize: 12 }}>
            Son un dinero extra que puedes entregar como incentivo. Es tu decisión cómo empleador otorgarlos o no.
          </li>
          <li style={{ fontSize: 12 }}>
            Ten en cuenta que si tu bonificación + otros no salariales superan el 40% del salario, aumentará la Seguridad Social.
          </li>
        </ul>
        <a
          href={APP_HELP_CENTER}
          style={{ textAlign: "center", display: "flex", alignContent: "center", justifyContent: "center" }}>
          <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
          <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto", fontSize: 12 }}>
            ¿Quieres saber más?
          </p>
        </a>
        <div style={{ width: "100%", backgroundColor: "#F5F5F5", borderRadius: "10px", paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          <h6 style={{ color: "#00CCCB", fontSize: 11 }}>
            Recuerda
          </h6>
          <p style={{ fontSize: 9 }}>
            No puede superar el 40% del salario. Si quieres entregar más esto afectará la seguridad social.
          </p>
        </div>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={() => sendToMachine({ type: "ACCEPT" })}
        >
          Continuar
        </Button>

      </div>
    </Container >
  )
}
const BonusForm = ({ sendToMachine }: { sendToMachine: (arg0: {}) => void }) => {
  const [bonusAmount, setBonusAmount] = useState<number>(0)
  const [error, setError] = useState(false)
  let checkBonus = () => bonusAmount !== 0
  return (
    <div style={{ width: "100%" }}>
      <p style={{ textAlign: "center", width: "100%" }}>
        Ingresa el valor de la bonificación
      </p>
      <InputText
        key="valor"
        name="valor"
        min={0}
        value={`${bonusAmount ? bonusAmount : "Valor"}`}
        placeholder="Valor"
        error={error ? "No es un valor válido" : false}
        type="number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setError(false)
          setBonusAmount(parseInt(e.target.value))
        }} />
      <Button
        style={{ width: "100%" }}
        onClick={() => checkBonus() ? sendToMachine({ type: "COMPLETE", data: { bonusAmount } }) : setError(true)}
      >
        Guardar
      </Button>
    </div>
  )
}

const Bonus = ({ stateMachine, sendToMachine }:
  {
    stateMachine: { matches: (arg0: {}) => boolean },
    sendToMachine: (arg0: {}) => void
  }
) => {

  return (
    <>
      <AppContainer title="Bonificaciones" onPressBack={() => sendToMachine({ type: "GOBACK" })}>
        {stateMachine.matches({ selectReportType: { bonus: "start" } })
          ? <InfoScreen sendToMachine={sendToMachine} />
          : <BonusForm sendToMachine={sendToMachine} />}
      </AppContainer>
    </>
  )
}
export default Bonus