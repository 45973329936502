import { config, SERVER } from 'shared/constants/app';
import Http from 'shared/utils/Http2';

import { auth, googleProvider, facebookProvider } from 'shared/constants/firebase';

import { loginAction, signInAction} from 'redux/actions';
import store from 'redux/storages/global';

import { parseJwt } from 'shared/utils/utils';

export class AccessControl {

    // =======================================================
    /// Login con usuario y contraseña
    // =======================================================
    static async loginMailAndPassword(email, password){
        return await Http.post(
            `${config.accessControlUrl}/users/login/`, 
            {email, password, external_id: null}
        );
    }

    // =======================================================
    /// Accede a la platarforma solicitando token con Google
    // =======================================================
    static async loginWithGoogle(e){
        e.preventDefault();
        googleProvider.addScope('profile');
        googleProvider.addScope('email');
        const result = await auth.signInWithPopup(googleProvider);

        if (result.hasOwnProperty('credential')) {
            // This gives you a Google Access Token.
            let token = result.user.uid;
            const resp = await Http.post(`${config.accessControlUrl}/users/login/external`, { email: null, password: null, external_id: token });

            if (resp.success) {
                store.dispatch(loginAction(resp.data.token, resp.data.refresh_token));
                return resp.data;

            } else {

                const respAccess = await Http.post(`${config.accessControlUrl}/users/`, {
                    name: result.user.displayName,
                    email: result.user.email,
                    password: "google",
                    cellphone: result.user.phone,
                    is_active: true,
                    requires_password_change: false,
                    requires_2fa_login: false,
                    external_login_vendor: 1,
                    external_login_id: token,
                    cellphone_validated: false,
                    email_validated: true
                });

                if ( respAccess.success ) {
                    const respLogin = await Http.post(`${config.accessControlUrl}/users/login/external`, { email: null, password: null, external_id: token });

                    store.dispatch(loginAction(respLogin.data.token, respLogin.data.refresh_token));

                    return {
                        ...respLogin,
                        data: respLogin.data.token || '',
                        google: true
                    };
                }else
                    return respAccess;
            }
        }else {
            return {
                success: false,
                message: 'No se pudo realizar login con Google'
            }
        }
    } // FIN loginWithGoogle

    // =======================================================
    /// Accede a la platarforma solicitando token con Facebook
    // =======================================================
    static async loginWithFacebook(e){
        e.preventDefault();
        facebookProvider.addScope('user_birthday');
        const result = await auth.signInWithPopup(facebookProvider);
        
        if (result.credential) {
            // This gives you a Facebook Access Token.
            var token = result.credential.accessToken;
            const resp = Http.post(`${config.accessControlUrl}/users/login/`, { email: null, password: null, external_id: token })

            if (resp.success) {
                store.dispatch(loginAction(resp.data.token, resp.data.refresh_token));
                return resp;
            }
            else {
                const respAccess = await Http.post(`${config.accessControlUrl}/users/`, {
                    email: result.user.email,
                    password: result.user.password,
                    cellphone: result.user.phone,
                    is_active: false,
                    requires_password_change: false,
                    requires_2fa_login: false,
                    external_login_vendor: 1,
                    external_login_id: token,
                    cooldown_until: null,
                    groups: null,
                    actions: [0,1,2],
                    related_to: [],
                    cellphone_validated: false,
                    email_validated: false
                });

                return {...respAccess, facebook: true};

            }
        }else {
            return {
                success: false,
                message: 'No se pudo realizar login con Facebook'
            }
        }
    } // FIN loginWithFacebook

    // =======================================================
    ///  REGISTRAR USUARIO
    // =======================================================
    static async registerMailAndPassword(email, password, cellphone, name) {
        return await Http.post(`${config.accessControlUrl}/users/`, {
            email,
            password,
            cellphone,
            name,
            username: email,
            is_active: true,
            requires_password_change: false,
            requires_2fa_login: false,
            external_login_vendor: 1,
            external_login_id: "",
            cooldown_until: null,
            groups: null,
            actions: [],
            related_to: [],
            cellphone_validated: false,
            email_validated: false
        });
    } // FIN registerMailAndPassword

    // =======================================================
    /// solicita la creacion de un OTP
    // =======================================================
    static async forgotPass(email, tipo) {

        const url = tipo === 'email' ? `${config.accessControlUrl}/otp/request/mail/` : `${config.accessControlUrl}/otp/request/phone/${email}`;
        return await Http.post(url, { email });
    }

    // =======================================================
    /// envia codigo de reinicio de contraseña
    // =======================================================
    static async sendCodeOTP(otp_code) {
        return await Http.post(`${config.accessControlUrl}/users/login/otp/`,
            { otp_code }
        );
    }


    // =======================================================
    /// Envia la nueva contraseña para actualizar
    // =======================================================
    static async changePass(otp_code, password) {
        
        return await Http.post(`${config.accessControlUrl}/users/change-password/${otp_code}/`,
            { password }
        );
    }


    // =======================================================
    /// [GET]: Consulta Obtiene los datos de un empleador
    // =======================================================
    static async getEmployer(client_id, token = '') {
        const url = `${SERVER}/enchantress/client/${client_id}/employer/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });
        
        if (resp.success)
            return {
                ...resp,
                data: resp.data.length > 0 ? resp.data[0] : {}
            };

        return resp;
    }

    // =======================================================
    /// [GET]: Consulta Obtiene los lugares de trabajo de un empleador
    // =======================================================
    static async getEmployerDefaultWorkplace(client_id, token = '') {
        const url = `${SERVER}/enchantress/employer/${client_id}/workplace/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });
        if (resp.success) {
            return resp.data;
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Consulta los datos de contratos de un empleador
    // =======================================================
    static async getEmployerContracts(employer_id, token = '') {
        const url = `${SERVER}/enchantress/employer/${employer_id}/contracts/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });
        
        return resp.success ? resp.data : [];
    }

    // ==============================================
    /// [GET]: Request collection of choices that use throught the app
    // ==============================================
    static async getChoices(token = '') {
        const url = `${SERVER}/enchantress/load_initials_choices/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, {headers});
        return resp.success ? resp.data : {};
    }


    // =======================================================
    /// Valida el token y redirecciona según la respuesta
    // =======================================================
    static async doRedirect( token, refresh_token, email ) {

        let employerToSave = {};
        let contracts = [];
        if (token) {
            const { client_id } = parseJwt(token);
            const empleador = await AccessControl.getEmployer(client_id, token);
            const listChoices = await AccessControl.getChoices();

            if (empleador.success) {
                const { data: employer } = empleador;

                if (employer && employer.hasOwnProperty('id') && employer.id) {

                    contracts = await AccessControl.getEmployerContracts(employer.id, token);
                    const respWorkplaces = await AccessControl.getEmployerDefaultWorkplace(employer.id, token);
                    
                    employerToSave = { ...employer, workplaces: respWorkplaces };
                }

            }

            store.dispatch(signInAction(token, refresh_token, email, employerToSave, contracts, listChoices));

            return true;

        } else
            return false;
    }

}