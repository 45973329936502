import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// BOOTSTRAP
import {Row, Col} from 'react-bootstrap';

// ICONS
import CheckIcon from '../../../../../assets/ico/check-green.png';
import ClockIcon from '../../../../../assets/ico/clock-gray.png';

import PersonDoneIcon from '../../../../../assets/ico/user-circle-green.png';
import PersonIcon from '../../../../../assets/ico/user-circle-red.png';
import SignDoneIcon from '../../../../../assets/ico/firma-circle-green.png';
import SignIcon from '../../../../../assets/ico/firma-circle-red.png';
import SecurityDoneIcon from '../../../../../assets/ico/health-circle-green.png';
import SecurityIcon from '../../../../../assets/ico/health-circle-red.png';
import DocDoneIcon from '../../../../../assets/ico/doc-circle-green.png';
import DocIcon from '../../../../../assets/ico/doc-circle-red.png';

const CardOnboarding = ({ title, type, done = false, onClick = ()=>{} }) => {

    const type_card = {
        'person': {
            'done': PersonDoneIcon,
            'undone': PersonIcon,
        },
        'sign': {
            'done': SignDoneIcon,
            'undone': SignIcon,
        },
        'security': {
            'done': SecurityDoneIcon,
            'undone': SecurityIcon,
        },
        'document': {
            'done': DocDoneIcon,
            'undone': DocIcon,
        },
        'paymethod': {
            'done': DocDoneIcon,
            'undone': DocIcon,
        },
    }

    return (
        <Row className='card-onboarding-contain my-3 p-3 position-relative d-table' onClick={onClick}>

            <Col className='d-table-cell w-auto' >
                <img
                    src={done ? type_card[type].done : type_card[type].undone}
                    alt="Icon"
                />
            </Col>
            <Col className='align-items-center d-table-cell' >
                <p className='my-0'>{title}</p>
            </Col>
            <span className='card-onboarding-chulo m-2' >
                <img
                    src={done ? CheckIcon : ClockIcon}
                    alt='check'
                />
            </span>
        </Row>
    )
}

CardOnboarding.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['person', 'sign', 'security', 'document', 'paymethod']),
    done: PropTypes.bool,
    onClick: PropTypes.func,
}


export { CardOnboarding };