import React, { useState } from 'react';

// Bootstrap
import {Row, Col} from 'react-bootstrap';

interface AccordionCustomProps {
    group: string,
    amount?: string,
    open?: boolean,
    children?: React.ReactChild,
}

export const AccordionCustom: React.FC<AccordionCustomProps> = ({ group, amount, open = true, children }) => {

    const [show, setShow] = useState<boolean>(open);

    return (
        <div className="container-toggle">
            <Row className="underline-color pointer" onClick={() => setShow(show => !show)}>
                <Col>
                    <span className="header-finaliza-container">
                        <p className="texto-container">{group}</p>
                        <p className="value-container">{amount}</p>
                    </span>
                </Col>
            </Row>
            {
                show && (
                    <Row>
                        <Col className="padding-0">
                            { children }
                        </Col>
                    </Row>
                )
            }
        </div>
    );
};