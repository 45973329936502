import React, { useState } from 'react';

// bOOTSTRAP
import { Col, Row, Form } from 'react-bootstrap';

// Components
import { InputText, InputToggle, InputSelect, SubmitButton } from '../../../../../shared/components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// API
import PaymentRepository from '../../../repository/payment.repository';

const BankAccountForm = ({ tipoDoc, listBanks, listAccountType, history, handleNotify, email, client_id }) => {

    const [isLoading, setIsLoading] = useState(false);

    // =======================================================
    /// 
    // =======================================================
    const submitForm = async () => {

        if (validate() > 0) return;

        setIsLoading(true);
        const resp = await PaymentRepository.setAccountBank(inputs);
        setIsLoading(false);
        if (resp.success) {
            handleNotify('success', 'Se creo la cuenta bancaria correctamente');
            window.setTimeout(() => history.goBack(), 2000);
        } else {
            handleNotify('danger', resp.message);
        }
    }

    // =======================================================
    /// 
    // =======================================================
    const validate = () => {

        let count = 0;

        if (!inputs.account_number || inputs.account_number.length === 0) {
            handleError({ account_number: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.bank || inputs.bank.length === 0) {
            handleError({ bank: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.hasOwnProperty('is_accountholder')) {
            handleError({ is_accountholder: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.name || inputs.name.length === 0) {
            handleError({ name: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.document_type || inputs.document_type.length === 0) {
            handleError({ document_type: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.document_number || inputs.document_number.length === 0) {
            handleError({ document_number: 'Este campo es obligatorio' });
            count++;
        }
        if (!inputs.account_type || inputs.account_type.length === 0) {
            handleError({ account_type: 'Este campo es obligatorio' });
            count++;
        }

        return count;

    }

    const {
        handleSubmit, handleInputChange, inputs, errors, handleError, handleCheckChange
    } = useCustomForm({
        account_number: '',
        bank: '',
        is_accountholder: true,
        name: '',
        document_type: '',
        document_number: '',
        client_id,
        account_type: '',
        email,
    }, submitForm);

    return(
        <Form 
            className='my-5' 
            onSubmit={handleSubmit}
            autoComplete="off"
        >

            <Row className='my-2' >
                <Col md={4} sm={12}>
                    <InputSelect
                        name="account_type"
                        error={errors.account_type}
                        value={inputs.account_type}
                        onChange={handleInputChange} 
                        default_option='Tipo de cuenta' 
                        options={listAccountType}
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputText
                        name="account_number"
                        type="number"
                        error={errors.account_number}
                        value={inputs.account_number}
                        onChange={handleInputChange} 
                        placeholder='Número de cuenta' 
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputSelect 
                        name="bank"
                        error={errors.bank}
                        value={inputs.bank}
                        onChange={handleInputChange}
                        default_option='Banco' 
                        options={listBanks}
                    />
                </Col>
            </Row>

            <Row className='my-2' >
                <Col className='d-table justify-content-around'  md={4} sm={12}>
                    <div className='d-table-cell'><p className='text-bold' >¿Eres el títular?</p></div>
                    <div className='d-table-cell text-right'>
                        <InputToggle
                            name="is_accountholder"
                            error={errors.is_accountholder}
                            value={inputs.is_accountholder}
                            onChange={handleCheckChange}
                        />
                    </div>
                </Col>
                <Col md={4} sm={12}>
                    <InputText 
                        name="name"
                        error={errors.name}
                        value={inputs.name}
                        onChange={handleInputChange}
                        placeholder='Nombre del títular' 
                    />
                </Col>
                <Col md={4} sm={12}>
                    <InputSelect 
                        name="document_type"
                        error={errors.document_type}
                        value={inputs.document_type}
                        onChange={handleInputChange}
                        default_option='Tipo de documento' 
                        options={tipoDoc}
                    />
                </Col>
            </Row>

            <Row className='my-2' >
                <Col md={4} sm={12} >
                    <InputText 
                        name="document_number"
                        type="number"
                        error={errors.document_number}
                        value={inputs.document_number}
                        onChange={handleInputChange}
                        placeholder='Número de documento' 
                    />
                </Col>
            </Row>
            <Row className='d-lg-flex justify-content-center my-5'  md={4} sm={12}>
                <Col className='col-lg-4' >
                    <SubmitButton 
                        text='Guardar' 
                        block 
                        disabled={isLoading}
                        spinner={isLoading}
                    />
                </Col>
            </Row>

        </Form>
    )
}

export {BankAccountForm};