import React from 'react';

import { Badge } from 'react-bootstrap';

type VariantBadge = 'danger' | 'warning' | 'info';

interface SYBadgeProps {
    text: string;
    variant: VariantBadge;
}

export const SYBadge: React.FC<SYBadgeProps> = ({ text, variant}) => {
    return (
        <Badge pill variant={variant}>
            {text}
        </Badge>
    );
}