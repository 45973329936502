import React from 'react';
import { connect } from 'react-redux';

// Boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CONTAINER
import { AuthContainer } from '../../../../shared/containers';

// Components
import { Notify } from '../../../../shared/components';

// Form
import {ForgotPasswordForm} from './forms';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

// Actions
import { setForgotPasswordAction } from '../../../../redux/actions';

const ForgotPassword = ({ history, setForgotPass }) => {

    const { showNotify, handleNotify } = useNotifyState();

    return(
        <AuthContainer >
            <Notify {...showNotify} />
            <Col>
                <Row >
                    <Col >
                        <h3 className='color-symplifica text-center'>¿Olvidaste tu contraseña?</h3>
                        <p className='text-center' >Te enviaremos un email y un SMS con los pasos para recuperarla.</p>
                    </Col>
                </Row>
                <Row>
                    <ForgotPasswordForm
                        handleNotify={handleNotify}
                        history={history}
                        setForgotPass={setForgotPass}
                    />
                </Row>
            </Col>
        </AuthContainer>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setForgotPass: (email) => dispatch(setForgotPasswordAction(email))
    }
}

const ForgotPasswordPage = connect(null, mapDispatchToProps)(ForgotPassword);

export {ForgotPasswordPage};