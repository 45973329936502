import React, {useState} from 'react'

// BOOTSTRAP
import {Form, Row, Col} from 'react-bootstrap';

// COMPONENT
import { InputText, InputSelect, SubmitButton } from 'shared/components';
import { ModalMandato } from '../components';

// ICONS
import BellIcon from 'assets/ico/bell-ico.png';

// API
import {RegisterRepository} from 'shared/repository';

// Constans
import { PRICE_PAGE_LINK } from '../../../../../shared/constants/app';
import { REGISTER_CHECKOUT_CONFIRM, REGISTER_CHECKOUT_PROMO, REGISTER_ADD_METHODS_PAYMENT } from 'shared/constants/urls';

const SummaryForm = ({ client, metodos, handleNotify, history }) => {

    const [promo_code, setPromoCode] = useState(client.referred_by || '');
    const [promo_code_err, setPromoCodeErr] = useState('');
    const [payment_subscription_id, setPaymentSubscriptionId] = useState('');
    const [mandato, setMandato] = useState(false);
    const [modal, showModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    // =======================================================
    /// setea un código promocional
    // =======================================================
    const setValPromoCode = (e) => {
        const pattern = new RegExp('^[A-Za-z0-9]+$', 'i');
        e.persist();
        setPromoCode(e.target.value)
        if ( !pattern.test(e.target.value) && e.target.value.length > 0 )
            setPromoCodeErr('No es un código válido');
        else
            setPromoCodeErr('');
    }

    // =======================================================
    /// Posteo de código de referidos para aplicar descuento
    // =======================================================
    const submitReferralCode = async () => {
        setIsLoading(true);
        const resp = await RegisterRepository.applyReferralCode({ referal_code: promo_code});
        setIsLoading(false);
        if (resp.success) {
            handleNotify('success', 'El código de referido se aplico correctamente');
        } else {
            handleNotify('danger', resp.message);
        }
    }

    // =======================================================
    /// Función para posteo de datos
    // =======================================================
    const endCheckout = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        const resp = await RegisterRepository.checkout(payment_subscription_id);
        setIsLoading(false);
        if (resp.success) {
            handleNotify('success', 'Se creo correctamente la factura');
            window.setTimeout(() => history.push(REGISTER_CHECKOUT_CONFIRM, {invoice: resp.data} ), 2000);
            // redirection
        } else {
            handleNotify('danger', resp.message);
            // #DEBUG
            window.setTimeout(() => history.push(REGISTER_CHECKOUT_CONFIRM, { invoice: resp.data }), 2000);
        }
    }

    return(
        <>
            <ModalMandato open={modal} showModal={showModal} />
            <Form
                onSubmit={endCheckout}
                autoComplete="off"
            >
                <Row >
                    <Col className='summary-form-left col-12 col-lg-6 justify-content-center  align-items-center flex-column d-flex' >
                        <Row >
                            <Col className='col-2 d-flex align-items-center' >
                                <img src={BellIcon} alt='bell' />
                            </Col>
                            <Col className='col-8 ' >
                                <p className='red-symplifica text-12 my-0' >Ten en cuenta</p>
                                <p className='my-0' >Cobraremos el servicio de Symplifica de mensual de acuerdo al tipo de contrato que tengas.</p>
                                <a className='link text-12' href={`${PRICE_PAGE_LINK}/precios`} target="_blank" rel="noopener noreferrer">Ver precios</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-12 col-lg-6' >
                        <Row >
                            <Col className='col-11  my-2' >
                                <InputSelect
                                    default_option='Selecciona método de pago'
                                    name="payment_subscription_id"
                                    disabled={metodos.length === 0}
                                    options={metodos}
                                    value={payment_subscription_id}
                                    onChange={event => setPaymentSubscriptionId(event.target.value)}
                                />
                            </Col>
                            <Col className='col-1 my-2 px-lg-0 d-flex justify-content-end' >
                                <SubmitButton
                                    text='+'
                                    type="button"
                                    className='block btn-more'
                                    onClick={() => history.push(REGISTER_ADD_METHODS_PAYMENT)}
                                />
                            </Col>
                            <Col className='col-8 my-2' >
                                <InputText
                                    placeholder='¿Alguién te refirió? Pon su código'
                                    value={promo_code}
                                    error={promo_code_err}
                                    disabled={(client.referred_by && client.referred_by.length > 0)}
                                    onChange={setValPromoCode}
                                />
                            </Col>
                            <Col className='col-4 px-lg-0 my-2' >
                                <SubmitButton
                                    text='Aplicar'
                                    block
                                    variant="dark"
                                    type="button"
                                    disabled={(client.referred_by && client.referred_by.length > 0) || isLoading || !promo_code || promo_code_err}
                                    spinner={isLoading}
                                    onClick={(e) => submitReferralCode()}
                                />
                            </Col>
                            <Col className='col-lg-8 col-12 my-2' >
                                <SubmitButton
                                    variant="light"
                                    type="button"
                                    block
                                    text='¿Tienes un código promocional?'
                                    disabled={isLoading}
                                    spinner={isLoading}
                                    onClick={() => history.push(REGISTER_CHECKOUT_PROMO)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center my-4' >
                    <Col md={4} className="justify-content-center">
                        <div className="accept-mandato">
                            <input type="checkbox" name="mandato" value={mandato} onChange={(e) => setMandato(e.target.checked)} />
                            <span
                                onClick={() => showModal(true)}
                            >
                                Acepto mandato Symplifica
                        </span>
                        </div>
                    </Col>
                </Row>
                <Row className='d-lg-flex justify-content-center my-4' >
                    <Col md={4}>
                        <SubmitButton
                            text='Guardar'
                            block
                            disabled={isLoading || !mandato || !payment_subscription_id}
                            spinner={isLoading}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export { SummaryForm };
