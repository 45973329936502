import React, { useState } from 'react'

// BOOTSTRAP
import {Form, Col} from 'react-bootstrap';

// COMPONENTS
import { InputText, SubmitButton } from '../../../../../shared/components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// API
import { AccessControl } from '../../../repository/auth.repository';

const ChangePasswordForm = ({ forgotPass, handleNotify, history }) => {

    const [isLoading, setIsLoading] = useState(false);

    const submitForm = async () => {

        if (inputs.password.length < 6) {
            handleError({ password: 'La contraseña debe tener mínimo 6 carácteres' })
            return;
        }

        if (inputs.password !== inputs.repassword) {
            handleError({ repassword: 'Las contraseñas no coinciden' })
            return;
        }

        setIsLoading(true);
        const resp = await AccessControl.changePass(forgotPass.otp_code, inputs.password);
        setIsLoading(false);

        if (resp.success) {
            handleNotify('success', 'Se actualizó correctamente la contraseña', true);
            window.setTimeout(() => history.push('/auth'), 2500);
        } else {
            handleNotify('danger', resp.message, true);
        }

    }

    const {
        handleSubmit,
        handleInputChange,
        inputs,
        errors,
        handleError,
    } = useCustomForm({ password: '', repassword: '' }, submitForm);

    return (
        <Col >
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <InputText
                    type="password"
                    placeholder='Contraseña'
                    className='my-2'
                    name="password"
                    value={inputs.password}
                    error={errors.password}
                    onChange={handleInputChange}
                />
                <InputText
                    type="password"
                    placeholder='Repetir contraseña'
                    className='my-2'
                    name="repassword"
                    value={inputs.repassword}
                    error={errors.repassword}
                    onChange={handleInputChange}
                />
                <SubmitButton
                    className='my-2'
                    text='Recuperar contraseña'
                    variant="primary"
                    disabled={isLoading}
                    spinner={isLoading}
                    block
                />
            </Form>
        </Col>
    )
}

export { ChangePasswordForm };

