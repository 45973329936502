import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Containers
import { RegisterContainer } from '../../../../shared/containers';

import { Notify } from '../../../../shared/components';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

// API
import GeneralRepository from '../../repository/general.repository';

// Components
import { BeneficiaryForm } from './forms';

const BeneficiaryPage = ({history}) => {

    const { state: params } = useLocation();
    console.log("params", params);
    const employee_id = params && params.employee_id ? params.employee_id : null;

    const { showNotify, handleNotify } = useNotifyState();
    const [isLoading, setIsLoading] = useState(false);

    const [choices, setChoices] = useState({
        listRelationship: [],
        listStates: [],
        listDocTypes: [],
    });

    useEffect(() => {
        const fetchData = async () => {

            const [
                resp_relationship,
                resp_states,
                resp_doctypes,
            ] = await Promise.all([
                GeneralRepository.getRelationships(),
                GeneralRepository.getStates(),
                GeneralRepository.getDocumentTypes(),
            ]);

            setChoices({
                listRelationship: resp_relationship,
                listStates: resp_states,
                listDocTypes: resp_doctypes,
            });
        }

        fetchData();
    }, []);


    return (
        <RegisterContainer 
            title='Beneficiario - Familia' 
            info={{ title: 'Indicanos...', subtitle: 'todos los datos del beneficario para que sea incluido en las afiliaciones. Utiliza el documento que tenga vigente en el momento.' }} 
            showNotify={showNotify}
        >
            <Container>
                
                <Row className='d-block d-lg-flex justify-content-center my-5'  >
                    <Col className='col-12 col-lg-6 text-center'>
                        <h4 className='color-symplifica' >
                            Tu empleado tiene derecho de inscribir beneficiarios para servicios de salud (EPS) y/o cajas de compensación.
                        </h4>
                    </Col>
                </Row>

                <BeneficiaryForm
                    handleNotify={handleNotify}
                    choices={choices}
                    employee_id={employee_id}
                    history={history}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />

            </Container>
        </RegisterContainer>
    );
};

export {BeneficiaryPage};