import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { AppContainer } from 'shared/containers';

// Components Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Hooks
import useNotifyState from 'shared/utils/NotifyHook';
import { SubmitButton } from 'shared/components';

// VIEWS COMPONENTS
import { ResumeHome, SelectEmployee, LiquidationForm, LiquidationCheck, LiquidationFinal, LiquidationDoc } from './components';

export const LiquidationResume: React.FC = () => {

    const history = useHistory();
    const { showNotify, handleNotify } = useNotifyState();
    const [step, setStep] = useState(0)
    const [formData, setFormData] = useState({})
    const [contract, setContract] = useState<string>('')

    const nextPage = () => {
        setStep(step + 1)
    }

    const handleSelectEmployee = (e: string) =>{
        setContract(e)
        nextPage()
    }

    const handleForm = (e: any) => {
        setFormData({...formData, e})
        nextPage()
    }

    const handleChek = () =>{
        nextPage()
    }

    const seeDetailLiquidation = () =>{
        setStep(5)
    }


    const Stepper = ()=>{
        switch(step){
            case 0:
                return <ResumeHome onNextClick={nextPage} />
            case 1: 
                return <SelectEmployee onNextClick={handleSelectEmployee} />
            case 2: 
                return <LiquidationForm onNextClick={handleForm} contract_id={contract} />
            case 3: 
                return <LiquidationCheck onNextClick={handleChek} detailClick={seeDetailLiquidation} />
            case 4: 
                return <LiquidationFinal />
            case 5: 
                return <LiquidationDoc />
            default:
                return null
        }
    }

    return (
        <AppContainer title="Liquidación de contrato" onPressBack={() => history.goBack()} showFooter={false} showNotify={showNotify}>
            <Stepper />
        </AppContainer>
    )
}