import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import {Row, Col} from 'react-bootstrap';

// COMPONENTS
import { SubmitButton, TablePrices, TablePricesCategory } from 'shared/components';

// ICONS
import EditIcon from 'assets/ico/cont-icon-edit.png';

// Utils
import { dolarize } from 'shared/utils/utils';

// constants
import { REGISTER_CONTRACT_SALARY } from 'shared/constants/urls';

const ContractSalaryTab = ({ contract, history, id }) => {

    let defaultData = {
        assistances: [],
        others_assistances: [],
        costs: [],
        net_monthly_value: 0,
        net_daily_value: 0,
        salary_in_kind_value : 0,
    };

    const [salary, setSalary] = useState(defaultData);

    // =======================================================
    /// Initialize the salary contract data
    // =======================================================
    useEffect(() => {
        let newSalary = { ...defaultData};

        newSalary.assistances = [
            { title: "Salario Base", amount: dolarize(contract.base_salary) },
            
            { title: "Auxilio de Transporte", amount: dolarize(contract.transport_aid) },
            { title: "Descuento Salud", amount: dolarize(contract.eps_employee) },
            { title: "Descuento Pensión", amount: dolarize(contract.afp_employee) },
        ];

        if(contract.contract_type){
            newSalary.assistances.push({title: "Salario en Especie", amount: dolarize(contract.salary_in_kind_value)})
        }

        if ( contract && contract.salary_aids ) {
            newSalary.others_assistances = contract.salary_aids.map(item => ({ title: item.name, amount: dolarize(Number(item.amount)) }));
        }

        let security_social = Number(contract.eps) + Number(contract.afp) + Number(contract.arl) + Number(contract.ccf);
        let provisions = Number(contract.mandatory_unemployment_savings_provision) + 
            Number(contract.mandatory_unemployment_savings_arrears_provision) + 
            Number(contract.mandatory_bonus_provision) + 
            Number(contract.vacation_provision);

        newSalary.costs = [
            {
                category: "Seguridad Social",
                total: dolarize(security_social),
                items: [
                    { title: "Salud", amount: dolarize(contract.eps) },
                    { title: "Pensión", amount: dolarize(contract.afp) },
                    { title: "Riesgos laborales", amount: dolarize(contract.arl) },
                    { title: "Caja de compensación", amount: dolarize(contract.ccf) },
                ]
            },
            {
                category: "Provisión",
                total: dolarize(provisions),
                items: [
                    { title: "Cesantías", amount: dolarize(contract.mandatory_unemployment_savings_provision) },
                    { title: "Intereses de cesantías", amount: dolarize(contract.mandatory_unemployment_savings_arrears_provision) },
                    { title: "Bonificación obligatoria", amount: dolarize(contract.mandatory_bonus_provision) },
                    { title: "Vacaciones", amount: dolarize(contract.vacation_provision) },
                ]
            }
        ];
        newSalary.net_monthly_value = dolarize(contract.net_monthly_value);
        newSalary.net_daily_value = dolarize(contract.net_daily_value);

        setSalary(newSalary);
    }, [contract]);

    return (
        <Row  >
            <Col >
                <Row className="my-4" >
                    <Col >
                        <p >De acuerdo a lo que nos has dicho. Este es el valor que recibe tu empleada cada mes. Puedes editarlo y verlo detallado a continuación</p>
                    </Col>
                    <Col className='d-flex justify-content-end' >
                        <SubmitButton 
                            type="button" 
                            className='SubmitButton-circle button-circle' 
                            variant='secundary' 
                            onClick={() => history.push(REGISTER_CONTRACT_SALARY, { contract_id: contract.id, contract_type: contract.contract_type})} 
                        >
                            <img src={EditIcon} height={25} alt='edit' />
                        </SubmitButton>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TablePrices
                            title='Auxilios'
                            data={salary.assistances}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <p className='color-symplifica' >Auxilios no salariales</p>
                                <p className='text-12' >Los auxilios No Salariales son pagos que haces a tu empleado para apoyarlo en alguna necesidad adicional relacionada con su trabajo. Por ejemplo, transporte adicional o alimentación. Estos no suman la seguridad social</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <TablePrices
                                    data={salary.others_assistances}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='text-bold' >Ingresos netos</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SubmitButton block variant='light' >
                                    <p className='text-10 my-0'  >Mensual Neto</p>
                                    <p className='color-symplifica my-0'>{salary.net_monthly_value}</p>
                                </SubmitButton>
                            </Col>
                            <Col>
                                <SubmitButton block variant='light' >
                                    <p className='my-0 text-10'  >Diario Neto</p>
                                    <p className='color-symplifica my-0'  >{salary.net_daily_value}</p>
                                </SubmitButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <TablePricesCategory
                            title="Tus costos"
                            data={salary.costs}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

ContractSalaryTab.propTypes = {
    history: PropTypes.object,
    id: PropTypes.string,
};

export {ContractSalaryTab};