import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// CONTAINERS
import { RegisterContainer } from '../../../../shared/containers';

// COMPONENTS
import { Notify, BackTitle } from '../../../../shared/components';
import { SalaryForm } from './forms';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

// API
import GeneralRepository from '../../repository/general.repository';
import RegisterRepository from '../../repository/register.repository';

const ContractSalaryPage = ({ history }) => {

    const {state: params} = useLocation();
    
    const [isLoading, setIsLoading] = useState(false);
    const { showNotify, handleNotify } = useNotifyState();

    const [salary, setSalary] = useState({
        base_salary: '',
        salary_in_kind_value: '',
        bonus_list: [],
        new_bonus_list: []
    });
    const [choiceAids, setChoiceAids] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const aidsList = await GeneralRepository.getChoiceAids();
            setChoiceAids(aidsList);
            const getSalary = await RegisterRepository.getContractSalary(params.contract_id);
            if (getSalary.success) {
                
                const resultado = getSalary.data.bonus_list.map(aid => {
                    const bonus = aidsList.find(item => item.value === aid.bonus_type);
                    if (bonus && bonus.text)
                        return { ...aid, name: bonus.text };
                    else
                        return { ...aid, name: '' };
                });

                setSalary(prev => ({ ...prev, ...getSalary.data, bonus_list: resultado}));

            }

            setIsLoading(false);
        }
        fetchData();
    }, [params]);


    return (
        <RegisterContainer 
            title={<BackTitle history={history} text="Editar Salario" />} 
            info={{ title: 'Recuerda', subtitle: 'La Seguridad Social y prestaciones se calculan de acuerdo al salario base de tu empleado y su auxilio de transporte. El ingreso neto es el valor que recibe tu empleado en su mano.' }} 
            showNotify={showNotify}
        >
            <Container>

                <Row className='d-flex justify-content-center y-4' >
                    <Col className='col-12 col-lg-9' >
                        <h4 className='text-center color-symplifica my-5' >Edita los salarios y crea nuevos auxilios</h4>
                    </Col>
                </Row>

                <br />

                {
                    !isLoading && (
                        <SalaryForm
                            contract_id={params.contract_id}
                            contract_type={params.contract_type}
                            salary={salary}
                            aids={choiceAids}
                            handleNotify={handleNotify}
                        />
                    )
                }

            </Container>
        </RegisterContainer>
    );
};

ContractSalaryPage.propTypes = {
    history: PropTypes.object,
};

export {ContractSalaryPage};