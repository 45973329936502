import React from 'react';
import PropTypes from 'prop-types';

// BOOTSTRAP
import {Row, Form, Col} from 'react-bootstrap';

// Components
import { InputText, InputDate, InputSelect, InputCheck, GroupForm, SubmitButton } from 'shared/components';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// UTILS
import { dateToString, getChoicesOf } from 'shared/utils/utils';
import { validateName, validateNumber, validateBirthDate, validateDateIsGreater } from 'shared/utils/validate';

// CONST
import { REGISTER_WORKPLACE_DATA } from 'shared/constants/urls';

import { TypeChoice } from 'shared/utils/emuns';

const BasicDataForm = ({ setEmployer, history, employer }) => {

    const listTypeDoc = getChoicesOf(TypeChoice.ID_TYPES);

    const initEmployer = {
        first_name: '',
        last_name: '',
        mothers_name: '',
        middle_name: '',
        document_number: '',
        ...employer,
    };

    // =======================================================
    /// Valida cada uno de los campos del formulario
    // =======================================================
    const validate = () => {
        let errors = 0;

        if (!inputs.first_name || inputs.first_name.length < 1) {
            handleError({first_name: 'Por favor ingresa un Primer Nombre válido'});
            errors++;
        } else if (!validateName(inputs.first_name)) {
            handleError({ first_name: 'Por favor ingresa un Primer Nombre válido' });
            errors++;
        }

        if (!inputs.last_name || inputs.last_name.length < 1) {
            handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
            errors++;
        } else if (!validateName(inputs.last_name)) {
            handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
            errors++;
        }

        if (inputs.middle_name && !validateName(inputs.middle_name)) {
            handleError({ middle_name: 'Por favor ingresa un Segundo Nombre válido' });
            errors++;
        }

        if (inputs.mothers_name && !validateName(inputs.mothers_name)) {
            handleError({ mothers_name: 'Por favor ingresa un Segundo Apellido válido' });
            errors++;
        }

        if (!inputs.birth_date || inputs.birth_date.length < 1) {
            handleError({ birth_date: 'No es una fecha válida' });
            errors++;
        } else if (!validateBirthDate(inputs.birth_date) ) {
            handleError({ birth_date: 'Debes ser mayor de edad para ser empleador' });
            errors++;
        }

        if (!inputs.document_type || inputs.document_type.length < 1) {
            handleError({ document_type: 'Seleccione un tipo de documento' });
            errors++;
        }

        if (!inputs.document_number || inputs.document_number.length < 5 || !validateNumber(inputs.document_number)) {
            handleError({ document_number: 'Ingresa un No. de documento válido' });
            errors++;
        }

        if (!inputs.expedition_date || inputs.expedition_date.length < 1) {
            handleError({ expedition_date: 'No es una fecha válida' });
            errors++;
        } else if (!validateDateIsGreater(inputs.expedition_date, inputs.birth_date) ) {
            handleError({ expedition_date: 'La fecha de expedición debe ser posterior a la fecha de Nacimiento' });
            errors++;
        }

        if (!inputs.gender || inputs.gender.length < 1) {
            handleError({ gender: 'Seleccione un género' });
            errors++;
        }

        return errors;
    }

    // =======================================================
    /// Postea los datos del formulario
    // =======================================================
    const handleSubmitForm = async () => {

        if(validate() > 0) return;

        const formData = {
            ...inputs,
            expedition_date: dateToString(inputs.expedition_date),
            birth_date: dateToString(inputs.birth_date),
        };

        setEmployer(formData);
        history.push(REGISTER_WORKPLACE_DATA);

    }

    const {
        handleSubmit, handleInputChange, inputs, errors, handleError, setInputChange
    } = useCustomForm(initEmployer, handleSubmitForm);

    return(
        <Form
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <Row >
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Primer nombre'
                        name="first_name"
                        value={inputs.first_name}
                        error={errors.first_name}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Segundo nombre' 
                        name="middle_name"
                        value={inputs.middle_name}
                        error={errors.middle_name}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Primer apellido'
                        name="last_name"
                        value={inputs.last_name}
                        error={errors.last_name}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Segundo apellido'
                        name="mothers_name"
                        value={inputs.mothers_name}
                        error={errors.mothers_name}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputDate 
                        placeholder='Fecha de nacimiento' 
                        name='birth_date'
                        showYearDropdown
                        showMonthDropdown
                        value={inputs.birth_date}
                        error={errors.birth_date}
                        maxDate={new Date()}
                        onChange={setInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2'>
                    <GroupForm error={errors.gender} text="Género">
                        <div className="d-sm-table-cell px-2"><InputCheck name="gender" onChange={handleInputChange} checked={inputs.gender === 'F'} text="Femenino" value="F" id="female" /></div>
                        <div className="d-sm-table-cell px-2"><InputCheck name="gender" onChange={handleInputChange} checked={inputs.gender === 'M'} text="Masculino" value="M" id="male" /></div>
                    </GroupForm>
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputSelect 
                        default_option='Tipo de documento'
                        options={listTypeDoc}
                        name="document_type"
                        error={errors.document_type}
                        value={inputs.document_type}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Número de documento' 
                        name="document_number"
                        type="number"
                        value={inputs.document_number}
                        error={errors.document_number}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputDate 
                        placeholder='Fecha de expedición'
                        name='expedition_date'
                        showYearDropdown
                        showMonthDropdown
                        value={inputs.expedition_date}
                        error={errors.expedition_date}
                        onChange={setInputChange}
                        maxDate={new Date()}
                    />
                </Col>
            </Row>
            <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton text='Guardar' block />
                </Col>
            </Row>
        </Form>
    )
}

BasicDataForm.propTypes = { 
    client_id: PropTypes.string,
    vendor_id: PropTypes.string,
    setEmployer: PropTypes.func,
    history: PropTypes.object,
    employer: PropTypes.object,
};

export {BasicDataForm};