import React, {useState} from 'react';

// Boostrap
import {Row, Nav, Col} from 'react-bootstrap';

interface TabHorizontalProps {
    children?: Array<React.ReactChild>;
    default_tab?: number;
    className?: string;
}

export const TabHorizontal: React.FC<TabHorizontalProps> = ({ children = [], default_tab = 0, className=''}) => {

    const [tabState, changeTab] = useState(default_tab);

    return (
        <Row className={className}>
            <Col>
                <Nav variant="tabs" className="justify-content-center"  defaultActiveKey="/home"  >
                    {
                        children.map((item: any, i:number)=>(
                            <Nav.Item className={`tabHorizontan-button ${tabState === i ? "tabHorizontan-button-active" : null}`} key={i} >
                                <Nav.Link onClick={() => { changeTab(i)}} >{item.props.id}</Nav.Link>
                            </Nav.Item>
                        ))
                    }
                </Nav>
                {
                    children[tabState]
                }
            </Col>
        </Row>
    )
}