import React, { useState, useEffect } from 'react';

// BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

// Components
import { InputText, InputSelect, SubmitButton } from 'shared/components';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// API
import { GeneralRepository, RegisterRepository} from 'shared/repository';

// CONST
import { REGISTER_CREATE_CONTRACT } from 'shared/constants/urls';

const WorkplaceForm = ({ employer, setEmployer, history, handleNotify }) =>{

    const [stateList, setStateList] = useState([]);
    const [cityList, setcityList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // =======================================================
    /// Obtiene los departamentos para el select
    // =======================================================
    useEffect(() => {
        const fetchData = async () => {
            const deptos = await GeneralRepository.getStates();
            setStateList(deptos);
            return;
        }

        fetchData();
        return;
    }, []);

    // =======================================================
    /// Consulto las ciudades de acuerdo a el departamento seleccionado
    // =======================================================
    const getCitys = async (state) => {
        setcityList([]);
        setIsLoading(true);
        const citys = await GeneralRepository.getCities(state);
        setcityList(citys);
        setIsLoading(false);
    }

    const validate = () => {
        let count = 0;

        if (!inputs.address_1 || inputs.address_1.length < 8 || inputs.address_1.length > 100) {
            handleError({ address_1: 'No es una dirección válida'});
            count++;
        }

        if (!inputs.address_2 || inputs.address_2.length > 100) {
            handleError({ address_2: 'No es una dirección válida'});
            count++;
        }


        if (!inputs.state) {
            handleError({ state: 'No es un departamento válido'});
            count++;
        }

        if (!inputs.city) {
            handleError({ city: 'No es una ciudad válida'});
            count++;
        }

        return count;
    }

    // =======================================================
    /// Funcion para posteo de datos
    // =======================================================
    const createEmployer = async () => {

        const formData = {
            ...employer,
            ...inputs
        }

        if (validate() > 0) return;

        setIsLoading(true);
        const respuesta = await RegisterRepository.createEmployer(formData);

        if (respuesta.success) {
            let employerParse = JSON.parse(respuesta.data.employer);
            let workplaceParse = JSON.parse(respuesta.data.default_workplace);
            const employer = { ...employerParse, default_workplace_id: workplaceParse.id };
            setEmployer(employer);
            handleNotify('success', 'Tu información fue guardada correctamente');
            window.setTimeout(() => {
                history.push(REGISTER_CREATE_CONTRACT);
            }, 2000);
        } else {
            setIsLoading(false);
            let errorText = respuesta.message || 'Error conectando el servidor';
            handleNotify('danger', errorText);
        }

    }

    // =======================================================
    /// Defino logica para datos del formulario
    // =======================================================
    const { inputs, handleInputChange, handleSubmit, errors, handleError } = useCustomForm({
        address_1: '',
        address_2: '',
        city: '',
        state: '',
    }, createEmployer);

    return (
        <Form
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Row >
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Dirección residencial' 
                        name='address_1'
                        value={inputs.address_1}
                        error={errors.address_1}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputText 
                        placeholder='Ejemplo: Apartamento 00 Interior 00' 
                        name='address_2'
                        value={inputs.address_2}
                        error={errors.address_2}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputSelect 
                        default_option='Departamento' 
                        name='state'
                        value={inputs.state}
                        error={errors.state}
                        onChange={ e => {
                            handleInputChange(e);
                            getCitys(e.target.value);
                        }}
                        options={stateList}
                    />
                </Col>
                <Col className='col-lg-4 col-12 my-2' >
                    <InputSelect 
                        default_option='Ciudad'
                        name='city'
                        value={inputs.city}
                        error={errors.city}
                        disabled={cityList.length === 0}
                        onChange={handleInputChange}
                        options={cityList}
                    />
                </Col>
            </Row>
            <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton text='Guardar' block disabled={isLoading} spinner={isLoading} />
                </Col>
            </Row>
        </Form>
    );
}

export { WorkplaceForm };