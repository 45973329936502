import React, { useRef } from 'react';
import {Route, Switch} from 'react-router-dom';

// PAGES
import { BasicDataPage, WorkplacePage } from './Pages/Employer';
import { EmployeeCreatePage, EmployeeSummaryPage } from './Pages/Employee';
import { SummaryPage, PromoPage, DonePage } from './Pages/Checkout';
import { OnBoardingPage, OnBoardingEmployeePage } from './Pages/Dashboard';
import { ContractDataPage } from './Pages/Contract';
import { DocumentEmployeePage, DocumentEmployerPage } from './Pages/Documents';
import { PaymentMethodsPage } from './Pages/Payment';
import { NewPaymentMethodPage } from './Pages/Payment/NewPaymentMethodPage';
import { SocialSecurityPage, BeneficiaryPage } from './Pages/SocialSecurity';
import { ContractSalaryPage } from './Pages/Contract';
import { BasicDataEmployeePage } from './Pages/BasicData';

// constants
import { 
    REGISTER_CONTRACT_SALARY, 
    REGISTER_CONTRACT_DATA, 
    REGISTER_ONBOARDING_PAGE,
    REGISTER_LIST_METHODS_PAYMENT,
    REGISTER_ADD_METHODS_PAYMENT,
    REGISTER_BASIC_DATA,
    REGISTER_WORKPLACE_DATA,
    REGISTER_CREATE_CONTRACT,
    REGISTER_EMPLOYER_SUMMARY,
    REGISTER_CHECKOUT_SUMMARY,
    REGISTER_CHECKOUT_CONFIRM,
    REGISTER_CHECKOUT_PROMO,
    REGISTER_SOCIAL_SECURITY,
    REGISTER_ADD_NEW_BENEFICIARY,
    REGISTER_DOCUMENTS_EMPLOYEE,
    REGISTER_CREATE_EMPLOYEE,
    REGISTER_DOCUMENTS_EMPLOYER
} from 'shared/constants/urls';


const RegisterRouter = () => {

    return (
        <Switch ref={useRef()}>
            {/* CREATE EMPLOYEER */}
            <Route path={REGISTER_BASIC_DATA} component={BasicDataPage} />
            <Route path={REGISTER_WORKPLACE_DATA} component={WorkplacePage} />

            {/* CREATE EMPLOYEE */}
            <Route path={REGISTER_CREATE_CONTRACT} component={EmployeeCreatePage} />
            <Route path={REGISTER_EMPLOYER_SUMMARY} component={EmployeeSummaryPage} />

            {/* CHECKOUT */}
            <Route path={REGISTER_CHECKOUT_SUMMARY} component={SummaryPage} />
            <Route path={REGISTER_CHECKOUT_CONFIRM} component={DonePage} />
            <Route path={REGISTER_CHECKOUT_PROMO} component={PromoPage} />

            {/* DASHBOARD */}
            <Route path={REGISTER_ONBOARDING_PAGE} component={OnBoardingPage} />
            <Route path={REGISTER_CREATE_EMPLOYEE} component={BasicDataEmployeePage} />
            <Route path={`${REGISTER_DOCUMENTS_EMPLOYEE}/:id`} component={DocumentEmployeePage} />
            <Route path={`${REGISTER_SOCIAL_SECURITY}/:id`} component={SocialSecurityPage} />
            <Route path={REGISTER_ADD_NEW_BENEFICIARY} component={BeneficiaryPage} />
            <Route path='/register/dashboard/onboarding-employee' component={OnBoardingEmployeePage} />

            {/* CONTRACTS */}
            <Route path={`${REGISTER_CONTRACT_DATA}/:id`} component={ContractDataPage} />
            <Route path={REGISTER_CONTRACT_SALARY} component={ContractSalaryPage} />

            {/* DOCUMENTS */}
            <Route path={REGISTER_DOCUMENTS_EMPLOYER} component={DocumentEmployerPage} />


            {/* PAYMENT */}
            <Route path={REGISTER_LIST_METHODS_PAYMENT} component={PaymentMethodsPage} />
            <Route path={REGISTER_ADD_METHODS_PAYMENT} component={NewPaymentMethodPage} />

        </Switch>
    )
}

export default RegisterRouter;