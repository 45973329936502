import React from 'react';

// Bootstrap
import { Row, Col } from 'react-bootstrap';

interface CardEmptyProps {
    text?: React.ReactChild;
    subtext?: React.ReactChild;
}

export const CardEmpty: React.FC<CardEmptyProps> = ({ text, subtext }) => {
    return (
        <Row className="my-4">
            <Col xs={12}>
                <div className="my-3">
                    <span className="text-center">{text}</span>
                </div>
                <div className="w100">
                    <img className="w100" src="/images/img_aux/empty_card.png" alt="empty_card" />
                </div>
                <span className="text-center">{subtext}</span>
            </Col>
        </Row>
    );
}