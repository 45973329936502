import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';


const LinkButton = ({ text, className, href, action, type ="button"}) =>{

    const history = useHistory();

    const handleAction = (e) => {
        e.preventDefault()
        if(action){
            action()
        }
        else{
            history.push(href)
        }
    }

    return(
        <button type={type} onClick={(e)=>handleAction(e)} className={`LinkButton ${className}`} >{text}</button>
    )
}

LinkButton.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    action: PropTypes.func,
    type: PropTypes.oneOf(['submit', 'button', 'clear'])
}

export {LinkButton};