import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface CardDataProps {
    text: string;
    subtext?: string;
}

export const CardData: React.FC<CardDataProps> = ({text, subtext}) => {
    return (
        <Row className="container-card-data">
            <Col xs={6} className="text-content">
                <p className="font-1rem font-weight-400">{text}</p>
            </Col>
            <Col xs={6} className="subtext-content">
                <p className="font-1rem text-right">{subtext}</p>
            </Col>
        </Row>
    );
}