import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// BOOTSTRAP 
import { Form, Row, Col } from 'react-bootstrap';

// Constants
import { InputText, InputSelect, InputDate, GroupForm, InputCheck, SubmitButton } from 'shared/components';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// API
import { RegisterRepository, GeneralRepository} from "shared/repository";

// Utils
import { dateToString, getChoicesOf } from 'shared/utils/utils';
import { validateName, validateNumber, validateBirthDate, validateDateIsGreater } from 'shared/utils/validate';

// Interface
import { Employer, Employee, Choice, ResponseHttp2, FuncNotify } from 'shared/interfaces';

// Consts
import { REGISTER_ONBOARDING_PAGE } from 'shared/constants/urls';
import { setEmployerAction } from 'redux/actions';
import { TypeChoice } from 'shared/utils/emuns';

interface FormTabProps {
  choices: { listStates: Choice[], listCitiesBirth: Choice[], listCities: Choice[]};
  employeedata: Employee;
  handleNotify: FuncNotify;
}

export const FormTab: React.FC<FormTabProps> = ({ choices, employeedata, handleNotify }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  
  const employer = useSelector((state:any) => state.state?.employer || {id:''} );
  const contract = useSelector((state:any) => state.state?.contracts[0] || {id:''} );
  
  const setEmployer =  (employer:Employer) => dispatch(setEmployerAction(employer));

  const [loading, setLoading] = useState<boolean>(false);
  const [listCities, setListCities] = useState<Choice[]>(choices.listCitiesBirth);
  const [listCitiesBirth, setCitiesBirth] = useState<Choice[]>(choices.listCitiesBirth);

  const listDocTypes = getChoicesOf(TypeChoice.ID_TYPES);
  const listMaritalStatus = getChoicesOf(TypeChoice.MARITAL_STATUS);

  const data_default = {
    id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mothers_name: "",
    gender: "",
    id_type: "", // document_type
    id_num: "", // document_number
    marital_status: "",
    id_expedition_date: "", //id_expedition_date
    id_expedition_place: "", //document_expedition_city
    birthdate: "",
    birth_country: "",
    birth_state: "",
    birth_city: "",
    street_one: "",
    street_two: "",
    state: "",
    city: "",
    country_code: "",
    phone: "",
    email: "",
  };

  // ==============================================
  /// 
  // ==============================================
  const submitForm = async () => {
    let resp: ResponseHttp2;

    if (validate() > 0) return;

    setLoading(true);

    let formData = {
      ...inputs,

      document_expedition_date: dateToString(inputs.id_expedition_date),
      document_expedition_city: inputs.id_expedition_place,
      birthdate: dateToString(inputs.birthdate),
      country_code: inputs.birth_country,
      document_number: inputs.id_num,
      document_type: inputs.id_type,
      address_line1: inputs.street_one,
      address_line2: inputs.street_two,
      marital_status: 1,

      contract_id: contract.id,
      employer_id: employer.id,
    };

    const type_request = formData.employee_id ? 'UPDATE' : 'CREATE';

    if (type_request === 'UPDATE') {
      resp = await RegisterRepository.updateEmployee(formData); // Update
    } else {
      resp = await RegisterRepository.createEmployee(formData); // Create
    }
    
    setLoading(false);
    
    if (resp.success) {
      handleNotify('success', resp.message);
      if (type_request === 'CREATE') {
        setEmployer(resp.data);
      }
      window.setTimeout(() => history.push(REGISTER_ONBOARDING_PAGE), 2000);
    } else {
      handleNotify('danger', resp.message);
    }

  }

  // ==============================================
  /// Request a list of cities by birth_state
  // ==============================================
  const changeBirthCitiesList = async (state:string) => {
    setLoading(true);
    const resp_list = await GeneralRepository.getCities(state);
    setLoading(false);
    setCitiesBirth(resp_list);
  }

  // ==============================================
  /// Request a list of cities by state
  // ==============================================
  const changeCitiesList = async (state:string) => {
    setLoading(true);
    const resp_list = await GeneralRepository.getCities(state);
    setLoading(false);
    setListCities(resp_list);
  }

  // ==============================================
  /// Hook: handle Form new employee
  // ==============================================
  const {
    inputs,
    handleInputChange,
    setInputChange,
    handleSubmit,
    errors,
    handleError,
  } = useCustomForm( data_default, submitForm);

  // ==============================================
  /// 
  // ==============================================
  useEffect(() => {

    for (const key in data_default) {
      let value = '';
      if (employeedata.hasOwnProperty(key)) {
        value = employeedata[key] === null ? '' : employeedata[key];
        let set_key = key === 'id' ? 'employee_id' : key;
        setInputChange(set_key, value);
      }

      if (key !== 'id' && employeedata.client && employeedata.client.hasOwnProperty(key)) {
        value = employeedata.client[key] === null ? '' : employeedata.client[key];
        setInputChange(key, value);
      }
    }

    if (employeedata.client) {
      if (employeedata.client.birthdate) {
        setInputChange('birthdate', Date.parse(employeedata.client?.birthdate));
      }
      if (employeedata.client.id_expedition_date) {
        setInputChange('id_expedition_date', Date.parse(employeedata.client?.id_expedition_date));
      }
      if (employeedata.client.address_line1) {
        setInputChange('street_one', employeedata.client?.address_line1);
      }
      if (employeedata.client.address_line2) {
        setInputChange('street_two', employeedata.client?.address_line2);
      }
    };
  }, []);

  // ==============================================
  /// Validate the fields required
  // ==============================================
  const validate = () => {
    let count = 0;
    if (!inputs.first_name || inputs.first_name.length < 1) {
      handleError({ first_name: 'Por favor ingresa un Primer Nombre válido' });
      count++;
    } else if (!validateName(inputs.first_name)) {
      handleError({ first_name: 'Por favor ingresa un Primer Nombre válido' });
      count++;
    }
    if(!inputs.last_name || inputs.last_name.length < 1) {
      handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
      count++;
    } else if (!validateName(inputs.last_name)) {
      handleError({ last_name: 'Por favor ingresa un Primer Apellido válido' });
      count++;
    }
    if (inputs.middle_name && !validateName(inputs.middle_name)) {
      handleError({ middle_name: 'Por favor ingresa un Segundo Nombre válido' });
      count++;
    }
    if (inputs.mothers_name && !validateName(inputs.mothers_name)) {
      handleError({ mothers_name: 'Por favor ingresa un Segundo Apellido válido' });
      count++;
    }
    if (!inputs.birth_country || inputs.birth_country.length === 0) {
      handleError({ birth_country: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.birth_state || inputs.birth_state.length === 0) {
      handleError({ birth_state: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.birth_city || inputs.birth_city.length === 0) {
      handleError({ birth_city: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.birthdate || inputs.birthdate.length < 1) {
      handleError({ birthdate: 'No es una fecha válida' });
      count++;
    } else if (!validateBirthDate(inputs.birthdate)) {
      handleError({ birthdate: 'Debes ser mayor de edad para ser empleador' });
      count++;
    }
    if (!inputs.gender || inputs.gender.length === 0) {
      handleError({ gender: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.id_type || inputs.id_type.length === 0) {
      handleError({ id_type: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.id_num || inputs.id_num.length < 5 || !validateNumber(inputs.id_num)) {
      handleError({ id_num: 'Ingresa un No. de documento válido' });
      count++;
    }
    if (!inputs.id_expedition_date || inputs.id_expedition_date.length < 1) {
      handleError({ id_expedition_date: 'No es una fecha válida' });
      count++;
    } else if (!validateDateIsGreater(inputs.id_expedition_date, inputs.birthdate)) {
      handleError({ id_expedition_date: 'La fecha de expedición debe ser posterior a la fecha de Nacimiento' });
      count++;
    }
    if (!inputs.id_expedition_place || inputs.id_expedition_place.length === 0) {
      handleError({ id_expedition_place: 'Este campo es obligatorio' });
      count++;
    }
    if (!inputs.marital_status || inputs.marital_status.length === 0) {
      handleError({ marital_status: 'Este campo es obligatorio' });
      count++;
    }
    
    if (!inputs.street_one || inputs.street_one.length < 8 || inputs.street_one.length > 100) {
      handleError({ street_one: 'No es una dirección válida'});
      count++;
    }

    if (!inputs.state) {
      handleError({ state: 'No es un departamento válido'});
      count++;
    }

    if (!inputs.phone) {
      handleError({ phone: 'No es un departamento válido'});
      count++;
    }

    if (!inputs.city) {
      handleError({ city: 'No es una ciudad válida'});
      count++;
    }

    return count;
  }

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h4 className='text-center color-symplifica my-5' >Diligencia los datos básicos de tu empleado</h4>
          </Col>
        </Row>
        <Form
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Primer nombre'
                name="first_name"
                value={inputs.first_name}
                onChange={handleInputChange}
                error={errors.first_name}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Segundo nombre'
                onChange={handleInputChange}
                name="middle_name"
                value={inputs.middle_name}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Primer apellido'
                name="last_name"
                value={inputs.last_name}
                onChange={handleInputChange}
                error={errors.last_name}
              />
            </Col>
          </Row>
          <Row>
          <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Segundo apellido'
                name="mothers_name"
                value={inputs.mothers_name}
                onChange={handleInputChange}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                default_option='País de nacimiento'
                name="birth_country"
                options={[{ text: "Colombia", value: "57" }]}
                onChange={handleInputChange}
                value={inputs.birth_country}
                error={errors.birth_country}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                default_option='Departamento'
                options={choices.listStates}
                name="birth_state"
                value={inputs.birth_state}
                error={errors.birth_state}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  handleInputChange(event);
                  changeBirthCitiesList(event.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                options={listCitiesBirth}
                default_option='Ciudad'
                name="birth_city"
                value={inputs.birth_city}
                error={errors.birth_city}
                onChange={handleInputChange}
                disabled={loading}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputDate
                placeholder='Fecha de nacimiento'
                maxDate={new Date()}
                name="birthdate"
                value={inputs.birthdate}
                error={errors.birthdate}
                onChange={(name, date) => {
                  setInputChange(name, date);
                }}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <GroupForm text="Género" error={errors.gender}>
                <div className="d-sm-table-cell px-2"><InputCheck checked={inputs.gender === "F"} onChange={handleInputChange} name="gender" text="Femenino" value="F" id="female" /></div>
                <div className="d-sm-table-cell px-2"><InputCheck checked={inputs.gender === "M"} onChange={handleInputChange} name="gender" text="Masculino" value="M" id="male" /></div>
              </GroupForm>
            </Col>
          </Row>
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                options={listDocTypes}
                default_option='Tipo documento'
                name="id_type"
                value={inputs.id_type}
                error={errors.id_type}
                onChange={handleInputChange}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Número documento'
                name="id_num"
                type={"number"}
                value={inputs.id_num}
                error={errors.id_num}
                onChange={handleInputChange}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputDate
                placeholder='Fecha de Expedición'
                maxDate={new Date()}
                name="id_expedition_date"
                value={inputs.id_expedition_date ? new Date(inputs.id_expedition_date) : ""}
                error={errors.id_expedition_date}
                onChange={(name, date) => {
                  setInputChange(name, date);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                options={listCitiesBirth}
                default_option='Lugar de expedición'
                name="id_expedition_place"
                value={inputs.id_expedition_place}
                error={errors.id_expedition_place}
                onChange={handleInputChange}
                disabled={loading}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                default_option='Estado Civil'
                options={listMaritalStatus}
                name="marital_status"
                value={inputs.marital_status}
                error={errors.marital_status}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className='text-center my-2'>Datos de contacto</h3>
            </Col>
          </Row>
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Dirección de residencia'
                name="street_one"
                value={inputs.street_one}
                onChange={handleInputChange}
                error={errors.street_one}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Ejemplo: Apartamento 00 Interior 00'
                onChange={handleInputChange}
                name="street_two"
                value={inputs.street_two}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                default_option='Departamento'
                options={choices.listStates}
                name="state"
                value={inputs.state}
                error={errors.state}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  handleInputChange(event);
                  changeCitiesList(event.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='col-lg-4 col-12 my-2'>
              <InputSelect
                default_option='Ciudad'
                options={listCities}
                name="city"
                value={inputs.city}
                error={errors.city}
                onChange={handleInputChange}
                disabled={loading}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Celular'
                onChange={handleInputChange}
                name="phone"
                value={inputs.phone}
                error={errors.phone}
              />
            </Col>
            <Col className='col-lg-4 col-12 my-2'>
              <InputText
                placeholder='Correo electrónico'
                name="email"
                value={inputs.email}
                onChange={handleInputChange}
                error={errors.email}
              />
            </Col>
          </Row>
          <Row className='d-lg-flex justify-content-center my-5' >
            <Col className='col-lg-4' >
              <SubmitButton 
                text='Guardar' 
                block
                disabled={loading}
                spinner={loading}
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}