import React from 'react';
import { connect } from 'react-redux';

// Boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CONTAINER
import {AuthContainer} from '../../../../shared/containers';

// Components
import { Notify } from '../../../../shared/components';

// Form
import {ChangePasswordForm} from './forms';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

const ChangePassword = ({history, forgotPass}) => {

    const { showNotify, handleNotify } = useNotifyState();

    return(
        <AuthContainer >
            <Notify {...showNotify} />
            <Col>
                <Row >
                    <Col >
                        <h3 className='color-symplifica text-center'>Ingresa tu nueva contraseña</h3>
                    </Col>
                </Row>
                <Row>
                    <ChangePasswordForm
                        handleNotify={handleNotify}
                        history={history}
                        forgotPass={forgotPass}
                    />
                </Row>
            </Col>
        </AuthContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        forgotPass: state.forgotPass
    };
}

const ChangePasswordPage = connect(mapStateToProps, null)(ChangePassword);

export {ChangePasswordPage};