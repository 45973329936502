import React, { useState } from 'react';

import { Card, Row, Col, ListGroup, ListGroupItem} from 'react-bootstrap';

// Interface
import { Invoice, Invoice_Detail } from 'shared/interfaces';

// Utils
import { dolarize } from 'shared/utils/utils';
import { SYBadge } from '../SYBadge';

interface CardInvoiceProps {
    label_text: string;
    invoice: Invoice;
    selected: boolean;
    setSelected: (value:string) => void;
}

export const CardInvoice: React.FC<CardInvoiceProps> = ({ label_text, invoice, selected, setSelected }) => {

    // ==============================================
    /// 
    // ==============================================
    const invoiceDetail = (detail:Invoice_Detail) => {
        return (
            <ListGroupItem key={detail.id}>
                <Row>
                    <Col xs={8}>{detail.description}</Col>
                    <Col xs={4}>{dolarize(detail.amount)}</Col>
                </Row>
            </ListGroupItem>
        );
    }

    // ==============================================
    /// 
    // ==============================================
    const setStateBadge = (state:string = "1") => {

        const newstate: any = {
            "1": {text: "Por pagar", variant: "danger"},
            "2": {text: "Pago en proceso", variant: "warning"},
            "3": {text: "Pago en proceso", variant: "warning"},
            "4": {text: "Pago en proceso", variant: "warning"},
            "5": {text: "En mora", variant: "info"},
            "6": {text: "En mora", variant: "info"},
        };

        return (
            <SYBadge text={newstate[state].text} variant={newstate[state].variant} />
        )
    }

    return (
        <Card className = "card-invoice-container my-2" >
            <Card.Header>
                <Row>
                    <Col xs={2} className="padding-half d-flex justify-content-center" onClick={() => setSelected(invoice.id)}>
                        {
                            selected
                                ? <img className="image-selected" src="/icons/full_circle.svg" alt="circle" />
                                : <img className="image-selected" src="/icons/circle.svg" alt="circle" />
                        }
                    </Col>
                    <Col xs={3} className="padding-half label_text">{label_text}</Col>
                    <Col xs={4} className="padding-half d-flex align-items-center">{setStateBadge(invoice.state)}</Col>
                    <Col xs={3} className="padding-half d-flex align-items-center card-invoice-value">{dolarize(invoice.total_invoice)}</Col>
                </Row>
            </Card.Header>
            <ListGroup className="list-group-flush">
                {
                    invoice.details ? invoice.details.map(det => invoiceDetail(det) ) : null
                }
            </ListGroup>
        </Card >
    );
}