import React, {useState} from 'react';

// BOOTSTRAP
import {Form, Row, Col} from 'react-bootstrap';

// COMPONENTS
import { 
    InputText, InputTime, InputToggle, InputCheck, GroupForm, SubmitButton 
} from 'shared/components';

// Hooks
import useCustomForm from 'shared/utils/CustomHooks';

// API
import {RegisterRepository} from 'shared/repository';

//CONSTS
import { REGISTER_EMPLOYER_SUMMARY } from 'shared/constants/urls';

const EmployeeCreateForm = ({ contract, history, setPreContract, setContractToEmployer, handleNotify }) =>{

    const [isLoading, setIsLoading] = useState(false);

    // =======================================================
    /// Postea los datos para la creación de un contrato
    // =======================================================
    const createContract = async () => {

        if (validate() > 0) return;

        setIsLoading(true);

        const respuesta = await RegisterRepository.createContract(inputs);
        
        if (respuesta.success) {
            setInputs(prev => ({ ...prev, contract_id: respuesta.data.id}));
            setPreContract(respuesta.data);
            setContractToEmployer(respuesta.data);
            handleNotify('success', 'Contrato creado correctamente');
            window.setTimeout(() => history.push(REGISTER_EMPLOYER_SUMMARY), 2000 );

        } else {
            setIsLoading(false);
            let errorText = respuesta.message || 'Error conectando el servidor';
            handleNotify('danger', errorText);
        }
    }

    // =======================================================
    /// Valida cada campo de del formulario
    // =======================================================
    const validate = () => {
        let count = 0;

        if (
            inputs.works_on_monday === false &&
            inputs.works_on_tuesday === false &&
            inputs.works_on_wednesday === false &&
            inputs.works_on_thursday === false &&
            inputs.works_on_friday === false &&
            inputs.works_on_saturday === false &&
            inputs.works_on_sunday === false
        ) {
            handleError({works: 'Debe seleccionar mínimo un día'});
            count++;
        }

        if (!inputs.start_time || inputs.start_time.length === 0) {
            handleError({ start_time: 'Seleccione una hora de inicio de jornada' });
            count++;
        }

        if (!inputs.end_time || inputs.end_time.length === 0) {
            handleError({ end_time: 'Seleccione una hora de fin de jornada' });
            count++;
        }

        if (!inputs.salary_type || inputs.salary_type.length === 0) {
            handleError({ salary_type: 'Debe seleccionar mínimo un día' });
            count++;
        }

        if (!inputs.salary_type || inputs.salary_type.length === 0) {
            handleError({ salary_type: 'Debe seleccionar mínimo un día' });
            count++;
        }

        if (!inputs.salary || inputs.salary.length === 0) {
            handleError({ salary: 'No es un salario básico' });
            count++;
        } else if (Number(inputs.salary) < 828116) {
            handleError({ salary: 'El salario no puede ser menor al mínimo' });
            count++;
        }

        return count;
    }


    // =======================================================
    /// Defino logica para datos del formulario
    // =======================================================
    const { 
        inputs, 
        handleInputChange, 
        handleSubmit, 
        errors, 
        handleError, 
        setInputs, 
        setInputChange,
        handleCheckChange,
    } = useCustomForm(contract, createContract);

    return (
        <Form
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <Row>

                <Col md={6} sm={12} >

                    <Row className='d-flex flex-column my-3' >
                        <Col>
                            <p className='text-center text-lg-left text-bold' >¿Qué días a la semana trabaja en tu casa?</p>
                        </Col>
                        <Col>
                            <GroupForm error={errors.works}>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_monday" onChange={handleCheckChange} checked={inputs.works_on_monday} text="Lun" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_tuesday" onChange={handleCheckChange} checked={inputs.works_on_tuesday} text="Mar" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_wednesday" onChange={handleCheckChange} checked={inputs.works_on_wednesday} text="Mie" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_thursday" onChange={handleCheckChange} checked={inputs.works_on_thursday} text="Jue" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_friday" onChange={handleCheckChange} checked={inputs.works_on_friday} text="Vie" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_saturday" onChange={handleCheckChange} checked={inputs.works_on_saturday} text="Sab" /></div>
                                <div className="d-sm-table-cell"><InputCheck className="button-circle" type="checkbox" name="works_on_sunday" onChange={handleCheckChange} checked={inputs.works_on_sunday} text="Dom" /></div>
                            </GroupForm>
                        </Col>
                        
                    </Row>

                </Col>
                <Col md={6} sm={12} >
                    <Row className='d-flex flex-column' >
                        <Col>
                            <p className='text-center text-lg-left text-bold' >Horario</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <InputTime
                                        name='start_time'
                                        value={inputs.start_time}
                                        error={errors.start_time}
                                        onChange={setInputChange}
                                    />
                                </Col>
                                <Col>
                                    <InputTime
                                        name='end_time'
                                        value={inputs.end_time}
                                        error={errors.end_time}
                                        onChange={setInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='eso'> 
                <Col md={6} sm={12} >
                    <Row >
                        <Col className='col-sm-12' >
                            <p className='text-center text-lg-left text-bold' >¿Cuál es su salario?</p>
                        </Col>
                        <Col >
                            <GroupForm error={errors.salary_type}>
                                <Row>
                                    <Col md={6} sm={6}>
                                        <InputCheck name="salary_type" onChange={handleInputChange} checked={inputs.salary_type === 'daily'} text="Salario diario" value="daily" id="daily_type" />
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <InputCheck name="salary_type" onChange={handleInputChange} checked={inputs.salary_type === 'monthly'} text="Salario Mensual" value="monthly" id="monthly_type" />
                                    </Col>
                                </Row>
                            </GroupForm>
                        </Col> 
                    </Row>
                    <Row >
                        <Col className='col-sm-6' >
                            <InputText 
                                placeholder='$0.000.000' 
                                name="salary"
                                value={inputs.salary}
                                error={errors.salary}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col className='col-sm-6' >
                            <SubmitButton 
                                className='d-flex align-items-center justify-content-around flex-row mt-1'
                                variant="secondary"
                                block 
                                type="button"
                                onClick={() => setInputs(ipts => ({...ipts, salary: '877803'}))}
                            >
                                <div className="chevron-l"></div>
                                <p className='my-0 color-symplifica' >Usar salario mínimo</p>
                            </SubmitButton>
                        </Col>
                        <Col className='col-sm-12' >
                            <p className='mb-3 sub-text'><small></small>*Este es el salario total de tu empleado. A este valor se le hacen los descuentos de seguridad social.</p>
                        </Col>
                    </Row>
                </Col>
                               
                <Col md={6} sm={12} >
                    <Row>
                        
                        <Col md={7} sm={12} >
                            <Row className='flex-lg-column' >
                                <Col>
                                    <p className='text-bold' >¿Ya inscribiste a tu empleado en la seguridad social a tu nombre?</p>
                                </Col>
                                <Col className="text-md-left text-right">
                                    <InputToggle
                                        name='is_employee_subscribed'
                                        id='is_employee_subscribed'
                                        value={inputs.is_employee_subscribed}
                                        error={errors.is_employee_subscribed}
                                        onChange={handleCheckChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5} sm={12} >
                            <Row className='flex-lg-column' >
                                <Col>
                                    <p className='text-lg-left text-bold' >¿Vive en tu casa?</p>
                                </Col>
                                <Col className="text-md-left text-right">
                                    <InputToggle
                                        name='is_living_in'
                                        value={inputs.is_living_in}
                                        error={errors.is_living_in}
                                        onChange={handleCheckChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
               
            </Row>

            <Row className='d-lg-flex justify-content-center my-5' >
                <Col className='col-lg-4' >
                    <SubmitButton text='Guardar' disabled={isLoading} spinner={isLoading} block />
                </Col>
            </Row>
        </Form>
    )
}

export { EmployeeCreateForm };