// import RegisterRepository from '../repository/register.repository';

import store from '../../redux/storages/global';
import { signInAction } from '../../redux/actions';

// =======================================================
/// Valida el token y redirecciona según la respuesta
// =======================================================
export const doRedirect = async ({ token, refresh_token, email }) => {

    let employerToSave = {};
    let contracts = [];
    if (token) {
        // const empleador = await RegisterRepository.getEmployer(client_id, token);

        // if (empleador.success) {
        //     const { data: employer } = empleador;

        //     if (employer && employer.hasOwnProperty('id') && employer.id) {

        //         contracts = await RegisterRepository.getEmployerContracts(employer.id, token);
        //         const respWorkplaces = await RegisterRepository.getEmployerWorkplaces(employer.id, token);

        //         employerToSave = { ...employer, workplaces: respWorkplaces };
        //     }

        // }
        store.dispatch(signInAction(token, refresh_token, email, employerToSave, contracts));
        
        return true;

    } else
        return false;
}