import React from 'react';

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap';

import { Field } from './Field';
import { SubmitButton } from 'shared/components';

import { Employee } from 'shared/interfaces';

import { valueChoice } from 'shared/utils/utils';

import {TypeChoice} from 'shared/utils/emuns';

interface InfoTabProps {
  employee: Employee;
  infoData: any;
  setEditing: (value: boolean) => void;
  loading?: boolean;
}

export const InfoTab: React.FC<InfoTabProps> = ({ employee, infoData, setEditing, loading }) => {

  const document_type = valueChoice(TypeChoice.ID_TYPES, employee.client?.id_type);
  const gender_text = valueChoice(TypeChoice.GENDER, employee.client?.gender);
  const marital_text = valueChoice(TypeChoice.MARITAL_STATUS, employee.client?.marital_status);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h3 className='text-center color-symplifica mt-5' >Estos son los datos básicos de tu empleado</h3>
          </Col>
        </Row>
        <Row>
          <Field
            title='Nombre'
            value={`${employee.client?.name || ''}`}
          />
          <Field
            title='País de nacimiento'
            value={"Colombia"}
          />
          <Field
            title='Departamento'
            value={infoData.birth_state}
          />
          <Field
            title='Ciudad'
            value={infoData.birth_city}
          />
        </Row>
        <Row>
          <Field
            title='Tipo documento'
            value={document_type}
          />
          <Field
            title='Número documento'
            value={employee.client?.id_num}
          />
          <Field
            title='Lugar de expedición'
            value={infoData?.id_expedition_place}
          />
          <Field
            title='Fecha expedición'
            value={employee.client?.id_expedition_date}
          />
        </Row>
        <Row>
          <Field
            title='Fecha de nacimiento'
            value={employee.client?.birthdate}
          />
          <Field
            title='Género'
            value={gender_text}
          />
          <Field
            title='Estado civil'
            value={marital_text}
          />
        </Row>
        <Row>
          <Col>
            <h4 className='text-center my-2' >Datos de contacto</h4>
          </Col>
        </Row>
        <Row>
          <Field
            title='Dirección de residencia'
            value={employee.client?.address_line1}
          />
          <Field
            title='Detalles de dirección'
            value={employee.client?.address_line2}
          />
          <Field
            title='Departamento'
            value={infoData.state}
          />
          <Field
            title='Ciudad'
            value={infoData.city}
          />
        </Row>
        <Row>
          <Field
            title='Celular'
            value={employee.client?.phone}
          />
          <Field
            title='Correo Electrónico'
            value={employee.client?.email}
          />
        </Row>
        <Row className='d-lg-flex justify-content-center my-4' >
          <Col md={4}>
            <SubmitButton
              text='Editar'
              block
              onClick={() => setEditing(true)}
              disabled={loading}
              spinner={loading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}