import React from 'react';

// BOOTSTRAP
import {Row, Col, Spinner} from 'react-bootstrap';

// ICONS
import UpIcon from 'assets/ico/cont-icon-up.png';
import CheckIcon from 'assets/ico/check-green.png';
import UnCheckIcon from 'assets/ico/check-gray.png';

// IMAGES
import example from 'assets/ico/firma-circle-green.png';

// Interfaces
import { FuncNotify } from 'shared/interfaces';

// Utils
import { validFile } from 'shared/utils/utils';

type DocumentModelName = 'Employer' | 'Employee' | 'Beneficiary';

interface DocumentCardProps {
    id_label: string;
    is_uploaded: boolean;
    document_label: string;
    employee_id?: string; 
    client_id: string; 
    model_name: DocumentModelName; 
    callback: (form_data: FormData, idx: string) => void;
    handleNotify: FuncNotify;
    loading?: boolean;
    iddoc?: string;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ 
    id_label, 
    is_uploaded, 
    document_label, 
    model_name, 
    employee_id, 
    client_id, 
    callback,
    handleNotify,
    loading,
    iddoc
}) => {

    const model_name_choice = {
        'Employer' : '1',
        'Employee' : '2',
        'Beneficiary' : '3',
    }
    const document_label_choice = {
        front_doc: "1",
        back_doc: "2",
        secondary_id: "3",
        tax_id: "4",
        bank_certif: "5",
        relationship_support: "6",
        economic_dependency: "7",
        school_certif: "8",
        last_ss_payment: "9",
        data_autorization: "10",
        sisben_certf: "11",
        retirement_certi: "12"
    };

    // =======================================================
    /// 
    // =======================================================
    const uploadFile = (event) => {
        let form_data = new FormData();

        console.log(event.target.files[0]);
        if (!validFile(event.target.files[0].name))
            handleNotify('danger', 'No es un archivo válido');

        form_data.set('document_label', document_label_choice[document_label]);
        form_data.set('model_name', model_name_choice[model_name]);
        form_data.set('client_id', client_id);
        
        if (employee_id)
            form_data.set('employee_id', employee_id);
        
        form_data.set('document', event.target.files[0]);

        callback(form_data, iddoc || '');

    }

    return (
        <Col className='col-12 col-lg-4 col-md-4' >
            <div className="shadow p-4 my-3 document-card">
                <span >
                    {
                        is_uploaded
                            ? <img src={CheckIcon} alt='check' />
                            : <img src={UnCheckIcon} alt='uncheck' />
                    }
                </span>
                <Row>
                    <Col className='d-flex flex-column justify-content-center align-items-center' >
                        <img src={example} alt='icon' />
                        <p className='text-center mt-4 mb-0' >{id_label}</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className='d-flex justify-content-center' >
                        <label className="pointer">
                            {
                                loading
                                    ? (<Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                    />)
                                    : (<img src={UpIcon} alt='camera' />)
                            }
                            <input type="file" id={`id_${document_label}`} style={{ display: 'none' }} name="file" onChange={uploadFile} />
                        </label>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}
