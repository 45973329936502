import { useState } from 'react';

import { AlertType, TypeNotify, FuncNotify } from 'shared/interfaces';

// =======================================================
/// HOOK estado del alert seleccionado
// =======================================================
const useNotifyState = () => {
    const [showNotify, setDisplay] = useState<TypeNotify>({
        open: false,
        message: '',
        type: 'success',
        invasive: false,
    });

    const handleNotify: FuncNotify = (type: AlertType, message:string, invasive:boolean = false) => {
        setDisplay({ open: true, type, message, invasive });
        window.setTimeout(() => {
            setDisplay(prevState => ({ ...prevState, open: false }));
        }, 2500);

        return;
    }

    return {
        showNotify,
        handleNotify
    };
}

export default useNotifyState;