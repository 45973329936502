import React from 'react';

// COMPONENTS BOOTSTRAP
import { Container, Row, Col, Table } from 'react-bootstrap';

export const LiquidationDoc = () => {

    return(
        <Container>
            <Row>
                <Col>
                    <p>este no es el documento final de liquidación</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 >Liquidación final contrato de trabajo</h4>
                    <p><strong>Detalles de liquidación</strong></p>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Empleador</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p>Nombre empleador</p>
                        </Col>
                        <Col>
                            <p>Tipo documento</p>
                        </Col>
                        <Col>
                            <p>Número documento</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Trabajador</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p>Nombre trabajador</p>
                            <p><strong>Contrato:</strong> termino indefinido</p>
                        </Col>
                        <Col>
                            <p>Tipo documento</p>
                            <p><strong>Cargo:</strong> Empleada domestica</p>
                        </Col>
                        <Col>
                            <p>Número documento</p>
                            <p><strong>Causa de terminación:</strong> Renuncia</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Periodo de liquidación</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p><strong>Días del contrato:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Días de ausentismo:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Total días laborados:</strong> 0000</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Contrato</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p><strong>Días laborados por semana:</strong> 0000</p>
                            <p><strong>Salario mensual:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Días laborados por mes:</strong> 0000</p>
                            <p><strong>Fecha inicio del contrato:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Total días laburados:</strong> 0000</p>
                            <p><strong>Fecha fin del contrato:</strong> 0000</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Vacaciones</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p><strong>Días causados:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Días disfrutados:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Días pendientes:</strong> 0000</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Bases liquidación</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <p><strong>Base cesantías:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Base Prima:</strong> 0000</p>
                        </Col>
                        <Col>
                            <p><strong>Base vacaiones:</strong> 0000</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Row>
                        <Col>
                            <p style={{borderLeft: '5px solid #00CCCB'}} className='color-symplifica pl-2' >Nomina del 00 al 00 del 0000</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <Table  >
                                <thead className='border-0 bg-grey-symplifica ' >
                                    <tr>
                                        <td>
                                            Concepto
                                        </td>
                                        <td>
                                            Días
                                        </td>
                                        <td>
                                            Concepto
                                        </td>
                                        <td>
                                            Deducciones
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sueldo</td>
                                        <td>000</td>
                                        <td>$0'000.000</td>
                                        <td>$0'000.000</td>
                                    </tr>
                                </tbody>
                                <tfoot className='border-0 bg-grey-symplifica ' >
                                    <tr>
                                        <td>Total:</td>
                                        <td></td>
                                        <td>$0'000.000</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{border: '1px solid #00CCCB'}} className='rounded py-2' >
                <Col>
                    <p className='mb-0' >TOTAL LIQUIDACIÓN</p>
                </Col>
                <Col>
                    <p className='mb-0 text-right' >$0'000.000</p>
                </Col>
            </Row>
            <Row >
                <Col>
                    <p className='red-symplifica text-center my-4' ><strong>Esta no es una liquidación final. Es solo una simulación</strong></p>
                </Col>
            </Row>
        </Container>
    )
}