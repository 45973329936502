import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// BOOTSTRAP
import {Container, Row, Col } from 'react-bootstrap';

// CONTAINER
import { RegisterContainer } from '../../../../shared/containers';

// FORMS
import { PromoForm } from './forms';

// Components
import { Notify, BackTitle } from '../../../../shared/components';
import { AccordionPromo } from './components';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

// API
import RegisterRepository from '../../repository/register.repository';

const Promo = ({ client_id, history }) => {

    const { showNotify, handleNotify } = useNotifyState();
    const [promotions, setPromotions] = useState([]);

    const fetchData = async () => {
        const resp = await RegisterRepository.loadPromoCodes();
        if (resp.success) {
            setPromotions(resp.data);
        }
        return;
    }

    useEffect( () => {
        fetchData();
        return;
    }, []);

    // =======================================================
    /// Titulo con icono de acción
    // =======================================================
    const Titulo = () => (
        <>
            <div className='arrow-head-l' style={{cursor: 'pointer'}} alt='arrow down' onClick={() => history.goBack()}></div>
            <span style={{marginLeft: 15}}>Promociones</span>
        </>
    )

    return(
        <RegisterContainer 
            title={<BackTitle history={history} text="Promociones" />} 
            info={{title:'Recuerda', subtitle: 'La dotación de tus empleados deberás hacerla cada 6 meses de acuerdo a la ley.'}} 
            showNotify={showNotify}
        >
            <Container >
                <Row>
                    <Col >
                        <h4 className="color-symplifica text-center my-5" >Escoge qué códigos promocionales quieres aplicar para tu pago</h4>
                    </Col>
                </Row>
                <PromoForm
                    client_id={client_id}
                    handleNotify={handleNotify}
                    fetchData={fetchData}
                />
                <Row >
                    <Col>
                        <p className="my-5 promo-page-title color-symplifica">Mis promociones asignadas</p>
                    </Col>
                </Row>
                {
                    promotions.map(item => <AccordionPromo key={item.id} data={item} onChange={fetchData} handleNotify={handleNotify} /> )
                }
            </Container>
        </RegisterContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        client_id: state.client_id
    }
}

const PromoPage = connect(mapStateToProps, null)(Promo);

export { PromoPage };