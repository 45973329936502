import React, {useState} from 'react';

// BOOTSTRAP
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';

// COMPONENTS
import { InputText, SubmitButton, LinkButton, CompleteModal, closeModal } from '../../../../../shared/components';

// Images
import Google from '../../../../../assets/ico/ico-google.png';
import Facebook from '../../../../../assets/ico/ico-facebook.png';
import Logo from '../../../../../assets/images/logo.png';
import Banner from '../../../../../assets/images/upps.png';

// ICONS
import Eye from '../../../../../assets/ico/cont-icon-pass.png';

// Utils
import { validateEmail, validatePhone } from '../../../../../shared/utils/validate';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// API
import { AccessControl } from '../../../repository/auth.repository';

const LoginForm = ({ handleNotify }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [modalState, changeModalState] = useState(false);
    const [isHidden, setIsHidden] = useState(true)

    // =======================================================
    /// 
    // =======================================================
    const loginGoogle = async (e) => {
        const resp = await AccessControl.loginWithGoogle(e);
        redirectTo(resp);

    }

    // =======================================================
    /// 
    // =======================================================
    const loginFacebook = async (e) => {
        const resp = await AccessControl.loginWithFacebook(e);
        redirectTo(resp);
    }

    // =======================================================
    /// Valida el token y redirecciona según la respuesta
    // =======================================================
    const redirectTo = async (resp) => {
        if (resp && resp.success) {

            const respRedirect = await AccessControl.doRedirect(resp.data.token, resp.data.refresh_token, inputs.email);
            if (respRedirect)
                window.location.reload();
            else
                handleNotify('danger', 'Ups, no se pudo realizar el login', true);

        } else {
            if(resp.message === "No tiene acceso a este recurso")
                handleNotify('danger', "El usuario/contraseña no son válidos", true);
            else if(resp.errors && resp.errors.email === "already exist" && resp.facebook)
                handleNotify('danger', "Este usuario ya existe con una cuenta de google", true);
            else if(resp.errors && resp.errors.email === "already exist" && resp.google)
                handleNotify('danger', "Este usuario ya existe con una cuenta de facebook", true);
            else
                handleNotify('danger', resp.message, true);
        }

        setIsLoading(false);
    }

    // =======================================================
    /// Postea los datos del formulario
    // =======================================================
    const handleSubmitForm = async () => {

        // validar tipo de dato email/telefono
        if (!validateEmail(inputs.email) && !validatePhone(inputs.email)) {
            handleError({'email':'Ingresa un email o número telefónico válidos'});
            return;
        }

        if (!inputs.password || inputs.password.length < 6 ) {
            handleError({ 'password': 'La contraseña no es válida' });
            return;
        }

        setIsLoading(true);
        const resp = await AccessControl.loginMailAndPassword(inputs.email, inputs.password);
        redirectTo(resp);
    }

    // =======================================================
    /// Hook: Manejo de los datos del formulario
    // =======================================================
    const { 
        handleSubmit, handleInputChange, inputs, errors, handleError 
    } = useCustomForm({email: '', password: ''}, handleSubmitForm);

    return(
        <Col>

            <CompleteModal open={modalState} closeAction={()=>closeModal(changeModalState)} >
                <Container  >
                    <Row className='vh-100' >
                        <Col className='w-25 d-flex justify-content-center align-items-center flex-column' >
                            <Image src={Logo} />
                            <h2 className='red-symplifica text-center ' >¡UPPS!</h2>
                            <h3 className='color-symplifica text-center' >Lo sentimos, no eres tú, somos nosotros.</h3>
                            <p>Vuelve en un rato por favor.</p>
                            <Image  className='page50xBanner' src={Banner} />
                            <SubmitButton 
                                text="Ir a inicio" 
                                className='page50xButton'  
                            />
                        </Col>
                    </Row>
                </Container>
            </CompleteModal>

            <Form
                onSubmit={handleSubmit}
            >
                <InputText 
                    placeholder='Celular o correo electrónico' 
                    className='my-3'
                    name='email'
                    error={errors.email}
                    value={inputs.email}
                    onChange={handleInputChange}
                />
                <InputText 
                    placeholder="Contraseña" 
                    className='mt-3'
                    type={isHidden ? "password" : "text"}
                    name='password'
                    value={inputs.password}
                    error={errors.password}
                    onChange={handleInputChange}
                    icon={Eye}
                    onIconClick={()=>setIsHidden(!isHidden)}
                />
                <Col className='d-flex justify-content-center' >
                    <LinkButton href='/auth/forgot-password' className='my-3' text='Olvidé mi contraseña' />
                </Col>
                <div style={{display: 'flex'}}>
                    <SubmitButton
                        text='Ingresar'
                        block
                        disabled={isLoading}
                        spinner={isLoading}
                    />
                </div>
            </Form>

            <p className='text-center my-4' >Inicia con un clic</p>

            <Row>
                <Col className='d-flex justify-content-end' >
                    <SubmitButton 
                        block 
                        className='w-75'
                        onClick={loginFacebook}
                        variant="outline-gray"
                        disabled={isLoading}
                        noShadow
                    >
                        <Image src={Facebook} />
                    </SubmitButton>
                </Col>
                <Col  className='d-flex justify-content-start' >
                    <SubmitButton 
                        block 
                        noShadow
                        className='w-75'
                        onClick={loginGoogle}
                        variant="outline-gray"
                        disabled={isLoading}
                    >
                        <Image src={Google} />
                    </SubmitButton>
                </Col>
            </Row>

        </Col>
    );
}

export {LoginForm};