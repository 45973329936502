import moment from 'moment';

// =======================================================
/// Valida que el usuario de correo sea válido
// =======================================================
export const validateEmail = (email) => {
    const regxEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regxEmail.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    const regxPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
    return regxPhone.test(String(phone));
}

export const validateNumber = (number) => {
    const regxNumber = /^[0-9]*$/g;
    return regxNumber.test(String(number));
}

export const validateName = (name) => {
    const regxName = /^[A-Za-zÁÉÍÓÚñáéíóúÑ]*$/g;
    return regxName.test(String(name));
}

export const validateBirthDate = (birthdate) => {
    const years = moment().diff(birthdate, 'years');
    return years >= 18;
}

export const validateDateIsGreater = (fecha1, fecha2) => {
    if (fecha1 && fecha2) {
        return fecha1 > fecha2;
    } else
        return false;
}

export const filterStatesContracts = (contracts) => {

    const stateContracts = {
        submitted: 0,
        paid: 0,
        created: 0,
    };

    contracts.forEach(key => {
        switch (key.state) {
            case '1':
                stateContracts.created++;
                break;
            case '2':
                stateContracts.paid++;
                break;
            case '8':
            case '9':
                stateContracts.submitted++;
                break;
            default:
                break
        }

    });

    return stateContracts;
}