import React from 'react';

// Bootstrap
import { Form, FormGroup, InputGroup} from 'react-bootstrap';

interface GroupFormProps {
    error?: string;
    text?: string;
    children: React.ReactChild | Array<React.ReactChild>;
}

export const GroupForm: React.FC<GroupFormProps> = ({error, text, children}) => {
    return (
        <FormGroup>
            <InputGroup className="group-inputs-form d-sm-table">
                {
                    text && (
                            <div className="d-sm-table-cell">
                                <span className={`text-group-inputs-form ${error ? 'input-text-error' : ''}`}>
                                {text}
                                </span>
                            </div>
                        )
                }
                {children}
            </InputGroup>
            {
                error && error.length > 0 && (
                    <Form.Text className="text-muted input-text-error">
                        {error}
                    </Form.Text>
                )
            }
        </FormGroup>
    );
};