import React from 'react';

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';

// COMPONENTS
import { SubmitButton } from 'shared/components';

interface ResumeHomeProps {
    onNextClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ResumeHome: React.FC<ResumeHomeProps> = ({onNextClick}) => {

    return(
        <Container >
            <Row >
                <Col xs={12} className="d-flex justify-content-center">
                    <p className="text-center w80">Revisa los cálculos y el proceso de liquidación de contrato, ten en cuenta:</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul >
                        <li><p>Puedes ver los detalles de la liquidación.</p></li>
                        <li><p>Si finalizas el proceso desactivaremos a tu empleado del sistema de seguridad social.</p></li>
                        <li><p>Reporta novedades  cambios antes de completar el proceso de liquidación.</p></li>
                        <li><p>Calcularemos los valores basado en historicos la causa de liquidación.</p></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubmitButton
                        block
                        text="Continuar"
                        onClick={onNextClick}
                    />
                </Col>
            </Row>
        </Container>
    )
}