import React from 'react'
import { Button } from 'react-bootstrap'
import ReportItem from './ReportItem'

const ConflictModal = ({ employees, stateMachine, sendToMachine }:
  {
    employees: [{
      id: string,
      employee: {
        first_name: string,
        last_name: string,
      }
    }],
    stateMachine: {
      context: {
        user: {
          data: {
            message: string;
          }
        },
        employee: {}
      }
    },
    sendToMachine: (arg0: {}) => void
  }) => {
  let reportError = JSON.parse(stateMachine.context.user.data.message)[0]
  let employee = employees.filter(employee => employee.id === stateMachine.context.employee)[0]
  const GrayDate = ({ topText, bottomText }) =>
    <div
      className="bg-grey-symplifica border-r-10"
      style={{
        width: "75%",
        height: "3em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <p
        style={{ margin: "0px", fontSize: 12 }} >
        {topText}
      </p>
      <p
        style={{ margin: "0px", fontSize: 12 }} >
        {bottomText}
      </p>
    </div>
  return (
    <div style={{ paddingTop: 130 }}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
        <img src='/icons/reports/conflict.svg' width="80%" alt="" />
        <h5 className="color-symplifica" style={{ textAlign: "center" }}>
          ¡Novedad existente!
        </h5>
        <p style={{ textAlign: "center" }}>
          Esta novedad tiene fechas en conflicto con las de unas novedades ya existentes. Si deseas reportarla debes borrar las siguientes novedades primero:
        </p>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", }}>
          <h5 style={{ textAlign: "center"}}>
            {reportError.novelty}
          </h5>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplate: "1fr / 1fr 1fr",
              alignItems: "center",
              columnGap: "4%",
              justifyItems: "center",
              marginBottom: "4%"
            }}>
            <GrayDate topText="Fecha inicio" bottomText={reportError.start_date} />
            <GrayDate topText="Fecha fin" bottomText={reportError.end_date} />
          </div>
        </div>
        <Button
          onClick={() => sendToMachine({ type: "VIEWREPORTS" })}
          style={{
            color: 'white',
            backgroundColor: '#FD5C5C',
            borderRadius: 50,
            width: '80%'
          }}>
          Ver Novedades
        </Button>
      </div>
    </div>
  )
}

export default ConflictModal