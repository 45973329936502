import Http from 'shared/utils/Http2';
import { SERVER } from 'shared/constants/app';

import { ResponseHttp2, Choice } from 'shared/interfaces';

export class PaymentRepository {

    // =======================================================
    /// [GET]: Request payment methods registered by client
    // =======================================================
    static async getListPaymentMethods(client_id:string): Promise<Array<Choice>> {
        const url:string = `${SERVER}/enchantress/client/${client_id}/payment_methods/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            return data.map((item:any) => {
                return {
                    value: item.id,
                    text: `${item.instrument.franchise || ''} | ${item.instrument.mask}`
                }
            });
        } else
            return [];
    } // END getListPaymentMethods

    // =======================================================
    /// [POST]: create a account bank to client
    // =======================================================
    static async setAccountBank(formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/create_account/`;
        return await Http.post(url, formData);
    } // END setAccountBank

    // =======================================================
    /// [POST]: register a credit card to a client
    // =======================================================
    static async setCreditCard(formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/create_credit_card/`;
        return await Http.post(url, formData);

    } // END setCreditCard

    // =======================================================
    /// [GET]: Request payment methods registered and automatic debit status by client
    // =======================================================
    static async getInstrumentsData(client_id:string): Promise<any> {

        const account_type: any = {
            '00000000-0000-0000-0000-000000000001': { type: 'Tarjeta de Crédito', name: 'Crédito -' },
            '00000000-0000-0000-0000-000000000002': { type: 'Cuenta Bancaria', name: 'Ahorros -' },
            '00000000-0000-0000-0000-000000000003': { type: 'Cuenta Bancaria', name: 'Ahorros -' },
            '00000000-0000-0000-0000-000000000004': { type: 'Daviplata', name: 'Daviplata' }
        };

        const url:string = `${SERVER}/enchantress/client/${client_id}/payment_info/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const instruments = data.all_instruments ? JSON.parse(data.all_instruments) : [];
            const map_intruments = instruments.map((mtd:any) => {
                let method = {
                    type: '',
                    name: '',
                    amount: '',
                    status: ''
                };

                method.type = account_type[mtd.instrument.account_type].type;
                method.name = `${account_type[mtd.instrument.account_type].name} ${mtd.instrument.franchise || mtd.instrument.account_bank}`;
                method.amount = mtd.instrument.mask;

                switch (mtd.state) {
                    case '1':
                    case '4':
                    case '10':
                        method.status = 'wait';
                        break;
                    case '6':
                        method.status = 'done';
                        break;
                    default:
                        method.status = 'undone';
                        break;
                }

                return method;
            });

            return {
                debit: data.debit,
                instruments: map_intruments
            };

        } else
            return { debit: false, instruments: [] };
    } // END getInstrumentsData

    // =======================================================
    /// [PUT]: Function to update the flag indicating if the client has the automatic debit feature activated
    // =======================================================
    static async updateAutomaticDebit(client_id:string, is_automatic:boolean): Promise<ResponseHttp2> {
        let url = `${SERVER}/enchantress/client/${client_id}/${is_automatic ? 'activate_debit' : 'deactivate_debit'}/`;
        return await Http.put(url, {});
    }

}