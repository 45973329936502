import React from 'react';
import { connect } from 'react-redux';

// Boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CONTAINER
import { AuthContainer } from '../../../../shared/containers';

// Components
import { Notify } from '../../../../shared/components';

// Form
import {RecoveryPasswordForm} from './forms';

// Hooks
import useNotifyState from '../../../../shared/utils/NotifyHook';

// Actions
import { setForgotPasswordAction } from '../../../../redux/actions';

const RecoveryPassword = ({ history, forgotPass, setForgotPass }) => {

    const { showNotify, handleNotify } = useNotifyState();

    return(
        <AuthContainer >
            <Notify {...showNotify} />
            <Col>
                <Row >
                    <Col >
                        <h3 className='color-symplifica text-center'>Te hemos enviado un código de verificación</h3>
                        <p className='text-center' >Revisa tu correo electrónico o celular e ingresa el código para poder continuar.</p>
                    </Col>
                </Row>
                <Row>
                    <RecoveryPasswordForm
                        history={history}
                        forgotPass={forgotPass}
                        handleNotify={handleNotify}
                        setForgotPass={setForgotPass}
                    />
                </Row>
            </Col>
        </AuthContainer>
    )
}

const mapStateToProps = ({state}) => {
    return {
        forgotPass: state.forgotPass || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForgotPass: (email) => dispatch(setForgotPasswordAction(email))
    }
}

const RecoveryPasswordPage = connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);

export {RecoveryPasswordPage};