import React from 'react';

// BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// IMAGES
import AvatarImg from '../../../assets/ico/cont-icon-user.png';

const CardPrice = ({title, avatar, price}) => {

    return (
        <Row className='card-price-container p-4' >
            <Col className='d-flex align-items-center' >
                <Row className='d-flex align-items-center' >
                   {
                        avatar ?
                            <Col >
                                <img src={AvatarImg} alt='avatar' style={{height: 40}} />
                            </Col>
                        : null
                   }
                    <Col >
                        <p className='my-0' >{title}</p>
                    </Col>
                </Row>
            </Col>
            <Col className='d-flex justify-content-end'  >
                <Row className='d-flex flex-column ' >
                    <Col>
                        <p className='m-0 card-price-top-amount text-right' >Total mensual</p>
                    </Col>
                    <Col >
                        <p className='m-0 card-price-amount bold-grey-symplifica  text-right' >{price}</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export {CardPrice}