import Http from '../../../../shared/utils/Http2';
import { SERVER } from '../../../../shared/constants/app';

export default class ReportsRepository {
  // =======================================================
  /// [GET]: Consulta los datos de contratos de un empleador
  // =======================================================
  static async getEmployerContractsWithEmployeeInfo(employer_id) {
    const url = `${SERVER}/enchantress/employer/${employer_id}/employees_view/`;
    const resp = await Http.get(url);
    if (resp.success) {
      return {
        ...resp,
        data: JSON.parse(resp.data.new_employees)
      }
    }

    return resp;
  }
  // =======================================================
  /// [GET]: Ask for reports by employer
  // =======================================================
  static async getReportsByEmployer(employer_id, isOld = false) {
    const url = `${SERVER}/enchantress/novedades/${employer_id}/`;
    const resp = await Http.get(url);
    if (resp.success) {
      return {
        ...resp,
        data: JSON.parse(resp.data.contracts)
      }
    }

    return resp;
  }
  // =======================================================
  /// [GET]: Ask for oldreports by employer
  // =======================================================
  static async getOldReportsByEmployer(employer_id) {
    const url = `${SERVER}/enchantress/novedades/${employer_id}/old/0/?page=1`;
    const resp = await Http.get(url);
    if (resp.success) {
      return {
        ...resp,
        data: JSON.parse(resp.data.contracts)
      }
    }

    return resp;
  }
  // =======================================================
  /// [GET]: get employee by employee_id
  // =======================================================
  static async getEmployee(employee_id) {
    const url = `${SERVER}/enchantress/employee/${employee_id}/`;
    const resp = await Http.get(url);
    if (resp.success) {
      return {
        ...resp
      };
    }

    return resp;
  }
    // =======================================================
  /// [GET]: get employee holidays info
  // =======================================================
  static async getEmployeeVacations(employee_id) {
    const url = `${SERVER}/enchantress/novedades/${employee_id}/incidence/vacations/`;
    const resp = await Http.get(url);
    if (resp.success) {
      return {
        ...resp
      };
    }

    return resp;
  }

  // =======================================================
  /// [DELETE]: Delete a Report
  // =======================================================
  static async deleteReportById(type, report_id) {
    const url = `${SERVER}/enchantress/novedades/${type}/${report_id}/`;
    const resp = await Http.reqDelete(url, { type });
    return resp;
  }

  // =======================================================
  /// [POST]: Create a Bonus Report
  // =======================================================
  static async createBonus(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/bonus/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create an Extra Time Report
  // =======================================================
  static async createExtraTime(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/extrahours/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Worked Days Report
  // =======================================================
  static async createWorkedDays(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/workeddays/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Holidays Report
  // =======================================================
  static async createHolidays(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/vacations/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Permission Report
  // =======================================================
  static async createPermission(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/leave/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Licence Report
  // =======================================================
  static async createLicence(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/licence/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Incapacity Report
  // =======================================================
  static async createIncapacity(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/incapacity/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
    return resp
  }

  // =======================================================
  /// [POST]: Create a Absence Report
  // =======================================================
  static async createAbsence(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/absence/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty);
      return {
        ...resp,
        data
      }
    }
    return resp
  }
  // =======================================================
  /// [POST]: Create a Loan Report
  // =======================================================
  static async createLoan(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/lending/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.new_novelty)
      return {
        ...resp,
        data
      }
    }
  }
   // =======================================================
  /// [POST]: Create a Advance Report
  // =======================================================
  static async createAdvance(contract_id, formData) {
    const url = `${SERVER}/enchantress/novedades/${contract_id}/incidence/advance/`;
    const resp = await Http.post(url, formData);
    if (resp.success) {
      const data = JSON.parse(resp.data.advance)
      return {
        ...resp,
        data
      }
    }
  }
}


