import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useMachine } from '@xstate/react';
import ReportsMachine from "./stateMachine"
import ReportsRepository from './repository';

// BOOTSTRAP
import { Container, Accordion } from 'react-bootstrap'

// CONTAINER
import { AppContainer, RegisterContainer } from '../../../../shared/containers'
import { CreateNewReportPage } from './CreateNewReport';
import EmptyState from './ReportsComponents/EmptyState';
import AddNewReportButton from './ReportsComponents/AddNewReportButton';
import PriorReports from './ReportsComponents/PriorReports';
import CurrentReports from './ReportsComponents/CurrentReports';
import DeleteModal from './ReportsComponents/DeleteModal';
import AddedModal from './ReportsComponents/AddedModal';
import ConflictModal from './ReportsComponents/ConflictModal';
import { HELP_CENTER_REPORTS_WHAT_ARE_REPORTS } from 'shared/constants/app';
import useNotifyState from 'shared/utils/NotifyHook';

// COMPONENTS


const Reports = ({ employer }) => {
  const [reports, setReports] = useState({ current: [], prior: [] })
  const [employees, setEmployees] = useState([])
  const [employeeDropdown, setEmployeeDropdown] = useState([])
  const [stateMachine, sendToMachine] = useMachine(ReportsMachine)
  const [currentReports, setCurrentReports] = useState([])
  const [priorReports, setPriorReports] = useState([])
  const [deleteCounter, setDeleteCounter] = useState(0)
  const { showNotify, handleNotify } = useNotifyState()

  const sendToMachineAndRefetch = (command) => {
    sendToMachine(command)
    if (command.type === "COMPLETE") setDeleteCounter(1)
  }
  useEffect(() => {
    // calls the endpoint
    async function getData() {
      let newData = await ReportsRepository.getReportsByEmployer(employer.id)
      setReports(newData.data)
      setEmployees(newData.data)
      let currentArray = []
      newData.data.map(employee => {
        let fullData = employee.employee
        Array.isArray(fullData.incidences_special)
          && fullData.incidences_special.length
          && fullData.incidences_special.map(special => {
            currentArray.push({ ...special, type: "", isDeletable: false })
          })
        Array.isArray(fullData.incidences)
          && fullData.incidences.length
          && fullData.incidences.map(incidence => {
            (currentArray.push({ ...incidence, type: "", isDeletable: true }))
          })
        Array.isArray(fullData.loans)
          && fullData.loans.length
          && fullData.loans.map(loan => {
            (currentArray.push({ ...loan, type: "loans", isDeletable: true }))
          })
      })
      setCurrentReports(currentArray)
      let oldData = await ReportsRepository.getOldReportsByEmployer(employer.id)
      let priorArray = []
      oldData.data.map(employee => {
        let fullData = employee.employee
        Array.isArray(fullData.incidences)
          && fullData.incidences.length
          && fullData.incidences.map(incidence => {
            priorArray.push({ ...incidence, type: "" })
          })
        Array.isArray(fullData.loans)
          && fullData.loans.length
          && fullData.loans.map(loan => {
            priorArray.push({ ...loan, type: "loans" })
          })
      })
      setPriorReports(priorArray)

    }
    setDeleteCounter(0)
    getData()

  }, [employer.id, deleteCounter])

  useEffect(() => {
    setEmployeeDropdown(employees.filter(employee => employee.employee).map(employee => {
      return {
        text: `${employee.employee.first_name} ${employee.employee.last_name}`,
        value: employee.id
      }
    })
    )
  }, [employees])
  useEffect(() => {
    if (stateMachine.context.notification) handleNotify('success', stateMachine.context.notification)
  }, [stateMachine.context.notification])
  const stateMatcher = () => {
    let currentView = <></>
    if (stateMachine.matches("delete"))
      currentView = <DeleteModal
        setDeleteCounter={setDeleteCounter}
        stateMachine={stateMachine}
        employees={employees}
        reports={currentReports}
        sendToMachine={sendToMachine} />
    else if (stateMachine.matches("home")) {
      currentView =
        <AppContainer title="Novedades" >
          {reports && (currentReports.length || priorReports.length)
            ?
            <>
              <a
                href={HELP_CENTER_REPORTS_WHAT_ARE_REPORTS}
                style={{ width: "100%", textAlign: "center", display: "flex", alignContent: "center", justifyContent: "flex-end", marginBottom: 15 }}>
                <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
                <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
                  ¿Qué son las novedades?
                </p>
              </a>
              <p style={{ textAlign: "center", fontSize: 12, fontWeight: 300 }}>
                Estas son las novedades que has reportado para tus empleados. Puedes agregar nuevas o eliminar vigentes.
              </p>
              <AddNewReportButton onClick={sendToMachine} />
              <Accordion defaultActiveKey="0">
                <CurrentReports
                  employees={employees}
                  sendToMachine={sendToMachine}
                  reports={currentReports} />
                <PriorReports
                  employees={employees}
                  reports={priorReports} />
              </Accordion>
            </>
            :
            <>
              <a
                href={HELP_CENTER_REPORTS_WHAT_ARE_REPORTS}
                style={{ width: "100%", textAlign: "center", display: "flex", alignContent: "center", justifyContent: "flex-end", marginBottom: 15 }}>
                <img alt="" src="/icons/reports/interrogation-circle.svg" style={{ height: "1em", width: "1em", marginTop: "auto", marginBottom: "auto" }} />
                <p className="color-symplifica" style={{ marginTop: "auto", marginBottom: "auto" }}>
                  ¿Qué son las novedades?
                </p>
              </a>
              <EmptyState />
              <AddNewReportButton onClick={sendToMachine} />
            </>}
        </AppContainer>
    }
    else if (stateMachine.matches({ addNewReport: "acceptedReport" }))
      currentView = <AddedModal
        employees={employees}
        stateMachine={stateMachine}
        sendToMachine={sendToMachine} />
    else if (stateMachine.matches({ addNewReport: "rejectedReport" }))
      currentView = <ConflictModal
        employees={employees}
        stateMachine={stateMachine}
        sendToMachine={sendToMachine} />
    else if (!!!stateMachine.matches({ selectEmployee: {} }))
      currentView = <CreateNewReportPage
        employeeDropdown={employeeDropdown}
        employees={employees}
        employer={employer}
        stateMachine={stateMachine}
        sendToMachine={sendToMachineAndRefetch} />

    return currentView
  }
  return (
    <RegisterContainer title='Novedades' showNotify={showNotify}>
      <Container style={{ height: '100%', paddingRight: 0 }}>
        {stateMatcher()}
      </Container>
    </RegisterContainer >
  )
}

const mapStateToProps = ({ state }) => {
  return {
    employer: state.employer,
    client_id: state.client_id
  };
}

const ReportsPage = connect(mapStateToProps, null)(Reports);

export { ReportsPage }
