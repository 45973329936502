import Http from 'shared/utils/Http2';
import { SERVER } from 'shared/constants/app';

import {Contract, Employer, Employee} from 'shared/models';

import { ResponseHttp2, Choice } from 'shared/interfaces';
import { isJsonString } from 'shared/utils/utils';

export class RegisterRepository {

    // =======================================================
    /// ** CLIENT **
    // =======================================================

    // =======================================================
    /// [GET]: Consulta Obtiene los datos de un empleador
    // =======================================================
    static async getClient(client_id:string):Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/client/${client_id}/`;
        return await Http.get(url);
    }

    // =======================================================
    /// ** EMPLOYER **
    // =======================================================

    // =======================================================
    /// [POST]: crea los datos del registro en su primera parte
    // =======================================================
    static async createEmployer(formData: any): Promise<ResponseHttp2> {

        const url:string = `${SERVER}/enchantress/employer/`;
        return await Http.post(url, formData);
    }

    // =======================================================
    /// [GET]: Consulta Obtiene los datos de un empleador
    // =======================================================
    static async getEmployer(client_id: string, token: string = ''): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/client/${client_id}/employer/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        const employer = resp.data.length > 0 ? resp.data[0] : {};
        return {
            ...resp,
            data: employer
        };
    }

    // =======================================================
    /// [GET]: Get list of employees by employer
    // =======================================================
    static async getEmployerEmployees(employer_id: string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employer/${employer_id}/employees_view/`;
        const resp:ResponseHttp2 = await Http.get(url);

        if(resp.success) {
            const { new_employees, active_employees } = resp.data;
            return {
                ...resp,
                data: {
                    active_employees: active_employees ? JSON.parse(active_employees) : [],
                    new_employees: new_employees ? JSON.parse(new_employees) : [],
                }
            }
        }

        return resp;
    }

    // =======================================================
    /// [GET]: Consulta Obtiene los lugares de trabajo de un empleador
    // =======================================================
    static async getEmployerWorkplaces(client_id: string, token: string = ''): Promise<Array<any>> {
        const url:string = `${SERVER}/enchantress/employer/${client_id}/workplaces/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        return resp.success ? resp.data : [];
    }

    // =======================================================
    /// [GET]: Request choise of employer workplaces
    // =======================================================
    static async getChoiceEmployerWorkplaces(client_id: string): Promise<Array<Choice>> {
        const url:string = `${SERVER}/enchantress/employer/${client_id}/workplaces/?fields=id,name&pagination=0`;
        const resp = await Http.get(url);
        if (resp.success) {
            const { data } = resp;
            return data.map((item:any) =>
                ({ value: item.id, text: item.name })
            );
        } else
            return [];
    }

    // =======================================================
    /// [GET]: Consulta los datos de contratos de un empleador
    // =======================================================
    static async getEmployerContracts(employer_id: string, token: string = ''): Promise<Array<any>> {
        const url:string = `${SERVER}/enchantress/employer/${employer_id}/contracts/`;
        const headers = token ? { Authorization: token } : {};
        const resp = await Http.get(url, { headers });

        return resp.success ? resp.data : [];
    }

    // =======================================================
    /// [GET]: Consulta el workspace por defecto
    // =======================================================
    static async getDefaultWorkspace(employer_id: string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employer/${employer_id}/workplace/`;
        let resp = await Http.get(url);

        const workplace = resp.data && resp.data.length > 0 ? resp.data[0] : {};
        return {
            ...resp,
            data: workplace
        };

    }

    // =======================================================
    /// [POST]: Set a ARL entity to a employer
    // =======================================================
    static async setArlEmployer(formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employer/ss_entity/`; //#PENDING
        const resp = await Http.post(url, formData);

        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            return {
                ...resp,
                data: contract
            };
        } else
            return resp;
    }

    // =======================================================
    /// ** END EMPLOYER **
    // =======================================================

    // =======================================================
    /// ** CONTRACT **
    // =======================================================

    // =======================================================
    /// [POST]: Crea un contrato por defecto
    // =======================================================
    static async createContract(formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/contract/`;
        const resp = await Http.post(url, formData);

        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            return {
                ...resp,
                data: contract
            };
        } else
            return resp;
    }

    // =======================================================
    /// [GET]: Consulta los datos de contrato de un empleado
    // =======================================================
    static async getContract(contract_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/contract/${contract_id}/?pagination=0&depth=1`;
        const resp = await Http.get(url);
        if (resp.success)
            return {
                ...resp,
                data: resp.data[0] || {}
            };

        return resp;
    }

    // =======================================================
    /// [GET]: Request the salary data from a contract employee
    // =======================================================
    static async getContractSalary(contract_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/contract/${contract_id}/salary/`;
        const resp = await Http.get(url);
        if (resp.success) {
            const contract = resp.data.contract ? JSON.parse(resp.data.contract) : {};
            return {
                ...resp,
                data: {
                    base_salary: `${Number(contract.base_salary)}` || '',
                    salary_in_kind_value: `${contract.salary_in_kind_value}` || '',
                    bonus_list: contract.bonus_list || [],
                }
            }
        } else {
            return resp;
        }
    }

    // =======================================================
    /// [PUT]: Update the employee contract data
    // =======================================================
    static async updateContract(contract_id:string, formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/contract/${contract_id}/`;
        const resp = await Http.put(url, formData);
        return resp;
    }

    // =======================================================
    /// [PUT]: Update the salary data from a contract employee
    // =======================================================
    static async updateContractSalary(contract_id:string, formData:any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/contract/${contract_id}/salary/`;
        return await Http.put(url, formData);
    }

    // =======================================================
    /// ** END CONTRACT **
    // =======================================================

    // =======================================================
    /// [POST]: Finaliza el proceso del checkout
    // =======================================================
    static async checkout(payment_subscription_id: string): Promise<ResponseHttp2> {
        const resp = await Http.post(`${SERVER}/enchantress/checkout/`, { payment_subscription_id });

        if (resp.success) {
            const { data } = resp;
            const invoice = JSON.parse(data.invoice);
            return {
                ...resp,
                data: invoice
            };
        } else {
            return resp;
        }
    }

    // =======================================================
    /// [GET]: Consulta los items de shopping cart de un cliente
    // =======================================================
    static async shoppingCart(): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/shopping_cart/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            let shoppingcart_items = data.shoppingcart_items ? JSON.parse(data.shoppingcart_items) : [];
            let totals_list = data.totals_list ? JSON.parse(data.totals_list) : {};
            return {
                ...resp,
                data: {
                    shoppingcart_items,
                    totals_list,
                }
            }
        } else {
            return {
                ...resp,
                data: {
                    shoppingcart_items: [],
                    totals_list: [],
                }
            }
        }

    }

    // =======================================================
    /// [GET]: Consulta los items de promociones asignadas a un cliente
    // =======================================================
    static async loadPromoCodes(): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/load_promocodes/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            const assigned_promocodes = data.assigned_promocodes ? JSON.parse(data.assigned_promocodes) : [];
            return {
                ...resp,
                data: assigned_promocodes
            };
        } else {
            return {
                ...resp,
                data: []
            };
        }
    }

    // =======================================================
    /// [POST]: Agrega un código de referido a un cliente
    // =======================================================
    static async applyReferralCode(formData: any): Promise<ResponseHttp2> {
        return await Http.post(`${SERVER}/enchantress/apply_referal_code/`, formData);
    };

    // =======================================================
    /// [POST]: Agrega una promoción a un cliente
    // =======================================================
    static async setPromo(formData: any): Promise<ResponseHttp2> {
        return await Http.post(`${SERVER}/enchantress/promo_codes/validate/`, formData);
    };

    // =======================================================
    /// [POST]: Asigna una promoción registrada a un cliente
    // =======================================================
    static async applyPromoCode(formData: any): Promise<ResponseHttp2> {
        return await Http.post(`${SERVER}/enchantress/promo_codes/apply/`, formData);
    };

    // =======================================================
    /// [POST]: Asigna una promoción registrada a un cliente
    // =======================================================
    static async unapplyPromoCode(formData: any): Promise<ResponseHttp2> {
        return await Http.post(`${SERVER}/enchantress/promo_codes/apply/`, formData);
    };

    // =======================================================
    /// [GET]: Consulta los items de invoice para mostrar resumen
    // =======================================================
    static async getInvoices(invoice_id: string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/invoice/${invoice_id}/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const { data } = resp;
            let items: any[] = [];
            let promo_codes: any[] = [];
            let items_value:number = 0;
            let promo_codes_value:number = 0;
            data.items.forEach((key:any) => {
                items_value += Number(key.amount);
                items.push({ amount: key.amount, name: key.description });
            });
            data.promo_codes.forEach((key:any) => {
                promo_codes_value += Number(key.amount);
                promo_codes.push({ amount: key.amount, name: key.description });
            });

            let listItems:any = {
                items,
                promo_codes,
                items_value,
                promo_codes_value,
            };


            return {
                ...resp,
                data: listItems
            }
        } else
            return resp;

    } static async getDashboardData(contract_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/load_onboarding/?contract_id=${contract_id}`;
        const resp = await Http.get(url);

        if (resp.success) {
            const contract = resp.data.contract ? JSON.parse(resp.data.contract) : new Contract();
            const employer = resp.data.employer ? JSON.parse(resp.data.employer) : new Employer();
            const employee = resp.data.employee ? JSON.parse(resp.data.employee) : new Employee();

            return {
                ...resp,
                data: {
                    contract: {
                        ...contract,
                        employee: employee,
                        employer: employer,
                        employee_id: contract.employee,
                        employer_id: contract.employer,
                    },
                    employer,
                    employee
                }
            }
        }

        return resp;
    }


    // =======================================================
    /// [GET]: Ask by employer and employee documents status
    // =======================================================
    static async getEmployeeSuscriptions(contract_id:string): Promise<Array<any>> {
        const url:string = `${SERVER}/enchantress/contract/${contract_id}/ss_subscriptions_view/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const suscriptions = resp.data && resp.data.ss_subsystems ? JSON.parse(resp.data.ss_subsystems) : [];
            return suscriptions;
        } else
            return [];

    }

    // =======================================================
    /// [GET]: Request the beneficiaries by employee
    // =======================================================
    static async getEmployeeBeneficiaries(employer_id: string): Promise<Array<any>> {
        const url:string = `${SERVER}/enchantress/employee/${employer_id}/beneficiaries/?pagination=0&depth=2&is_active=true`;

        const resp = await Http.get(url);

        if (resp.success) {
            return resp.data;
        } else
            return [];

    }


    // =======================================================
    /// [GET]: get employee by employee_id
    // =======================================================
    static async getEmployee(employee_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employee/${employee_id}/`;
        return await Http.get(url);
    }


    // =======================================================
    /// [GET]: get full data of employee by employee_id
    // =======================================================
    static async getFullEmployee(employee_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employee/${employee_id}/complete_data/`;
        const resp:ResponseHttp2 = await Http.get(url);

        if( resp.success ) {
            const { employee, client_employee} = resp.data;
            return {
                ...resp,
                data: {
                    ...employee,
                    client: client_employee
                }
            }
        }

        return resp;

    }

    // ==============================================
    /// Return data of employee and employee_client
    // ==============================================
    static async getEmployeeClient(employee_id: string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/client/employee/${employee_id}/`;
        const resp: ResponseHttp2 = await Http.get(url);

        if(resp.success) {

            let employee = JSON.parse(resp.data.employee);
            let employee_client = JSON.parse(resp.data.employee_client);

            return {
                ...resp,
                data: {
                    ...employee,
                    client: {...employee_client}
                }
            }
        }else {
            return {
                ...resp,
                data: new Employee(),
            }
        }

    }

    // =======================================================
    /// [POST]: create employee by employee_id
    // =======================================================
    static async createEmployee(formData: any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employee/`;
        return await Http.post(url, formData);
    }

    // =======================================================
    /// [PUT]: create employee by employee_id
    // =======================================================
    static async updateEmployee(formData: any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employee/${formData.employee_id}/`;
        return await Http.put(url, formData);
    }

    // =======================================================
    /// [DELETE]: request delete a beneficiary
    // =======================================================
    static async deleteBeneficiary(beneficiary_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/beneficiary/${beneficiary_id}/`;
        return await Http.reqDelete(url);
    }

    // =======================================================
    /// [DELETE]: Delete a Report
    // =======================================================
    static async deleteReportById(type:string, report_id:string): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/novedades/${type}/${report_id}`;
        const resp = await Http.reqDelete(url);
        if (resp.success) {
            const contract = JSON.parse(resp.data.contract);
            const employer = JSON.parse(resp.data.employer);
            return {
                ...resp,
                data: {
                    contract,
                    employer
                }
            }
        }else
            return resp;

    }

    // =======================================================
    /// [GET]: get employee inscriptions per employer
    // =======================================================
    static async getEmployeeSocialSecurity(employer_id:string): Promise<ResponseHttp2> {

        const url:string = `${SERVER}/enchantress/employer/${employer_id}/ss_employee_subscriptions/`;
        const resp = await Http.get(url);

        console.log(resp);
        
        if (resp.success) {
            let incoming = resp.data.contracts ? JSON.parse(resp.data.contracts) : [];
            return {
                ...resp,
                data: incoming
            };
        }
        
        return resp;
    }

    // =======================================================
    /// [POST]: Set ARL suscription to employer
    // =======================================================
    static async setEmployerArlSuscription(formData:any): Promise<ResponseHttp2> {

        const url:string = `${SERVER}/enchantress/employer_arl_subscription/`;
        return await Http.post(url, formData);
    }

    /// [POST]: Set SS entity suscription to employer
    // =======================================================
    static async setEmployerSecuritySuscription(formData: any): Promise<ResponseHttp2> {

        const url:string = `${SERVER}/enchantress/employer_ss_subscription/`;
        return await Http.post(url, formData);
    }

    // =======================================================
    /// [POST]: Create a employee's beneficiary
    // =======================================================
    static async setEmployerBeneficiary(formData:any): Promise<ResponseHttp2> {

        const url:string = `${SERVER}/enchantress/beneficiary/`;
        return await Http.post(url, formData);
    }

    // =======================================================
    /// [POST]: upload employee documents
    // =======================================================
    static async getDocumentsEmployee(formData: any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employee/documents/`;
        const resp = await Http.post(url, formData);

        if (resp.success && resp.data) {
            const contract = isJsonString(resp.data.contract) && resp.data.contract 
                ? JSON.parse(resp.data.contract) : {};
            const beneficiary_documents = isJsonString(resp.data.beneficiary_documents) && resp.data.beneficiary_documents 
                ? JSON.parse(resp.data.beneficiary_documents) : [];
            const employee_documents = isJsonString(resp.data.employee_documents) && resp.data.employee_documents 
                ? JSON.parse(resp.data.employee_documents) : [];
            const beneficiaries = isJsonString(resp.data.beneficiaries) && resp.data.beneficiaries 
                ? JSON.parse(resp.data.beneficiaries) : [];

            const beneficiaries_list = beneficiaries.reduce((prev, data) => {

                let documentos: any[] = [];

                beneficiary_documents.forEach( (bdoc:any[]) => {
                    const filtro_bdoc = bdoc.filter((filtro) => filtro.beneficiary_id && filtro.beneficiary_id == data.id );
                    if (filtro_bdoc.length > 0) {
                        documentos = bdoc.filter((bdf: any) => bdf.hasOwnProperty('id_label') );
                    }
                } );

                return [...prev, {...data, docs: documentos}];

            }, []);

            const newResp = {
                ...resp,
                data: {
                    contract,
                    employee_documents,
                    beneficiaries: beneficiaries_list,
                }
            }

            return newResp;
        }

        return resp;
    }

    // =======================================================
    /// [POST]: upload employer documents
    // =======================================================
    static async getDocumentsEmployer(employer_id: string): Promise<ResponseHttp2> {
        const url: string = `${SERVER}/enchantress/employer/${employer_id}/documents/`;
        const resp = await Http.get(url);

        if (resp.success) {
            const needed_documents = isJsonString(resp.data.needed_documents) && resp.data.needed_documents 
                ? JSON.parse(resp.data.needed_documents) : [];

            const newResp = {
                ...resp,
                data: needed_documents
            }

            return newResp;
        }

        return resp;
    }

    // =======================================================
    /// [GET]: Consulta los datos de contratos de un empleador
    // =======================================================
    static async getEmployerContractsWithEmployeeInfo(employer_id:string): Promise<Array<any>> {
        const url:string = `${SERVER}/enchantress/employer/${employer_id}/contracts_with_employee_info/`;
        const resp = await Http.get(url);

        return resp.success ? resp.data : [];
    }

    /// [POST]: upload employee documents
    // =======================================================
    static async uploadDocuments(formData: any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/save_document/`;
        return await Http.postFile(url, formData);
    }


    // =======================================================
    /// [POST]: Finish the register process
    // =======================================================
    static async activateEmployees(employer_id: string, formData: any): Promise<ResponseHttp2> {
        const url:string = `${SERVER}/enchantress/employer/${employer_id}/activate/`;
        return await Http.post(url, formData);
    }

}


