import React, { useState } from 'react';

// BOOTSTRAP
import {Form, Col} from 'react-bootstrap';

// COMPONENTS
import { InputText, SubmitButton } from '../../../../../shared/components';

// Hooks
import useCustomForm from '../../../../../shared/utils/CustomHooks';

// Utils
import { validateEmail, validatePhone } from '../../../../../shared/utils/validate';

// API
import { AccessControl } from '../../../repository/auth.repository';

const ForgotPasswordForm = ({ handleNotify, history, setForgotPass }) => {

    const [isLoading, setIsLoading] = useState(false);

    // =======================================================
    /// Valida si el dato es un correo o celular válido
    // =======================================================
    const validate = () => {
        if (validateEmail(inputs.email)) {
            return 'email';
        } else {
            if (validatePhone(inputs.email)) {
                return 'cellphone';
            } else {
                return '';
            }
        }
    }

    // =======================================================
    /// Postea los datos según sea correo o celular
    // =======================================================
    const submitForm = async () => {

        const valido = validate();

        if (valido.length === 0) {
            handleError({ email: 'El correo/celular no es válido' });
            return;
        }

        setIsLoading(true);
        const resp = await AccessControl.forgotPass(inputs.email, valido);

        if (resp.success) {
            setForgotPass({ email: inputs.email, type: valido });

            history.push('/auth/recovery-password');
        } else {
            setIsLoading(false);
            handleNotify('danger', 'El correo/celular no existe', true);
        }

    }

    const {
        inputs,
        handleInputChange,
        handleSubmit,
        handleError,
        errors,
    } = useCustomForm({ email: '' }, submitForm);

    return (
        <Col >
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <InputText
                    placeholder='Celular o correo electrónico'
                    className='my-3'
                    name="email"
                    value={inputs.email}
                    error={errors.email}
                    onChange={handleInputChange}
                />
                <SubmitButton
                    text='Recuperar contraseña'
                    variant="primary"
                    disabled={isLoading}
                    spinner={isLoading}
                    block
                />
            </Form>
        </Col>
    )
}

export { ForgotPasswordForm };