import React from 'react';

import { CompleteModal, SubmitButton } from 'shared/components';

import { Container, Row, Col, Form } from 'react-bootstrap';

// API
import {RegisterRepository} from 'shared/repository/register.repository';

// Interface
import { FuncNotify } from 'shared/interfaces';

interface ModalDeleteBeneficiaryProps {
    id: string;
    open: boolean;
    showModal: (value:boolean) => void;
    handleNotify: FuncNotify;
    callback: () => void;
}

export const ModalDeleteBeneficiary: React.FC<ModalDeleteBeneficiaryProps> = ({ open, showModal, handleNotify, id, callback }) => {

    // =======================================================
    /// post data to the server, add or update EPS entity to employee
    // =======================================================
    const confirmDelete = async (event) => {
        event.preventDefault();

        const resp = await RegisterRepository.deleteBeneficiary(id);

        if (resp.success) {
            handleNotify("success", resp.message);
            callback();
        } else
            handleNotify("danger", resp.message);

        showModal(false);

    }

    return (
        <CompleteModal open={open} closeAction={(event) => showModal(false)}>
            <Container>
                <div className='max-w-600 mx-auto'>
                    <Form>
                        <Row>
                            <Col className='text-center my-5'>
                                <h4 className='color-symplifica'>¿Estás seguro de eliminar el beneficiario de tu empleado?</h4>
                                <p className='text-bold'>Recuerda que perderas la información que ya hayas generado.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SubmitButton
                                    type="reset"
                                    block
                                    variant="secondary"
                                    text="Cancelar"
                                    onClick={() => showModal(false)}
                                />
                            </Col>
                            <Col>
                                <SubmitButton
                                    type="button"
                                    block
                                    variant="primary"
                                    text="Aceptar"
                                    onClick={ confirmDelete }
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </CompleteModal>
    );
};