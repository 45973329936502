import React from 'react';

// BOOTSTRAP
import {Button, Spinner} from 'react-bootstrap';

interface SubmitButtonProps {
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark' | 'outline-light';
    children?: React.ReactChild;
    text?: string;
    block?: boolean;
    className?: string;
    type?: "submit" | "button" | "reset";
    disabled?: boolean;
    spinner?: boolean;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    noShadow?: boolean;
}


export const SubmitButton: React.FC<SubmitButtonProps> = ({noShadow ,variant, block, children, text, className, type = "submit", disabled, spinner, style, onClick=()=>{}}) =>{

    return(
        <Button
            className={`SubmitButton ${noShadow ? 'btn-no-shadow' : ''} ${className ? className : ''}`}
            variant={variant}
            block={block}
            type={type}
            onClick={onClick}
            disabled={disabled}
            style={{
                ...style
            }}
            
        >
            {
                spinner && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                        />
                    )
            }
            {
                children ? 
                    children
                :
                    text
            }
        </Button>
    )
}