import React from 'react';

// BOOTSTRAP
import {Row, Accordion, Col} from 'react-bootstrap';

// ICONS
import ArrowIcon from '../../../assets/ico/cont-icon-arrow.png';
import { dolarize } from '../../utils/utils';

const AccordionConfirmCheckout = ({data=[]}) => {

    return (
        <div>
            {
                data.map((item, i) =>{
                    return(
                        <Row key={`accordion_item_${i}`} className='accordion-confirm-checkout-border-bottom' >
                            <Col>
                                <Accordion className='py-2' >
                                    <Accordion.Toggle className='py-3' as={Row} eventKey={i} >
                                            <Col className='d-flex align-items-center' >
                                                <p className='my-0' >{item.name}</p>
                                            </Col>
                                            <Col className='d-flex align-items-center justify-content-end' >
                                                <p className='my-0 mr-2' >{dolarize(item.amount)}</p>
                                                <img src={ArrowIcon} alt='arrow down' />
                                            </Col>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={i}  >
                                        <h1>hola</h1>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Col>
                        </Row>
                    )
                })
            }
        </div>
    )
}

export { AccordionConfirmCheckout };