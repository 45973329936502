import React from 'react';
import PropTypes from 'prop-types';

import { CompleteModal } from '../../../../../shared/components';

import { Container, Row, Col } from 'react-bootstrap';


const ModalMandato = ({ open, showModal, name, document_type, document }) => {


    return (
        <CompleteModal open={open} closeAction={() => showModal(false)}>
            <Container>
                <Row className='d-block d-lg-flex justify-content-center my-5'  >
                    <Col className='col-12 col-lg-6 text-center'>
                        <h3 className='color-symplifica'>Mandato Symplifica</h3>
                    </Col>
                </Row>
                <Row className="d-lg-flex justify-content-center">
                    <Col>
                        <p>
                        Mediante la presente, yo, {name}, identificado con {document_type} No. {document}, 
                        actuando en nombre propio, en adelante el MANDANTE, confiero a
                        SYMPLIFICA SAS, identificada con NIT 900.862.831-7 (“SYMPLIFICA” o el
                        “MANDATARIO”), un mandato sin representación en las condiciones explicadas a
                        continuación:
                        <br/>
                        1) MANDATO ESPECÍFICO PARA EL MANEJO DE RECURSOS: Confiero a
                        SYMPLIFICA un mandato específico para la administración de los recursos
                        transferidos por mi a través de los medios de pago disponibles en la página web
                        Symplifica.com (la “Página Web”), destinados a que SYMPLIFICA realice pagos
                        según los servicios seleccionados por mi en la Página Web y en el punto 4 de este
                        documento. Por lo tanto, SYMPLIFICA deberá destinar dichos dineros únicamente a
                        aportes a seguridad social y/o pago de nómina de mis trabajadores que hayan sido
                        inscritos y activados por mi en la Página Web, ajustándose estrictamente a las
                        instrucciones dadas por mi a través de la Página Web en cuanto al valor y fecha de los
                        pagos a los trabajadores que yo registre y estén activos o en proceso de liquidación en
                        la misma, y el valor y fecha de los aportes a cada una de las entidades de seguridad
                        social (por medio del operador de información de la P.I.L.A. escogido por
                        SYMPLIFICA). No obstante, como MANDANTE reconozco que dependiendo del
                        medio de pago, la transferencia de los recursos al operador de información de la
                        P.I.L.A. y/o a los trabajadores, según el caso, no es inmediata teniendo en cuenta los
                        ciclos bancarios y los plazos para obtener autorización de las tarjetas de crédito, por lo
                        que se requerirá que las solicitudes de débito a través de la Página Web se realicen
                        con al menos tres (3) días hábiles de antelación a las fechas de vencimiento
                        estipuladas en la normatividad vigente. Si el MANDANTE decidiera realizar el pago
                        de aportes a través de la Página Web después de la fecha de vencimiento que
                        SYMPLIFICA le indique, el MANDANTE autoriza a SYMPLIFICA a liquidar y
                        cobrarle a través de su plataforma, en forma anticipada, el valor de los intereses de
                        mora que SYMPLIFICA estime que se generarían ante las entidades del sistema de
                        seguridad social, para que SYMPLIFICA a su vez transfiera los intereses de mora a
                        dichas entidades. EL MANDANTE exonera de toda responsabilidad a SYMPLIFICA
                        por las consecuencias derivadas de la mora en los pagos de los aportes a las entidades
                        del sistema de Seguridad Social Integral.
                        <br/>
                        2) EL MANDANTE autoriza a SYMPLIFICA a debitar el valor de sus servicios, de la
                        cuenta inscrita en SYMPLIFICA al momento de debitar el valor de la nómina o de los
                        <br/>
                        aportes a seguridad social de los trabajadores del MANDANTE. Así mismo,
                        SYMPLIFICA queda autorizado para debitar de la cuenta del MANDANTE,
                        cualquier otra tarifa que se genere por los servicios de SYMPLIFICA.
                        <br/>
                        3) AUTORIZACIÓN DE RECAUDO/DÉBITO A CUENTA: Como titular de la
                        cuenta autorizo incondicionalmente a la Entidad Financiera en que tengo dicha cuenta
                        débito o crédito, para recibir transacciones débito enviadas por SYMPLIFICA y
                        debitar conforme a las siguientes reglas: (i) La Entidad Financiera debitará de la
                        cuenta indicada en la Página Web el valor que corresponde a la transacción, y
                        entregará dicho valor a SYMPLIFICA. (ii) SYMPLIFICA podrá conservar el presente
                        documento en sus oficinas y podrá enviar a la Entidad Financiera la información
                        contenida en esta autorización, de manera electrónica. (iii) En aquellos casos en los
                        que SYMPLIFICA tenga inconvenientes técnicos u operativos que no le permitan
                        debitar la cuenta oportunamente, la Entidad Financiera queda autorizada a debitar la
                        cuenta aquí indicada en una fecha diferente a la inicialmente prevista. (iv) Me
                        comprometo a mantener fondos suficientes en la cuenta indicada para cubrir las
                        operaciones autorizadas a SYMPLIFICA; si la cuenta no tuviera fondos disponibles
                        en el tiempo indicado para el débito autorizado, este se podrá hacer tan pronto existan
                        fondos disponibles en la cuenta. (v) La Entidad Financiera podrá abstenerse de hacer
                        el débito si no existen fondos disponibles para ello o si se presenta alguna otra causal
                        que lo impida. (vi) La presente Autorización de Recaudo solamente podrá ser
                        cancelada mediante comunicación escrita enviada a SYMPLIFICA y a la sucursal de
                        la Entidad Financiera donde tengo la cuenta, con una anticipación no inferior a diez
                        (10) días hábiles a la fecha a partir de la cual se desee hacer efectiva la cancelación.
                        (vii) En caso de reclamaciones o solicitudes de devolución, estas deberán dirigirse a
                        SYMPLIFICA con copia a la sucursal de la Entidad Financiera donde tengo radicada
                        la cuenta, en un plazo máximo de cuarenta y cinco (45) días calendario a partir de la
                        fecha de aplicación del débito. (viii) Puedo dar una orden de no pago a la sucursal de
                        la Entidad Financiera donde tengo la cuenta, para una transacción específica, con una
                        antelación no inferior a cinco (5) días hábiles antes de la fecha de aplicación del
                        débito; no obstante, reconozco que presentar órdenes de no pago o solicitudes de
                        devolución reiteradas, puede ser causal de cancelación del servicio por parte de
                        SYMPLIFICA o por parte de la Entidad Financiera. (ix) En caso que la cuenta
                        indicada en la Página Web tenga otros titulares, me obligo a proveer la autorización
                        de parte de todos los titulares de la cuenta y adjuntarlas a este documento, o en caso
                        contrario, asumiré las consecuencias que se deriven de no declarar dichos titulares,
                        liberando así a SYMPLIFICA y a la Entidad Financiera de toda responsabilidad. (x)
                        Autorizo a SYMPLIFICA a validar mi identidad cada vez que inscriba personas como
                        beneficiarios de los pagos que se autoricen realizar a través de esta cuenta.
                        <br/>
                        4) AFILIACIONES Y APORTES A ENTIDADES DE SEGURIDAD SOCIAL
                        <br/>

                        (i) En caso de no estar afiliado como EMPLEADOR a las entidades del sistema de
                        seguridad social requeridas, confiero a SYMPLIFICA y/o a las personas naturales a
                        quienes esta sociedad designe, autorización para solicitar ante las entidades del
                        sistema de seguridad social mi afiliación como EMPLEADOR del (de los)
                        TRABAJADOR(ES) registrados por mi en la página web Symplifica.com (la “Página
                        Web”) . Dicha afiliación se realizará con base en la información suministrada por mi a
                        SYMPLIFICA a través de la Página Web o cualquier otro medio. Por lo tanto,
                        SYMPLIFICA y/o las personas naturales a quienes esta sociedad designe, podrán
                        realizar todos los trámites, diligencias y suscripción de documentos que sean
                        necesarios para solicitar y obtener mi afiliación como EMPLEADOR ante las
                        siguientes entidades,
                        (ii) Confiero a SYMPLIFICA autorización para realizar la liquidación, administración
                        y pago de aportes a las entidades de Seguridad Social de mis trabajadores mediante el
                        Operador de Información elegido por SYMPLIFICA, y demás gestiones relacionadas
                        que se requieran ante dichas entidades, conforme a los Términos y Condiciones
                        publicados en la Página Web. El presente mandato conlleva mi autorización para que
                        SYMPLIFICA realice ante el Operador de Información de la P.I.L.A., las entidades de
                        seguridad social y entidades financieras, los trámites, reclamaciones, correcciones y
                        demás gestiones que se requieran para cumplir debidamente con la actividad
                        encomendada.
                        <br/>
                        5) Declaro que la administración de aportes a la seguridad social de mis trabajadores
                        será realizada por SYMPLIFICA en calidad de mandatario y ajustándose a las
                        instrucciones y a la información que yo le haya suministrado. Por lo tanto, en ningún
                        caso podrá considerarse que SYMPLIFICA actúa como patrono o empleador de los
                        trabajadores del MANDANTE.
                        <br/>
                        6) El mandato objeto de este documento es conferido a SYMPLIFICA por el término
                        de mi duración como cliente, y será aplicable a todos los pagos y aportes a seguridad
                        social que el MANDANTE opte por efectuar o autorizar su débito a través de los
                        medios disponibles en la Página Web o en la Aplicación Móvil, respecto de cualquiera
                        de los trabajadores registrados en la misma ahora o en el futuro por el MANDANTE.
                        Este mandato podrá terminar por la renuncia del MANDATARIO o la revocatoria del
                        MANDANTE, en cualquier momento, sin que se genere la obligación de indemnizar a
                        la otra parte por dicha terminación.
                        <br/>
                        7) EL MANDANTE y EL MANDATARIO acuerdan que se reconocerán entre ellos
                        la validez de los documentos que decidan firmar electrónicamente mediante
                        herramientas tecnológicas, siempre y cuando el método utilizado cumpla los
                        requisitos de la Ley 527 de 1999 y el Decreto 2364 de 2012..
                        <br/>
                        En constancia de lo anterior se acepta y envía a SYMPLIFICA SAS el día {new Date().toISOString()}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Harold Pepio Pompilio Pimpollo</p>
                        <p>CC 00000000 Btá</p>
                        <p>Empleador</p>
                    </Col>
                    <Col>
                        <p>Harold Pepio Pompilio Pimpollo</p>
                        <p>CC 00000000 Btá</p>
                        <p>Representante Legal</p>
                    </Col>
                </Row>
            </Container>
        </CompleteModal>
    );
};

ModalMandato.propTypes = {
    open: PropTypes.bool,
};

export { ModalMandato };